import DocumentMeta from 'react-document-meta';
import './styles.css';
import React from 'react';
import Nav from "./nav/index";
import Header from "./header/index";
import Worry from "./worry/index";
import What from "./what/index";
// import System from "./system/index";
import Strong from "./strong/index";
import While from "./while/index";
import Recruit from "./recruit/index";
import Management from "./management/index";
// import Price from "./price/index";
import Question from './question/index';
import Contact from "./contact/index";
import Footer from "../tabs/footer/index";

const MemoriiWorksLanding = () => {

  const meta = {
    title: '美容学生の採用管理サービス（採用担当者様向け） | memorii works',
    description: '美容室へ就職される美容学生へ向けて行きたい美容室が見つかる就活サイト、美容学生を採用したい方へ向けて新卒採用・管理が行えるサービス',
    canonical: 'https://memorii-works.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <Header />
      <Worry />
      <Strong />
      <What />
      <While />
      <Recruit />
      <Management />
      {/* <Price /> */}
      <Question />
      <Contact />
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default MemoriiWorksLanding;

import DocumentMeta from 'react-document-meta';
import React, {useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import Header from '../header/index';
import Search from '../search/index';
import Styles from './styles.module.css';
import Item from './../item/index';
import { onSnapshot } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Registration from './../registration/index';

const Footer = () => {

  const [ userList, setUserList ] = useState([]);
  const [ recruitList, setRecruitList ] = useState([]);

  const [ pref, setPref ] = useState('');
  const [ station, setStation ] = useState([]);
  const [ salary, setSalary ] = useState('');
  const [ post, setPost ] = useState('新卒');
  const [ style, setStyle ] = useState([]);

  const [ modal, setModal ] = useState(false);

  var link = new URL(window.location).href;
  var linkPlace = decodeURIComponent(link.slice(link.indexOf('search=') + 7, link.indexOf('+salary'))).split(',');
  var linkSalary = decodeURIComponent(link.slice(link.indexOf('+salary=') + 8, link.indexOf('+post')))
  var linkPost = decodeURIComponent(link.slice(link.indexOf('+post=') + 6, link.indexOf('+style=')));
  var linkStyle = decodeURIComponent(link.slice(link.indexOf('+style=') + 7, link.indexOf('+page='))).split(',');

  useEffect(() => {
    getRecruit();

    setPref(linkPlace[0]);
    linkPlace.shift();
    setStation(linkPlace);
    setSalary(Number(linkSalary));
    setPost(linkPost);
    setStyle(linkStyle);

    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        const docRef = firebase.firestore().collection('users').doc(user.uid)
        const unsub = onSnapshot(docRef, doc => {
          setUserList({...doc.data(), id: doc.id});
        })
        return unsub;
      }
    })
  }, [])

  function getRecruit() {

    var place = linkPlace.filter(function(s){return s !== '';});
    var style = linkStyle.filter(function(s){return s !== '';});

    // console.log(place);
    // console.log(linkSalary);
    // console.log(linkPost);
    // console.log(style);

    var searchTags = [ ...place, linkSalary === '0' ? '' : linkSalary, linkPost, ...style ];
    searchTags = searchTags.filter(function(s){return s !== '';});

    firebase.firestore().collection('recruits').where('recruit_searchTags', 'in', [ '神奈川県' ]).limit(20).get()
    .then(snapShot => {
      var recruits = [];
      snapShot.forEach((doc) => {
        recruits.push({...doc.data(), id: doc.id });
        console.log('fdsa');
      });
      setRecruitList(recruits);
    });


    // 都道府県
    // if (place.length === 0) {
    //   firebase.firestore().collection('recruits').limit(20).get()
    //   .then(snapShot => {
    //     var recruits = [];
    //     snapShot.forEach((doc) => {
    //       if (doc.data().status === 1 && linkSalary !== '0' && linkPost !== '' && style.length !== 0) {
    //         console.log(1);
    //         if (
    //           doc.data().monthlySalaryStart >= Number(linkSalary) || 
    //           doc.data().employmentStatus === linkPost || 
    //           doc.data().hashTags.filter(x => style.indexOf(x) !== -1).length !== 0) {
    //             recruits.push({...doc.data(), id: doc.id })
    //         }
    //       } else if (doc.data().status === 1 && linkSalary !== '0' && linkPost !== '') {
    //         if (
    //           doc.data().monthlySalaryStart >= Number(linkSalary) || 
    //           doc.data().employmentStatus === linkPost) {
    //             recruits.push({...doc.data(), id: doc.id })
    //         }
    //       } else if (doc.data().status === 1 && linkSalary !== '0' && style.length !== 0) {
    //         if (
    //           doc.data().monthlySalaryStart >= Number(linkSalary) || 
    //           doc.data().hashTags.filter(x => style.indexOf(x) !== -1).length !== 0) {
    //             recruits.push({...doc.data(), id: doc.id })
    //         }
    //       } else if (doc.data().status === 1 && linkPost !== '' && style.length !== 0) {
    //         if (
    //           doc.data().employmentStatus === linkPost || 
    //           doc.data().hashTags.filter(x => style.indexOf(x) !== -1).length !== 0) {
    //             recruits.push({...doc.data(), id: doc.id })
    //         }
    //       } else if (doc.data().status === 1 && linkSalary !== '0') {
    //         if (doc.data().monthlySalaryStart >= Number(linkSalary)) {
    //           recruits.push({...doc.data(), id: doc.id })
    //         }
    //       } else if (doc.data().status === 1 && linkPost !== '') {
    //         if (doc.data().employmentStatus === linkPost) {
    //           recruits.push({...doc.data(), id: doc.id })
    //         }
    //       } else if (doc.data().status === 1 && style.length !== 0) {
    //         if (doc.data().hashTags.filter(x => style.indexOf(x) !== -1).length !== 0) {
    //           recruits.push({...doc.data(), id: doc.id })
    //         }
    //       } else if (doc.data().status === 1) {
    //         recruits.push({...doc.data(), id: doc.id })
    //       }
    //     });
    //     setRecruitList(recruits);
    //   });
    // } else {
    //   firebase.firestore().collection('recruits').where('stationTags', 'array-contains-any', place).limit(20).get()
    //   .then(snapShot => {
    //     var recruits = [];
    //     snapShot.forEach((doc) => {
    //       if (doc.data().status === 1 && linkSalary !== '0' && linkPost !== '' && style.length !== 0) {
    //         console.log(1);
    //         if (
    //           doc.data().monthlySalaryStart >= Number(linkSalary) || 
    //           doc.data().employmentStatus === linkPost || 
    //           doc.data().hashTags.filter(x => style.indexOf(x) !== -1).length !== 0) {
    //             recruits.push({...doc.data(), id: doc.id })
    //         }
    //       } else if (doc.data().status === 1 && linkSalary !== '0' && linkPost !== '') {
    //         console.log(2);
    //         if (
    //           doc.data().monthlySalaryStart >= Number(linkSalary) || 
    //           doc.data().employmentStatus === linkPost) {
    //             recruits.push({...doc.data(), id: doc.id })
    //         }
    //       } else if (doc.data().status === 1 && linkSalary !== '0' && style.length !== 0) {
    //         console.log(3);
    //         if (
    //           doc.data().monthlySalaryStart >= Number(linkSalary) || 
    //           doc.data().hashTags.filter(x => style.indexOf(x) !== -1).length !== 0) {
    //             recruits.push({...doc.data(), id: doc.id })
    //         }
    //       } else if (doc.data().status === 1 && linkPost !== '' && style.length !== 0) {
    //         console.log(4);
    //         if (
    //           doc.data().employmentStatus === linkPost || 
    //           doc.data().hashTags.filter(x => style.indexOf(x) !== -1).length !== 0) {
    //             recruits.push({...doc.data(), id: doc.id })
    //         }
    //       } else if (doc.data().status === 1 && linkSalary !== '0') {
    //         console.log(9);
    //         if (doc.data().monthlySalaryStart >= Number(linkSalary)) {
    //           recruits.push({...doc.data(), id: doc.id })
    //         }
    //       } else if (doc.data().status === 1 && linkPost !== '') {
    //         console.log(10);
    //         if (doc.data().employmentStatus === linkPost) {
    //           recruits.push({...doc.data(), id: doc.id })
    //         }
    //       } else if (doc.data().status === 1 && style.length !== 0) {
    //         console.log(11);
    //         if (doc.data().hashTags.filter(x => style.indexOf(x) !== -1).length !== 0) {
    //           recruits.push({...doc.data(), id: doc.id })
    //         }
    //       } else if (doc.data().status === 1) {
    //         recruits.push({...doc.data(), id: doc.id })
    //       }
    //     });
    //     setRecruitList(recruits);
    //   }); 
    // }
    
  }


  // linkSalary
  // linkPost
  // linkStyle

  const meta = {
    title: `${linkPlace === '' ? '' : linkPlace} ${linkPost === '' ? '' : linkPost} ${linkStyle === '' ? '' : linkStyle}で検索 | memorii works`,
    // description: '美容室へ就職される美容学生へ向けて行きたい美容室が見つかる就活サイト、美容学生を採用したい方へ向けて新卒採用・管理が行えるサービス',
    canonical: 'https://memorii-works.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Header />
      <div className={Styles.box}>
        <Search 
          pref={pref}
          station={station}
          salary={salary}
          post={post}
          style={style}
        />
        <div className={Styles.contents}>
          <div className={Styles.title}>
            <p>ヒット数：{recruitList.length}件</p>
            <div>
              <h4>新着</h4>
              <h4>おすすめ</h4>
              <h4>人気</h4>
            </div>
          </div>
          <div className={Styles.results}>
            {recruitList.length === 0 ?
              <h4 className={Styles.list_none}>指定した条件で美容室を検索しましたが、該当する美容室がございませんでした。</h4>
            : <></> }
            {recruitList.map((data, index) =>
              <Item 
                setModal={setModal}

                userList={userList}
                recruitList={data} 
                pref={pref}
                station={station}
                style={style}
              />
            )}
          </div>
        </div>
      </div>

      {modal ?
        <Registration setModal={setModal} />
      : <></> }
    </DocumentMeta>
    </>
  );
}

export default Footer;

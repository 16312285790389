import React from 'react';
import Styles from './styles.module.css';

const DefaultInput1 = (props) => {

  return (
    <>
    <div className={Styles.title}>
      <div>
        <p>{props.title}</p>
        <div className={ props.required ? Styles.title_yes : Styles.title_no }>{props.required ? '※必須' : '任意'}</div>
      </div>
      <p></p>
    </div>
    <div className={Styles.input}>
      <div>
        <input placeholder='' type='number' value={props.value1} onChange={(e) => props.setValue1(e.target.value)} />
        <p>{props.unit}</p>
      </div>
      <p>〜</p>
      <div>
        <input placeholder='' type='number' value={props.value2} onChange={(e) => props.setValue2(e.target.value)} />
        <p>{props.unit}</p>
      </div>
    </div>
    <p className={Styles.desc}>{props.desc}</p>
    </>
  );
}

export default DefaultInput1;

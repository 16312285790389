
import './styles.css';

const SalonTopic = (props) => {
  
  return (
    <>
    <div className='works_service_manage_contents_examination_table_score'>
      <div className='memorii_recruit_body_edit_area_content_item_title'>
        <p>{props.count}.評価：{props.title}</p>
      </div>
      <div className='works_service_manage_contents_examination_table_score_results'>
        <p style={props.score === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
        <p style={props.score === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
        <p style={props.score === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
        <p style={props.score === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
      </div>
    </div>
    <p className='works_service_manage_contents_examination_table_score_desc'>{props.comment}</p>
    </>
  );
}

export default SalonTopic;

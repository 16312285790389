import Styles from './styles.module.css';

const MemoriiWorksManageBar = (props) => {

  return (
    <>
    {1 <= props.count && props.count <= 8 ?
      <p className={Styles.box}>1</p>
    : <></> }
    {2 <= props.count && props.count <= 8 ?
      <p className={Styles.box}>2</p>
    : <></> }
    {3 <= props.count && props.count <= 8 ?
      <p className={Styles.box}>3</p>
    : <></> }
    {4 <= props.count && props.count <= 8 ?
      <p className={Styles.box}>4</p>
    : <></> }
    {5 <= props.count && props.count <= 8 ?
      <p className={Styles.box}>5</p>
    : <></> }
    {6 <= props.count && props.count <= 8 ?
      <p className={Styles.box}>6</p>
    : <></> }
    {7 <= props.count && props.count <= 8 ?
      <p className={Styles.box}>7</p>
    : <></> }
    {8 === props.count ?
      <p className={Styles.box}>8</p>
    : <></> }
    </>
  );
}

export default MemoriiWorksManageBar;

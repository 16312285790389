import React from 'react';
import { useState } from 'react';
import { LoadScript } from '@react-google-maps/api';

const Footer = () => {

  const [ place, setPlace ] = useState();
  const [ lat, setLat ] = useState();
  const [ lng, setLng ] = useState();

  const changeLocationName = (event) => {
    if (event.key === 'Enter') {
      geocode();
      return;
    }
    setPlace(event.target.value)
  }

  function geocode() {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: place }, (results, status) => {
      if (status === 'OK') {
        // console.log(results[0].geometry.location);
        const { lat, lng } = results[0].geometry.location.lat();
        setLat(results[0].geometry.location.lat());
        setLng(results[0].geometry.location.lng());

        getNearest(results[0].geometry.location.lng(), results[0].geometry.location.lat(), );
      }
    });
  }

  function getNearest(code1, code2) {
    console.log(code1, code2);

    fetch(`https://maps.googleapis.com/maps/api/directions/json?origin=東京駅&destination=スカイツリー&key=AIzaSyD11R3CwdaCQwjGTnM9dvwLCqPfJ-gjH_s`)
    .then(response => response.json())
    .then(data => {
      console.log('data');
      console.log(data);
    })
    .catch(error => {
      console.error('リクエストエラー:', error);
    });

    // 36.06012597014898, 139.50959998465578

  }

  return (
    <>
      <div>
        <input type="text" 
          onChange={(event) => changeLocationName(event)} 
          onKeyPress={(event) => changeLocationName(event)}
        />
        <LoadScript googleMapsApiKey={'AIzaSyD11R3CwdaCQwjGTnM9dvwLCqPfJ-gjH_s'}></LoadScript>
        <p>緯度：{lat}</p>
        <p>経度：{lng}</p>

        {/* 実行結果
          input:福岡県
          緯度：33.566255900000010000
          経度：130.715857
        */}
       </div>
    </>
  );
}

export default Footer;

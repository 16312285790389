import authStyles from './styles.module.css';
import inputStyles from './input.module.css';
import firebase from 'firebase/compat/app';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../header/index';
import { FaEye } from "react-icons/fa";
import Modal from '../../../service/package/parts/pop-modal/index';
import SignInGoogle from './connect/1-google';
import SignInApple from './connect/4-apple';

const WorksLogin = () => {
  
  const history = useHistory();

  const [ error, setError ] = useState('');

  const [ registerEmail, setRegisterEmail ] = useState('');
  const [ registerPassword, setRegisterPassword ] = useState('');

  const [ passwordEye, setPasswordEye ] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (user.auth.currentUser.emailVerified) {
          history.push('/student');
        } else {
          history.push('/student/confirm');
        }
      }
    });
  }, [history]);

  function check() {
    setError('');
    if (registerEmail === '') {
      setError('mail');
    } else if (registerPassword.length <= 7) {
      setError('pass');
    } else {
      Register();
    }
  }

  const Register = async () => {
    await firebase.auth().signInWithEmailAndPassword(registerEmail, registerPassword)
    .then(() => { 
      success();
    })
    .catch((error) => {
      setError(true);
    });
  };

  function success() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (user.auth.currentUser.emailVerified) {
          // history.push('/student');
          studentAuth(user.uid);
        } else {
          history.push('/student/confirm');
        }
      } else {
        history.push('/student/login');
      }
    });
  }

  function studentAuth(uid) {
    firebase.firestore().collection('users').doc(uid).get()
    .then((doc) => {
      if (doc.exists) {
        history.push(`/student`);
      } else {
        firebase.firestore().collection('users').doc(uid)
        .set({
          user_name: '',
          user_like_count : 0,
          user_award : 0,
          user_likes : [],
          user_text : '',
          user_uid : uid,
          user_createdAt : new Date(),
          user_scout : false,
          user_address : [],
          user_address_postalCode: [],
          user_badge: 0,
          user_business: "美容師",
          user_recruit: false,
          user_token: "",
          user_connection : [],
          user_connection_count : 0,
          user_connection_permission : [],
          user_connection_request : [],
          user_likes_list : [],
          user_login : '',
          user_login_count : [],
          user_notification : [],
          user_notification_confiram : false,
          user_post_like_count : 0,
          user_post_view_count : 0,
          user_views : '',
          user_post_count : 0,
          user_recruit_save: [],
          user_instagram : '',
          user_tiktok : '',
          memorii_score : 0,
        }).then(() => {
          history.push(`/student`);
        });
      }
    });
  }



  return (
    <>
    <Header />
    <div className={authStyles.background}>
      <div className={authStyles.contents}>

        <h2 className={authStyles.contents_title}>ログイン</h2>

        <p className={inputStyles.input_title}>メールアドレス</p>
        <div className={inputStyles.input_input}>
          <input placeholder='memorii@trankllc.com' value={registerEmail} onChange={(e) => setRegisterEmail(e.target.value)} />
        </div>
        <p className={inputStyles.input_error} style={error === 'pass' ? { color : '#AD0200' } : { color : '#EBEBEB' }}>
          ※メールアドレスを入力してください。
        </p>
        
        <p className={inputStyles.input_title}>パスワード</p>
        <div className={inputStyles.input_input}>
          <input placeholder='Memorii*' type={passwordEye ? '' : 'password'} value={registerPassword} onChange={(e) => setRegisterPassword(e.target.value)} />
          <FaEye className={inputStyles.input_icon} onClick={() => setPasswordEye(!passwordEye)} />
        </div>

        <p className={inputStyles.input_error} style={error === 'pass' ? { color : '#AD0200' } : { color : '#EBEBEB' }}>
          ※英数字8文字以上のパスワードを入力してください。
        </p>

        <button className={authStyles.contents_btn} onClick={() => check()}>ログインする</button>

        <p className={authStyles.contents_link} onClick={() => history.push('/student/signin')}>
          アカウントをお持ちではない方はこちら
        </p>
        <p className={authStyles.contents_link} onClick={() => history.push('/student/reset')}>
          パスワードをお忘れの方はこちら
        </p>

        <div className={authStyles.contents_link}></div>

        <p className={authStyles.contents_desc}>外部サービスで新規登録/ログインする</p>

        <div className={authStyles.contents_connect}>
          <SignInGoogle setError={setError} />
          <SignInApple setError={setError} />
        </div>

      </div>
    </div>

    {error ?
      <Modal 
        title={'ログイン エラー'}
        desc={'メールアドレス、もしくはパスワードが間違っています。\nまた、アカウント登録がお済みではない方は「アカウントをお持ちではない方はこちら」より、アカウントを作成してください。'}
        setError={setError}
      />
    : <></> }
    </>
  );
}

export default WorksLogin;
import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import Header from './../2.edit/6.header/index';
import MakeStore from './2.make/index';
import EditStore from './3.edit/index';
import DeleteStore from './4.delete/index';
import DefaultImage from './../../../../image/works/default/home.png';
import { useHistory } from 'react-router-dom';
import firebase from "firebase/compat/app";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { FaChevronRight } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore';

const WorksEdit = () => {
  
  const history = useHistory();
  
  var link = new URL(window.location).href;

  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var recruit = decodeURIComponent(link.slice(link.indexOf('recruitId=') + 10, link.length));

  const [ storeList, setStoreList ] = useState([]);
  const [ makeStore, setMakeStore ] = useState(false);
  const [ deleteStore, setDeleteStore ] = useState('');

  const [ desc, setDesc ] = useState('');
  const [ editInfo, setEditInfo ] = useState([]);

  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));

  useEffect(() => {

    console.log(new Date().getDate());

    window.scrollTo(0, 0);
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        getRecruits();
      } else {
        history.push('/company/login')
      }
    })

  }, [history]);

  function getRecruits() {

    const docRef = collection(firebase.firestore(), 'stores')
    const queryRef = query(docRef, where('companyId', '==', company))
    const queryRefs = query(queryRef, orderBy('number', 'asc'))
    const unsub = onSnapshot(queryRefs, snapshot => {
      var tmp = [];
      snapshot.forEach((doc) => {
        tmp.push({...doc.data(), id: doc.id })
      });
      setStoreList(tmp);
    })
    return () => unsub();
  }


  return (
    <>
    <Header />

    <div className={Styles.box} onClick={(e) => e.target === e.currentTarget && setDesc('')}>

      <h4 className={Styles.store_title}>店 舗 編 集</h4>

      <div className={Styles.store_list}>
        {storeList.map((data, index) =>
          <div className={Styles.store_list_items} key={index}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/stores%2Fresize_images%2F${data.image}_500x500?alt=media&token=?${new Date().getTime()}`}
              onError={e => {
                e.target.onError = null;
                e.target.src = DefaultImage;
              }}/>
            <div className={Styles.store_list_items_right}>
              <div className={Styles.store_list_items_right_top}>
                <h4>{data.name}</h4>
                <div className={Styles.store_list_items_right_top_details}>
                  <BsThreeDots className={Styles.store_dots} onClick={() => setDesc(data.id)} />
                  {desc === data.id ?
                    <div>
                      <p onClick={() => setEditInfo(data)}>編集する</p>
                      <p onClick={() => setDeleteStore(data.id)}>削除する</p>
                    </div>
                  : <></> }
                </div>
              </div>

              <div className={Styles.store_list_items_right_bottom}>
                <div onClick={() => history.push(`/edit/area/id=${company}+page=2+storeId=${data.id}`)}
                  style={data.status_place ? {border : '1px solid #222222', boxShadow : 'none'} : {border : '1px solid #AD0200'}}>
                  <p style={data.status_place ? {color : '#222222'} : {color : '#AD0200'}}>最寄り駅</p>
                  {data.status_place ?
                    <FaRegCheckCircle className={Styles.store_items_icons_red} />
                    :
                    <FaChevronRight className={Styles.store_items_icons} />
                  }
                </div>
                <div onClick={() => history.push(`/edit/pentagon/id=${company}+page=2+storeId=${data.id}`)}
                  style={data.status_pentagon ? {border : '1px solid #222222', boxShadow : 'none'} : {border : '1px solid #AD0200'}}>
                  <p style={data.status_pentagon ? {color : '#222222'} : {color : '#AD0200'}}>六角形</p>
                  {data.status_pentagon ?
                    <FaRegCheckCircle className={Styles.store_items_icons_red} />
                    :
                    <FaChevronRight className={Styles.store_items_icons} />
                  }
                </div>
                <div onClick={() => history.push(`/edit/type/id=${company}+page=2+storeId=${data.id}`)}
                  style={data.status_type ? {border : '1px solid #222222', boxShadow : 'none'} : {border : '1px solid #AD0200'}}>
                  <p style={data.status_type ? {color : '#222222'} : {color : '#AD0200'}}>8タイプ診断</p>
                  {data.status_type ?
                    <FaRegCheckCircle className={Styles.store_items_icons_red} />
                    :
                    <FaChevronRight className={Styles.store_items_icons} />
                  }
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={Styles.button}>
        <button onClick={() => history.push(`/service/id=${company}+page=2`)}>戻る</button>
        <button onClick={() => setMakeStore(true)}>店舗追加</button>
      </div>

    </div>

    {makeStore ?
      <MakeStore setMakeStore={setMakeStore} companyId={company} length={storeList.length} />
    : <></> }

    {editInfo.length !== 0 ?
      <EditStore setEditInfo={setEditInfo} companyId={company} editInfo={editInfo} />
    : <></> }

    {deleteStore !== '' ?
      <DeleteStore setDeleteStore={setDeleteStore} companyId={company} deleteStore={deleteStore} />
    : <></> }

    </>
  );
}

export default WorksEdit;

import Styles from './styles.module.css';
import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import { v4 as uuid } from 'uuid';
import { FaEye } from 'react-icons/fa';
import { IoPersonSharp } from 'react-icons/io5';
import { IoMdBookmark } from 'react-icons/io';
import { BsThreeDots } from 'react-icons/bs';
import DeleteRecruit from './deleteRecruit/index';
import Image1 from './../../../../image/works/employment/1.png';
import Image2 from './../../../../image/works/employment/2.png';
import Image3 from './../../../../image/works/employment/3.png';
import Image4 from './../../../../image/works/employment/4.png';
import Image5 from './../../../../image/works/employment/5.png';
import Image6 from './../../../../image/works/employment/6.png';
import { useHistory } from 'react-router-dom';
import EditConfirm from './editConfirm/index';

const MemoriiWorksRecruitItem = (props) => {

  const history = useHistory();

  // ポップアップパネル
  const [ storeId, setStoreId ] = useState('');
  const [ recruitId, setRecruitId ] = useState('');
  const [ makeDoc, setMakeDoc ] = useState(0);

  const [ editStatus, setEditStatus ] = useState([]);

  function duplicate(data) {
    var docId = uuid();

    firebase.firestore().collection('recruits').doc(docId)
    .set({
      ...data,
    });

    firebase.firestore().collection('recruits').doc(docId)
    .update({
      store_title: `コピー：${data.store_title}`,
      last_time: new Date(),
      time: new Date(),

      // ステータス
      status: 3,
      status_store: false,
      status_recruit: false,
      status_area: false,
      status_document: false,

      // データ
      data_applicants: [],
      data_pv: [],
      data_recruitment: [],
      data_save: [],
    });
    
    firebase.firestore().collection('companies').doc(props.companyList.id)
      .update({ recruits: firebase.firestore.FieldValue.arrayUnion(docId) });
  }

  return (
    <>
    <div className={Styles.items_table}>
      <div className={Styles.items_table_box_1}
        onClick={() => props.data.status === 3 ? history.push(`/edit/id=${props.companyList.id}+page=2+recruitId=${props.data.id}`) : ''}>
        <img src={
          props.data.recruit_employmentStatus === '新卒採用（正社員）' ? Image1 :
          props.data.recruit_employmentStatus === '中途採用（正社員）' ? Image2 :
          props.data.recruit_employmentStatus === '中途採用（契約社員）' ? Image3 :
          props.data.recruit_employmentStatus === 'アルバイト・パート採用' ? Image4 :
          props.data.recruit_employmentStatus === '業務委託（フリーランス）' ? Image5 : Image6
        } className={Styles.items_table_box_1_img} alt='' />
        <div className={Styles.items_table_box_post}>
          <div>
            <div style={props.data.status === 1 ? {backgroundColor: '#59C47C'} : props.data.status === 2 ? {backgroundColor: '#F1AE54'} : {backgroundColor: '#D14F60'}}></div>
            <p>{props.data.status === 1 ? '公開中' : props.data.status === 2 ? '審査中' : '作成中'}</p>
          </div>
          <h2>{props.data.recruit_employmentStatus}</h2>
        </div>
      </div>
      <div className={Styles.items_table_box_2}
        onClick={() => props.data.status === 3 ? history.push(`/edit/id=${props.companyList.id}+page=2+recruitId=${props.data.id}`) : ''}>
        <h4>{props.data.store_title}</h4>
      </div>
      <p className={Styles.items_table_box_3}>
        {new Date(props.data.time.seconds * 1000).getFullYear()}年
        {new Date(props.data.time.seconds * 1000).getMonth()+1}月
        {new Date(props.data.time.seconds * 1000).getDate()}日
      </p>
      <div className={Styles.items_table_date}>
        <div>
          <FaEye className={Styles.items_table_date_icon} />
          <p>{props.data.data_pv.length}</p>
        </div>
        <div>
          <IoMdBookmark className={Styles.items_table_date_icon} />
          <p>{props.data.data_save.length}</p>
        </div>
        <div>
          <IoPersonSharp className={Styles.items_table_date_icon} />
          <p>{props.data.data_applicants.length}</p>
        </div>
      </div>
      <div className={Styles.items_table_list}>
        <BsThreeDots className={Styles.items_table_list_icon} onClick={() => props.setDescToggle(props.data.id)} />
        {props.descToggle === props.data.id ?
          <div>
            <p onClick={() => duplicate(props.data)}>複製する</p>
            <p onClick={() => {setMakeDoc(1); setStoreId(props.data.storeId); setRecruitId(props.data.id)}}>削除する</p>
            <p onClick={() => {
              if (props.data.status === 1 || props.data.status === 2) {
                setEditStatus([ props.companyList.id, props.data.id, ]);
              } else {
                history.push(`/edit/id=${props.companyList.id}+page=2+recruitId=${props.data.id}`)
              }
            }}>編集・停止</p>
            <p onClick={() => history.push(`/edit/evaluation/id=${props.companyList.id}+page=2+recruitId=${props.data.id}`)}>評価設定</p>
          </div>
        : <></> }
      </div>
    </div>

    {/* 求人削除 */}
    {makeDoc === 1 ?
      <DeleteRecruit setMakeDoc={setMakeDoc} companyList={props.companyList} storeId={storeId} recruitId={recruitId} />
    : <></> }

    {/* 求人編集 */}
    {editStatus.length !== 0 ?
      <EditConfirm editStatus={editStatus} setEditStatus={setEditStatus} />
    : <></> }
    </>
  );
}

export default MemoriiWorksRecruitItem;

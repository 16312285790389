import Styles from './styles.module.css';
import React from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../6.header/index';
import firebase from "firebase/compat/app"
import ErrorMessage from '../../../../package/parts/error';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import DefaultInput1 from '../../../../package/parts/1-input';
import BackModal from '../../11.back/index';

const EditStore = () => {

  const history = useHistory();

  // リンク
  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var recruit = decodeURIComponent(link.slice(link.indexOf('recruitId=') + 10, link.length));

  var time = new Date();

  const [ errorMessage, setErrorMessage ] = useState('');

  const [ numberTag, setNumberTags ] = useState([]);

  const [ select1, setSelect1 ] = useState(0);
  const [ select2, setSelect2 ] = useState(0);
  const [ select3, setSelect3 ] = useState(0);
  const [ select4, setSelect4 ] = useState(0);
  const [ select5, setSelect5 ] = useState(0);
  const [ select6, setSelect6 ] = useState(0);
  const [ select7, setSelect7 ] = useState(0);
  const [ select8, setSelect8 ] = useState(0);
  const [ select9, setSelect9 ] = useState(0);
  const [ select10, setSelect10 ] = useState(0);
  const [ select11, setSelect11 ] = useState('');
  const [ select12, setSelect12 ] = useState(0);
  const [ select13, setSelect13 ] = useState('');

  // 戻るボタン
  const [ backStatus, setBackStatus ] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        if (company !== '' && recruit !== '') {
          firebase.firestore().collection("recruits").doc(recruit).get()
          .then((doc) => {

            setNumberTags(doc.data().recruit_storeNumberTags === undefined ? [] : doc.data().recruit_storeNumberTags);

            setSelect1(doc.data().recruit_storeNumber1 === undefined ? 1 : doc.data().recruit_storeNumber1);
            setSelect2(doc.data().recruit_storeNumber2 === undefined ? 1 : doc.data().recruit_storeNumber2);
            setSelect3(doc.data().recruit_storeNumber3 === undefined ? 1 : doc.data().recruit_storeNumber3);
            setSelect4(doc.data().recruit_storeNumber4 === undefined ? 1 : doc.data().recruit_storeNumber4);
            setSelect5(doc.data().recruit_storeNumber5 === undefined ? 1 : doc.data().recruit_storeNumber5);
            setSelect6(doc.data().recruit_storeNumber6 === undefined ? 1 : doc.data().recruit_storeNumber6);
            setSelect7(doc.data().recruit_storeNumber7 === undefined ? 1 : doc.data().recruit_storeNumber7);
            setSelect8(doc.data().recruit_storeNumber8 === undefined ? 1 : doc.data().recruit_storeNumber8);
            setSelect9(doc.data().recruit_storeNumber9 === undefined ? 1 : doc.data().recruit_storeNumber9);
            setSelect10(doc.data().recruit_storeNumber10 === undefined ? 1 : doc.data().recruit_storeNumber10);
            setSelect11(doc.data().recruit_storeNumber11 === undefined ? '' : doc.data().recruit_storeNumber11);
            setSelect12(doc.data().recruit_storeNumber12 === undefined ? 1 : doc.data().recruit_storeNumber12);
            setSelect13(doc.data().recruit_storeNumber13 === undefined ? '' : doc.data().recruit_storeNumber13);

          });
        }
      } else {
        history.push('/company/login')
      }
    })
  }, [history, company, recruit]);

  function check() {
    window.scrollTo(0, 0);

    if (numberTag.includes('創業から') && select1 === '') {
      setErrorMessage('創業からを入力してください。');
    } else if (numberTag.includes('平均勤続年数') && select2 === '') {
      setErrorMessage('平均勤続年数を入力してください。');
    } else if (numberTag.includes('店舗数') && select3 === '') {
      setErrorMessage('店舗数を入力してください。');
    } else if (numberTag.includes('男女比') && select4 === '') {
      setErrorMessage('男女比を入力してください。');
    } else if (numberTag.includes('採用人数') && select5 === '') {
      setErrorMessage('採用人数を入力してください。');
    } else if (numberTag.includes('最短デビュー') && select6 === '') {
      setErrorMessage('最短デビューを入力してください。');
    } else if (numberTag.includes('平均顧客単価') && select7 === '') {
      setErrorMessage('平均顧客単価を入力してください。');
    } else if (numberTag.includes('アシスタント給与') && select8 === '') {
      setErrorMessage('アシスタント給与を入力してください。');
    } else if (numberTag.includes('引越し金') && select9 === '') {
      setErrorMessage('引越し金を入力してください。');
    } else if (numberTag.includes('平均年齢') && select10 === '') {
      setErrorMessage('平均年齢を入力してください。');
    } else if (numberTag.includes('リピート率') && select11 === '') {
      setErrorMessage('リピート率を入力してください。');
    } else if (numberTag.includes('コンテスト受賞数') && select12 === '') {
      setErrorMessage('コンテスト受賞数を入力してください。');
    } else if (numberTag.includes('地方出身者割合') && select13 === '') {
      setErrorMessage('地方出身者割合を入力してください。');
    } else {
      save(true);
    }
  }

  function save(e) {

    firebase.firestore().collection("recruits").doc(recruit)
    .update({
      status_number: e,

      recruit_storeNumberTags: numberTag,

      recruit_storeNumber1: select1,
      recruit_storeNumber2: select2,
      recruit_storeNumber3: select3,
      recruit_storeNumber4: select4,
      recruit_storeNumber5: select5,
      recruit_storeNumber6: select6,
      recruit_storeNumber7: select7,
      recruit_storeNumber8: select8,
      recruit_storeNumber9: select9,
      recruit_storeNumber10: select10,
      recruit_storeNumber11: select11,
      recruit_storeNumber12: select12,
      recruit_storeNumber13: select13,

      last_time: new Date(),
    }).then(() => {
      history.push(`/edit/id=${company}+page=2+recruitId=${recruit}`);
    })
  }

  function pushStyle(value) {
    if (numberTag.length < 4) {
      setNumberTags([...numberTag, value])
    }
  }

  var スタイル = [
    '創業から',
    '平均勤続年数',
    '店舗数',
    '男女比',
    '採用人数',
    '最短デビュー',
    '平均顧客単価',
    'アシスタント給与',
    '引越し金',
    '平均年齢',
    'リピート率',
    'コンテスト受賞数',
    '地方出身者割合',
  ]
  
  return (
    <>
    <Header />

    <div className={Styles.store}>
      <div className={Styles.store_box}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        {/* 店舗理念 */}
        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>数字で店舗を表現（4個まで）</h5>

          <div className={Styles.numberTag}>

            {スタイル.map((data, index) =>
              <div key={index}>
                <input type="checkbox"
                  checked={numberTag.indexOf(data) !== -1 ? 'checked' : ''}
                  onChange={(e) => e.target.checked ? pushStyle(data) : setNumberTags(numberTag.filter((v, index) => (v !== data)))}/>
                <p>{data}</p>
              </div>
            )}

          </div>

          {/* 創業から */}
          {numberTag.includes('創業から') ?
            <DefaultInput1
              title={'創業から(年)'} required={true} length={5} place={''} type={'number'}
              value={select1} setValue={setSelect1}
              desc={''}
            />
          : <></> }

          {/* 平均勤続年数 */}
          {numberTag.includes('平均勤続年数') ?
            <DefaultInput1
              title={'平均勤続年数(年)'} required={true} length={5} place={''} type={'number'}
              value={select2} setValue={setSelect2}
              desc={''}
            />
          : <></> }

          {/* 店舗数 */}
          {numberTag.includes('店舗数') ?
            <DefaultInput1
              title={'店舗数(店舗)'} required={true} length={5} place={''} type={'number'}
              value={select3} setValue={setSelect3}
              desc={''}
            />
          : <></> }

          {/* 男女比 */}
          {numberTag.includes('男女比') ?
            <DefaultInput1
              title={'男女比(〇:〇)'} required={true} length={5} place={''} type={'text'}
              value={select4} setValue={setSelect4}
              desc={''}
            />
          : <></> }

          {/* 採用人数 */}
          {numberTag.includes('採用人数') ?
            <DefaultInput1
              title={'採用人数(人)'} required={true} length={5} place={''} type={'number'}
              value={select5} setValue={setSelect5}
              desc={''}
            />
          : <></> }

          {/* 最短デビュー */}
          {numberTag.includes('最短デビュー') ?
            <DefaultInput1
              title={'最短デビュー(ヶ月)'} required={true} length={5} place={''} type={'number'}
              value={select6} setValue={setSelect6}
              desc={''}
            />
          : <></> }

          {/* 平均顧客単価 */}
          {numberTag.includes('平均顧客単価') ?
            <DefaultInput1
              title={'平均顧客単価(円)'} required={true} length={5} place={''} type={'number'}
              value={select7} setValue={setSelect7}
              desc={''}
            />
          : <></> }

          {/* アシスタント給与 */}
          {numberTag.includes('アシスタント給与') ?
            <DefaultInput1
              title={'アシスタント給与(万円)'} required={true} length={5} place={''} type={'number'}
              value={select8} setValue={setSelect8}
              desc={''}
            />
          : <></> }

          {/* 引越し金 */}
          {numberTag.includes('引越し金') ?
            <DefaultInput1
              title={'引越し金(万円)'} required={true} length={5} place={''} type={'number'}
              value={select9} setValue={setSelect9}
              desc={''}
            />
          : <></> }

          {/* 平均年齢 */}
          {numberTag.includes('平均年齢') ?
            <DefaultInput1
              title={'平均年齢(歳)'} required={true} length={5} place={''} type={'number'}
              value={select10} setValue={setSelect10}
              desc={''}
            />
          : <></> }

          {/* リピート率 */}
          {numberTag.includes('リピート率') ?
            <DefaultInput1
              title={'リピート率(%)'} required={true} length={5} place={''} type={'text'}
              value={select11} setValue={setSelect11}
              desc={''}
            />
          : <></> }

          {/* コンテスト受賞数 */}
          {numberTag.includes('コンテスト受賞数') ?
            <DefaultInput1
              title={'コンテスト受賞数(回)'} required={true} length={5} place={''} type={'number'}
              value={select12} setValue={setSelect12}
              desc={''}
            />
          : <></> }

          {/* 地方出身者割合 */}
          {numberTag.includes('地方出身者割合') ?
            <DefaultInput1
              title={'地方出身者割合(%)'} required={true} length={5} place={''} type={'text'}
              value={select13} setValue={setSelect13}
              desc={''}
            />
          : <></> }
          
        </div>

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check()}>保存</button>
        </div>

      </div>
    </div>

    {backStatus ?
      <BackModal
        companyId={company}
        recruitId={recruit}
        setBackStatus={setBackStatus}
      />
    : <></> }

    </>
  );
}

export default EditStore;

import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App.js';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase/compat/app"
import { initializeApp } from "firebase/app";
import './index.css';
import "firebase/storage";
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"
import "firebase/compat/analytics"

const firebaseConfig = {
  apiKey: 'AIzaSyAA-k-w372drcjGAGuuuaTtlpMGctssxZw',
  authDomain: 'trankllc-com.firebaseapp.com',
  databaseURL: 'https://trankllc-com.firebaseio.com',
  projectId: 'trankllc-com',
  storageBucket: 'trankllc-com.appspot.com',
  messagingSenderId: '160357234383',
  appId: '1:160357234383:web:e45e81b8a13d6c11c129ba',
  measurementId: 'G-8ETZ53Z7LF',

  // REACT_APP_APIKEY='AIzaSyAA-k-w372drcjGAGuuuaTtlpMGctssxZw'
  // REACT_APP_AUTHDOMAIN='trankllc-com.firebaseapp.com'
  // REACT_APP_DATABASEURL='https://trankllc-com.firebaseio.com'
  // REACT_APP_PROJECT_ID='trankllc-com'
  // REACT_APP_STORAGE_BUCKET='trankllc-com.appspot.com'
  // REACT_APP_MESSAGING_SENDER_ID='160357234383'
  // REACT_APP_APP_ID='1:160357234383:web:e45e81b8a13d6c11c129ba'
  // REACT_APP_MEASUREMENT_ID='G-8ETZ53Z7LF'
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
const analytics = firebase.analytics();
const auth = initializeApp(firebaseConfig);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

reportWebVitals();

export { storage, analytics, auth, firebase as default };

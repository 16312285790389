import './styles.css';
import Icon from "./../../../../image/icon/icon_white.png";
import { useHistory } from 'react-router-dom';

const Home = () => {

  const history = useHistory();

  return (
    <>
    <div className="nav">
      <div className="nav_icon" onClick={() => history.push('/')}>
        <img src={Icon} alt='' />
        <p>memorii</p>
      </div>
      <div className="nav_text">
        <p onClick={() => history.push('/student')}>美容学生</p>
        <p onClick={() => history.push('/company')}>事業者様</p>
        <p onClick={() => history.push('/contact')}>問い合わせ</p>
      </div>
    </div>
    </>
  );
}

export default Home;
import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import { FaSearch } from "react-icons/fa";
import firebase from "firebase/compat/app";
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore';
import Chat from './1.chat/index';
import Schedule from './3.schedule/index';
import Message from './2.message/index';

const MemoriiWorksMessage = (props) => {

  const [ choiceNavigation, setChoiceNavigation ] = useState(10000);
  const [ chatListStatus, setChatListStatus ] = useState(1);
  const [ choiceRecruit, setChoiceRecruit ] = useState(1000);
  const [ applicantProgress, setApplicantProgress ] = useState([]);
  
  const [ searchText, setSearchText ] = useState('');

  const [ chatNumber, setChatNumber ] = useState(10000);
  const [ chatList, setChatList ] = useState([]);
  const [ nowChatData, setNowChatData ] = useState([]);

  const [ message, setMessage ] = useState([]);
  
  var link = new URL(window.location).href;
  var linkApplicant = decodeURIComponent(link.slice(link.indexOf('+search') + 8, link.length));
  
  useEffect(() => {
    getChatPath();
    if (link.indexOf('search') !== -1) {
      setSearchText(linkApplicant);
    }
  }, [linkApplicant]);
  
  // チャット情報取得
  
  function getChatPath() {
    const docRef = collection(firebase.firestore(), 'chat')
    const queryRef = query(docRef, where('companyId', '==', props.companyList.id))
    const queryRefs = query(queryRef, orderBy('chat_time', 'asc'))
    const unsub = onSnapshot(queryRefs, snapshot => {
      var tmp = [];
      snapshot.docs.forEach((doc) => {
        tmp.push({...doc.data(), id: doc.id});
      });

      setChatList(tmp);

      if (tmp.length !== 0) {
        getMessage(tmp[0].id);
        getApplicantProgress(tmp[0].recruitId);
      }
    })
    return () => unsub();
  }

  // メッセージ内容取得
  function getMessage(id) {
    var link = 'chat/' + id + '/message';
    const docRef = collection(firebase.firestore(), link)
    const queryRefs = query(docRef, orderBy('message_time', 'asc'))
    const unsub = onSnapshot(queryRefs, snapshot => {
      var tmp = []
      snapshot.docs.forEach(doc => {
        tmp.push({...doc.data(), id: doc.id });
      })
      setMessage(tmp);
    })
    return () => unsub();
  }

  // ユーザーの選考進捗
  function getApplicantProgress(id) {

    const docRef = collection(firebase.firestore(), 'applicants')
    const queryRef = query(docRef, where('recruitId', '==', id))
    const unsub = onSnapshot(queryRef, snapshot => {
      // var tmp0 = [];
      var tmp1 = [];
      var tmp2 = [];
      var tmp3 = [];
      var tmp4 = [];
      var tmp5 = [];
      var tmp6 = [];
      var tmp7 = [];
      snapshot.docs.forEach(doc => {

        if (doc.data().examination === 1) {
          tmp1.push(doc.id);
        } else if (doc.data().examination === 2) {
          tmp2.push(doc.id);
        } else if (doc.data().examination === 3) {
          tmp3.push(doc.id);
        } else if (doc.data().examination === 4) {
          tmp4.push(doc.id);
        } else if (doc.data().examination === 5) {
          tmp5.push(doc.id);
        } else if (doc.data().examination === 6) {
          tmp6.push(doc.id);
        } else if (doc.data().examination === 7) {
          tmp7.push(doc.id);
        }
      })
      setApplicantProgress([
        tmp1.length + tmp2.length + tmp3.length + tmp4.length + tmp5.length + tmp6.length + tmp7.length,
        tmp1, tmp2, tmp3, tmp4, tmp5, tmp6, tmp7,
      ]);
    })
    return () => unsub();
  }

  const isAllIncludes = (arr, target) => arr.some(el => target.includes(el));

  return (
    <>
    <div className='works_service'>
      
      {props.recruitList.length !== 0 && applicantProgress.length !== 0 && choiceRecruit === 1000 ?
      <div className={Styles.progress}>
        <h4 style={choiceNavigation === 10000 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}} 
          onClick={() => setChoiceNavigation(10000)}>一覧({applicantProgress[0]})</h4>

        <h4 style={choiceNavigation === 1 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => setChoiceNavigation(1)}>1次選考({applicantProgress[1].length})</h4>

        <h4 style={choiceNavigation === 2 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => setChoiceNavigation(2)}>2次選考({applicantProgress[2].length})</h4>

        <h4 style={choiceNavigation === 3 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => setChoiceNavigation(3)}>3次選考({applicantProgress[3].length})</h4>

        <h4 style={choiceNavigation === 4 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => setChoiceNavigation(4)}>4次選考({applicantProgress[4].length})</h4>

        <h4 style={choiceNavigation === 5 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => setChoiceNavigation(5)}>5次選考({applicantProgress[5].length})</h4>

        <h4 style={choiceNavigation === 6 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => setChoiceNavigation(6)}>採用({applicantProgress[6].length})</h4>

        <h4 style={choiceNavigation === 7 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => setChoiceNavigation(7)}>不採用({applicantProgress[7].length})</h4>
      </div>
      : props.recruitList.length !== 0 && applicantProgress.length !== 0 && choiceRecruit !== 1000 ?
      <div className={Styles.progress}>
        <h4 style={choiceNavigation === 10000 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}} 
          onClick={() => setChoiceNavigation(10000)}>一覧({applicantProgress[0]})</h4>

        {Number(props.recruitList[choiceRecruit === 1000 ? 
          0 : choiceRecruit].exam_selection) === 1 || Number(props.recruitList[choiceRecruit === 1000 ? 
          0 : choiceRecruit].exam_selection) === 2 || Number(props.recruitList[choiceRecruit === 1000 ? 
          0 : choiceRecruit].exam_selection) === 3 || Number(props.recruitList[choiceRecruit === 1000 ? 
          0 : choiceRecruit].exam_selection) === 4 || Number(props.recruitList[choiceRecruit === 1000 ? 0 : choiceRecruit].exam_selection) === 5 ?
          <h4 style={choiceNavigation === 1 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
            onClick={() => setChoiceNavigation(1)}>1次選考({applicantProgress[1].length})</h4>
        : <></> }

        {Number(props.recruitList[choiceRecruit === 1000 ?
          0 : choiceRecruit].exam_selection) === 2 || Number(props.recruitList[choiceRecruit === 1000 ?
          0 : choiceRecruit].exam_selection) === 3 || Number(props.recruitList[choiceRecruit === 1000 ? 
          0 : choiceRecruit].exam_selection) === 4 || Number(props.recruitList[choiceRecruit === 1000 ? 
          0 : choiceRecruit].exam_selection) === 5 ?
          <h4 style={choiceNavigation === 2 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
            onClick={() => setChoiceNavigation(2)}>2次選考({applicantProgress[2].length})</h4>
        : <></> }

        {Number(props.recruitList[choiceRecruit === 1000 ?
          0 : choiceRecruit].exam_selection) === 3 || Number(props.recruitList[choiceRecruit === 1000 ? 
          0 : choiceRecruit].exam_selection) === 4 || Number(props.recruitList[choiceRecruit === 1000 ? 
          0 : choiceRecruit].exam_selection) === 5 ?
          <h4 style={choiceNavigation === 3 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
            onClick={() => setChoiceNavigation(3)}>3次選考({applicantProgress[3].length})</h4>
        : <></> }

        {Number(props.recruitList[choiceRecruit === 1000 ? 
          0 : choiceRecruit].exam_selection) === 4 || Number(props.recruitList[choiceRecruit === 1000 ? 
          0 : choiceRecruit].exam_selection) === 5 ?
          <h4 style={choiceNavigation === 4 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
            onClick={() => setChoiceNavigation(4)}>4次選考({applicantProgress[4].length})</h4>
        : <></> }

        {Number(props.recruitList[choiceRecruit === 1000 ? 0 : choiceRecruit].exam_selection) === 5 ?
          <h4 style={choiceNavigation === 5 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
            onClick={() => setChoiceNavigation(5)}>5次選考({applicantProgress[5].length})</h4>
        : <></> }

        <h4 style={choiceNavigation === 6 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => setChoiceNavigation(6)}>採用({applicantProgress[6].length})</h4>

        <h4 style={choiceNavigation === 7 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => setChoiceNavigation(7)}>不採用({applicantProgress[7].length})</h4>
      </div> : <></> }

      <div className={Styles.contact}>

        <div className={Styles.contact_nav}>
          <select 
            onChange={(e) => {
              if (e.target.value === '')  {
                setChoiceRecruit(1000); getChatPath(props.recruitListOnlyId);
              } else {
                setChoiceRecruit(Number(e.target.value)); getChatPath([props.recruitListOnlyId[Number(e.target.value)]]);
              }
            }}>
            <option value=''>全ての求人</option>
            {props.recruitList.map((data, index) =>
              <option value={index} key={index}>{props.recruitList[index].store_title}</option>
            )}
          </select>
          <div>
            <FaSearch className={Styles.contact_nav_icon} />
            <input placeholder='応募者名 検索' value={searchText} onChange={(e) => setSearchText(e.target.value)}  />
          </div>
        </div>

        <div className={Styles.contact_box}>
          <div className={Styles.contact_box_list}>
            <div className={Styles.contact_box_list_bar}>
              <p onClick={() => setChatListStatus(1)} 
                style={chatListStatus === 1 ? {backgroundColor : '#AD0200', color: 'white', fontWeight : 'bold',} : {}}>一覧</p>
              <p onClick={() => setChatListStatus(2)} 
                style={chatListStatus === 2 ? {backgroundColor : '#AD0200', color: 'white', fontWeight : 'bold',} : {}}>未読</p>
              <p onClick={() => setChatListStatus(3)} 
                style={chatListStatus === 3 ? {backgroundColor : '#AD0200', color: 'white', fontWeight : 'bold',} : {}}>返信済</p>
            </div>

            {chatList.map((data, index) =>
            <div onClick={() => { getMessage(data.id); setChatNumber(index); setNowChatData(data) }} key={index}>
              {choiceRecruit === 1000 ? 
              <>
                {isAllIncludes([searchText], data[`${data.chat_name.indexOf(props.companyList.id) === 0 ? data.chat_name[1] : data.chat_name[0]}`][0]) ?
                <>
                  {choiceNavigation === 10000 ?
                    <Chat
                      chat={data}
                      chatListStatus={chatListStatus}
                      progress={true}
                      includes={false}
                      setMessage={setMessage}
                      userList={props.userList}
                      companyList={props.companyList}
                    />
                  :
                  <>
                    {applicantProgress.length !== 0 ?
                      <Chat
                        chat={data}
                        chatListStatus={chatListStatus}
                        progress={false}
                        includes={applicantProgress[choiceNavigation].includes(chatList[index].applicantId)}
                        setMessage={setMessage}
                        companyList={props.companyList}
                        userList={props.userList}
                      />
                    : <></> }
                  </> }
                </> : <></> }
              </>
              :
              <>
                {props.recruitList[choiceRecruit].id === data.recruitId && isAllIncludes([searchText], data[`${data.chat_name.indexOf(props.companyList.id) === 0 ? data.chat_name[1] : data.chat_name[0]}`][0]) ?
                <>
                  {choiceNavigation === 10000 ?
                    <Chat
                      chat={data}
                      chatListStatus={chatListStatus}
                      progress={true}
                      includes={false}
                      setMessage={setMessage}
                      companyList={props.companyList}
                      userList={props.userList}
                    />
                  :
                  <>
                    {applicantProgress.length !== 0 ?
                      <Chat
                        chat={data}
                        chatListStatus={chatListStatus}
                        progress={false}
                        includes={applicantProgress[choiceNavigation].includes(chatList[index].applicantId)}
                        setMessage={setMessage}
                        companyList={props.companyList}
                        userList={props.userList}
                      />
                    : <></> }
                  </> }
                </> : <></> }
              </> }
            </div> )}
          </div>

          <div className={Styles.contact_box_chat}>
            {chatNumber !== 10000 && chatList.length !== 0 ?
              <Message
                uid={chatList[chatNumber].chat_name[0] === props.companyList.id ?
                  chatList[chatNumber].chat_name[1] : chatList[chatNumber].chat_name[0]} 
                name={chatList[chatNumber][`${chatList[chatNumber].chat_name[0] === props.companyList.id ?
                  chatList[chatNumber].chat_name[1] : chatList[chatNumber].chat_name[0]}`][0]}

                message={message} 
                myUid={props.companyList.id}
                chatList={chatList[chatNumber]}
                recruitId={chatList[chatNumber].recruitId}
                applicantId={chatList[chatNumber].applicantId}
                userList={props.userList}
                companyList={props.companyList}
              />
            : <></> }
          </div>

          <div className={Styles.contact_box_info}>
            {nowChatData.length === 0 ? <></> :
              <Schedule applicantId={nowChatData.applicantId} />
            }
          </div>

        </div>
      </div>
    </div>
    </>
  );
}

export default MemoriiWorksMessage;

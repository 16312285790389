import Styles from './styles.module.css';
import React, { useState } from 'react';
import { sendEmailVerification } from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import { FaEye } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import Modal from '../../../service/package/parts/pop-modal/index';

const Footer = () => {
  
  const history = useHistory();

  const [ error, setError ] = useState('');
  const [ errorAlready, setErrorAlready ] = useState(false);
  const [ errorAll, setErrorAll ] = useState(false);

  const [ send, setSend ] = useState(false);

  const [ registerEmail, setRegisterEmail ] = useState('');
  const [ registerPassword, setRegisterPassword ] = useState('');

  const [ passwordEye, setPasswordEye ] = useState(false);

  function check() {
    setError('');

    if (registerEmail === '') {
      setError('mail');
    } else if (registerPassword === '' || registerPassword.length <= 7) {
      setError('pass');
    } else {
      Register();
    }
  }

  // メールアドレス登録
  const Register = async () => {    
    await firebase.auth().createUserWithEmailAndPassword(registerEmail, registerPassword)
    .then((userCredential) => {
      sendEmailVerification(userCredential.user)
      setSend(true);
    })
    .catch((error) => {
      if (error.code === 'auth/email-already-in-use') {
        setErrorAlready(true);
      } else {
        setErrorAll(true);
      }
    });
  };

  return (
    <>
    <div className={Styles.box}>

      <div className={Styles.box_inline}>

        <h2>新規会員登録</h2>

        {send ?
          <div className={Styles.send}>
            メールアドレスに届いたURLより<br />本登録を完了してください。
          </div>
        :
        <>
          <p className={Styles.input_title}>メールアドレス</p>
          <div className={Styles.input_input}>
            <input placeholder='memorii@trankllc.com' value={registerEmail} onChange={(e) => setRegisterEmail(e.target.value)} />
          </div>
          <p className={Styles.error} style={error === 'mail' ? { color : '#AD0200' } : { color : '#EBEBEB' }}>
            ※メールアドレスを入力してください。
          </p>

          <p className={Styles.input_title}>パスワード</p>
          <div className={Styles.input_input}>
            <input placeholder='Memorii*' type={passwordEye ? '' : 'password'} value={registerPassword} onChange={(e) => setRegisterPassword(e.target.value)} />
            <FaEye className={Styles.input_icon} onClick={() => setPasswordEye(!passwordEye)} />
          </div>
          <p className={Styles.error} style={error === 'pass' ? { color : '#AD0200' } : { color : '#EBEBEB' }}>※英数字8文字以上のパスワードを入力してください。</p>

          <button onClick={() => { check() }}>新規登録する</button>
        </> }
        
        <p className={Styles.link} onClick={() => history.push(`/company/login`)}>アカウントをお持ちの方はこちら</p>

      </div>

      {!isMobile ?
        <div className={Styles.right}>
          <h4>memorii works</h4>
          <h5>美容学生の作品撮り管理<br />投稿から閲覧まで全て完結</h5>
          <div></div>
        </div>
      : <></> }

    </div>

    {errorAlready ?
      <Modal 
        title={'ログイン エラー'}
        desc={'既に登録されているメールアドレスです。\n下部の「アカウントをお持ちの方はこちら」より、ログインを行なってください。'}
        setError={setErrorAlready}
      />
    : <></> }

    {errorAll ?
      <Modal 
        title={'ログイン エラー'}
        desc={'メールアドレス、もしくはパスワードが間違っています。\nまた、アカウント登録がお済みではない方は「アカウントをお持ちではない方はこちら」より、アカウントを作成してください。'}
        setError={setErrorAll}
      />
    : <></> }
    </>
  );
}

export default Footer;


import './styles.css';
import React from 'react';
// import Worry1 from './../../../../../image/works/landing/worry_1.png';
// import Worry2 from './../../../../../image/works/landing/worry_2.png';
// import Worry3 from './../../../../../image/works/landing/worry_3.png';

import { FaUser } from "react-icons/fa";
import { RiMoneyCnyCircleFill } from "react-icons/ri";
import { IoDocumentText } from "react-icons/io5";

import { useHistory } from 'react-router-dom';

const MemoriiWorksLanding = () => {

  const history = useHistory();

  return (
    <>
    <div className='works_landing_contact'>
      <div>
        <div style={{backgroundColor: '#E5F0E6'}}>
          <FaUser className='works_landing_contact_icon' style={{color: '#279A30'}} />
        </div>
        <h4>お問い合わせ</h4>
        <p>2営業日以内に担当者より、<br />サービスのご案内いたします。</p>
        <button onClick={() => history.push('/contact')}>話を聞いてみる</button>
      </div>
      <div>
        <div style={{backgroundColor: '#FAF7E8'}}>
          <RiMoneyCnyCircleFill className='works_landing_contact_icon' style={{color: '#FCDC4A'}} />
        </div>
        <h4>お見積もり</h4>
        <p>お客さまのご状況を踏まえて、<br />担当者が最適なサービスを紹介します。</p>
        <button onClick={() => history.push('/contact')}>お見積もりを依頼する</button>
      </div>
      <div>
        <div style={{backgroundColor: '#E6EEF3'}}>
          <IoDocumentText className='works_landing_contact_icon' style={{color: '#2E81B0'}} />
        </div>
        <h4>資料ダウンロード</h4>
        <p>サービス機能紹介や使用方法を<br />記載した資料をダウンロードできます。</p>
        <button onClick={() => history.push('/contact')}>資料を確認する</button>
      </div>
    </div>
    </>
  );
}

export default MemoriiWorksLanding;

import Styles from './../station/styles.module.css';
import Styless from './styles.module.css';
import React from 'react'

const Footer = (props) => {

  var カット = [
    'セニングカット',
    'シャギーカット',
    'スライドカット',
    'ドライカット',
    'レザーカット',
    'レイヤーカット',
    'ウルフカット',
    'ボブカット',
    'チョップカット',
    'ブラントカット',
    'グラデーションカット',
  ]

  var パーマ = [
    'コールドパーマ',
    '水パーマ',
    'ピンパーマ',
    'ストレートパーマ',
    'ツイストパーマ',
    'スパイラルパーマ',
    'ツイストスパイラルパーマ',
    'ニュアンスパーマ',
    'カルマパーマ',
    'ワンカールパーマ',
    'デジタルパーマ',
    'ホットパーマ',
    'エアウエーブパーマ',
    'クリープパーマ',
  ]

  var カラー = [
    'グラデーションカラー',
    'ハイライトカラー',
    'ローライトカラー',
    'イヤリングカラー',
    'ヴェールカラー',
    'イルミナカラー',
    'ハイトーンカラー',
  ]

  var 縮毛矯正 = [
    '酸性縮毛',
    'アルカリ性縮毛',
    '低温縮毛',
  ]

  return (
    <>
    <div className={Styles.station}>
      <div>
        <div className={Styles.background} onClick={() => props.setSwitchStyle(false)}></div>
        <div className={Styles.contents}>
          <div className={Styles.title}>
            <h5>得意な施術</h5>
            <div>
              <button onClick={() => props.setStyle([])}>クリア</button>
              <button onClick={() => props.setSwitchStyle(false)}>検索</button>
            </div>
          </div>
          <div className={Styless.radio}>
            <h5>カット関連</h5>
            <div>
              {カット.map((data, index) =>
                <div>
                  <input type="checkbox"
                    checked={props.style.indexOf(data) !== -1 ? 'checked' : ''}
                    onChange={(e) => {
                      e.target.checked ? props.setStyle([...props.style, data]) : props.setStyle(props.style.filter((v, index) => (v !== data)))
                    }}/>
                  <p>{data}</p>
                </div>
              )}
            </div>
            <h5>パーマ関連</h5>
            <div>
              {パーマ.map((data, index) =>
                <div>
                  <input type="checkbox"
                    checked={props.style.indexOf(data) !== -1 ? 'checked' : ''}
                    onChange={(e) => {
                      e.target.checked ? props.setStyle([...props.style, data]) : props.setStyle(props.style.filter((v, index) => (v !== data)))
                    }}/>
                  <p>{data}</p>
                </div>
              )}
            </div>
            <h5>カラー関連</h5>
            <div>
              {カラー.map((data, index) =>
                <div>
                  <input type="checkbox"
                    checked={props.style.indexOf(data) !== -1 ? 'checked' : ''}
                    onChange={(e) => {
                      e.target.checked ? props.setStyle([...props.style, data]) : props.setStyle(props.style.filter((v, index) => (v !== data)))
                    }}/>
                  <p>{data}</p>
                </div>
              )}
            </div>
            <h5>縮毛矯正関連</h5>
            <div>
              {縮毛矯正.map((data, index) =>
                <div>
                  <input type="checkbox"
                    checked={props.style.indexOf(data) !== -1 ? 'checked' : ''}
                    onChange={(e) => {
                      e.target.checked ? props.setStyle([...props.style, data]) : props.setStyle(props.style.filter((v, index) => (v !== data)))
                    }}/>
                  <p>{data}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Footer;

import React, { useState, useEffect } from 'react';
import Header from "../../service/2.recruit/2.edit/6.header/index";
import Confirm from "./items/1-confirm";
import Resume from "./items/2-resume";
import Question from "./items/3-question";
import Cordination from "./items/4-coordination";
import OtherDocument from "./items/5-otherDocument";
import Apply from "./items/6-apply";
import firebase from "firebase/compat/app";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useHistory } from 'react-router-dom';

const Footer = () => {

  const history = useHistory();
  
  const [ progress, setProgress ] = useState(1);
  
  const [ uid, setUid ] = useState('');
  const [ userList, setUserList ] = useState([]);
  const [ companyList, setCompanyList ] = useState([]);
  const [ recruitList, setRecruitList ] = useState([]);

  // 履歴書
  const [ idPhoto, setIdPhoto ] = useState(false);
  const [ idPhotoFile, setIdPhotoFile ] = useState('');
  const [ name, setName ] = useState('');
  const [ nameHurigana, setNameHurigana ] = useState('');
  const [ birthdayYears, setBirthdayYears ] = useState('');
  const [ birthdayMonth, setBirthdayMonth ] = useState('');
  const [ birthdayDays, setBirthdayDays ] = useState('');
  const [ age, setAge ] = useState('');
  const [ sex, setSex ] = useState('');
  const [ bloodType, setBloodType ] = useState('');

  const [ postCode, setPostCode ] = useState('');
  const [ address, setAddress ] = useState('');
  const [ addressHurigana, setAddressHurigana ] = useState('');
  const [ phoneNumber, setPhoneNumber ] = useState('');
  const [ mailAddress, setMailAddress ] = useState('');

  const [ instagram, setInstagram ] = useState('');
  const [ instagramFollower, setInstagramFollower ] = useState('');
  const [ twitter, setTwitter ] = useState('');
  const [ twitterFollower, setTwitterFollower ] = useState('');
  const [ tiktok, setTiktok ] = useState('');
  const [ tiktokFollower, setTiktokFollower ] = useState('');

  const [ hopeStore, setHopeStore ] = useState('');
  const [ motivation, setMotivation ] = useState('');
  const [ prText, setPrText ] = useState('');

  const [ historyYears1, setHistoryYears1 ] = useState('');
  const [ historyMonth1, setHistoryMonth1 ] = useState('');
  const [ history1, setHistory1 ] = useState('');
  const [ historyYears2, setHistoryYears2 ] = useState('');
  const [ historyMonth2, setHistoryMonth2 ] = useState('');
  const [ history2, setHistory2 ] = useState('');
  const [ historyYears3, setHistoryYears3 ] = useState('');
  const [ historyMonth3, setHistoryMonth3 ] = useState('');
  const [ history3, setHistory3 ] = useState('');
  const [ historyYears4, setHistoryYears4 ] = useState('');
  const [ historyMonth4, setHistoryMonth4 ] = useState('');
  const [ history4, setHistory4 ] = useState('');
  const [ historyYears5, setHistoryYears5 ] = useState('');
  const [ historyMonth5, setHistoryMonth5 ] = useState('');
  const [ history5, setHistory5 ] = useState('');
  const [ historyYears6, setHistoryYears6 ] = useState('');
  const [ historyMonth6, setHistoryMonth6 ] = useState('');
  const [ history6, setHistory6 ] = useState('');

  // Award
  const [ awardYears1, setAwardYears1 ] = useState('');
  const [ awardMonth1, setAwardMonth1 ] = useState('');
  const [ award1, setAward1 ] = useState('');
  const [ awardYears2, setAwardYears2 ] = useState('');
  const [ awardMonth2, setAwardMonth2 ] = useState('');
  const [ award2, setAward2 ] = useState('');
  const [ awardYears3, setAwardYears3 ] = useState('');
  const [ awardMonth3, setAwardMonth3 ] = useState('');
  const [ award3, setAward3 ] = useState('');
  const [ awardYears4, setAwardYears4 ] = useState('');
  const [ awardMonth4, setAwardMonth4 ] = useState('');
  const [ award4, setAward4 ] = useState('');

  const [ question01Input, setQuestion01Input ] = useState('');
  const [ question02Input, setQuestion02Input ] = useState('');
  const [ question03Input, setQuestion03Input ] = useState('');

  const [ coordinate1, setCoordinate1 ] = useState(true);
  const [ coordinate1Title, setCoordinate1Title ] = useState('');
  const [ coordinate1Point, setCoordinate1Point ] = useState('');
  const [ coordinate2, setCoordinate2 ] = useState(true);
  const [ coordinate2Title, setCoordinate2Title ] = useState('');
  const [ coordinate2Point, setCoordinate2Point ] = useState('');
  const [ coordinate3, setCoordinate3 ] = useState(true);
  const [ coordinate3Title, setCoordinate3Title ] = useState('');
  const [ coordinate3Point, setCoordinate3Point ] = useState('');

  const [ coordinate1File, setCoordinate1File ] = useState('');
  const [ coordinate2File, setCoordinate2File ] = useState('');
  const [ coordinate3File, setCoordinate3File ] = useState('');
  
  const [ document1File, setDocument1File ] = useState('');
  const [ document2File, setDocument2File ] = useState('');
  const [ document3File, setDocument3File ] = useState('');

  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var recruit = decodeURIComponent(link.slice(link.indexOf('recruitId=') + 10, link.length));

  useEffect(() => {
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        setUid(user.uid);
        getData(user.uid);

        firebase.firestore().collection('users').doc(user.uid).collection('resume').doc(user.uid).get()
        .then((doc) => {
          if (doc.exists) {
            setName(doc.data().name === undefined ? '' : doc.data().name);
            setNameHurigana(doc.data().nameHurigana === undefined ? '' : doc.data().nameHurigana);
            setBirthdayYears(doc.data().birthdayYears === undefined ? '' : doc.data().birthdayYears);
            setBirthdayMonth(doc.data().birthdayMonth === undefined ? '' : doc.data().birthdayMonth);
            setBirthdayDays(doc.data().birthdayDays === undefined ? '' : doc.data().birthdayDays);
            setSex(doc.data().sex === undefined ? '' : doc.data().sex);
            setAge(doc.data().age === undefined ? '' : doc.data().age);
            setBloodType(doc.data().bloodType === undefined ? '' : doc.data().bloodType);
            setPostCode(doc.data().postCode === undefined ? '' : doc.data().postCode);
            setAddress(doc.data().address === undefined ? '' : doc.data().address);
            setAddressHurigana(doc.data().addressHurigana === undefined ? '' : doc.data().addressHurigana);
            setPhoneNumber(doc.data().phoneNumber === undefined ? '' : doc.data().phoneNumber);
            setMailAddress(doc.data().mailAddress === undefined ? '' : doc.data().mailAddress);
            setInstagram(doc.data().instagram === undefined ? '' : doc.data().instagram);
            setInstagramFollower(doc.data().instagramFollower === undefined ? 0 : doc.data().instagramFollower);
            setTwitter(doc.data().twitter === undefined ? '' : doc.data().twitter);
            setTwitterFollower(doc.data().twitterFollower === undefined ? 0 : doc.data().twitterFollower);
            setTiktok(doc.data().tiktok === undefined ? '' : doc.data().tiktok);
            setTiktokFollower(doc.data().tiktokFollower === undefined ? 0 : doc.data().tiktokFollower);

            setHistoryYears1(doc.data().historyYears1 === undefined ? '' : doc.data().historyYears1);
            setHistoryMonth1(doc.data().historyMonth1 === undefined ? '' : doc.data().historyMonth1);
            setHistory1(doc.data().history1 === undefined ? '' : doc.data().history1);
            setHistoryYears2(doc.data().historyYears2 === undefined ? '' : doc.data().historyYears2);
            setHistoryMonth2(doc.data().historyMonth2 === undefined ? '' : doc.data().historyMonth2);
            setHistory2(doc.data().history2 === undefined ? '' : doc.data().history2);
            setHistoryYears3(doc.data().historyYears3 === undefined ? '' : doc.data().historyYears3);
            setHistoryMonth3(doc.data().historyMonth3 === undefined ? '' : doc.data().historyMonth3);
            setHistory3(doc.data().history3 === undefined ? '' : doc.data().history3);
            setHistoryYears4(doc.data().historyYears4 === undefined ? '' : doc.data().historyYears4);
            setHistoryMonth4(doc.data().historyMonth4 === undefined ? '' : doc.data().historyMonth4);
            setHistory4(doc.data().history4 === undefined ? '' : doc.data().history4);
            setHistoryYears5(doc.data().historyYears5 === undefined ? '' : doc.data().historyYears5);
            setHistoryMonth5(doc.data().historyMonth5 === undefined ? '' : doc.data().historyMonth5);
            setHistory5(doc.data().history5 === undefined ? '' : doc.data().history5);
            setHistoryYears6(doc.data().historyYears6 === undefined ? '' : doc.data().historyYears6);
            setHistoryMonth6(doc.data().historyMonth6 === undefined ? '' : doc.data().historyMonth6);
            setHistory6(doc.data().history6 === undefined ? '' : doc.data().history6);

            setAwardYears1(doc.data().awardYears1 === undefined ? '' : doc.data().awardYears1);
            setAwardMonth1(doc.data().awardMonth1 === undefined ? '' : doc.data().awardMonth1);
            setAward1(doc.data().award1 === undefined ? '' : doc.data().award1);
            setAwardYears2(doc.data().awardYears2 === undefined ? '' : doc.data().awardYears2);
            setAwardMonth2(doc.data().awardMonth2 === undefined ? '' : doc.data().awardMonth2);
            setAward2(doc.data().award2 === undefined ? '' : doc.data().award2);
            setAwardYears3(doc.data().awardYears3 === undefined ? '' : doc.data().awardYears3);
            setAwardMonth3(doc.data().awardMonth3 === undefined ? '' : doc.data().awardMonth3);
            setAward3(doc.data().award3 === undefined ? '' : doc.data().award3);
            setAwardYears4(doc.data().awardYears4 === undefined ? '' : doc.data().awardYears4);
            setAwardMonth4(doc.data().awardMonth4 === undefined ? '' : doc.data().awardMonth4);
            setAward4(doc.data().award4 === undefined ? '' : doc.data().award4);
          }
        })
      } else {
        history.push('/company/login')
      }
    })
  }, []);

  function getData(uid) {
    window.scrollTo(0, 0);

    firebase.firestore().collection("users").doc(uid).get()
    .then((doc) => {
      setUserList({...doc.data(), id: doc.id});
    });

    firebase.firestore().collection("companies").doc(company).get()
    .then((doc) => {
      setCompanyList({...doc.data(), id: doc.id});
    });
    
    firebase.firestore().collection("recruits").doc(recruit).get()
    .then((doc) => {
      setRecruitList({...doc.data(), id: doc.id});
    });
  }

  return (
    <>
    <Header />

    {progress === 1 ?
      <Confirm 
        uid={uid}
        userList={userList}
        companyList={companyList} 
        recruitList={recruitList}
        setProgress={setProgress}
      /> 
    : progress === 2 ?
      <Resume
        uid={uid}
        userList={userList}
        companyList={companyList} 
        recruitList={recruitList}
        setProgress={setProgress}

        idPhoto={idPhoto}
        setIdPhoto={setIdPhoto}
        idPhotoFile={idPhotoFile}
        setIdPhotoFile={setIdPhotoFile}

        name={name}
        setName={setName}
        nameHurigana={nameHurigana}
        setNameHurigana={setNameHurigana}
        birthdayYears={birthdayYears}
        setBirthdayYears={setBirthdayYears}
        birthdayMonth={birthdayMonth}
        setBirthdayMonth={setBirthdayMonth}
        birthdayDays={birthdayDays}
        setBirthdayDays={setBirthdayDays}
        age={age}
        setAge={setAge}
        sex={sex}
        setSex={setSex}
        bloodType={bloodType}
        setBloodType={setBloodType}

        postCode={postCode}
        setPostCode={setPostCode}
        address={address}
        setAddress={setAddress}
        addressHurigana={addressHurigana}
        setAddressHurigana={setAddressHurigana}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        mailAddress={mailAddress}
        setMailAddress={setMailAddress}
        
        instagram={instagram}
        setInstagram={setInstagram}
        instagramFollower={instagramFollower}
        setInstagramFollower={setInstagramFollower}
        twitter={twitter}
        setTwitter={setTwitter}
        twitterFollower={twitterFollower}
        setTwitterFollower={setTwitterFollower}
        tiktok={tiktok}
        setTiktok={setTiktok}
        tiktokFollower={tiktokFollower}
        setTiktokFollower={setTiktokFollower}

        historyYears1={historyYears1}
        setHistoryYears1={setHistoryYears1}
        historyMonth1={historyMonth1}
        setHistoryMonth1={setHistoryMonth1}
        history1={history1}
        setHistory1={setHistory1}
        historyYears2={historyYears2}
        setHistoryYears2={setHistoryYears2}
        historyMonth2={historyMonth2}
        setHistoryMonth2={setHistoryMonth2}
        history2={history2}
        setHistory2={setHistory2}
        historyYears3={historyYears3}
        setHistoryYears3={setHistoryYears3}
        historyMonth3={historyMonth3}
        setHistoryMonth3={setHistoryMonth3}
        history3={history3}
        setHistory3={setHistory3}
        historyYears4={historyYears4}
        setHistoryYears4={setHistoryYears4}
        historyMonth4={historyMonth4}
        setHistoryMonth4={setHistoryMonth4}
        history4={history4}
        setHistory4={setHistory4}
        historyYears5={historyYears5}
        setHistoryYears5={setHistoryYears5}
        historyMonth5={historyMonth5}
        setHistoryMonth5={setHistoryMonth5}
        history5={history5}
        setHistory5={setHistory5}
        historyYears6={historyYears6}
        setHistoryYears6={setHistoryYears6}
        historyMonth6={historyMonth6}
        setHistoryMonth6={setHistoryMonth6}
        history6={history6}
        setHistory6={setHistory6}

        awardYears1={awardYears1}
        setAwardYears1={setAwardYears1}
        awardMonth1={awardMonth1}
        setAwardMonth1={setAwardMonth1}
        award1={award1}
        setAward1={setAward1}
        awardYears2={awardYears2}
        setAwardYears2={setAwardYears2}
        awardMonth2={awardMonth2}
        setAwardMonth2={setAwardMonth2}
        award2={award2}
        setAward2={setAward2}
        awardYears3={awardYears3}
        setAwardYears3={setAwardYears3}
        awardMonth3={awardMonth3}
        setAwardMonth3={setAwardMonth3}
        award3={award3}
        setAward3={setAward3}
        awardYears4={awardYears4}
        setAwardYears4={setAwardYears4}
        awardMonth4={awardMonth4}
        setAwardMonth4={setAwardMonth4}
        award4={award4}
        setAward4={setAward4}

        hopeStore={hopeStore}
        setHopeStore={setHopeStore}
        motivation={motivation}
        setMotivation={setMotivation}
        prText={prText}
        setPrText={setPrText}
      />
    : progress === 3 ?
      <Question
        uid={uid}
        userList={userList}
        companyList={companyList} 
        recruitList={recruitList}
        setProgress={setProgress}

        question01Input={question01Input}
        question02Input={question02Input}
        question03Input={question03Input}

        setQuestion01Input={setQuestion01Input}
        setQuestion02Input={setQuestion02Input}
        setQuestion03Input={setQuestion03Input}
      />
    : progress === 4 ?
      <Cordination
        uid={uid}
        userList={userList}
        companyList={companyList} 
        recruitList={recruitList}
        setProgress={setProgress}

        coordinate1={coordinate1}
        coordinate1Title={coordinate1Title}
        coordinate1Point={coordinate1Point}
        coordinate2={coordinate2}
        coordinate2Title={coordinate2Title}
        coordinate2Point={coordinate2Point}
        coordinate3={coordinate3}
        coordinate3Title={coordinate3Title}
        coordinate3Point={coordinate3Point}

        setCoordinate1={setCoordinate1}
        setCoordinate1Title={setCoordinate1Title}
        setCoordinate1Point={setCoordinate1Point}
        setCoordinate2={setCoordinate2}
        setCoordinate2Title={setCoordinate2Title}
        setCoordinate2Point={setCoordinate2Point}
        setCoordinate3={setCoordinate3}
        setCoordinate3Title={setCoordinate3Title}
        setCoordinate3Point={setCoordinate3Point}

        coordinate1File={coordinate1File}
        setCoordinate1File={setCoordinate1File}
        coordinate2File={coordinate2File}
        setCoordinate2File={setCoordinate2File}
        coordinate3File={coordinate3File}
        setCoordinate3File={setCoordinate3File}
      />
    : progress === 5 ?
      <OtherDocument
        uid={uid}
        userList={userList}
        companyList={companyList} 
        recruitList={recruitList}
        setProgress={setProgress}

        document1File={document1File}
        setDocument1File={setDocument1File}
        document2File={document2File}
        setDocument2File={setDocument2File}
        document3File={document3File}
        setDocument3File={setDocument3File}
      />
    : progress === 6 ?
      <Apply
        uid={uid}
        userList={userList}
        companyList={companyList} 
        recruitList={recruitList}
        setProgress={setProgress}

        name={name}
        nameHurigana={nameHurigana}
        birthdayYears={birthdayYears}
        birthdayMonth={birthdayMonth}
        birthdayDays={birthdayDays}
        sex={sex}
        age={age}
        bloodType={bloodType}
        postCode={postCode}
        address={address}
        addressHurigana={addressHurigana}
        phoneNumber={phoneNumber}
        mailAddress={mailAddress}
        instagram={instagram}
        instagramFollower={instagramFollower}
        twitter={twitter}
        twitterFollower={twitterFollower}
        tiktok={tiktok}
        tiktokFollower={tiktokFollower}

        historyYears1={historyYears1}
        historyMonth1={historyMonth1}
        history1={history1}
        historyYears2={historyYears2}
        historyMonth2={historyMonth2}
        history2={history2}
        historyYears3={historyYears3}
        historyMonth3={historyMonth3}
        history3={history3}
        historyYears4={historyYears4}
        historyMonth4={historyMonth4}
        history4={history4}
        historyYears5={historyYears5}
        historyMonth5={historyMonth5}
        history5={history5}
        historyYears6={historyYears6}
        historyMonth6={historyMonth6}
        history6={history6}

        awardYears1={awardYears1}
        awardMonth1={awardMonth1}
        award1={award1}
        awardYears2={awardYears2}
        awardMonth2={awardMonth2}
        award2={award2}
        awardYears3={awardYears3}
        awardMonth3={awardMonth3}
        award3={award3}
        awardYears4={awardYears4}
        awardMonth4={awardMonth4}
        award4={award4}

        hopeStore={hopeStore}
        motivation={motivation}
        prText={prText}

        question01Input={question01Input}
        question02Input={question02Input}
        question03Input={question03Input}

        coordinate1={coordinate1}
        coordinate1Title={coordinate1Title}
        coordinate1Point={coordinate1Point}
        coordinate2={coordinate2}
        coordinate2Title={coordinate2Title}
        coordinate2Point={coordinate2Point}
        coordinate3={coordinate3}
        coordinate3Title={coordinate3Title}
        coordinate3Point={coordinate3Point}

        document1File={document1File}
        document2File={document2File}
        document3File={document3File}
      /> : <></> }
    </>
  );
}

export default Footer;

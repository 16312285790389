import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react'
import firebase from 'firebase/compat/app';
import Resume from './resume/index';
import Photo from './photo/index';
import Evaluation from './evaluation/index';
import { isMobile } from "react-device-detect";

const SalonTopic = (props) => {

  const [ resumeAppearSwich, setResumeAppearSwich ] = useState(1);
  const [ recruitList, setRecruitList ] = useState([]);
  const [ applicantsList, setApplicantsList ] = useState([]);

  useEffect(() => {

    firebase.firestore().collection('recruits').doc(props.recruitId).get()
    .then((doc) => {
      setRecruitList({...doc.data(), id: doc.id});
    });

    firebase.firestore().collection('applicants').doc(props.applicantId).get()
    .then((doc) => {
      setApplicantsList({...doc.data(), id: doc.id});
    });

  }, [props.recruitId, props.applicantId]);

  return (
    <>
    <div className={Styles.box}>
      <div>
        <div className={Styles.box_black} onClick={() => {props.setRecruitId(''); props.setApplicantId('');}}></div>
        <div className={Styles.mobile}>
          
          <div className={Styles.mobile_title}>
            <div>
              <h4 style={resumeAppearSwich === 1 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
                onClick={() => setResumeAppearSwich(1)}>履歴書</h4>
              <h4 style={resumeAppearSwich === 2 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
                onClick={() => setResumeAppearSwich(2)}>memorii photo</h4>
            </div>
          </div>

          <div className={Styles.mobile_contents}>

            {resumeAppearSwich === 1 && recruitList.length !== 0  && applicantsList.length !== 0 && props.userList.length !== 0 ?
              <Resume recruitList={recruitList} applicantsList={applicantsList} navigation={props.navigation} userList={props.userList} />
            : <></> }

            {resumeAppearSwich === 2 && recruitList.length !== 0  && applicantsList.length !== 0 && props.userList.length !== 0 ?
              <Photo uid={applicantsList.uid} />
            : <></> }

          </div>

        </div>
      </div>
    </div>
    </>
  );
}

export default SalonTopic;



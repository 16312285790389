import './styles.css';
import { isMobile } from "react-device-detect";
import Pc from "./pc/index";
import Mobile from "./mobile/index";

const MemoriiWorksManage = (props) => {

  return (
    <>
    {!isMobile && props.userList.length !== 0  && props.companyList.length !== 0 ?
      <Pc
        userList={props.userList}
        companyList={props.companyList}
        recruitList={props.recruitList}
        recruitListOnlyId={props.recruitListOnlyId} 
      />
    :
      <Mobile
        userList={props.userList}
        companyList={props.companyList}
        recruitList={props.recruitList}
        recruitListOnlyId={props.recruitListOnlyId} 
      />
    }
    </>
  );
}

export default MemoriiWorksManage;

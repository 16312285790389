import Styles from './styles.module.css';
import React from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ImCross } from "react-icons/im";
import Header from './../../6.header/index';
import firebase from "firebase/compat/app"
import ErrorMessage from '../../../../package/parts/error';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import BackModal from './../../11.back/index';
import DefaultInput1 from '../../../../package/parts/1-input';
import { v4 as uuid } from 'uuid';
import DefaultImage from './../../../../../../image/works/wait.png';

const EditStore = () => {

  const history = useHistory();

  // リンク
  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var recruit = decodeURIComponent(link.slice(link.indexOf('recruitId=') + 10, link.length));

  var time = new Date();

  const [ errorMessage, setErrorMessage ] = useState('');
  
  // 店舗名
  const [ name, setName ] = useState('');
  const [ title, setTitle ] = useState('');

  // 写真
  const [ imageBefore, setImageBefore ] = useState([]);
  const [ imageFiles, setImageFiles ] = useState([]);
  const [ imageFilesName, setImageFilesName ] = useState([]);
  const [ imageFilePaths, setImageFilePaths ] = useState([]);

  // 戻るボタン
  const [ backStatus, setBackStatus ] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        if (company !== '' && recruit !== '') {
          firebase.firestore().collection("recruits").doc(recruit).get()
          .then((doc) => {
            // 店舗情報 > 基本
            setName(doc.data().store_name === undefined ? '' : doc.data().store_name);
            setTitle(doc.data().store_title === undefined ? '' : doc.data().store_title);

            // 店舗情報 > 紹介
            setImageBefore(doc.data().store_imageName === undefined ? [] : doc.data().store_imageName)
            setImageFiles(doc.data().store_imageName === undefined ? [] : doc.data().store_imageName);
            setImageFilesName(doc.data().store_imageName === undefined ? [] : doc.data().store_imageName);
            setImageFilePaths(doc.data().store_imageName === undefined ? [] : doc.data().store_imageName);
          });
        }
      } else {
        history.push('/company/login')
      }
    })
  }, [history, company, recruit]);

  function check() {
    window.scrollTo(0, 0);
    if (name === '') {
      setErrorMessage('サロン名を入力してください。');
    } else if (title === '') {
      setErrorMessage('求人名を入力してください。');
    } else if (imageFiles.length === 0) {
      setErrorMessage('写真は1枚以上添付してください。');
    } else {
      save(true);
    }
  }

  async function save(e) {

    firebase.firestore().collection("recruits").doc(recruit)
    .update({
      status_store: e,
      // 店舗情報 > 基本
      store_name: name,
      store_title: title,

      // 店舗情報 > 情報
      store_imageName: imageFilesName,

      last_time: new Date(),
    }).then(() => {
      history.push(`/edit/id=${company}+page=2+recruitId=${recruit}`);
    })

    for (let i = 0; i < imageFilePaths.length; i++){
      if (imageFilesName[i].length === 36) {
        firebase.storage().ref().child(`/styles/${imageFilesName[i]}`).put(imageFilePaths[i]);
      }
    }

    for (let i = 0; i < imageBefore.length; i++){
      if (!imageFilePaths.includes(imageBefore[i])) {
        firebase.storage().ref().child(`/styles/${imageBefore[i]}`).delete();
        firebase.storage().ref().child(`/styles/resize_images/${imageBefore[i]}_1080x1080`).delete();
        firebase.storage().ref().child(`/styles/resize_images/${imageBefore[i]}_750x750`).delete();
        firebase.storage().ref().child(`/styles/resize_images/${imageBefore[i]}_500x500`).delete();
        firebase.storage().ref().child(`/styles/resize_images/${imageBefore[i]}_200x200`).delete();
      }
    }
  }

  const styleImages = e => {
    var styleImageTmp = [];
    var styleImagePathsTmp = [];
    var uid = uuid();

    if (e.target.files[0]) {
      styleImageTmp.push(URL.createObjectURL(e.target.files[0]));
      styleImagePathsTmp.push(e.target.files[0]);
      
      setImageFiles(imageFiles.length === 0 ? styleImageTmp : [...imageFiles, ...styleImageTmp]);
      setImageFilePaths(imageFilePaths.length === 0 ? styleImagePathsTmp : [...imageFilePaths, ...styleImagePathsTmp]);
      setImageFilesName([...imageFilesName, uid])
    }
  }
  
  return (
    <>
    <Header />

    <div className={Styles.store}>
      <div className={Styles.store_box}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.store_contents}>
          <h5 className={Styles.store_title}>店舗概要</h5>

          <DefaultInput1
            title={'サロン名'} required={true} length={30} place={'memorii美容院、memorii美容院 横浜店'} type={'text'}
            value={name} setValue={setName}
            desc={''}
          />

          <DefaultInput1
            title={'求人タイトル'} required={true} length={30} place={'<メディア掲載実績多数>　急成長中のトレンドサロンで美容師になりませんか？'} type={'text'}
            value={title} setValue={setTitle}
            desc={''}
          />

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>店舗写真（5枚まで）</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            {imageFiles.length >= 5 ? <></> :
              <div class={Styles.store_input_btn}>
                <label>
                  写真添付
                  <input type="file" onChange={styleImages} />
                </label>
              </div>
            }
          </div>

          <div className={Styles.image_prev}>

            {imageFiles.length === 0 ?
              <p className={Styles.desc}>写真が添付されていません。</p>
              :
              <p className={Styles.desc}></p>
            }

            {imageFiles.map((data, index) =>
              <div>
                <img src={data.length === 36 ? `https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${data}_500x500?alt=media&token=?${time.getTime()}` : data} alt=''
                  onError={e => {
                    e.target.onError = null;
                    e.target.src = DefaultImage;
                  }}/>
                <ImCross className={Styles.image_prev_icon}
                  onClick={() => {
                    var v = [ ...imageFiles ];
                    v.splice( index, 1 );
                    var array = v;
                    setImageFiles(array);
                    
                    var x = [ ...imageFilePaths ];
                    x.splice( index, 1 );
                    var arrays = x;
                    setImageFilePaths(arrays);
                    
                    var y = [ ...imageFilesName ];
                    y.splice( index, 1 );
                    var arrayss = y;
                    setImageFilesName(arrayss);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check()}>保存</button>
        </div>

      </div>
    </div>

    {backStatus ?
      <BackModal
        companyId={company}
        recruitId={recruit}
        setBackStatus={setBackStatus}
      />
    : <></> }

    </>
  );
}

export default EditStore;


import Styles from './styles.module.css';
import React from 'react';

const WorksModal = (props) => {

  return (
    <>
    <div className={Styles.box}>
      <div>
        <div className={Styles.box_black} onClick={() => props.setError(false)}></div>
        <div className={Styles.box_error}>
          <h4>{props.title}</h4>
          <p>{props.desc}</p>
          <div>
            <button onClick={() => props.setError(false)}>閉じる</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default WorksModal;

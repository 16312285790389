import Styles from './styles.module.css';
import 'firebase/auth'
import React from 'react-router-dom';
import { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import { useHistory } from 'react-router-dom';
import ErrorMessage from '../../../../package/parts/error/index';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import BackModal from '../../11.back/index';
import Header from '../../6.header/index';

const MemoriiWorksEditRecruit = () => {

  const history = useHistory();

  const [ errorMessage, setErrorMessage ] = useState('');
  
  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var recruit = decodeURIComponent(link.slice(link.indexOf('recruitId=') + 10, link.length));

  const [ hashTags1, setHashTags1 ] = useState([]);
  const [ hashTags2, setHashTags2 ] = useState([]);
  const [ hashTags3, setHashTags3 ] = useState([]);

  var 教育体制の特徴 = [
    'デビューまで2年以内',
    'SNS研修あり',
    '自社アカデミーあり',
    '動画研修制度あり',
    'セミナー参加費補助',
    'ウィッグ代補助',
    '奨学金返済サポートあり',
    '営業中の練習OK',
    '資格取得支援',
    '社内コンテストあり',
    '複数の教育体制から選択可',
    '複数のキャリアプランを選択可',
    '独立・開業支援あり',
    '海外研修あり',
  ]

  var 給与待遇福利厚生の特徴 = [
    '社会保険完備',
    'ボーナス支給実績あり',
    '寮・社宅あり',
    '住宅手当あり',
    '育児休暇あり',
    '車・バイク出勤可',
  ]

  var 働き方の特徴 = [
    '年間休日120日以上',
    '完全週休2日制',
    '残業月20時間以内',
    '20時までに退勤可能',
    '有給休暇消化率90%以上',
    '配属店舗の選択可',
    '時短勤務可',
  ]
    
  useEffect(() => {
    window.scrollTo(0, 0);
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {

        firebase.firestore().collection("recruits").doc(recruit)
        .get().then((doc) => {
          setHashTags1(doc.data().recruit_hashTag1 === undefined ? [] : doc.data().recruit_hashTag1);
          setHashTags2(doc.data().recruit_hashTag2 === undefined ? [] : doc.data().recruit_hashTag2);
          setHashTags3(doc.data().recruit_hashTag3 === undefined ? [] : doc.data().recruit_hashTag3);
        });
      } else {
        history.push('/company/login')
      }
    })

  }, [history, recruit])

  function check() {
    window.scrollTo(0, 0);

    if (0 === hashTags1.length) {
      setErrorMessage('教育体制の特徴を1つ以上選択してください。');
    } else if (0 === hashTags2.length) {
      setErrorMessage('給与・待遇・福利厚生の特徴を1つ以上選択してください。');
    } else if (0 === hashTags3.length) {
      setErrorMessage('働き方の特徴を1つ以上選択してください。');
    } else {
      save(true);
    }
  }

  function save(e) {
    firebase.firestore().collection("recruits").doc(recruit)
    .update({
      status_hashTags: e,

      recruit_hashTag: [ ...hashTags1, ...hashTags2, ...hashTags3, ],

      recruit_hashTag1: [ ...hashTags1 ],
      recruit_hashTag2: [ ...hashTags2 ],
      recruit_hashTag3: [ ...hashTags3 ],

      last_time: new Date(),
    }).then(() => {
      history.push(`/edit/id=${company}+page=2+recruitId=${recruit}`);
    });
  }

  function pushStyle1(value) {
    setHashTags1([...hashTags1, value])
  }

  function pushStyle2(value) {
    setHashTags2([...hashTags2, value])
  }

  function pushStyle3(value) {
    setHashTags3([...hashTags3, value])
  }

  const [ backStatus, setBackStatus ] = useState(false);

  return (
    <>

    <Header />

    <div className={Styles.box}>
      <div className={Styles.box_inline}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.contents}>

          <h5 className={Styles.title}>教育体制の特徴</h5>
          <div className={Styles.numberTag}>
            {教育体制の特徴.map((data, index) =>
              <div key={index}>
                <input type="checkbox"
                  checked={hashTags1.indexOf(data) !== -1 ? 'checked' : ''}
                  onChange={(e) => e.target.checked ? pushStyle1(data) : setHashTags1(hashTags1.filter((v, index) => (v !== data)))}/>
                <p>{data}</p>
              </div>
            )}
          </div>

        </div>

        <div className={Styles.contents}>

          <h5 className={Styles.title}>給与・待遇・福利厚生の特徴</h5>
          <div className={Styles.numberTag}>
            {給与待遇福利厚生の特徴.map((data, index) =>
              <div key={index}>
                <input type="checkbox"
                  checked={hashTags2.indexOf(data) !== -1 ? 'checked' : ''}
                  onChange={(e) => e.target.checked ? pushStyle2(data) : setHashTags2(hashTags2.filter((v, index) => (v !== data)))}/>
                <p>{data}</p>
              </div>
            )}
          </div>

        </div>

        <div className={Styles.contents}>

          <h5 className={Styles.title}>働き方の特徴</h5>
          <div className={Styles.numberTag}>
            {働き方の特徴.map((data, index) =>
              <div key={index}>
                <input type="checkbox"
                  checked={hashTags3.indexOf(data) !== -1 ? 'checked' : ''}
                  onChange={(e) => e.target.checked ? pushStyle3(data) : setHashTags3(hashTags3.filter((v, index) => (v !== data)))}/>
                <p>{data}</p>
              </div>
            )}
          </div>

        </div>

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check()}>保存</button>
        </div>
        
      </div>
    </div>

    {backStatus ?
      <BackModal
        companyId={company}
        recruitId={recruit}
        setBackStatus={setBackStatus}
      />
    : <></> }
    </>
  );
}

export default MemoriiWorksEditRecruit;
import Styles from './styles.module.css';
import React, { useState } from 'react';
import AccountEdit from "./../accountEdit/index";
import DefaultImg from "./../../../../image/works/default/home.png";
import DefaultAccountImg from "./../../../../image/works/default/account.png";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { IoMailOutline } from "react-icons/io5";
import { getAuth, signOut } from "firebase/auth";
import { useHistory } from 'react-router-dom';
import { IoPersonSharp } from "react-icons/io5";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { FaChevronDown } from "react-icons/fa";

const MemoriiWorksNavigation = (props) => {
  
  const history = useHistory();

  const [ accountEdit, setAccountEdit ] = useState(false);

  async function logOut() {
    const auth = getAuth();
    await signOut(auth).then(() => history.push('/company/login'));
  }

  return (
    <>
    <div className={Styles.header}>
      <div className={Styles.header_nav}>
        <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/companies_logo%2Fresize_images%2F${props.companyList.id}_200x200?alt=media&token=?${new Date().getTime()}`} alt=''
          onError={e => {
            e.target.onError = null;
            e.target.src = DefaultImg;
          }} />
        <h4>{props.companyList.name}</h4>
        {/* <FaChevronDown className={Styles.header_nav_icon} onClick={() => {history.push(`/works/select`)}} /> */}
      </div>
      
      <div className={Styles.header_link}>
        <div className={Styles.header_link_account}>
          <div className={Styles.header_link_account_info} onClick={() => props.setProfileSwitch(!props.profileSwitch)}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${props.userList.id}_200x200?alt=media&token=?${new Date().getTime()}`} alt=''
              onError={(e) => {
                e.target.onError = null;
                e.target.src = DefaultAccountImg;
              }}
            />
          </div>
          
          {props.profileSwitch ?
            <div className={Styles.header_link_accoun_nav}>
              <div onClick={() => {history.push(`/`)}}>
                <IoMdHelpCircleOutline className={Styles.header_link_accoun_nav_icon} />
                <p>お問い合わせ</p>
              </div>
              <div onClick={() => {history.push(`/`)}}>
                <IoMailOutline className={Styles.header_link_accoun_nav_icon} />
                <p>ヘルプ</p>
              </div>
              <div onClick={() => setAccountEdit(true)}>
                <IoPersonSharp className={Styles.header_link_accoun_nav_icon} />
                <p>アカウント</p>
              </div>
              <div>
                <RiLogoutBoxRLine className={Styles.header_link_accoun_nav_icon} />
                <p onClick={() => logOut()}>ログアウト</p>
              </div>
            </div>
          : <></> }
        </div>
      </div>
    </div>

    {accountEdit && props.userList.length !== 0 ?
      <AccountEdit userList={props.userList} setAccountEdit={setAccountEdit} />
    : <></> }
    </>
  );
}

export default MemoriiWorksNavigation;

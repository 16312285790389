import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from "firebase/compat/app";
import Header from "../2.edit/6.header/index";
import ErrorMessage from '../../package/parts/error';
import DefaultTitle from '../../package/parts/title';
import DefaultInput1 from '../../package/parts/1-input';

const MemoriiWorksHeader = (props) => {

  const history = useHistory();
  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var recruit = decodeURIComponent(link.slice(link.indexOf('recruitId=') + 10, link.length));

  const [ errorMessage, setErrorMessage ] = useState('');

  // 選考数
  const [ selection, setSelection ] = useState(0);

  // 1次選考 評価
  const [ selection1Count, setSelection1Count ] = useState(0);
  const [ selection1Contents, setSelection1Contents ] = useState('');

  const [ selection1Text1, setSelection1Text1 ] = useState('');
  const [ selection1Text2, setSelection1Text2 ] = useState('');
  const [ selection1Text3, setSelection1Text3 ] = useState('');
  const [ selection1Text4, setSelection1Text4 ] = useState('');
  const [ selection1Text5, setSelection1Text5 ] = useState('');
  const [ selection1Text6, setSelection1Text6 ] = useState('');
  const [ selection1Text7, setSelection1Text7 ] = useState('');
  const [ selection1Text8, setSelection1Text8 ] = useState('');

  // 二次選考 評価
  const [ selection2Count, setSelection2Count ] = useState(0);
  const [ selection2Contents, setSelection2Contents ] = useState('');

  const [ selection2Text1, setSelection2Text1 ] = useState('');
  const [ selection2Text2, setSelection2Text2 ] = useState('');
  const [ selection2Text3, setSelection2Text3 ] = useState('');
  const [ selection2Text4, setSelection2Text4 ] = useState('');
  const [ selection2Text5, setSelection2Text5 ] = useState('');
  const [ selection2Text6, setSelection2Text6 ] = useState('');
  const [ selection2Text7, setSelection2Text7 ] = useState('');
  const [ selection2Text8, setSelection2Text8 ] = useState('');

  // 3次選考 評価
  const [ selection3Count, setSelection3Count ] = useState(0);
  const [ selection3Contents, setSelection3Contents ] = useState('');

  const [ selection3Text1, setSelection3Text1 ] = useState('');
  const [ selection3Text2, setSelection3Text2 ] = useState('');
  const [ selection3Text3, setSelection3Text3 ] = useState('');
  const [ selection3Text4, setSelection3Text4 ] = useState('');
  const [ selection3Text5, setSelection3Text5 ] = useState('');
  const [ selection3Text6, setSelection3Text6 ] = useState('');
  const [ selection3Text7, setSelection3Text7 ] = useState('');
  const [ selection3Text8, setSelection3Text8 ] = useState('');

  // 4次選考 評価
  const [ selection4Count, setSelection4Count ] = useState(0);
  const [ selection4Contents, setSelection4Contents ] = useState('');

  const [ selection4Text1, setSelection4Text1 ] = useState('');
  const [ selection4Text2, setSelection4Text2 ] = useState('');
  const [ selection4Text3, setSelection4Text3 ] = useState('');
  const [ selection4Text4, setSelection4Text4 ] = useState('');
  const [ selection4Text5, setSelection4Text5 ] = useState('');
  const [ selection4Text6, setSelection4Text6 ] = useState('');
  const [ selection4Text7, setSelection4Text7 ] = useState('');
  const [ selection4Text8, setSelection4Text8 ] = useState('');

  // 5次選考 評価
  const [ selection5Count, setSelection5Count ] = useState(0);
  const [ selection5Contents, setSelection5Contents ] = useState('');

  const [ selection5Text1, setSelection5Text1 ] = useState('');
  const [ selection5Text2, setSelection5Text2 ] = useState('');
  const [ selection5Text3, setSelection5Text3 ] = useState('');
  const [ selection5Text4, setSelection5Text4 ] = useState('');
  const [ selection5Text5, setSelection5Text5 ] = useState('');
  const [ selection5Text6, setSelection5Text6 ] = useState('');
  const [ selection5Text7, setSelection5Text7 ] = useState('');
  const [ selection5Text8, setSelection5Text8 ] = useState('');


  useEffect(() => {
    firebase.firestore().collection("recruits").doc(recruit).get()
    .then((doc) => {
      setSelection(doc.data().exam_selection === undefined ? 0 : doc.data().exam_selection);

      // 1次選考
      setSelection1Count(doc.data().exam_selection1Count === undefined ? 0 : doc.data().exam_selection1Count);
      setSelection1Contents(doc.data().exam_selection1Contents === undefined ? '' : doc.data().exam_selection1Contents);
      setSelection1Text1(doc.data().exam_selection1Text1 === undefined ? '' : doc.data().exam_selection1Text1);
      setSelection1Text2(doc.data().exam_selection1Text2 === undefined ? '' : doc.data().exam_selection1Text2);
      setSelection1Text3(doc.data().exam_selection1Text3 === undefined ? '' : doc.data().exam_selection1Text3);
      setSelection1Text4(doc.data().exam_selection1Text4 === undefined ? '' : doc.data().exam_selection1Text4);
      setSelection1Text5(doc.data().exam_selection1Text5 === undefined ? '' : doc.data().exam_selection1Text5);
      setSelection1Text6(doc.data().exam_selection1Text6 === undefined ? '' : doc.data().exam_selection1Text6);
      setSelection1Text7(doc.data().exam_selection1Text7 === undefined ? '' : doc.data().exam_selection1Text7);
      setSelection1Text8(doc.data().exam_selection1Text8 === undefined ? '' : doc.data().exam_selection1Text8);

      // 二次選考
      setSelection2Count(doc.data().exam_selection2Count === undefined ? 0 : doc.data().exam_selection2Count);
      setSelection2Contents(doc.data().exam_selection2Contents === undefined ? '' : doc.data().exam_selection2Contents);
      setSelection2Text1(doc.data().exam_selection2Text1 === undefined ? '' : doc.data().exam_selection2Text1);
      setSelection2Text2(doc.data().exam_selection2Text2 === undefined ? '' : doc.data().exam_selection2Text2);
      setSelection2Text3(doc.data().exam_selection2Text3 === undefined ? '' : doc.data().exam_selection2Text3);
      setSelection2Text4(doc.data().exam_selection2Text4 === undefined ? '' : doc.data().exam_selection2Text4);
      setSelection2Text5(doc.data().exam_selection2Text5 === undefined ? '' : doc.data().exam_selection2Text5);
      setSelection2Text6(doc.data().exam_selection2Text6 === undefined ? '' : doc.data().exam_selection2Text6);
      setSelection2Text7(doc.data().exam_selection2Text7 === undefined ? '' : doc.data().exam_selection2Text7);
      setSelection2Text8(doc.data().exam_selection2Text8 === undefined ? '' : doc.data().exam_selection2Text8);

      // 3次選考
      setSelection3Count(doc.data().exam_selection3Count === undefined ? 0 : doc.data().exam_selection3Count);
      setSelection3Contents(doc.data().exam_selection3Contents === undefined ? '' : doc.data().exam_selection3Contents);
      setSelection3Text1(doc.data().exam_selection3Text1 === undefined ? '' : doc.data().exam_selection3Text1);
      setSelection3Text2(doc.data().exam_selection3Text2 === undefined ? '' : doc.data().exam_selection3Text2);
      setSelection3Text3(doc.data().exam_selection3Text3 === undefined ? '' : doc.data().exam_selection3Text3);
      setSelection3Text4(doc.data().exam_selection3Text4 === undefined ? '' : doc.data().exam_selection3Text4);
      setSelection3Text5(doc.data().exam_selection3Text5 === undefined ? '' : doc.data().exam_selection3Text5);
      setSelection3Text6(doc.data().exam_selection3Text6 === undefined ? '' : doc.data().exam_selection3Text6);
      setSelection3Text7(doc.data().exam_selection3Text7 === undefined ? '' : doc.data().exam_selection3Text7);
      setSelection3Text8(doc.data().exam_selection3Text8 === undefined ? '' : doc.data().exam_selection3Text8);

      // 4次選考
      setSelection4Count(doc.data().exam_selection4Count === undefined ? 0 : doc.data().exam_selection4Count);
      setSelection4Contents(doc.data().exam_selection4Contents === undefined ? '' : doc.data().exam_selection4Contents);
      setSelection4Text1(doc.data().exam_selection4Text1 === undefined ? '' : doc.data().exam_selection4Text1);
      setSelection4Text2(doc.data().exam_selection4Text2 === undefined ? '' : doc.data().exam_selection4Text2);
      setSelection4Text3(doc.data().exam_selection4Text3 === undefined ? '' : doc.data().exam_selection4Text3);
      setSelection4Text4(doc.data().exam_selection4Text4 === undefined ? '' : doc.data().exam_selection4Text4);
      setSelection4Text5(doc.data().exam_selection4Text5 === undefined ? '' : doc.data().exam_selection4Text5);
      setSelection4Text6(doc.data().exam_selection4Text6 === undefined ? '' : doc.data().exam_selection4Text6);
      setSelection4Text7(doc.data().exam_selection4Text7 === undefined ? '' : doc.data().exam_selection4Text7);
      setSelection4Text8(doc.data().exam_selection4Text8 === undefined ? '' : doc.data().exam_selection4Text8);
      
      // 5次選考
      setSelection5Count(doc.data().exam_selection5Count === undefined ? 0 : doc.data().exam_selection5Count);
      setSelection5Contents(doc.data().exam_selection5Contents === undefined ? '' : doc.data().exam_selection5Contents);
      setSelection5Text1(doc.data().exam_selection5Text1 === undefined ? '' : doc.data().exam_selection5Text1);
      setSelection5Text2(doc.data().exam_selection5Text2 === undefined ? '' : doc.data().exam_selection5Text2);
      setSelection5Text3(doc.data().exam_selection5Text3 === undefined ? '' : doc.data().exam_selection5Text3);
      setSelection5Text4(doc.data().exam_selection5Text4 === undefined ? '' : doc.data().exam_selection5Text4);
      setSelection5Text5(doc.data().exam_selection5Text5 === undefined ? '' : doc.data().exam_selection5Text5);
      setSelection5Text6(doc.data().exam_selection5Text6 === undefined ? '' : doc.data().exam_selection5Text6);
      setSelection5Text7(doc.data().exam_selection5Text7 === undefined ? '' : doc.data().exam_selection5Text7);
      setSelection5Text8(doc.data().exam_selection5Text8 === undefined ? '' : doc.data().exam_selection5Text8);
    });
  
  }, [])

  function check1() {
    window.scrollTo(0, 0);
    setErrorMessage('');

    if (1 <= selection && selection <= 5) {
      if (selection1Contents === '') {
        setErrorMessage('1次選考の選考方法を選択してください。');
      } else if (selection1Count === 0) {
        setErrorMessage('1次選考の評価数を選択してください。');
      } else if (1 <= selection1Count && selection1Count <= 8 && selection1Text1 === '') {
        setErrorMessage('1次選考の評価①を入力してください。');
      } else if (2 <= selection1Count && selection1Count <= 8 && selection1Text2 === '') {
        setErrorMessage('1次選考の評価②を入力してください。');
      } else if (3 <= selection1Count && selection1Count <= 8 && selection1Text3 === '') {
        setErrorMessage('1次選考の評価③を入力してください。');
      } else if (4 <= selection1Count && selection1Count <= 8 && selection1Text4 === '') {
        setErrorMessage('1次選考の評価④を入力してください。');
      } else if (5 <= selection1Count && selection1Count <= 8 && selection1Text5 === '') {
        setErrorMessage('1次選考の評価⑤を入力してください。');
      } else if (6 <= selection1Count && selection1Count <= 8 && selection1Text6 === '') {
        setErrorMessage('1次選考の評価⑥を入力してください。');
      } else if (7 <= selection1Count && selection1Count <= 8 && selection1Text7 === '') {
        setErrorMessage('1次選考の評価⑦を入力してください。');
      } else if (8 === selection1Count && selection1Text8 === '') {
        setErrorMessage('1次選考の評価⑧を入力してください。');
      } else {
        check2();
      }
    } else {
      save();
    }
  }

  function check2() {
    window.scrollTo(0, 0);
    setErrorMessage('');

    if (2 <= selection && selection <= 5) {
      if (selection2Contents === '') {
        setErrorMessage('2次選考の選考方法を選択してください。');
      } else if (selection2Count === 0) {
        setErrorMessage('2次選考の評価数を選択してください。');
      } else if (1 <= selection2Count && selection2Count <= 8 && selection2Text1 === '') {
        setErrorMessage('2次選考の評価①を入力してください。');
      } else if (2 <= selection2Count && selection2Count <= 8 && selection2Text2 === '') {
        setErrorMessage('2次選考の評価②を入力してください。');
      } else if (3 <= selection2Count && selection2Count <= 8 && selection2Text3 === '') {
        setErrorMessage('2次選考の評価③を入力してください。');
      } else if (4 <= selection2Count && selection2Count <= 8 && selection2Text4 === '') {
        setErrorMessage('2次選考の評価④を入力してください。');
      } else if (5 <= selection2Count && selection2Count <= 8 && selection2Text5 === '') {
        setErrorMessage('2次選考の評価⑤を入力してください。');
      } else if (6 <= selection2Count && selection2Count <= 8 && selection2Text6 === '') {
        setErrorMessage('2次選考の評価⑥を入力してください。');
      } else if (7 <= selection2Count && selection2Count <= 8 && selection2Text7 === '') {
        setErrorMessage('2次選考の評価⑦を入力してください。');
      } else if (8 === selection2Count && selection2Text8 === '') {
        setErrorMessage('2次選考の評価⑧を入力してください。');
      } else {
        check3();
      }
    } else {
      save();
    }
  }

  function check3() {
    window.scrollTo(0, 0);
    setErrorMessage('');

    if (3 <= selection && selection <= 5) {
      if (selection3Contents === '') {
        setErrorMessage('3次選考の選考方法を選択してください。');
      } else if (selection3Count === 0) {
        setErrorMessage('3次選考の評価数を選択してください。');
      } else if (1 <= selection3Count && selection3Count <= 8 && selection3Text1 === '') {
        setErrorMessage('3次選考の評価①を入力してください。');
      } else if (2 <= selection3Count && selection3Count <= 8 && selection3Text2 === '') {
        setErrorMessage('3次選考の評価②を入力してください。');
      } else if (3 <= selection3Count && selection3Count <= 8 && selection3Text3 === '') {
        setErrorMessage('3次選考の評価③を入力してください。');
      } else if (4 <= selection3Count && selection3Count <= 8 && selection3Text4 === '') {
        setErrorMessage('3次選考の評価④を入力してください。');
      } else if (5 <= selection3Count && selection3Count <= 8 && selection3Text5 === '') {
        setErrorMessage('3次選考の評価⑤を入力してください。');
      } else if (6 <= selection3Count && selection3Count <= 8 && selection3Text6 === '') {
        setErrorMessage('3次選考の評価⑥を入力してください。');
      } else if (7 <= selection3Count && selection3Count <= 8 && selection3Text7 === '') {
        setErrorMessage('3次選考の評価⑦を入力してください。');
      } else if (8 === selection3Count && selection3Text8 === '') {
        setErrorMessage('3次選考の評価⑧を入力してください。');
      } else {
        check4();
      }
    } else {
      save();
    }
  }

  function check4() {
    window.scrollTo(0, 0);
    setErrorMessage('');

    if (4 <= selection && selection <= 5) {
      if (selection4Contents === '') {
        setErrorMessage('4次選考の選考方法を選択してください。');
      } else if (selection4Count === 0) {
        setErrorMessage('4次選考の評価数を選択してください。');
      } else if (1 <= selection4Count && selection4Count <= 8 && selection4Text1 === '') {
        setErrorMessage('4次選考の評価①を入力してください。');
      } else if (2 <= selection4Count && selection4Count <= 8 && selection4Text2 === '') {
        setErrorMessage('4次選考の評価②を入力してください。');
      } else if (3 <= selection4Count && selection4Count <= 8 && selection4Text3 === '') {
        setErrorMessage('4次選考の評価③を入力してください。');
      } else if (4 <= selection4Count && selection4Count <= 8 && selection4Text4 === '') {
        setErrorMessage('4次選考の評価④を入力してください。');
      } else if (5 <= selection4Count && selection4Count <= 8 && selection4Text5 === '') {
        setErrorMessage('4次選考の評価⑤を入力してください。');
      } else if (6 <= selection4Count && selection4Count <= 8 && selection4Text6 === '') {
        setErrorMessage('4次選考の評価⑥を入力してください。');
      } else if (7 <= selection4Count && selection4Count <= 8 && selection4Text7 === '') {
        setErrorMessage('4次選考の評価⑦を入力してください。');
      } else if (8 === selection4Count && selection4Text8 === '') {
        setErrorMessage('4次選考の評価⑧を入力してください。');
      } else {
        check5();
      }
    } else {
      save();
    }
  }

  function check5() {
    window.scrollTo(0, 0);
    setErrorMessage('');

    if (5 === selection) {
      if (selection5Contents === '') {
        setErrorMessage('5次選考の選考方法を選択してください。');
      } else if (selection5Count === 0) {
        setErrorMessage('5次選考の評価数を選択してください。');
      } else if (1 <= selection5Count && selection5Count <= 8 && selection5Text1 === '') {
        setErrorMessage('5次選考の評価①を入力してください。');
      } else if (2 <= selection5Count && selection5Count <= 8 && selection5Text2 === '') {
        setErrorMessage('5次選考の評価②を入力してください。');
      } else if (3 <= selection5Count && selection5Count <= 8 && selection5Text3 === '') {
        setErrorMessage('5次選考の評価③を入力してください。');
      } else if (4 <= selection5Count && selection5Count <= 8 && selection5Text4 === '') {
        setErrorMessage('5次選考の評価④を入力してください。');
      } else if (5 <= selection5Count && selection5Count <= 8 && selection5Text5 === '') {
        setErrorMessage('5次選考の評価⑤を入力してください。');
      } else if (6 <= selection5Count && selection5Count <= 8 && selection5Text6 === '') {
        setErrorMessage('5次選考の評価⑥を入力してください。');
      } else if (7 <= selection5Count && selection5Count <= 8 && selection5Text7 === '') {
        setErrorMessage('5次選考の評価⑦を入力してください。');
      } else if (8 === selection5Count && selection5Text8 === '') {
        setErrorMessage('5次選考の評価⑧を入力してください。');
      } else {
        save();
      }
    } else {
      save();
    }
  }

  function save() {
    firebase.firestore().collection("recruits").doc(recruit)
    .update({
      exam_selection: Number(selection),

      // 1次選考
      exam_selection1Count: Number(selection1Count),
      exam_selection1Contents: selection1Contents,
      exam_selection1Text1: selection1Text1,
      exam_selection1Text2: selection1Text2,
      exam_selection1Text3: selection1Text3,
      exam_selection1Text4: selection1Text4,
      exam_selection1Text5: selection1Text5,
      exam_selection1Text6: selection1Text6,
      exam_selection1Text7: selection1Text7,
      exam_selection1Text8: selection1Text8,

      // 二次選考
      exam_selection2Count: Number(selection2Count),
      exam_selection2Contents: selection2Contents,
      exam_selection2Text1: selection2Text1,
      exam_selection2Text2: selection2Text2,
      exam_selection2Text3: selection2Text3,
      exam_selection2Text4: selection2Text4,
      exam_selection2Text5: selection2Text5,
      exam_selection2Text6: selection2Text6,
      exam_selection2Text7: selection2Text7,
      exam_selection2Text8: selection2Text8,

      // 3次選考
      exam_selection3Count: Number(selection3Count),
      exam_selection3Contents: selection3Contents,
      exam_selection3Text1: selection3Text1,
      exam_selection3Text2: selection3Text2,
      exam_selection3Text3: selection3Text3,
      exam_selection3Text4: selection3Text4,
      exam_selection3Text5: selection3Text5,
      exam_selection3Text6: selection3Text6,
      exam_selection3Text7: selection3Text7,
      exam_selection3Text8: selection3Text8,

      // 4次選考
      exam_selection4Count: Number(selection4Count),
      exam_selection4Contents: selection4Contents,
      exam_selection4Text1: selection4Text1,
      exam_selection4Text2: selection4Text2,
      exam_selection4Text3: selection4Text3,
      exam_selection4Text4: selection4Text4,
      exam_selection4Text5: selection4Text5,
      exam_selection4Text6: selection4Text6,
      exam_selection4Text7: selection4Text7,
      exam_selection4Text8: selection4Text8,
      
      // 5次選考
      exam_selection5Count: Number(selection5Count),
      exam_selection5Contents: selection5Contents,
      exam_selection5Text1: selection5Text1,
      exam_selection5Text2: selection5Text2,
      exam_selection5Text3: selection5Text3,
      exam_selection5Text4: selection5Text4,
      exam_selection5Text5: selection5Text5,
      exam_selection5Text6: selection5Text6,
      exam_selection5Text7: selection5Text7,
      exam_selection5Text8: selection5Text8,

      examinationStatus: true,

    }).then(() => {
      history.push(`/service/id=${company}+page=2`);
    })

  }

  return (
    <>
    <Header />

    <div className={Styles.box}>
      
      <div className={Styles.box_inline}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.contents}>
          <h5 className={Styles.title}>選考回数</h5>
          <DefaultTitle title={'選考回数'} required={true} />
          <select className={Styles.select}
            value={selection} onChange={(e) => setSelection(Number(e.target.value))}>
            <option value={0}>未選択</option>
            <option value={1}>1回</option>
            <option value={2}>2回</option>
            <option value={3}>3回</option>
            <option value={4}>4回</option>
            <option value={5}>5回</option>
          </select>
          <p className={Styles.desc}>　</p>
          <p className={Styles.desc}>　</p>
        </div>

        {1 <= selection && selection <= 5 ?
          <div className={Styles.contents}>

            <h5 className={Styles.title}>1次選考</h5>
            <DefaultTitle title={'1次選考'} required={true} />
            <select className={Styles.select}
              value={selection1Contents} onChange={(e) => setSelection1Contents(e.target.value)}>
              <option value=''>未選択</option>
              <option value='書類審査'>書類審査</option>
              <option value='面接'>面接</option>
              <option value='グループ面接'>グループ面接</option>
              <option value='技術チェック'>技術チェック</option>
              <option value='サロンワーク'>サロンワーク</option>
              <option value='グループディスカッション'>グループディスカッション</option>
            </select>
            <p className={Styles.desc}>　</p>

            <DefaultTitle title={'評価項目の数'} required={true} />
            <select className={Styles.select} value={selection1Count} onChange={(e) => setSelection1Count(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1つ</option>
              <option value={2}>2つ</option>
              <option value={3}>3つ</option>
              <option value={4}>4つ</option>
              <option value={5}>5つ</option>
              <option value={6}>6つ</option>
              <option value={7}>7つ</option>
              <option value={8}>8つ</option>
            </select>
            <p className={Styles.desc}>　</p>

            {1 <= Number(selection1Count) && Number(selection1Count) <= 8 ?
            <DefaultInput1
              title={'評価①'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection1Text1} setValue={setSelection1Text1}
              desc={'　'}
            /> : <></> }

            {2 <= Number(selection1Count) && Number(selection1Count) <= 8 ?
            <DefaultInput1
              title={'評価②'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection1Text2} setValue={setSelection1Text2}
              desc={'　'}
            /> : <></> }

            {3 <= Number(selection1Count) && Number(selection1Count) <= 8 ?
            <DefaultInput1
              title={'評価③'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection1Text3} setValue={setSelection1Text3}
              desc={'　'}
            /> : <></> }

            {4 <= Number(selection1Count) && Number(selection1Count) <= 8 ?
            <DefaultInput1
              title={'評価④'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection1Text4} setValue={setSelection1Text4}
              desc={'　'}
            /> : <></> }

            {5 <= Number(selection1Count) && Number(selection1Count) <= 8 ?
            <DefaultInput1
              title={'評価⑤'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection1Text5} setValue={setSelection1Text5}
              desc={'　'}
            /> : <></> }

            {6 <= Number(selection1Count) && Number(selection1Count) <= 8 ?
            <DefaultInput1
              title={'評価⑥'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection1Text6} setValue={setSelection1Text6}
              desc={'　'}
            /> : <></> }

            {7 <= Number(selection1Count) && Number(selection1Count) <= 8 ?
            <DefaultInput1
              title={'評価⑦'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection1Text7} setValue={setSelection1Text7}
              desc={'　'}
            /> : <></> }

            {8 <= Number(selection1Count) && Number(selection1Count) <= 8 ?
            <DefaultInput1
              title={'評価⑧'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection1Text8} setValue={setSelection1Text8}
              desc={'　'}
            /> : <></> }

          </div>
        : <></> }

        {2 <= selection && selection <= 5 ?
          <div className={Styles.contents}>

            <h5 className={Styles.title}>2次選考</h5>
            <DefaultTitle title={'2次選考'} required={true} />
            <select className={Styles.select}
              value={selection2Contents} onChange={(e) => setSelection2Contents(e.target.value)}>
              <option value=''>未選択</option>
              <option value='書類審査'>書類審査</option>
              <option value='面接'>面接</option>
              <option value='グループ面接'>グループ面接</option>
              <option value='技術チェック'>技術チェック</option>
              <option value='サロンワーク'>サロンワーク</option>
              <option value='グループディスカッション'>グループディスカッション</option>
            </select>
            <p className={Styles.desc}>　</p>

            <DefaultTitle title={'評価項目の数'} required={true} />
            <select className={Styles.select} value={selection2Count} onChange={(e) => setSelection2Count(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1つ</option>
              <option value={2}>2つ</option>
              <option value={3}>3つ</option>
              <option value={4}>4つ</option>
              <option value={5}>5つ</option>
              <option value={6}>6つ</option>
              <option value={7}>7つ</option>
              <option value={8}>8つ</option>
            </select>
            <p className={Styles.desc}>　</p>

            {1 <= Number(selection2Count) && Number(selection2Count) <= 8 ?
            <DefaultInput1
              title={'評価①'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection2Text1} setValue={setSelection2Text1}
              desc={'　'}
            /> : <></> }

            {2 <= Number(selection2Count) && Number(selection2Count) <= 8 ?
            <DefaultInput1
              title={'評価②'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection2Text2} setValue={setSelection2Text2}
              desc={'　'}
            /> : <></> }

            {3 <= Number(selection2Count) && Number(selection2Count) <= 8 ?
            <DefaultInput1
              title={'評価③'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection2Text3} setValue={setSelection2Text3}
              desc={'　'}
            /> : <></> }

            {4 <= Number(selection2Count) && Number(selection2Count) <= 8 ?
            <DefaultInput1
              title={'評価④'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection2Text4} setValue={setSelection2Text4}
              desc={'　'}
            /> : <></> }

            {5 <= Number(selection2Count) && Number(selection2Count) <= 8 ?
            <DefaultInput1
              title={'評価⑤'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection2Text5} setValue={setSelection2Text5}
              desc={'　'}
            /> : <></> }

            {6 <= Number(selection2Count) && Number(selection2Count) <= 8 ?
            <DefaultInput1
              title={'評価⑥'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection2Text6} setValue={setSelection2Text6}
              desc={'　'}
            /> : <></> }

            {7 <= Number(selection2Count) && Number(selection2Count) <= 8 ?
            <DefaultInput1
              title={'評価⑦'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection2Text7} setValue={setSelection2Text7}
              desc={'　'}
            /> : <></> }

            {8 <= Number(selection2Count) && Number(selection2Count) <= 8 ?
            <DefaultInput1
              title={'評価⑧'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection2Text8} setValue={setSelection2Text8}
              desc={'　'}
            /> : <></> }

          </div>
        : <></> }


        {3 <= selection && selection <= 5 ?
          <div className={Styles.contents}>

            <h5 className={Styles.title}>3次選考</h5>
            <DefaultTitle title={'3次選考'} required={true} />
            <select className={Styles.select}
              value={selection3Contents} onChange={(e) => setSelection3Contents(e.target.value)}>
              <option value=''>未選択</option>
              <option value='書類審査'>書類審査</option>
              <option value='面接'>面接</option>
              <option value='グループ面接'>グループ面接</option>
              <option value='技術チェック'>技術チェック</option>
              <option value='サロンワーク'>サロンワーク</option>
              <option value='グループディスカッション'>グループディスカッション</option>
            </select>
            <p className={Styles.desc}>　</p>

            <DefaultTitle title={'評価項目の数'} required={true} />
            <select className={Styles.select} value={selection3Count} onChange={(e) => setSelection3Count(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1つ</option>
              <option value={2}>2つ</option>
              <option value={3}>3つ</option>
              <option value={4}>4つ</option>
              <option value={5}>5つ</option>
              <option value={6}>6つ</option>
              <option value={7}>7つ</option>
              <option value={8}>8つ</option>
            </select>
            <p className={Styles.desc}>　</p>

            {1 <= Number(selection3Count) && Number(selection3Count) <= 8 ?
            <DefaultInput1
              title={'評価①'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection3Text1} setValue={setSelection3Text1}
              desc={'　'}
            /> : <></> }

            {2 <= Number(selection3Count) && Number(selection3Count) <= 8 ?
            <DefaultInput1
              title={'評価②'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection3Text2} setValue={setSelection3Text2}
              desc={'　'}
            /> : <></> }

            {3 <= Number(selection3Count) && Number(selection3Count) <= 8 ?
            <DefaultInput1
              title={'評価③'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection3Text3} setValue={setSelection3Text3}
              desc={'　'}
            /> : <></> }

            {4 <= Number(selection3Count) && Number(selection3Count) <= 8 ?
            <DefaultInput1
              title={'評価④'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection3Text4} setValue={setSelection3Text4}
              desc={'　'}
            /> : <></> }

            {5 <= Number(selection3Count) && Number(selection3Count) <= 8 ?
            <DefaultInput1
              title={'評価⑤'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection3Text5} setValue={setSelection3Text5}
              desc={'　'}
            /> : <></> }

            {6 <= Number(selection3Count) && Number(selection3Count) <= 8 ?
            <DefaultInput1
              title={'評価⑥'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection3Text6} setValue={setSelection3Text6}
              desc={'　'}
            /> : <></> }

            {7 <= Number(selection3Count) && Number(selection3Count) <= 8 ?
            <DefaultInput1
              title={'評価⑦'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection3Text7} setValue={setSelection3Text7}
              desc={'　'}
            /> : <></> }

            {8 <= Number(selection3Count) && Number(selection3Count) <= 8 ?
            <DefaultInput1
              title={'評価⑧'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection3Text8} setValue={setSelection3Text8}
              desc={'　'}
            /> : <></> }

          </div>
        : <></> }

        {4 <= selection && selection <= 5 ?
          <div className={Styles.contents}>

            <h5 className={Styles.title}>4次選考</h5>
            <DefaultTitle title={'4次選考'} required={true} />
            <select className={Styles.select}
              value={selection4Contents} onChange={(e) => setSelection4Contents(e.target.value)}>
              <option value=''>未選択</option>
              <option value='書類審査'>書類審査</option>
              <option value='面接'>面接</option>
              <option value='グループ面接'>グループ面接</option>
              <option value='技術チェック'>技術チェック</option>
              <option value='サロンワーク'>サロンワーク</option>
              <option value='グループディスカッション'>グループディスカッション</option>
            </select>
            <p className={Styles.desc}>　</p>

            <DefaultTitle title={'評価項目の数'} required={true} />
            <select className={Styles.select}
              value={selection4Count} onChange={(e) => setSelection4Count(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1つ</option>
              <option value={2}>2つ</option>
              <option value={3}>3つ</option>
              <option value={4}>4つ</option>
              <option value={5}>5つ</option>
              <option value={6}>6つ</option>
              <option value={7}>7つ</option>
              <option value={8}>8つ</option>
            </select>
            <p className={Styles.desc}>　</p>

            {1 <= Number(selection4Count) && Number(selection4Count) <= 8 ?
            <DefaultInput1
              title={'評価①'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection4Text1} setValue={setSelection4Text1}
              desc={'　'}
            /> : <></> }

            {2 <= Number(selection4Count) && Number(selection4Count) <= 8 ?
            <DefaultInput1
              title={'評価②'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection4Text2} setValue={setSelection4Text2}
              desc={'　'}
            /> : <></> }

            {3 <= Number(selection4Count) && Number(selection4Count) <= 8 ?
            <DefaultInput1
              title={'評価③'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection4Text3} setValue={setSelection4Text3}
              desc={'　'}
            /> : <></> }

            {4 <= Number(selection4Count) && Number(selection4Count) <= 8 ?
            <DefaultInput1
              title={'評価④'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection4Text4} setValue={setSelection4Text4}
              desc={'　'}
            /> : <></> }

            {5 <= Number(selection4Count) && Number(selection4Count) <= 8 ?
            <DefaultInput1
              title={'評価⑤'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection4Text5} setValue={setSelection4Text5}
              desc={'　'}
            /> : <></> }

            {6 <= Number(selection4Count) && Number(selection4Count) <= 8 ?
            <DefaultInput1
              title={'評価⑥'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection4Text6} setValue={setSelection4Text6}
              desc={'　'}
            /> : <></> }

            {7 <= Number(selection4Count) && Number(selection4Count) <= 8 ?
            <DefaultInput1
              title={'評価⑦'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection4Text7} setValue={setSelection4Text7}
              desc={'　'}
            /> : <></> }

            {8 <= Number(selection4Count) && Number(selection4Count) <= 8 ?
            <DefaultInput1
              title={'評価⑧'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection4Text8} setValue={setSelection4Text8}
              desc={'　'}
            /> : <></> }

          </div>
        : <></> }


        {5 <= selection ?
          <div className={Styles.contents}>

            <h5 className={Styles.title}>5次選考</h5>
            <DefaultTitle title={'5次選考'} required={true} />
            <select className={Styles.select}
              value={selection5Contents} onChange={(e) => setSelection5Contents(e.target.value)}>
              <option value=''>未選択</option>
              <option value='書類審査'>書類審査</option>
              <option value='面接'>面接</option>
              <option value='グループ面接'>グループ面接</option>
              <option value='技術チェック'>技術チェック</option>
              <option value='サロンワーク'>サロンワーク</option>
              <option value='グループディスカッション'>グループディスカッション</option>
            </select>
            <p className={Styles.desc}>　</p>

            <DefaultTitle title={'評価項目の数'} required={true} />
            <select className={Styles.select}
              value={selection5Count} onChange={(e) => setSelection5Count(e.target.value)}>
              <option value=''>未選択</option>
              <option value='1'>1つ</option>
              <option value='2'>2つ</option>
              <option value='3'>3つ</option>
              <option value='4'>4つ</option>
              <option value='5'>5つ</option>
              <option value='6'>6つ</option>
              <option value='7'>7つ</option>
              <option value='8'>8つ</option>
            </select>
            <p className={Styles.desc}>　</p>

            {1 <= Number(selection5Count) && Number(selection5Count) <= 8 ?
            <DefaultInput1
              title={'評価①'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection5Text1} setValue={setSelection5Text1}
              desc={'　'}
            /> : <></> }

            {2 <= Number(selection5Count) && Number(selection5Count) <= 8 ?
            <DefaultInput1
              title={'評価②'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection5Text2} setValue={setSelection5Text2}
              desc={'　'}
            /> : <></> }

            {3 <= Number(selection5Count) && Number(selection5Count) <= 8 ?
            <DefaultInput1
              title={'評価③'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection5Text3} setValue={setSelection5Text3}
              desc={'　'}
            /> : <></> }

            {4 <= Number(selection5Count) && Number(selection5Count) <= 8 ?
            <DefaultInput1
              title={'評価④'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection5Text4} setValue={setSelection5Text4}
              desc={'　'}
            /> : <></> }

            {5 <= Number(selection5Count) && Number(selection5Count) <= 8 ?
            <DefaultInput1
              title={'評価⑤'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection5Text5} setValue={setSelection5Text5}
              desc={'　'}
            /> : <></> }

            {6 <= Number(selection5Count) && Number(selection5Count) <= 8 ?
            <DefaultInput1
              title={'評価⑥'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection5Text6} setValue={setSelection5Text6}
              desc={'　'}
            /> : <></> }

            {7 <= Number(selection5Count) && Number(selection5Count) <= 8 ?
            <DefaultInput1
              title={'評価⑦'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection5Text7} setValue={setSelection5Text7}
              desc={'　'}
            /> : <></> }

            {8 <= Number(selection5Count) && Number(selection5Count) <= 8 ?
            <DefaultInput1
              title={'評価⑧'} required={true} length={20} place={'評価内容'} type={'text'}
              value={selection5Text8} setValue={setSelection5Text8}
              desc={'　'}
            /> : <></> }

          </div>
        : <></> }

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} 
            onClick={() => history.push(`/service/id=${company}+page=2`)}>戻る</button>
          <button className={Styles.save_box_save} onClick={() => check1()}>保存</button>
        </div>

      </div>

    </div>
    </>
  );
}

export default MemoriiWorksHeader;

import Styles from './../styles.module.css';
import React, { useState, } from 'react';
import firebase from 'firebase/compat/app';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';

const Footer = (props) => {

  const history = useHistory();

  var time = new Date();

  function before() {
    if (props.recruitList.document_otherDocument) {
      props.setProgress(5);
    } else if (props.recruitList.document_coordinate) {
      props.setProgress(4);
    } else if (props.recruitList.document_question) {
      props.setProgress(3);
    } else if (props.recruitList.document_resume) {
      props.setProgress(2);
    }
  }

  var docId = uuid();

  async function send() {

    firebase.firestore().collection('applicants').doc(docId)
    .set({
      recruitId: props.recruitList.id,
      uid: props.uid,
      companyId: props.companyList.id,

      // 証明写真
      name: props.name,
      nameHurigana: props.nameHurigana,
      birthdayYears: props.birthdayYears,
      birthdayMonth: props.birthdayMonth,
      birthdayDays: props.birthdayDays,
      age: props.age,
      sex: props.sex,
      bloodType: props.bloodType,
      postCode: props.postCode,
      address: props.address,
      addressHurigana: props.addressHurigana,
      phoneNumber: props.phoneNumber,
      mailAddress: props.mailAddress,
      instagram: props.instagram,
      instagramFollower: Number(props.instagramFollower),
      twitter: props.twitter,
      twitterFollower: Number(props.twitterFollower),
      tiktok: props.tiktok,
      tiktokFollower: Number(props.tiktokFollower),

      history1: props.history1,
      historyYears1: props.historyYears1,
      historyMonth1: props.historyMonth1,
      history2: props.history2,
      historyYears2: props.historyYears2,
      historyMonth2: props.historyMonth2,
      history3: props.history3,
      historyYears3: props.historyYears3,
      historyMonth3: props.historyMonth3,
      history4: props.history4,
      historyYears4: props.historyYears4,
      historyMonth4: props.historyMonth4,
      history5: props.history5,
      historyYears5: props.historyYears5,
      historyMonth5: props.historyMonth5,
      history6: props.history6,
      historyYears6: props.historyYears6,
      historyMonth6: props.historyMonth6,

      award1: props.award1,
      awardYears1: props.awardYears1,
      awardMonth1: props.awardMonth1,
      award2: props.award2,
      awardYears2: props.awardYears2,
      awardMonth2: props.awardMonth2,
      award3: props.award3,
      awardYears3: props.awardYears3,
      awardMonth3: props.awardMonth3,
      award4: props.award4,
      awardYears4: props.awardYears4,
      awardMonth4: props.awardMonth4,
      
      // 希望店舗
      hopeStore: props.hopeStore,
      motivation: props.motivation,
      prText: props.prText,
      
      // 質問集
      question01Text: props.question01Input,
      question02Text: props.question02Input,
      question03Text: props.question03Input,
      
      // コーディネート
      coordinate1Title: props.coordinate1Title,
      coordinate1Point: props.coordinate1Point,
      coordinate2Title: props.coordinate2Title,
      coordinate2Point: props.coordinate2Point,
      coordinate3Title: props.coordinate3Title,
      coordinate3Point: props.coordinate3Point,
      
      // 評価スケジュール
      examination: 1,
      examination1_schedule: [ new Date( 2024, 1, 1, ), new Date( 2024, 1, 1, ) ],
      examination2_schedule: [ new Date( 2024, 1, 1, ), new Date( 2024, 1, 1, ) ],
      examination3_schedule: [ new Date( 2024, 1, 1, ), new Date( 2024, 1, 1, ) ],
      examination4_schedule: [ new Date( 2024, 1, 1, ), new Date( 2024, 1, 1, ) ],
      examination5_schedule: [ new Date( 2024, 1, 1, ), new Date( 2024, 1, 1, ) ],

      examList1: [],
      examList2: [],
      examList3: [],
      examList4: [],
      examList5: [],

      memo: '',
      managerMemo: '',
      time: new Date(),
      last: new Date(),
      lastMessage: '',

      save: [],
    });

    var badge1 = {};
    var key1 = `${props.uid}_badge`;
    badge1[key1] = 0;

    var badge2 = {};
    var key2 = `${props.companyList.id}_badge`;
    badge2[key2] = 0;

    var badge3 = {};
    var key3 = props.uid;
    badge3[key3] = [ props.name, props.userList.id ];

    var badge4 = {};
    var key4 = props.companyList.id;
    badge4[key4] = [ props.companyList.name, props.companyList.id ];

    firebase.firestore().collection('chat').doc(docId)
    .set({
      chat_name: [ props.uid, props.recruitList.id ],
      chat_last: `この度は、${props.companyList.name}にご応募くださり、ありがとうございます。\n担当者より、ご連絡いたしますので、今後ともよろしくお願いします。`,
      chat_time: new Date(),
      chat_id: docId,
      applicantId: docId,
      lsat_send: props.recruitList.id,
      companyId: props.companyList.id,
      recruitId: props.recruitList.id,
      ...badge1,
      ...badge2,
      ...badge3,
      ...badge4,
    }).then(() => {
      firebase.firestore().collection('chat').doc(docId).collection('message').doc()
      .set({
        id: docId,
        message_receive: props.uid,
        message_send: props.companyList.id,
        message_text: `この度は、${props.companyList.name}にご応募くださり、ありがとうございます。\n担当者より、ご連絡いたしますので、今後ともよろしくお願いします。`,
        message_time: new Date(),
      });
    });
    
    firebase.firestore().collection('recruits').doc(props.recruitList.id)
    .update({
      data_applicants: firebase.firestore.FieldValue.arrayUnion(docId),
      data_apply: firebase.firestore.FieldValue.arrayUnion(new Date()),
    }).then(() => {
      history.push(`/done/id=${props.companyList.id}`);
    });
  }

  return (
    <>
    <div className={Styles.box}>

      <div className={Styles.box_inline}>

        <h5 className={Styles.title}>基本情報</h5>

        {props.recruitList.document_resume ?
        <>
        <table className={Styles.table}>
          <tr>
            <th style={{width : '150px'}}><strong><p>氏名</p></strong></th>
            <td><p>{props.name}</p></td>
          </tr>
          <tr>
            <th style={{width : '150px'}}><strong><p>氏名（フリガナ）</p></strong></th>
            <td><p>{props.nameHurigana}</p></td>
          </tr>
          <tr>
            <th style={{width : '150px'}}><strong><p>生年月日</p></strong></th>
            <td><p>{props.birthdayYears}年{props.birthdayMonth}月{props.birthdayDays}日</p></td>
          </tr>
          <tr>
            <th style={{width : '150px'}}><strong><p>性別</p></strong></th>
            <td><p>{props.sex}</p></td>
          </tr>
          <tr>
            <th style={{width : '150px'}}><strong><p>血液型</p></strong></th>
            <td><p>{props.bloodType}</p></td>
          </tr>
          <tr>
            <th style={{width : '150px'}}><strong><p>郵便番号</p></strong></th>
            <td><p>{props.postCode}</p></td>
          </tr>
          <tr>
            <th style={{width : '150px'}}><strong><p>住所</p></strong></th>
            <td><p>{props.address}</p></td>
          </tr>
          <tr>
            <th style={{width : '150px'}}><strong><p>住所（フリガナ）</p></strong></th>
            <td><p>{props.addressHurigana}</p></td>
          </tr>
          <tr>
            <th style={{width : '150px'}}><strong><p>電話番号</p></strong></th>
            <td><p>{props.phoneNumber}</p></td>
          </tr>
          <tr>
            <th style={{width : '150px'}}><strong><p>メールアドレス</p></strong></th>
            <td><p>{props.mailAddress}</p></td>
          </tr>
          <tr>
            <th style={{width : '150px'}}><strong><p>instgramアカウント</p></strong></th>
            <td><p>{props.instagram}</p></td>
          </tr>
          <tr>
            <th style={{width : '150px'}}><strong><p>instgramフォロワー数</p></strong></th>
            <td><p>{props.instagramFollower}</p></td>
          </tr>
          <tr>
            <th style={{width : '150px'}}><strong><p>Twitter(X)アカウント</p></strong></th>
            <td><p>{props.twitter}</p></td>
          </tr>
          <tr>
            <th style={{width : '150px'}}><strong><p>Twitter(X)フォロワー数</p></strong></th>
            <td><p>{props.twitterFollower}</p></td>
          </tr>
          <tr>
            <th style={{width : '150px'}}><strong><p>TikTokアカウント</p></strong></th>
            <td><p>{props.tiktok}</p></td>
          </tr>
          <tr>
            <th style={{width : '150px'}}><strong><p>TikTokフォロワー数</p></strong></th>
            <td><p>{props.tiktokFollower}</p></td>
          </tr>
        </table>

        <h5 className={Styles.title}>学歴・職歴</h5>
        <table className={Styles.table}>
          <tr>
            <th style={{width : '100px'}}><strong><p>{props.historyYears1}月{props.historyMonth1}日</p></strong></th>
          </tr>
          <tr>
            <td style={{width : '100px'}}>{props.history1}</td>
          </tr>
          <tr>
            <th style={{widtd : '100px'}}><strong><p>{props.historyYears2}月{props.historyMonth2}日</p></strong></th>
          </tr>
          <tr>
            <td style={{width : '100px'}}>{props.history2}</td>
          </tr>
          <tr>
            <th style={{width : '100px'}}><strong><p>{props.historyYears3}月{props.historyMonth3}日</p></strong></th>
          </tr>
          <tr>
            <td style={{width : '100px'}}>{props.history3}</td>
          </tr>
          <tr>
            <th style={{widtd : '100px'}}><strong><p>{props.historyYears4}月{props.historyMonth4}日</p></strong></th>
          </tr>
          <tr>
            <td style={{width : '100px'}}>{props.history4}</td>
          </tr>
          <tr>
            <th style={{width : '100px'}}><strong><p>{props.historyYears5}月{props.historyMonth5}日</p></strong></th>
          </tr>
          <tr>
            <td style={{width : '100px'}}>{props.history5}</td>
          </tr>
          <tr>
            <th style={{widtd : '100px'}}><strong><p>{props.historyYears6}月{props.historyMonth6}日</p></strong></th>
          </tr>
          <tr>
            <td style={{width : '100px'}}>{props.history6}</td>
          </tr>
        </table>

        <h5 className={Styles.title}>資格取得・受賞歴</h5>
        <table className={Styles.table}>
          <tr>
            <th style={{width : '100px'}}><strong><p>{props.awardYears1}月{props.awardMonth1}日</p></strong></th>
          </tr>
          <tr>
            <td style={{width : '100px'}}>{props.award1}</td>
          </tr>
          <tr>
            <th style={{widtd : '100px'}}><strong><p>{props.awardYears2}月{props.awardMonth2}日</p></strong></th>
          </tr>
          <tr>
            <td style={{width : '100px'}}>{props.award2}</td>
          </tr>
          <tr>
            <th style={{width : '100px'}}><strong><p>{props.awardYears3}月{props.awardMonth3}日</p></strong></th>
          </tr>
          <tr>
            <td style={{width : '100px'}}>{props.award3}</td>
          </tr>
        </table>

        <h5 className={Styles.title}>入力欄</h5>
        <table className={Styles.table}>
          <tr>
            <th style={{width : '100px'}}><strong><p>希望店舗</p></strong></th>
          </tr>
          <tr>
            <td style={{width : '100px'}}>
              {1 <= props.hopeStore.length && props.hopeStore.length <= 5 ?
              <>
                第1希望：{props.hopeStore[0]}<br />
              </> : <></> }
              {2 <= props.hopeStore.length && props.hopeStore.length <= 5 ?
              <>
                第2希望：{props.hopeStore[1]}<br />
              </> : <></> }
              {3 <= props.hopeStore.length && props.hopeStore.length <= 5 ?
              <>
                第3希望：{props.hopeStore[2]}<br />
              </> : <></> }
              {4 <= props.hopeStore.length && props.hopeStore.length <= 5 ?
              <>
                第4希望：{props.hopeStore[3]}<br />
              </> : <></> }
              {5 <= props.hopeStore.length && props.hopeStore.length <= 5 ?
              <>
                第5希望：{props.hopeStore[4]}<br />
              </> : <></> }
            </td>
          </tr>
          <tr>
            <th style={{width : '100px'}}><strong><p>志望動機</p></strong></th>
          </tr>
          <tr>
            <td style={{width : '100px'}}>{props.motivation}</td>
          </tr>
          <tr>
            <th style={{widtd : '100px'}}><strong><p>自己PR</p></strong></th>
          </tr>
          <tr>
            <td style={{width : '100px'}}>{props.prText}</td>
          </tr>
        </table>
        </> : <></>}

        {props.recruitList.document_question ?
        <>
        <h5 className={Styles.title}>追加質問</h5>
        <table className={Styles.table}>
          {1 <= props.recruitList.document_questionCount && props.recruitList.document_questionCount <= 3 ?
          <>
            <tr>
              <th style={{width : '100px'}}><strong><p>{props.recruitList.document_question01}</p></strong></th>
            </tr>
            <tr>
              <td style={{width : '100px'}}>{props.question01Input}</td>
            </tr>
          </> : <></> }
          {2 <= props.recruitList.document_questionCount && props.recruitList.document_questionCount <= 3 ?
          <>
            <tr>
              <th style={{widtd : '100px'}}><strong><p>{props.recruitList.document_question02}</p></strong></th>
            </tr>
            <tr>
              <td style={{width : '100px'}}>{props.question02Input}</td>
            </tr>
          </> : <></> }
          {3 === props.recruitList.document_questionCount ?
          <>
            <tr>
              <th style={{widtd : '100px'}}><strong><p>{props.recruitList.document_question03}</p></strong></th>
            </tr>
            <tr>
              <td style={{width : '100px'}}>{props.question03Input}</td>
            </tr>
          </> : <></> }
        </table>
        </> : <></> }
        
        {props.recruitList.document_coordinate ?
        <>
        <h5 className={Styles.title}>コーディネート</h5>
        <table className={Styles.table}>
          <tr>
            <th style={{width : '100px'}}><strong><p>1.写真</p></strong></th>
            <td>
              <img style={{height : '150px'}}
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/coordination%2Fresize_images%2F${props.recruitList.id}_${props.uid}_1_1080x1080?alt=media&token=?${time.getTime()}`} alt='' />
            </td>
          </tr>
          <tr>
            <th style={{width : '100px'}}><strong><p>1.タイトル</p></strong></th>
            <td><p>{props.coordinate1Title}</p></td>
          </tr>
          <tr>
            <th style={{width : '100px'}}><strong><p>1.ポイント</p></strong></th>
            <td><p>{props.coordinate1Point}</p></td>
          </tr>
          <tr>
            <th style={{width : '100px'}}><strong><p>2.写真</p></strong></th>
            <td>
              <img style={{height : '150px'}}
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/coordination%2Fresize_images%2F${props.recruitList.id}_${props.uid}_2_1080x1080?alt=media&token=?${time.getTime()}`} alt='' />
            </td>
          </tr>
          <tr>
            <th style={{width : '100px'}}><strong><p>2.タイトル</p></strong></th>
            <td><p>{props.coordinate2Title}</p></td>
          </tr>
          <tr>
            <th style={{width : '100px'}}><strong><p>2.ポイント</p></strong></th>
            <td><p>{props.coordinate2Point}</p></td>
          </tr>
          <tr>
            <th style={{width : '100px'}}><strong><p>3.写真</p></strong></th>
            <td>
              <img style={{height : '150px'}}
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/coordination%2Fresize_images%2F${props.recruitList.id}_${props.uid}_3_1080x1080?alt=media&token=?${time.getTime()}`} alt='' />
            </td>
          </tr>
          <tr>
            <th style={{width : '100px'}}><strong><p>3.タイトル</p></strong></th>
            <td><p>{props.coordinate3Title}</p></td>
          </tr>
          <tr>
            <th style={{width : '100px'}}><strong><p>3.ポイント</p></strong></th>
            <td><p>{props.coordinate3Point}</p></td>
          </tr>
        </table>
        </> : <></> }

        {props.recruitList.document_otherDocument ?
        <>
        <h5 className={Styles.title}>その他の添付書類</h5>
        <table className={Styles.table}>
          {1 <= props.recruitList.document_otherDocumentCount && props.recruitList.document_otherDocumentCount <= 3 ?
          <>
            <tr>
              <th style={{widtd : '100px'}}><strong><p>{props.recruitList.document_otherDocument01}</p></strong></th>
            </tr>
            <tr>
              <td style={{width : '100px'}}>
                <a href={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/document%2F${props.recruitList.id}_${props.uid}_1?alt=media&token=?${time.getTime()}`} style={{color: '#222222'}} target="_blank">プレビュー</a>
              </td>
            </tr>
          </> : <></> }
          {2 <= props.recruitList.document_otherDocumentCount && props.recruitList.document_otherDocumentCount <= 3 ?
          <>
            <tr>
              <th style={{widtd : '100px'}}><strong><p>{props.recruitList.document_otherDocument02}</p></strong></th>
            </tr>
            <tr>
              <td style={{width : '100px'}}>
                <a href={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/document%2F${props.recruitList.id}_${props.uid}_2?alt=media&token=?${time.getTime()}`} style={{color: '#222222'}}  target="_blank">プレビュー</a>
              </td>
            </tr>
          </> : <></> }
          {props.recruitList.document_otherDocumentCount == 3 ?
          <>
            <tr>
              <th style={{widtd : '100px'}}><strong><p>{props.recruitList.document_otherDocument03}</p></strong></th>
            </tr>
            <tr>
              <td style={{width : '100px'}}>
                <a href={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/document%2F${props.recruitList.id}_${props.uid}_3?alt=media&token=08b6a1ef-5019-48fc-92de-6e2d176eee8c`} style={{color: '#222222'}} target="_blank">プレビュー</a>
              </td>
            </tr>
          </> : <></> }
        </table>
        </> : <></> }
      </div>

      <div className={Styles.save}>
        <button className={Styles.save_back} onClick={() => before()}>戻る</button>
        <button className={Styles.save_save} onClick={() => send()}>応募</button>
      </div>

    </div>
    </>
  );
}

export default Footer;

import React from 'react';
import firebase from 'firebase/compat/app';
import Styles from './styles.module.css';
import { FcGoogle } from 'react-icons/fc';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useHistory } from 'react-router-dom';

const AuthGoogle = (props) => {
  
  const history = useHistory();

  // google
  function google() {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    localStorage.clear();
    firebase.auth().signOut().then(() => {});

    if (localStorage.saveUid === undefined) {
      signInWithPopup(auth, provider)
      .then((result) => {
        localStorage.saveUid = firebase.auth().currentUser.uid;
        history.push('/select');
      });
    } else {
      props.setError(true); 
      // localStorage.saveUid = firebase.auth().currentUser.uid;
      // history.push('/select');
    }
  }

  return (
    <>
    <div className={Styles.signIn_box} onClick={() => google()}>
      <FcGoogle className={Styles.signIn_box_icon} />
      <h4>Googleアカウントでログインする</h4>
    </div>
    </>
  );
}

export default AuthGoogle;

import Styles from './../styles.module.css';
import React, { useState, useEffect } from 'react'
import firebase from 'firebase/compat/app';
import ErrorMessage from '../../../service/package/parts/error/index';
import DefaultTitle from '../../../service/package/parts/title/index';
import DefaultInput1 from '../../../service/package/parts/1-input';
import DefaultSelect1 from '../../../service/package/parts/1-select';
import DefaultTime2 from '../../../service/package/parts/2-input-time';
import DefaultTextarea1 from '../../../service/package/parts/1-textarea';

const Footer = (props) => {
  
  const [ errorMessage, setErrorMessage ] = useState('');

  function check() {

    window.scrollTo(0, 0);
    setErrorMessage('');

    if (props.idPhotoFile === '') {
      setErrorMessage('証明写真を添付してください。');
    } else if (props.name === '') {
      setErrorMessage('氏名を入力してください。');
    } else if (props.nameHurigana === '') {
      setErrorMessage('氏名（フリガナ）を入力してください。');
    } else if (props.birthdayYears === 0 || props.birthdayYears === '' || props.birthdayMonth === 0 || props.birthdayMonth === '' || props.birthdayDays === 0 || props.birthdayDays === '') {
      setErrorMessage('生年月日を入力してください。');
    } else if (props.age === 0 || props.age === '') {
      setErrorMessage('満年齢を入力してください。');
    } else if (props.sex === '') {
      setErrorMessage('性別を入力してください。');
    } else if (props.bloodType === '') {
      setErrorMessage('血液型を入力してください。');
    } else if (props.postCode === '') {
      setErrorMessage('郵便番号を入力してください。');
    } else if (props.address === '') {
      console.log(props.address);
      setErrorMessage('住所を入力してください。');
    } else if (props.addressHurigana === '') {
      setErrorMessage('住所(フリガナ)を入力してください。');
    } else if (props.mailAddress === '') {
      setErrorMessage('メールアドレスを入力してください。');
    } else if (props.phoneNumber === '' || props.phoneNumber === 0) {
      setErrorMessage('電話番号を入力してください。');
    } else if (props.historyYears1 === '' || props.historyYears1 === 0 || props.historyMonth1 === '' || props.historyMonth1 === 0) {
      setErrorMessage('学歴・職歴1の時期を入力してください。');
    } else if (props.history1 === '') {
      setErrorMessage('学歴・職歴1を入力してください。');
    } else if (props.hopeStore.length === 0) {
      setErrorMessage('希望店舗を1つ以上選択してください。');
    } else if (props.motivation === '') {
      setErrorMessage('志望動機を入力してください。');
    } else if (props.prText === '') {
      setErrorMessage('自己PRを入力してください。');
    } else {
      save();
    }
  }

  function save() {
    firebase.firestore().collection('users').doc(props.uid).collection('resume').doc(props.uid)
    .set({
      name: props.name,
      nameHurigana: props.nameHurigana,
      birthdayYears: props.birthdayYears,
      birthdayMonth: props.birthdayMonth,
      birthdayDays: props.birthdayDays,
      sex: props.sex,
      age: props.age,
      bloodType: props.bloodType,

      postCode: props.postCode,
      address: props.address,
      addressHurigana: props.addressHurigana,
      phoneNumber: props.phoneNumber,
      mailAddress: props.mailAddress,
      instagram: props.instagram,
      instagramFollower: props.instagramFollower,
      twitter: props.twitter,
      twitterFollower: props.twitterFollower,
      tiktok: props.tiktok,
      tiktokFollower: props.tiktokFollower,

      historyYears1: props.historyYears1,
      historyMonth1: props.historyMonth1,
      history1: props.history1,
      historyYears2: props.historyYears2,
      historyMonth2: props.historyMonth2,
      history2: props.history2,
      historyYears3: props.historyYears3,
      historyMonth3: props.historyMonth3,
      history3: props.history3,
      historyYears4: props.historyYears4,
      historyMonth4: props.historyMonth4,
      history4: props.history4,
      historyYears5: props.historyYears5,
      historyMonth5: props.historyMonth5,
      history5: props.history5,
      historyYears6: props.historyYears6,
      historyMonth6: props.historyMonth6,
      history6: props.history6,

      awardYears1: props.awardYears1,
      awardMonth1: props.awardMonth1,
      award1: props.award1,
      awardYears2: props.awardYears2,
      awardMonth2: props.awardMonth2,
      award2: props.award2,
      awardYears3: props.awardYears3,
      awardMonth3: props.awardMonth3,
      award3: props.award3,
      awardYears4: props.awardYears4,
      awardMonth4: props.awardMonth4,
      award4: props.award4,

      prText: props.prText,
      motivation: props.motivation,
    }).then(() => {
      if (props.recruitList.document_question) {
        props.setProgress(3);
      } else if (props.recruitList.document_coordinate) {
        props.setProgress(4);
      } else if (props.recruitList.document_otherDocument) {
        props.setProgress(5);
      } else {
        props.setProgress(6);
      }
    })
  }
  
  const idPhotoImage = e => {
    if (e.target.files[0]) {
      props.setIdPhotoFile(URL.createObjectURL(e.target.files[0]))
      firebase.storage().ref().child(`resume/${props.uid}_${props.recruitList.id}`).put(e.target.files[0]);
    }
  }

  return (
    <>
    <div className={Styles.box}>
      {errorMessage !== '' ? 
        <ErrorMessage message={errorMessage} />
      : <></> }

      <div className={Styles.box_inline}>
        <h5 className={Styles.title}>基本情報</h5>

        <DefaultTitle title={'証明写真'} required={true} />
        <div className={Styles.idPhoto}>
          {props.idPhotoFile === '' ?
            <div className={Styles.idPhoto_input}>
              <p>証明写真を添付する</p>
              <input type='file' onChange={idPhotoImage} />
            </div>
            :
            <div className={Styles.idPhoto_image}>
              <p>証明写真を変更する</p>
              <img src={props.idPhotoFile} />
              <input type='file' onChange={idPhotoImage} />
            </div>
          }
          <div className={Styles.idPhoto_image}>
            <img src='https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/resume%2Fresize_images%2Fsample_500x500.webp?alt=media&token=827bbad4-1b8a-4a94-8b80-24199d9e8100' />
          </div>
        </div>
        <p className={Styles.desc}>　</p>
        
        <DefaultInput1
          title={'氏名'} required={true} length={20} place={'鈴木　太郎'} type={'text'}
          value={props.name} setValue={props.setName}
          desc={'　'}
        />

        <DefaultInput1
          title={'氏名（フリガナ）'} required={true} length={20} place={'スズキ　タロウ'} type={'text'}
          value={props.nameHurigana} setValue={props.setNameHurigana}
          desc={'　'}
        />

        <DefaultTitle title={'生年月日'} required={true} />
        <div className={Styles.birthday}>
          <input placeholder='2000' type='number' value={props.birthdayYears} onChange={(e) => props.setBirthdayYears(e.target.value)} />
          <p>年</p>
          <input placeholder='12' type='number' value={props.birthdayMonth} onChange={(e) => props.setBirthdayMonth(e.target.value)} />
          <p>月</p>
          <input placeholder='10' type='number' value={props.birthdayDays} onChange={(e) => props.setBirthdayDays(e.target.value)} />
          <p>日</p>
        </div>
        <p className={Styles.desc}>※半角数字で入力してください。</p>

        <DefaultInput1
          title={'満年齢'} required={true} length={2} place={'20'} type={'number'}
          value={props.age} setValue={props.setAge}
          desc={'　'}
        />

        <DefaultSelect1
          title={'性別'} required={true} length={0} place={''} type={'text'}
          value={props.sex} setValue={props.setSex}
          list={['未選択', '男', '女', '該当しない']}
          desc={'　'}
        />

        <DefaultSelect1
          title={'血液型'} required={true} length={0} place={''} type={'text'}
          value={props.bloodType} setValue={props.setBloodType}
          list={['未選択', 'A型', 'B型', 'AB型', 'O型', '不明',]}
          desc={'　'}
        />

        <DefaultTitle title={'郵便番号'} required={true} />
        <input className={Styles.post_input}
          placeholder='2220011' value={props.postCode} 
          onChange={(e) => {
            props.setPostCode(e.target.value);
            fetch(`https://api.zipaddress.net/?zipcode=${e.target.value}`)
            .then(response => response.json())
            .then(data => {
              props.setAddress(data.data.fullAddress);
            }).catch(err => {})
          }} />
        <p className={Styles.desc}>※半角数字（ハイフン無し）で入力してください。</p>

        <DefaultInput1
          title={'住所'} required={true} length={50} place={'カナガワケンヨコハマシ〇〇 〇〇-〇〇-〇〇 マンション名'} type={'text'}
          value={props.address} setValue={props.setAddress}
          desc={''}
        />

        <DefaultInput1
          title={'住所（フリガナ）'} required={true} length={50} place={'カナガワケンヨコハマシ〇〇 〇〇-〇〇-〇〇 マンション名'} type={'text'}
          value={props.addressHurigana} setValue={props.setAddressHurigana}
          desc={''}
        />

        <DefaultInput1
          title={'電話番号'} required={true} length={13} place={'08012345678'} type={'number'}
          value={props.phoneNumber} setValue={props.setPhoneNumber}
          desc={'※半角数字（ハイフン無し）で入力してください。'}
        />

        <DefaultInput1
          title={'メールアドレス'} required={true} length={40} place={'memorii@trankllc.com'} type={'text'}
          value={props.mailAddress} setValue={props.setMailAddress}
          desc={''}
        />

        <DefaultInput1
          title={'instagramアカウント名'} required={false} length={30} place={'memorii.photo'} type={'text'}
          value={props.instagram} setValue={props.setInstagram}
          desc={'※アットマーク（@）以降を記載してください。'}
        />

        <DefaultInput1
          title={'instagram フォロワー数'} required={false} length={10} place={'123'} type={'number'}
          value={props.instagramFollower} setValue={props.setInstagramFollower}
          desc={'※フォロワー数は数値で入力してください。'}
        />

        <DefaultInput1
          title={'Twitter(X)アカウント名'} required={false} length={30} place={'memorii.photo'} type={'text'}
          value={props.twitter} setValue={props.setTwitter}
          desc={'※アットマーク（@）以降を記載してください。'}
        />

        <DefaultInput1
          title={'Twitter(X)フォロワー数'} required={false} length={10} place={'123'} type={'number'}
          value={props.twitterFollower} setValue={props.setTwitterFollower}
          desc={'※フォロワー数は数値で入力してください。'}
        />

        <DefaultInput1
          title={'TikTokアカウント名'} required={false} length={30} place={'memorii.photo'} type={'text'}
          value={props.tiktok} setValue={props.setTiktok}
          desc={'※アットマーク（@）以降を記載してください。'}
        />

        <DefaultInput1
          title={'TikTok フォロワー数'} required={false} length={10} place={'123'} type={'number'}
          value={props.tiktokFollower} setValue={props.setTiktokFollower}
          desc={'※フォロワー数は数値で入力してください。'}
        />

      </div>

      <div style={{height : '30px'}}></div>
      <div className={Styles.box_inline}>
        <h5 className={Styles.title}>学歴・職歴</h5>

        <DefaultTime2 
          title={'1. 学歴・職歴の時期'} required={true} length={0}
          place1={'2020'} type1={'number'} value1={props.historyYears1} setValue1={props.setHistoryYears1}
          place2={'1'} type2={'number'} value2={props.historyMonth1} setValue2={props.setHistoryMonth1}
          desc={'※半角数字で入力してください。'}
        />
        
        <DefaultInput1
          title={'1. 学歴・職歴'} required={true} length={20} place={'〇〇学校卒業'} type={'text'}
          value={props.history1} setValue={props.setHistory1}
          desc={'　'}
        />

        <DefaultTime2 
          title={'2. 学歴・職歴の時期'} required={false} length={0}
          place1={'2020'} type1={'number'} value1={props.historyYears2} setValue1={props.setHistoryYears2}
          place2={'1'} type2={'number'} value2={props.historyMonth2} setValue2={props.setHistoryMonth2}
          desc={'※半角数字で入力してください。'}
        />

        <DefaultInput1
          title={'2. 学歴・職歴'} required={false} length={20} place={'〇〇学校卒業'} type={'text'}
          value={props.history2} setValue={props.setHistory2}
          desc={'　'}
        />

        <DefaultTime2 
          title={'3. 学歴・職歴の時期'} required={false} length={0}
          place1={'2020'} type1={'number'} value1={props.historyYears3} setValue1={props.setHistoryYears3}
          place2={'1'} type2={'number'} value2={props.historyMonth3} setValue2={props.setHistoryMonth3}
          desc={'※半角数字で入力してください。'}
        />

        <DefaultInput1
          title={'3. 学歴・職歴'} required={false} length={20} place={'〇〇学校卒業'} type={'text'}
          value={props.history3} setValue={props.setHistory3}
          desc={'　'}
        />

        <DefaultTime2 
          title={'4. 学歴・職歴の時期'} required={false} length={0}
          place1={'2020'} type1={'number'} value1={props.historyYears4} setValue1={props.setHistoryYears4}
          place2={'1'} type2={'number'} value2={props.historyMonth4} setValue2={props.setHistoryMonth4}
          desc={'※半角数字で入力してください。'}
        /> 

        <DefaultInput1
          title={'4. 学歴・職歴'} required={false} length={20} place={'〇〇学校卒業'} type={'text'}
          value={props.history4} setValue={props.setHistory4}
          desc={'　'}
        />

        <DefaultTime2 
          title={'5. 学歴・職歴の時期'} required={false} length={0}
          place1={'2020'} type1={'number'} value1={props.historyYears5} setValue1={props.setHistoryYears5}
          place2={'1'} type2={'number'} value2={props.historyMonth5} setValue2={props.setHistoryMonth5}
          desc={'※半角数字で入力してください。'}
        />

        <DefaultInput1
          title={'5. 学歴・職歴'} required={false} length={20} place={'〇〇学校卒業'} type={'text'}
          value={props.history5} setValue={props.setHistory5}
          desc={'　'}
        />

        <DefaultTime2 
          title={'6. 学歴・職歴の時期'} required={false} length={0}
          place1={'2020'} type1={'number'} value1={props.historyYears6} setValue1={props.setHistoryYears6}
          place2={'1'} type2={'number'} value2={props.historyMonth6} setValue2={props.setHistoryMonth6}
          desc={'※半角数字で入力してください。'}
        />

        <DefaultInput1
          title={'6. 学歴・職歴'} required={false} length={20} place={'〇〇学校卒業'} type={'text'}
          value={props.history6} setValue={props.setHistory6}
          desc={'　'}
        />

      </div>

      <div style={{height : '30px'}}></div>
      <div className={Styles.box_inline}>
        <h5 className={Styles.title}>資格取得・受賞歴</h5>

        <DefaultTime2 
          title={'1. 資格取得・受賞歴の時期'} required={false} length={0}
          place1={'2020'} type1={'number'} value1={props.awardYears1} setValue1={props.setAwardYears1}
          place2={'1'} type2={'number'} value2={props.awardMonth1} setValue2={props.setAwardMonth1}
          desc={'※半角数字で入力してください。'}
        />

        <DefaultInput1
          title={'1. 資格取得・受賞歴'} required={false} length={20} place={'〇〇賞 受賞'} type={'text'}
          value={props.award1} setValue={props.setAward1}
          desc={'　'}
        />

        <DefaultTime2 
          title={'2. 資格取得・受賞歴の時期'} required={false} length={0}
          place1={'2020'} type1={'number'} value1={props.awardYears2} setValue1={props.setAwardYears2}
          place2={'1'} type2={'number'} value2={props.awardMonth2} setValue2={props.setAwardMonth2}
          desc={'※半角数字で入力してください。'}
        />

        <DefaultInput1
          title={'2. 資格取得・受賞歴'} required={false} length={20} place={'〇〇賞 受賞'} type={'text'}
          value={props.award2} setValue={props.setAward2}
          desc={'　'}
        />

        <DefaultTime2 
          title={'3. 資格取得・受賞歴の時期'} required={false} length={0}
          place1={'2020'} type1={'number'} value1={props.awardYears3} setValue1={props.setAwardYears3}
          place2={'1'} type2={'number'} value2={props.awardMonth3} setValue2={props.setAwardMonth3}
          desc={'※半角数字で入力してください。'}
        />

        <DefaultInput1
          title={'3. 資格取得・受賞歴'} required={false} length={20} place={'〇〇賞 受賞'} type={'text'}
          value={props.award3} setValue={props.setAward3}
          desc={'　'}
        />
        
      </div>
      
      <div style={{height : '30px'}}></div>
      <div className={Styles.box_inline}>
        <h5 className={Styles.title}>希望店舗</h5>
        
        <DefaultTitle title={'希望店舗'} required={true} />
        <div className={Styles.checkbox}>
          {1 <= props.recruitList.place_storeCount && props.recruitList.place_storeCount <= 5 ?
            <div>
              <input type='checkbox' checked={props.hopeStore.indexOf(props.recruitList.place_store1Name) !== -1 ? 'checked' : ''}
                onChange={(e) => 
                  e.target.checked ? props.setHopeStore([...props.hopeStore, props.recruitList.place_store1Name]) : props.setHopeStore(props.hopeStore.filter((v, index) => (v !== props.recruitList.place_store1Name)))
                }/>
              <p>{props.recruitList.place_store1Name}</p>
            </div>
          : <></> }
          {2 <= props.recruitList.place_storeCount && props.recruitList.place_storeCount <= 5 ?
            <div>
              <input type='checkbox' checked={props.hopeStore.indexOf(props.recruitList.place_store2Name) !== -1 ? 'checked' : ''}
                onChange={(e) => 
                  e.target.checked ? props.setHopeStore([...props.hopeStore, props.recruitList.place_store2Name]) : props.setHopeStore(props.hopeStore.filter((v, index) => (v !== props.recruitList.place_store2Name)))
                }/>
              <p>{props.recruitList.place_store2Name}</p>
            </div>
          : <></> }
          {3 <= props.recruitList.place_storeCount && props.recruitList.place_storeCount <= 5 ?
            <div>
              <input type='checkbox' checked={props.hopeStore.indexOf(props.recruitList.place_store3Name) !== -1 ? 'checked' : ''}
                onChange={(e) => 
                  e.target.checked ? props.setHopeStore([...props.hopeStore, props.recruitList.place_store3Name]) : props.setHopeStore(props.hopeStore.filter((v, index) => (v !== props.recruitList.place_store3Name)))
                }/>
              <p>{props.recruitList.place_store3Name}</p>
            </div>
          : <></> }
          {4 <= props.recruitList.place_storeCount && props.recruitList.place_storeCount <= 5 ?
          <div>
            <input type='checkbox' checked={props.hopeStore.indexOf(props.recruitList.place_store4Name) !== -1 ? 'checked' : ''}
              onChange={(e) => 
                e.target.checked ? props.setHopeStore([...props.hopeStore, props.recruitList.place_store4Name]) : props.setHopeStore(props.hopeStore.filter((v, index) => (v !== props.recruitList.place_store4Name)))
              }/>
            <p>{props.recruitList.place_store4Name}</p>
          </div>
          : <></> }
          {5 <= props.recruitList.place_storeCount && props.recruitList.place_storeCount <= 5 ?
          <div>
            <input type='checkbox' checked={props.hopeStore.indexOf(props.recruitList.place_store5Name) !== -1 ? 'checked' : ''}
              onChange={(e) => 
                e.target.checked ? props.setHopeStore([...props.hopeStore, props.recruitList.place_store5Name]) : props.setHopeStore(props.hopeStore.filter((v, index) => (v !== props.recruitList.place_store5Name)))
              }/>
            <p>{props.recruitList.place_store5Name}</p>
          </div>
          : <></> }
        </div>
        <p className={Styles.desc}>※複数ある場合は、複数選択してください。</p>

        <div className={Styles.checkbox_prev}>
          {1 <= props.recruitList.place_storeCount && props.recruitList.place_storeCount <= 5 ?
            <p>第1希望：{props.hopeStore[0]}</p>
          : <></> }
          {2 <= props.recruitList.place_storeCount && props.recruitList.place_storeCount <= 5 ?
            <p>第2希望：{props.hopeStore[1]}</p>
          : <></> }
          {3 <= props.recruitList.place_storeCount && props.recruitList.place_storeCount <= 5 ?
            <p>第3希望：{props.hopeStore[2]}</p>
          : <></> }
          {4 <= props.recruitList.place_storeCount && props.recruitList.place_storeCount <= 5 ?
            <p>第4希望：{props.hopeStore[3]}</p>
          : <></> }
          {5 <= props.recruitList.place_storeCount && props.recruitList.place_storeCount <= 5 ?
            <p>第5希望：{props.hopeStore[4]}</p>
          : <></> }
        </div>

      </div>
      
      <div style={{height : '30px'}}></div>
      <div className={Styles.box_inline}>
        <h5 className={Styles.title}>自由記載</h5>

        <DefaultTextarea1
          title={'志望動機'} required={true} length={400} type={'text'}
          place={'私が貴社に応募した理由は、「髪を切るだけでなく、美容のパートナーとしてお客様と共に成長する美容師になりたい」という私の美容師像と、貴社の「美容室は髪を切るだけの場所ではなく、人生に寄り添う場所だ」という理念がマッチしていると考えたからです。\n私はもともと美容にあまり興味がなかったのですが、中学生のころに通っていた美容室で、髪型に合わせたファッションやセットの方法を教えてもらい、美容に興味を持つようになりました。\nそして、毎年トレンドが変化する点や同じ施術でも個人の骨格によって見え方が変化する点に奥深さを感じ、その人や時代に合わせた美容をお客様全員に提供できる美容師になりたいと考えるようになりました。\n人生に寄り添う場所だという理念を掲げ、SNSでのトレンド分析や、雑誌で骨格分析を行っている貴社に入社することで、自分が理想とする美容師像に近づけると考えており、貴社の志望理由となっています。'}
          value={props.motivation} setValue={props.setMotivation}
          desc={'　'}
        />

        <DefaultTextarea1
          title={'自己PR'} required={true} length={400} type={'text'}
          place={'私の強みは、スターバックスのアルバイトで培った観察力です。\nお客様の行動を観察し、いち早く要望に対応できる力を育てました。\n私がアルバイトを行っていた店舗ではホスピタリティを特に大事にしており、お客様から指摘される前に要望に応えることを重要視していました。\n具体的には、お客様の行動を常に観察し、視線や表情からどのようなことを考えているかということを考察するようにしていました。\nその結果、お客様からお褒めの言葉をいただくことが増え、他の従業員が参考になると思った従業員を表彰する「グリーンエプロンカード」を受け取ることが出来ました。\n　美容師はお客様の要望を正確に受け取り、それを施術として実現することで高い満足度を獲得することが出来ると考えています。\n私は培った観察力を活かして「お客様の要望を正確に受け取る」という点に注力することで、多くのお客様に信頼される美容師になりたいと考えています。'} 
          value={props.prText} setValue={props.setPrText}
          desc={'　'}
        />

      </div>

      <div className={Styles.save}>
        <button className={Styles.save_back} onClick={() => props.setProgress(1)}>戻る</button>
        <button className={Styles.save_save} onClick={() => check()}>次へ</button>
      </div>
      
    </div>
    </>
  );
}

export default Footer;

import './styles.css';
import React, { useState } from 'react';
import Worry1 from './../../../../image/works/landing/recruit/1.png';
import Worry2 from './../../../../image/works/landing/recruit/2.png';
import Worry3 from './../../../../image/works/landing/recruit/3.png';
import Worry4 from './../../../../image/works/landing/recruit/4.png';
import Worry5 from './../../../../image/works/landing/recruit/5.png';

const MemoriiWorksLanding = () => {

  const [ navigation, setNavigation ] = useState(1);

  return (
    <>
    <div className='works_landing_flow'>
      <div className='works_landing_flow_title'>
        <div></div>
        <h4>求人制作の流れ</h4>
      </div>
      <p>必要事項を入力するだけで応募を簡単に行う事ができます。</p>
      <div className='works_landing_flow_screen'>
        <div className='works_landing_flow_recruit_title'>
          <div>
            <h4 style={navigation === 1 ? {backgroundColor : '#192651', color: 'white'} : {backgroundColor : 'white', color: '#EBEBEB'}}
              onClick={() => setNavigation(1)}>求人作成</h4>
            <h4 style={navigation === 2 ? {backgroundColor : '#192651', color: 'white'} : {backgroundColor : 'white', color: '#EBEBEB'}}
              onClick={() => setNavigation(2)}>店舗設定</h4>
            <h4 style={navigation === 3 ? {backgroundColor : '#192651', color: 'white'} : {backgroundColor : 'white', color: '#EBEBEB'}}
              onClick={() => setNavigation(3)}>求人設定</h4>
            <h4 style={navigation === 4 ? {backgroundColor : '#192651', color: 'white'} : {backgroundColor : 'white', color: '#EBEBEB'}}
              onClick={() => setNavigation(4)}>必要書類設定</h4>
            <h4 style={navigation === 5 ? {backgroundColor : '#192651', color: 'white'} : {backgroundColor : 'white', color: '#EBEBEB'}}
              onClick={() => setNavigation(5)}>応募開始</h4>
          </div>
        </div>
        <div className='works_landing_flow_screen_screen'>
          {navigation === 1 ?
            <img src={Worry1} alt='' />
          : navigation === 2 ?
            <img src={Worry2} alt='' />
          : navigation === 3 ?
            <img src={Worry3} alt='' />
          : navigation === 4 ?
            <img src={Worry4} alt='' />
          :
            <img src={Worry5} alt='' />
          }
        </div>
      </div>
    </div>
    </>
  );
}

export default MemoriiWorksLanding;

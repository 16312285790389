import React from 'react';
import Styles from './styles.module.css';

const DefaultInput1 = (props) => {

  return (
    <>
    <div className={Styles.title}>
      <div>
        <p>{props.title}</p>
        <div className={ props.required ? Styles.title_yes : Styles.title_no }>{props.required ? '※必須' : '任意'}</div>
      </div>
      <p></p>
    </div>
    <div className={Styles.select}>
      <select className={Styles.select} value={props.value1} onChange={(e) => props.setValue1(e.target.value)}>
        {props.list1.map((data, index) =>
          <option value={data === '未選択' ? '' : data} index={index}>{data}</option>
        )}
      </select>
      <p>〜</p>
      <select className={Styles.select} value={props.value2} onChange={(e) => props.setValue2(e.target.value)}>
        {props.list2.map((data, index) =>
          <option value={data === '未選択' ? '' : data} index={index}>{data}</option>
        )}
      </select>
    </div>
    <p className={Styles.desc}>{props.desc}</p>
    </>
  );
}

export default DefaultInput1;

import Styles from './styles.module.css';
import firebase from 'firebase/compat/app';
import { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import Evaluation from "./../manage/evaluation/index";
import Bar from "./../manage/bar/index";
import Summary from "./../manage/summary/index";

const MemoriiWorksManage = (props) => {

  const [ recruitPage, setRecruitPage ] = useState([]);;
  const [ progress, setProgress ] = useState(1);
  const [ evaluationData, setEvaluationData ] = useState([]);

  useEffect(() => {
    
    getEvaluation(props.applicantsList.id);

    for (let i = 0; i < props.recruitList.length; i++) {
      if (props.recruitList[i].id === props.applicantsList.recruitId) {
        setRecruitPage(props.recruitList[i]);
      }
    }

  }, []);

  function getEvaluation(data) {
    const docRef = collection(firebase.firestore(), `/applicants/${data}/examination`)
    const unsub = onSnapshot(docRef, snapshot => {
      var tmp = [];
      snapshot.docs.forEach(doc => {
        tmp.push({...doc.data(), id: doc.id})
      })
      setEvaluationData(tmp);
    })
    return () => unsub();
  }


  return (
    <>
    <div className={Styles.history}>
      <div className={Styles.history_black} onClick={() => props.setHistory(false)}></div>
      <div className={Styles.history_contents}>
        <div>
          <div className={Styles.box}>
            {1 <= Number(recruitPage.exam_selection) && Number(recruitPage.exam_selection) <= 5 ?
              <h4 style={progress === 1 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}} onClick={() => setProgress(1)}>1次選考</h4>
            : <></> }
            {2 <= Number(recruitPage.exam_selection) && Number(recruitPage.exam_selection) <= 5 ?
              <h4 style={progress === 2 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}} onClick={() => setProgress(2)}>2次選考</h4>
            : <></> }
            {3 <= Number(recruitPage.exam_selection) && Number(recruitPage.exam_selection) <= 5 ?
              <h4 style={progress === 3 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}} onClick={() => setProgress(3)}>3次選考</h4>
            : <></> }
            {4 <= Number(recruitPage.exam_selection) && Number(recruitPage.exam_selection) <= 5 ?
              <h4 style={progress === 4 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}} onClick={() => setProgress(4)}>4次選考</h4>
            : <></> }
            {5 <= Number(recruitPage.exam_selection) && Number(recruitPage.exam_selection) <= 5 ?
              <h4 style={progress === 5 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}} onClick={() => setProgress(5)}>5次選考</h4>
            : <></> }
          </div>

          <h5 className={Styles.title}>全体評価</h5>
          <div className={Styles.table}>
          
            {recruitPage.exam_length !== 0 ?
            <div className={Styles.all}>
              <p className={Styles.all_header}>審査員</p>
              <Bar count={Number(recruitPage[`selection${progress}Count`])} />
            </div> : <></> }

            {evaluationData.map((data, index) =>
            <>
              {data.evaluation === progress ?
              <Summary 
                image={data.uid}
                name={data.name}
                count={Number(recruitPage[`exam_selection${progress}Count`])}
                result1={Number(data.evaluation1Score)}
                result2={Number(data.evaluation2Score)}
                result3={Number(data.evaluation3Score)}
                result4={Number(data.evaluation4Score)}
                result5={Number(data.evaluation5Score)}
                result6={Number(data.evaluation6Score)}
                result7={Number(data.evaluation7Score)}
                result8={Number(data.evaluation8Score)}
              />
              : <></> }
            </> )}
          </div>

          <h5 className={Styles.title}>審査員の評価・コメント</h5>
          {evaluationData.map((data, index) =>
          <>
            {data.evaluation === progress ?
            <>
              <div className={Styles.items}>
                <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${data.uid}_200x200?alt=media&token`} />
                <p>{data.name}</p>
              </div>

              {1 <= Number(recruitPage[`exam_selection${progress}Count`]) && Number(recruitPage[`exam_selection${progress}Count`]) <= 8 ?
                <Evaluation count={1} title={recruitPage[`exam_selection${progress}Text1`]} score={data.evaluation1Score} comment={data.evaluation1Comment} />
              : <></> }
              {2 <= Number(recruitPage[`exam_selection${progress}Count`]) && Number(recruitPage[`exam_selection${progress}Count`]) <= 8 ?
                <Evaluation count={2} title={recruitPage[`exam_selection${progress}Text2`]} score={data.evaluation2Score} comment={data.evaluation2Comment} />
              : <></> }
              {3 <= Number(recruitPage[`exam_selection${progress}Count`]) && Number(recruitPage[`exam_selection${progress}Count`]) <= 8 ?
                <Evaluation count={3} title={recruitPage[`exam_selection${progress}Text3`]} score={data.evaluation3Score} comment={data.evaluation3Comment} />
              : <></> }
              {4 <= Number(recruitPage[`exam_selection${progress}Count`]) && Number(recruitPage[`exam_selection${progress}Count`]) <= 8 ?
                <Evaluation count={4} title={recruitPage[`exam_selection${progress}Text4`]} score={data.evaluation4Score} comment={data.evaluation4Comment} />
              : <></> }
              {5 <= Number(recruitPage[`exam_selection${progress}Count`]) && Number(recruitPage[`exam_selection${progress}Count`]) <= 8 ?
                <Evaluation count={5} title={recruitPage[`exam_selection${progress}Text5`]} score={data.evaluation5Score} comment={data.evaluation5Comment} />
              : <></> }
              {6 <= Number(recruitPage[`exam_selection${progress}Count`]) && Number(recruitPage[`exam_selection${progress}Count`]) <= 8 ?
                <Evaluation count={6} title={recruitPage[`exam_selection${progress}Text6`]} score={data.evaluation6Score} comment={data.evaluation6Comment} />
              : <></> }
              {7 <= Number(recruitPage[`exam_selection${progress}Count`]) && Number(recruitPage[`exam_selection${progress}Count`]) <= 8 ?
                <Evaluation count={7} title={recruitPage[`exam_selection${progress}Text7`]} score={data.evaluation7Score} comment={data.evaluation7Comment} />
              : <></> }
              {8 <= Number(recruitPage[`exam_selection${progress}Count`]) && Number(recruitPage[`exam_selection${progress}Count`]) <= 8 ?
                <Evaluation count={8} title={recruitPage[`exam_selection${progress}Text8`]} score={data.evaluation8Score} comment={data.evaluation8Comment} />
              : <></> }
            </>
            : <></> }
          </> )}
        </div>
      </div>
    </div>
    </>
  );
}

export default MemoriiWorksManage;

import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import DefaultTitle from "./../../../parts/title/index";
import DefaultTextarea1 from './../../../../package/parts/1-textarea';
import { MdError } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

const MemoriiWorksPackagePhoto = (props) => {

  const history = useHistory();

  const [ recruitList, setRecruitList ] = useState([]);
  const [ errorMessage, setErrorMessage ] = useState('');

  const [ banner, setBanner ] = useState(false);

  const [ score1, setScore1 ] = useState('');
  const [ comment1, setComment1 ] = useState('');
  const [ score2, setScore2 ] = useState('');
  const [ comment2, setComment2 ] = useState('');
  const [ score3, setScore3 ] = useState('');
  const [ comment3, setComment3 ] = useState('');
  const [ score4, setScore4 ] = useState('');
  const [ comment4, setComment4 ] = useState('');
  const [ score5, setScore5 ] = useState('');
  const [ comment5, setComment5 ] = useState('');
  const [ score6, setScore6 ] = useState('');
  const [ comment6, setComment6 ] = useState('');
  const [ score7, setScore7 ] = useState('');
  const [ comment7, setComment7 ] = useState('');
  const [ score8, setScore8 ] = useState('');
  const [ comment8, setComment8 ] = useState('');

  useEffect(() => {
    firebase.firestore().collection('recruits').doc(props.applicantsList.recruitId).get()
    .then((doc) => {
      setRecruitList({...doc.data(), id: doc.id});
    });
  }, [props.applicantsList]);
  
  function check() {

    var target = document.getElementById('contents');
	  target.scrollTop = 0;

    if (1 <= Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) && Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) <= 8 && score1 === '') {
      setErrorMessage('審査①を入力してください。');
    } else if (2 <= Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) && Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) <= 8 && score2 === '') {
      setErrorMessage('審査②を入力してください。');
    } else if (3 <= Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) && Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) <= 8 && score3 === '') {
      setErrorMessage('審査③を入力してください。');
    } else if (4 <= Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) && Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) <= 8 && score4 === '') {
      setErrorMessage('審査④を入力してください。');
    } else if (5 <= Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) && Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) <= 8 && score5 === '') {
      setErrorMessage('審査⑤を入力してください。');
    } else if (6 <= Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) && Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) <= 8 && score6 === '') {
      setErrorMessage('審査⑥を入力してください。');
    } else if (7 <= Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) && Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) <= 8 && score7 === '') {
      setErrorMessage('審査⑦を入力してください。');
    } else if (8 <= Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) && Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) <= 8 && score8 === '') {
      setErrorMessage('審査⑧を入力してください。');
    } else {
      save();
    }
  }

  function save() {
    setBanner(true);

    var docId = props.applicantsList.examination + '_' + props.userList.id;
  
    firebase.firestore().collection('applicants').doc(props.applicantsList.id).collection('examination').doc(docId)
    .set({
      uid: props.userList.id,
      name: props.userList.user_name,
      
      evaluation: props.applicantsList.examination,

      evaluation1Score: score1,
      evaluation1Comment: comment1,
      evaluation2Score: score2,
      evaluation2Comment: comment2,
      evaluation3Score: score3,
      evaluation3Comment: comment3,
      evaluation4Score: score4,
      evaluation4Comment: comment4,
      evaluation5Score: score5,
      evaluation5Comment: comment5,
      evaluation6Score: score6,
      evaluation6Comment: comment6,
      evaluation7Score: score7,
      evaluation7Comment: comment7,
      evaluation8Score: score8,
      evaluation8Comment: comment8,
    })
    
    firebase.firestore().collection('applicants').doc(props.applicantsList.id)
    .update(
      Number(props.applicantsList.examination) === 1 ?
        {examList1 : firebase.firestore.FieldValue.arrayUnion(props.userList.id)}
      : Number(props.applicantsList.examination) === 2 ?
        {examList2 : firebase.firestore.FieldValue.arrayUnion(props.userList.id)}
      : Number(props.applicantsList.examination) === 3 ?
        {examList3 : firebase.firestore.FieldValue.arrayUnion(props.userList.id)}
      : Number(props.applicantsList.examination) === 4 ?
        {examList4 : firebase.firestore.FieldValue.arrayUnion(props.userList.id)}
      : Number(props.applicantsList.examination) === 5 ?
        {examList5 : firebase.firestore.FieldValue.arrayUnion(props.userList.id)}
      : {}
    ).then(() => props.setApplicantsList([]));

    setTimeout(() => {
      setBanner(false);
    }, 1500);
  }

  return (
    <>
    <div className={Styles.box}>
      <div className={Styles.box_black} onClick={() => props.setApplicantsList([])}></div>
      <div className={Styles.box_contents} id='contents'>

        {errorMessage !== '' ?
          <div className={Styles.error}>
            <MdError className={Styles.error_icon} />
            <h4>エラー：{errorMessage}</h4>
          </div>
        : <></> }

        {recruitList['exam_selection'] === '' ?
          <div>
            <p className={Styles.setting_desc}>
              現在、選考評価の設定が行われていません。<br />応募者の設定を行う場合は以下の選考評価の設定ボタンより評価設定を行ってください。</p>
            <button className={Styles.setting_btn}
              onClick={() => history.push(`/edit/evaluation/id=${recruitList['companyId']}+page=2+recruitId=${recruitList.id}`)}
            >選考評価の設定</button>
            <p className={Styles.setting_attention}>※選考評価の設定は「求人管理画面 {">"} 求人 {">"} 詳細ボタン {">"} 評価設定」より設定を行うことが出来ます。</p>
          </div>
          :
          <div>
            <h4 className={Styles.title}>{props.applicantsList.examination}次選考　{props.applicantsList.name}</h4>

            {1 <= Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) && Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) <= 8 ?
              <div className='works_service_info_evaluation'>
                <div className='works_service_manage_contents_examination_table_score'>
                  <DefaultTitle title={`審査①：${recruitList[`exam_selection${props.applicantsList.examination}Text1`]}`} required={true} />
                  <div className='works_service_manage_contents_examination_table_score_results'>
                    <p onClick={() => setScore1(4)} 
                      style={score1 === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
                    <p onClick={() => setScore1(3)} 
                      style={score1 === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
                    <p onClick={() => setScore1(2)} 
                      style={score1 === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
                    <p onClick={() => setScore1(1)} 
                      style={score1 === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
                  </div>
                </div>
                <DefaultTextarea1
                  title={'コメント'} required={false} length={100} type={'text'} 
                  place={'コメント'}
                  value={comment1} setValue={setComment1}
                  desc={'　'}
                />
              </div>
            : <></> }
            {2 <= Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) && Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) <= 8 ?
              <div className='works_service_info_evaluation'>
                <div className='works_service_manage_contents_examination_table_score'>
                  <DefaultTitle title={`審査②：${recruitList[`exam_selection${props.applicantsList.examination}Text2`]}`} required={true} />
                  <div className='works_service_manage_contents_examination_table_score_results'>
                    <p onClick={() => setScore2(4)} 
                      style={score2 === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
                    <p onClick={() => setScore2(3)} 
                      style={score2 === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
                    <p onClick={() => setScore2(2)} 
                      style={score2 === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
                    <p onClick={() => setScore2(1)} 
                      style={score2 === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
                  </div>
                </div>
                <DefaultTextarea1
                  title={'コメント'} required={false} length={100} type={'text'} 
                  place={'コメント'}
                  value={comment2} setValue={setComment2}
                  desc={'　'}
                />
              </div>
            : <></> }
            {3 <= Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) && Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) <= 8 ?
              <div className='works_service_info_evaluation'>
                <div className='works_service_manage_contents_examination_table_score'>
                  <DefaultTitle title={`審査③：${recruitList[`exam_selection${props.applicantsList.examination}Text3`]}`} required={true} />
                  <div className='works_service_manage_contents_examination_table_score_results'>
                    <p onClick={() => setScore3(4)} 
                      style={score3 === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
                    <p onClick={() => setScore3(3)} 
                      style={score3 === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
                    <p onClick={() => setScore3(2)} 
                      style={score3 === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
                    <p onClick={() => setScore3(1)} 
                      style={score3 === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
                  </div>
                </div>
                <DefaultTextarea1
                  title={'コメント'} required={false} length={100} type={'text'} 
                  place={'コメント'}
                  value={comment3} setValue={setComment3}
                  desc={'　'}
                />
              </div>
            : <></> }
            {4 <= Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) && Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) <= 8 ?
              <div className='works_service_info_evaluation'>
                <div className='works_service_manage_contents_examination_table_score'>
                  <DefaultTitle title={`審査④：${recruitList[`exam_selection${props.applicantsList.examination}Text4`]}`} required={true} />
                  <div className='works_service_manage_contents_examination_table_score_results'>
                    <p onClick={() => setScore4(4)} 
                      style={score4 === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
                    <p onClick={() => setScore4(3)} 
                      style={score4 === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
                    <p onClick={() => setScore4(2)} 
                      style={score4 === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
                    <p onClick={() => setScore4(1)} 
                      style={score4 === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
                  </div>
                </div>
                <DefaultTextarea1
                  title={'コメント'} required={false} length={100} type={'text'} 
                  place={'コメント'}
                  value={comment4} setValue={setComment4}
                  desc={'　'}
                />
              </div>
            : <></> }
            {5 <= Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) && Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) <= 8 ?
              <div className='works_service_info_evaluation'>
                <div className='works_service_manage_contents_examination_table_score'>
                  <DefaultTitle title={`審査⑤：${recruitList[`exam_selection${props.applicantsList.examination}Text5`]}`} required={true} />
                  <div className='works_service_manage_contents_examination_table_score_results'>
                    <p onClick={() => setScore5(4)} 
                      style={score5 === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
                    <p onClick={() => setScore5(3)} 
                      style={score5 === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
                    <p onClick={() => setScore5(2)} 
                      style={score5 === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
                    <p onClick={() => setScore5(1)} 
                      style={score5 === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
                  </div>
                </div>
                <DefaultTextarea1
                  title={'コメント'} required={false} length={100} type={'text'} 
                  place={'コメント'}
                  value={comment5} setValue={setComment5}
                  desc={'　'}
                />
              </div>
            : <></> }
            {6 <= Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) && Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) <= 8 ?
              <div className='works_service_info_evaluation'>
                <div className='works_service_manage_contents_examination_table_score'>
                  <DefaultTitle title={`審査⑥：${recruitList[`exam_selection${props.applicantsList.examination}Text6`]}`} required={true} />
                  <div className='works_service_manage_contents_examination_table_score_results'>
                    <p onClick={() => setScore6(4)} 
                      style={score6 === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
                    <p onClick={() => setScore6(3)} 
                      style={score6 === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
                    <p onClick={() => setScore6(2)} 
                      style={score6 === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
                    <p onClick={() => setScore6(1)} 
                      style={score6 === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
                  </div>
                </div>
                <DefaultTextarea1
                  title={'コメント'} required={false} length={100} type={'text'} 
                  place={'コメント'}
                  value={comment6} setValue={setComment6}
                  desc={'　'}
                />
              </div>
            : <></> }
            {7 <= Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) && Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) <= 8 ?
              <div className='works_service_info_evaluation'>
                <div className='works_service_manage_contents_examination_table_score'>
                  <DefaultTitle title={`審査⑦：${recruitList[`exam_selection${props.applicantsList.examination}Text7`]}`} required={true} />
                  <div className='works_service_manage_contents_examination_table_score_results'>
                    <p onClick={() => setScore7(4)} 
                      style={score7 === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
                    <p onClick={() => setScore7(3)} 
                      style={score7 === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
                    <p onClick={() => setScore7(2)} 
                      style={score7 === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
                    <p onClick={() => setScore7(1)} 
                      style={score7 === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
                  </div>
                </div>
                <DefaultTextarea1
                  title={'コメント'} required={false} length={100} type={'text'} 
                  place={'コメント'}
                  value={comment7} setValue={setComment7}
                  desc={'　'}
                />
              </div>
            : <></> }
            {8 <= Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) && Number(recruitList[`exam_selection${props.applicantsList.examination}Count`]) <= 8 ?
              <div className='works_service_info_evaluation'>
                <div className='works_service_manage_contents_examination_table_score'>
                  <DefaultTitle title={`審査⑧：${recruitList[`exam_selection${props.applicantsList.examination}Text8`]}`} required={true} />
                  <div className='works_service_manage_contents_examination_table_score_results'>
                    <p onClick={() => setScore8(4)} 
                      style={score8 === 4 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>◎</p>
                    <p onClick={() => setScore8(3)} 
                      style={score8 === 3 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>○</p>
                    <p onClick={() => setScore8(2)} 
                      style={score8 === 2 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>△</p>
                    <p onClick={() => setScore8(1)} 
                      style={score8 === 1 ? {backgroundColor : '#AD0200', color : 'white'} : {}}>×</p>
                  </div>
                </div>
                <DefaultTextarea1
                  title={'コメント'} required={false} length={100} type={'text'} 
                  place={'コメント'}
                  value={comment8} setValue={setComment8}
                  desc={'　'}
                />
              </div>
            : <></> }

            <div className={Styles.button}>
              <button style={{color: '#AD0200', backgroundColor: 'white'}} onClick={() => props.setApplicantsList([])}>キャンセル</button>
              <button style={{color: 'white', backgroundColor: '#AD0200'}} onClick={() => check()}>送信</button>
            </div>

          </div>
        }
      </div>
    </div>

    {banner ?
      <div className='works_banner'>
        保存しました。
      </div> : <></>
    }
    </>
  );
}

export default MemoriiWorksPackagePhoto;



import { Splide, SplideSlide } from "@splidejs/react-splide";
import Styles from "./styles.module.css";
import '@splidejs/splide/css';

const Slider = (props) => {

  return (
    <>
    <div className={Styles.block}>
      <h4>インタビュー</h4>

      <Splide
        options={{
          type: 'loop',
          perPage: 3,
          perMove: 1,
          gap: 20,
          breakpoints: {
            700: {
              perPage: 2,
            },
          }
        }}
      >
        {1 <= props.count ?
          <SplideSlide>
            <div className={Styles.interview}>
              <img className={Styles.slideImg} 
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${props.recruit}_1_1080x1080?alt=media&token=`}
                alt="美容室 求人 memorii works" />
              <h4>{props.name1}</h4>
              <h5>{props.school1}</h5>
              <h6>{props.title1}</h6>
              <p>{props.desc1}</p>
            </div>
          </SplideSlide>
        : <></> }
        {2 <= props.count ?
          <SplideSlide>
            <div className={Styles.interview}>
              <img className={Styles.slideImg} 
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${props.recruit}_2_1080x1080?alt=media&token=`}
                alt="美容室 求人 memorii works" />
              <h4>{props.name2}</h4>
              <h5>{props.school2}</h5>
              <h6>{props.title2}</h6>
              <p>{props.desc2}</p>
            </div>
          </SplideSlide>
        : <></> }
        {3 <= props.count ?
          <SplideSlide>
            <div className={Styles.interview}>
              <img className={Styles.slideImg} 
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${props.recruit}_3_1080x1080?alt=media&token=`}
                alt="美容室 求人 memorii works" />
              <h4>{props.name3}</h4>
              <h5>{props.school3}</h5>
              <h6>{props.title3}</h6>
              <p>{props.desc3}</p>
            </div>
          </SplideSlide>
        : <></> }
        {4 <= props.count ?
          <SplideSlide>
            <div className={Styles.interview}>
              <img className={Styles.slideImg} 
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${props.recruit}_4_1080x1080?alt=media&token=`}
                alt="美容室 求人 memorii works" />
              <h4>{props.name4}</h4>
              <h5>{props.school4}</h5>
              <h6>{props.title4}</h6>
              <p>{props.desc4}</p>
            </div>
          </SplideSlide>
        : <></> }
        {5 <= props.count ?
          <SplideSlide>
            <div className={Styles.interview}>
              <img className={Styles.slideImg} 
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${props.recruit}_5_1080x1080?alt=media&token=`}
                alt="美容室 求人 memorii works" />
              <h4>{props.name5}</h4>
              <h5>{props.school5}</h5>
              <h6>{props.title5}</h6>
              <p>{props.desc5}</p>
            </div>
          </SplideSlide>
        : <></> }
        {6 <= props.count ?
          <SplideSlide>
            <div className={Styles.interview}>
              <img className={Styles.slideImg} 
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${props.recruit}_6_1080x1080?alt=media&token=`}
                alt="美容室 求人 memorii works" />
              <h4>{props.name6}</h4>
              <h5>{props.school6}</h5>
              <h6>{props.title6}</h6>
              <p>{props.desc6}</p>
            </div>
          </SplideSlide>
        : <></> }
      </Splide>
    </div>
    </>
  );
}

export default Slider;

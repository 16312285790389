import Styles from './styles.module.css';
import 'firebase/auth'
import React from 'react-router-dom';
import { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import { useHistory } from 'react-router-dom';
import ErrorMessage from '../../../../package/parts/error/index';
import DefaultTitle from '../../../../package/parts/title/index';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import BackModal from '../../11.back/index';
import DefaultInput1 from '../../../../package/parts/1-input/index';
import DefaultSelect1 from '../../../../package/parts/1-select/index';
import DefaultInput2 from '../../../../package/parts/2-input/index';
import DefaultSelect2 from '../../../../package/parts/2-select/index';
import DefaultInput1Salary from '../../../../package/parts/2-input-salary/index';
import DefaultTextarea1 from '../../../../package/parts/1-textarea/index';
import Header from '../../6.header/index';
// import DefaultSlider from '../../../package/parts/slider';

const MemoriiWorksEditRecruit = (props) => {

  const history = useHistory();

  const [ errorMessage, setErrorMessage ] = useState('');

  // みなし残業
  const [ overtime, setOvertime ] = useState(false);
  const [ overtimeOther, setOvertimeOther ] = useState('');
  
  // 職種・役職
  const [ post, setPost ] = useState('');
  
  // 中途採用
  const [ employmentStatus, setEmploymentStatus ] = useState('');
  
  // 給与形態
  const [ salary, setSalary ] = useState('');
  
  // 年俸
  const [ annualSalaryStart, setAnnualSalaryStart ] = useState('');
  const [ annualSalaryEnd, setAnnualSalaryEnd ] = useState('');
  
  // 想定年収
  const [ annualIncomeStart, setAnnualIncomeStart ] = useState('');
  const [ annualIncomeEnd, setAnnualIncomeEnd ] = useState('');
  
  // 月収
  const [ monthlySalaryStart, setMonthlySalaryStart ] = useState('');
  // const [ monthlySalaryEnd, setMonthlySalaryEnd ] = useState('');
  
  // 日給
  const [ dailyWageStart, setDailyWageStart ] = useState('');
  const [ dailyWageEnd, setDailyWageEnd ] = useState('');
  
  // 時給
  const [ hourWageStart, setHourWageStart ] = useState('');
  const [ hourWageEnd, setHourWageEnd ] = useState('');
  
  // 成功報酬金額
  const [ successFee, setSuccessFee ] = useState('');

  // 勤務時間
  const [ workingHoursStart, setWorkingHoursStart ] = useState('');
  const [ workingHoursEnd, setWorkingHoursEnd ] = useState('');

  // 休暇
  const [ holiday, setHoliday ] = useState([]);
  const [ holidayCheck, setHolidayCheck ] = useState(false);
  const [ holidayOther, setHolidayOther ] = useState('');

  // 福利厚生
  const [ welfare, setWelfare ] = useState([]);
  const [ welfareCheck, setWelfareCheck ] = useState(false);
  const [ welfareOther, setWelfareOther ] = useState('');

  // 給与体系・福利厚生に関する備考
  const [ salaryWelfare, setSalaryWelfare ] = useState('');
  
  // 必須資格
  const [ personStatue, setPersonStatue ] = useState('');
  
  // 仕事内容
  const [ workContents, setWorkContents ] = useState('');

  // 雰囲気
  const [ mood1, setMood1 ] = useState(1);
  const [ mood2, setMood2 ] = useState(1);
  const [ mood3, setMood3 ] = useState(1);
  const [ mood4, setMood4 ] = useState(1);
  const [ mood5, setMood5 ] = useState(1);
  const [ mood6, setMood6 ] = useState(1);
  
  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var recruit = decodeURIComponent(link.slice(link.indexOf('recruitId=') + 10, link.length));
    
  useEffect(() => {
    window.scrollTo(0, 0);
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {

        firebase.firestore().collection("recruits").doc(recruit)
        .get().then((doc) => {

          setPost(doc.data().recruit_post === undefined ? '' : doc.data().recruit_post);
          
          setEmploymentStatus(doc.data().recruit_employmentStatus === undefined ? '' : doc.data().recruit_employmentStatus);
          setSalary(doc.data().recruit_salary === undefined ? '' : doc.data().recruit_salary);
          setAnnualSalaryStart(doc.data().recruit_annualSalaryStart === undefined ? 0 : doc.data().recruit_annualSalaryStart);
          setAnnualSalaryEnd(doc.data().recruit_annualSalaryEnd === undefined ? 0 : doc.data().recruit_annualSalaryEnd);
          setAnnualIncomeStart(doc.data().recruit_annualIncomeStart === undefined ? 0 : doc.data().recruit_annualIncomeStart);
          setAnnualIncomeEnd(doc.data().recruit_annualIncomeEnd === undefined ? 0 : doc.data().recruit_annualIncomeEnd);
          setMonthlySalaryStart(doc.data().recruit_monthlySalaryStart === undefined ? 0 : doc.data().recruit_monthlySalaryStart);
          // setMonthlySalaryEnd(doc.data().recruit_monthlySalaryEnd === undefined ? 0 : doc.data().recruit_monthlySalaryEnd);
          setDailyWageStart(doc.data().recruit_dailyWageStart === undefined ? 0 : doc.data().recruit_dailyWageStart);
          setDailyWageEnd(doc.data().recruit_dailyWageEnd === undefined ? 0 : doc.data().recruit_dailyWageEnd);
          setHourWageStart(doc.data().recruit_hourWageStart === undefined ? 0 : doc.data().recruit_hourWageStart);
          setHourWageEnd(doc.data().recruit_hourWageEnd === undefined ? 0 : doc.data().recruit_hourWageEnd);
          setSuccessFee(doc.data().recruit_successFee === undefined ? '' : doc.data().recruit_successFee);

          setWorkingHoursStart(doc.data().recruit_workingHoursStart === undefined ? 0 : doc.data().recruit_workingHoursStart);
          setWorkingHoursEnd(doc.data().recruit_workingHoursEnd === undefined ? 0 : doc.data().recruit_workingHoursEnd);

          setOvertime(doc.data().recruit_overtime === undefined ? false : doc.data().recruit_overtime);
          setOvertimeOther(doc.data().recruit_overtimeOther === undefined ? '' : doc.data().recruit_overtimeOther);

          setHoliday(doc.data().recruit_holiday === undefined ? [] : doc.data().recruit_holiday);
          setHolidayCheck(doc.data().recruit_holidayCheck === undefined ? false : doc.data().recruit_holidayCheck);
          setHolidayOther(doc.data().recruit_holidayOther === undefined ? '' : doc.data().recruit_holidayOther);

          setWelfare(doc.data().recruit_welfare === undefined ? [] : doc.data().recruit_welfare);
          setWelfareCheck(doc.data().recruit_welfareCheck === undefined ? false : doc.data().recruit_welfareCheck);
          setWelfareOther(doc.data().recruit_welfareOther === undefined ? '' : doc.data().recruit_welfareOther);

          setWorkContents(doc.data().recruit_workContents === undefined ? '' : doc.data().recruit_workContents);
          setPersonStatue(doc.data().recruit_personStatue === undefined ? '' : doc.data().recruit_personStatue);

          setSalaryWelfare(doc.data().recruit_salaryWelfare === undefined ? '' : doc.data().recruit_salaryWelfare);

        });
      } else {
        history.push('/company/login')
      }
    })

  }, [history, recruit])

  function check() {
    window.scrollTo(0, 0);

    // if (post === '') {
    //   setErrorMessage('職種・役職を入力してください。');
    // } else 
    if (employmentStatus === '') {
      setErrorMessage('雇用形態を入力してください。');
    } else if (monthlySalaryStart === 0 || monthlySalaryStart === '0' || monthlySalaryStart === '') {
      setErrorMessage('月給を入力してください。');
    } else if (annualIncomeStart === 0 || annualIncomeStart === '0' || annualIncomeStart === '' || annualIncomeEnd === 0 || annualIncomeEnd === '0' || annualIncomeEnd === '') {
      setErrorMessage('想定年収を入力してください。');
    } else {
      check2();
    }
    
    // } else if (salary === '') {
    //   setErrorMessage('給与形態を入力してください。');
    // } else {
    //   if (salary === '年俸' && (annualSalaryStart === 0 || annualSalaryStart === '0' || annualSalaryStart === '' || annualSalaryEnd === 0 || annualSalaryEnd === '0' || annualSalaryEnd === '')) {
    //     setErrorMessage('年俸を入力してください。');
    //   } else if (salary === '月給' && ((monthlySalaryStart === 0 || monthlySalaryStart === '0' || monthlySalaryStart === '' || monthlySalaryEnd === 0 || monthlySalaryEnd === '0' || monthlySalaryEnd === '') || (annualIncomeStart === 0 || annualIncomeStart === '0' || annualIncomeStart === '' || annualIncomeEnd === 0 || annualIncomeEnd === '0' || annualIncomeEnd === ''))) {
    //     setErrorMessage('月給・想定年収を入力してください。');
    //   } else if (salary === '日給' && (dailyWageStart === 0 || dailyWageStart === '0' || dailyWageStart === '' || dailyWageEnd === 0 || dailyWageEnd === '0' || dailyWageEnd === '')) {
    //     setErrorMessage('日給を入力してください。');
    //   } else if (salary === '時給' && (hourWageStart === 0 || hourWageStart === '0' || hourWageStart === '' || hourWageEnd === 0 || hourWageEnd === '0' || hourWageEnd === '')) {
    //     setErrorMessage('時給を入力してください。');
    //   } else if (salary === '成果報酬(業務委託のみ)' && (successFee === '' || successFee === '0' || successFee === 0)) {
    //     setErrorMessage('成果報酬の備考を入力してください。');
    //   } else {
    //     check2();
    //   }
    // }
  }

  function check2() {

    if (workingHoursStart === '' || workingHoursEnd === '') {
      setErrorMessage('勤務時間を入力してください。');
    } else if (holidayCheck && holidayOther === '') {
      setErrorMessage('休日・休暇のその他を入力してください。');
    } else if (salaryWelfare === '') {
      setErrorMessage('給与体系・福利厚生に関する備考を入力してください。');
    } else if (overtime && overtimeOther === '') {
      setErrorMessage('みなし残業代の備考を入力してください。');
    } else if (welfareCheck && welfareOther === '') {
      setErrorMessage('福利厚生のその他を入力してください。');
    // } else if (workContents === '') {
    //   setErrorMessage('仕事内容を入力してください。');
    // } else if (personStatue === '') {
    //   setErrorMessage('求める人物像を入力してください。');
    } else {
      save(true);
    }
  }

  function save(e) {
    firebase.firestore().collection("recruits").doc(recruit)
    .update({
      status_recruit: e,

      recruit_post: post,
      recruit_employmentStatus: employmentStatus,

      recruit_salary: salary,
      recruit_annualSalaryStart: Number(annualSalaryStart),
      recruit_annualSalaryEnd: Number(annualSalaryEnd),
      recruit_annualIncomeStart: Number(annualIncomeStart),
      recruit_annualIncomeEnd: Number(annualIncomeEnd),
      recruit_monthlySalaryStart: Number(monthlySalaryStart),
      // recruit_monthlySalaryEnd: Number(monthlySalaryEnd),
      recruit_dailyWageStart: Number(dailyWageStart),
      recruit_dailyWageEnd: Number(dailyWageEnd),
      recruit_hourWageStart: Number(hourWageStart),
      recruit_hourWageEnd: Number(hourWageEnd),
      recruit_successFee: successFee,
      recruit_workingHoursStart: workingHoursStart,
      recruit_workingHoursEnd: workingHoursEnd,
      recruit_overtime: overtime,
      recruit_overtimeOther: overtimeOther,

      recruit_holidayCheck: holidayCheck,
      recruit_holiday: holiday,
      recruit_holidayOther: holidayOther,
      recruit_welfareCheck: welfareCheck,
      recruit_welfare: welfare,
      recruit_welfareOther: welfareOther,
      recruit_salaryWelfare: salaryWelfare,
      recruit_searchTags: [ employmentStatus ],

      recruit_workContents: workContents,
      recruit_personStatue: personStatue,
      last_time: new Date(),
    }).then(() => {
      history.push(`/edit/id=${company}+page=2+recruitId=${recruit}`);
    });
  }

  const [ backStatus, setBackStatus ] = useState(false);

  return (
    <>

    <Header />

    <div className={Styles.box}>
      <div className={Styles.box_inline}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.contents}>
          <h5 className={Styles.title}>雇用情報</h5>

          <DefaultSelect1
            title={'雇用形態'} required={true} length={0} place={''} type={''}
            value={employmentStatus} setValue={setEmploymentStatus}
            list={['未選択', '新卒採用（正社員）', '新卒採用（契約社員）' ]}
            // list={['未選択', '新卒採用（正社員）', '新卒採用（契約社員）', '中途採用（正社員）', '中途採用（契約社員）', 'アルバイト・パート採用	', '業務委託（フリーランス）', 'インターンシップ', ]}
            desc={''}
          />

          {/* <DefaultInput1
            title={'職種・役職'} required={true} length={20} place={'美容師・アシスタント'} type={'text'}
            value={post} setValue={setPost}
            desc={''}
          /> */}

          {/* <DefaultSelect1
            title={'給与形態'} required={true} length={0} place={''} type={''}
            value={salary} setValue={setSalary}
            list={['未選択', '年俸', '月給', '日給', '時給', '成果報酬(業務委託のみ)',]}
            desc={''}
          /> */}

          {/* {salary === '年俸' ?
            <DefaultInput2
              title={'年俸'} required={true} length={0} place={''} type={''} unit={'万円'}
              value1={annualSalaryStart} setValue1={setAnnualSalaryStart}
              value2={annualSalaryEnd} setValue2={setAnnualSalaryEnd}
              desc={''}
            />
          : salary === '月給' ?
          <> */}

            <DefaultInput1Salary
              title={'月給'} required={true} length={0} place={''} type={''} unit={'万円'}
              value1={monthlySalaryStart} setValue1={setMonthlySalaryStart}
              // value2={monthlySalaryEnd} setValue2={setMonthlySalaryEnd}
              desc={''}
            />

            <DefaultInput2
              title={'想定年収'} required={true} length={0} place={''} type={''} unit={'万円'}
              value1={annualIncomeStart} setValue1={setAnnualIncomeStart}
              value2={annualIncomeEnd} setValue2={setAnnualIncomeEnd}
              desc={''}
            />

          {/* </> : salary === '日給' ?
            <DefaultInput2
              title={'日給'} required={true} length={0} place={''} type={''} unit={'円'}
              value1={dailyWageStart} setValue1={setDailyWageStart}
              value2={dailyWageEnd} setValue2={setDailyWageEnd}
              desc={''}
            />
          : salary === '時給' ?
            <DefaultInput2
              title={'時給'} required={true} length={0} place={''} type={''} unit={'円'}
              value1={hourWageStart} setValue1={setHourWageStart}
              value2={hourWageEnd} setValue2={setHourWageEnd}
              desc={''}
            />
          : salary === '成果報酬(業務委託のみ)' ?
            <DefaultTextarea1
              title={'成果報酬の備考'} required={true} length={400} type={'text'}
              place={''}
              value={successFee} setValue={setSuccessFee}
              desc={''}
            />
          : <></> } */}

          <p className={Styles.desc}>　</p>

          <DefaultTitle title={'みなし残業代'} required={true} />
          
          <div className={Styles.overtime}>
            <div>
              <input id="overtime" type="radio" name="overtime" checked={overtime === true ? 'checked' : ''} onClick={() => setOvertime(true)} />
              <p>みなし残業代を含む</p>
            </div>
            <div>
              <input id="overtime" type="radio" name="overtime" checked={overtime === false ? 'checked' : ''} onClick={() => setOvertime(false)} />
              <p>みなし残業代を含まない</p>
            </div>
          </div>
          <p className={Styles.desc}>　</p>

          {overtime ?
            <DefaultTextarea1
              title={'みなし残業代の備考'} required={true} length={400} type={'text'}
              place={'上記額には固定残業代(20時間分、35,000円)を含みます。超過分は全額支給します。'}
              value={overtimeOther} setValue={setOvertimeOther}
              desc={'※固定残業代（みなし残業代）を賃金に含める場合は、以下2点をすべて記載してください。\n（1）固定残業代に該当する金額と、固定残業に含まれる時間\n（2）上記を超える際は割増賃金を追加で支払う旨\n'}
            />
          : <></> }

          <DefaultSelect2 
            title={'勤務時間'} required={true} length={0} place={''} type={'text'}
            value1={workingHoursStart} setValue1={setWorkingHoursStart}
            list1={['未選択', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00',]}
            value2={workingHoursEnd} setValue2={setWorkingHoursEnd}
            list2={['未選択', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00',]}
            desc={''}
          />

          <DefaultTitle title={'休日・休暇'} required={false} />
          <div className={Styles.check}>
            <div>
              <input type='checkbox' checked={holiday.indexOf('年次有給休暇') !== -1 ? 'checked' : ''}
                onChange={(e) => 
                  e.target.checked ? setHoliday([...holiday, '年次有給休暇']) : setHoliday(holiday.filter((v, index) => (v !== '年次有給休暇')))
                }/>
              <p>年次有給休暇</p>
            </div>
            <div>
              <input type='checkbox' checked={holiday.indexOf('産前休業') !== -1 ? 'checked' : ''}
                onChange={(e) => 
                  e.target.checked ? setHoliday([...holiday, '産前休業']) : setHoliday(holiday.filter((v, index) => (v !== '産前休業')))
                }/>
              <p>産前休業</p>
            </div>
            <div>
              <input type='checkbox' checked={holiday.indexOf('育児休業') !== -1 ? 'checked' : ''}
                onChange={(e) => 
                  e.target.checked ? setHoliday([...holiday, '育児休業']) : setHoliday(holiday.filter((v, index) => (v !== '育児休業')))
                }/>
              <p>育児休業</p>
            </div>
          </div>
          <div className={Styles.check}>
            <div>
              <input type='checkbox' checked={holiday.indexOf('介護休業') !== -1 ? 'checked' : ''}
                onChange={(e) => 
                  e.target.checked ? setHoliday([...holiday, '介護休業']) : setHoliday(holiday.filter((v, index) => (v !== '介護休業')))
                }/>
              <p>介護休業</p>
            </div>
            <div>
              <input type='checkbox' checked={holiday.indexOf('慶弔休暇') !== -1 ? 'checked' : ''}
                onChange={(e) => 
                  e.target.checked ? setHoliday([...holiday, '慶弔休暇']) : setHoliday(holiday.filter((v, index) => (v !== '慶弔休暇')))
                }/>
              <p>慶弔休暇</p>
            </div>
            <div>
              <input type='checkbox' checked={holiday.indexOf('夏季休暇') !== -1 ? 'checked' : ''}
                onChange={(e) => 
                  e.target.checked ? setHoliday([...holiday, '夏季休暇']) : setHoliday(holiday.filter((v, index) => (v !== '夏季休暇')))
                }/>
              <p>夏季休暇</p>
            </div>
          </div>
          <div className={Styles.check}>
            <div>
              <input type='checkbox' checked={holiday.indexOf('冬期休暇') !== -1 ? 'checked' : ''}
                onChange={(e) => 
                  e.target.checked ? setHoliday([...holiday, '冬期休暇']) : setHoliday(holiday.filter((v, index) => (v !== '冬期休暇')))
                }/>
              <p>冬期休暇</p>
            </div>
            <div>
              <input type='checkbox' checked={holiday.indexOf('年末年始休暇') !== -1 ? 'checked' : ''}
                onChange={(e) => 
                  e.target.checked ? setHoliday([...holiday, '年末年始休暇']) : setHoliday(holiday.filter((v, index) => (v !== '年末年始休暇')))
                }/>
              <p>年末年始休暇</p>
            </div>
            <div>
              <input type='checkbox' checked={holidayCheck ? 'checked' : ''} onChange={(e) => setHolidayCheck(e.target.checked)} />
              <p>その他</p>
            </div>
          </div>

          {holidayCheck ?
          <>
            <DefaultInput1
              title={'その他'} required={true} length={40} place={'リフレッシュ休暇、資格取得休暇、ボランティア休暇　等'} type={'text'}
              value={holidayOther} setValue={setHolidayOther}
              desc={''}
            />
          </> : <p className='memorii_recruit_body_page_company_edit_input_area_desc_none'>　</p> }

          <DefaultTitle title={'福利厚生'} required={false} />
          <div className={Styles.check}>
            <div>
              <input type='checkbox' checked={welfare.indexOf('雇用保険') !== -1 ? 'checked' : ''}
                onChange={(e) => {
                  e.target.checked ? setWelfare([...welfare, '雇用保険']) : setWelfare(welfare.filter((v, index) => (v !== '雇用保険')))
                }}/>
              <p>雇用保険</p>
            </div>
            <div>
              <input type='checkbox' checked={welfare.indexOf('労災保険') !== -1 ? 'checked' : ''}
                onChange={(e) => {
                  e.target.checked ? setWelfare([...welfare, '労災保険']) : setWelfare(welfare.filter((v, index) => (v !== '労災保険')))
                }}/>
              <p>労災保険</p>
            </div>
            <div>
              <input type='checkbox' checked={welfare.indexOf('厚生年金') !== -1 ? 'checked' : ''}
                onChange={(e) => {
                  e.target.checked ? setWelfare([...welfare, '厚生年金']) : setWelfare(welfare.filter((v, index) => (v !== '厚生年金')))
                }}/>
              <p>厚生年金</p>
            </div>
          </div>
          <div className={Styles.check}>
            <div>
              <input type='checkbox' checked={welfare.indexOf('健康保険') !== -1 ? 'checked' : ''}
                onChange={(e) => {
                  e.target.checked ? setWelfare([...welfare, '健康保険']) : setWelfare(welfare.filter((v, index) => (v !== '健康保険')))
                }}/>
              <p>健康保険</p>
            </div>
            <div>
              <input type='checkbox' checked={welfare.indexOf('交通費支給') !== -1 ? 'checked' : ''}
                onChange={(e) => {
                  e.target.checked ? setWelfare([...welfare, '交通費支給']) : setWelfare(welfare.filter((v, index) => (v !== '交通費支給')))
                }}/>
              <p>交通費支給</p>
            </div>
            <div>
              <input type='checkbox' checked={welfare.indexOf('資格取得手当') !== -1 ? 'checked' : ''}
                onChange={(e) => {
                  e.target.checked ? setWelfare([...welfare, '資格取得手当']) : setWelfare(welfare.filter((v, index) => (v !== '資格取得手当')))
                }}/>
              <p>資格取得手当</p>
            </div>
          </div>
          <div className={Styles.check}>
            <div>
              <input type='checkbox' checked={welfareCheck ? 'checked' : ''} onChange={(e) => setWelfareCheck(e.target.checked)} />
              <p>その他</p>
            </div>
            <div></div>
            <div></div>
            <div></div>
          </div>

          {welfareCheck ?
          <DefaultInput1
            title={'その他'} required={true} length={40} place={'昼食まかない制度、運動不足改善支援、友人施術費用半額制度　等'} type={'text'}
            value={welfareOther} setValue={setWelfareOther}
            desc={''}
          /> : <p className={Styles.desc}></p> }

          <DefaultTextarea1
            title={'給与体系・福利厚生に関する備考'} required={true} length={400} type={'text'} 
            place={'【インセンティブ】\n指名料：40%・店頭販売：20%'}
            value={salaryWelfare} setValue={setSalaryWelfare}
            desc={''}
          />
        </div>

        {/* <div className={Styles.contents}>
          <h5 className={Styles.title}>基本情報</h5>
          
          <DefaultTextarea1
            title={'仕事内容'} required={true} length={400} type={'text'} place={'ヘアカットやスタイリング、シャンプーといった基本的な業務をお任せします。\n加えてレジ操作や掃除等の周辺業務をお任せし、店舗を1人で運営できる美容師になることを応援します。'}
            value={workContents} setValue={setWorkContents}
            desc={''}
          />

          <DefaultTextarea1
            title={'求める人物像'} required={true} length={400} type={'text'} place={'【必須事項】\n・美容免許取得者\n・美容師に情熱をもって取り組める人\n・チームメンバーとコミュニケーションをとって業務を進められる人\n【歓迎項目】\n・人を笑顔にすることが好きな人\n・施術の中でもパーマが特に好きな人\n・アメカジが好きな人\n・お客さんと話すことが好きな人'}
            value={personStatue} setValue={setPersonStatue}
            desc={''}
          />

        </div> */}

        {/* <div className={Styles.contents}>
          <h5 className={Styles.title}>基本情報</h5>
          
          <div className={Styles.store_contents}>
            <h5 className={Styles.store_title}>店舗の雰囲気</h5>
            <div>
              <DefaultTitle title={'店舗スタイル'} required={true} />
              <DefaultSlider
                range1={'伝統的'}
                range2={'革新的'}
                value={mood1} setValue={setMood1}
              />
              <p className={Styles.desc}></p>

              <DefaultTitle title={'求める人材'} required={true} />
              <DefaultSlider
                range1={'接客重視'}
                range2={'技術重視'}
                value={mood2} setValue={setMood2}
              />
              <p className={Styles.desc}></p>

              <DefaultTitle title={'ワークライフバランス'} required={true} />
              <DefaultSlider
                range1={'プライベート重視'}
                range2={'仕事重視'}
                value={mood3} setValue={setMood3}
              />
              <p className={Styles.desc}></p>

              <DefaultTitle title={'従業員の雰囲気'} required={true} />
              <DefaultSlider
                range1={'みんなでわいわい'}
                range2={'しっかり集中'}
                value={mood4} setValue={setMood4}
              />
              <p className={Styles.desc}></p>

              <DefaultTitle title={'男女比率'} required={true} />
              <DefaultSlider
                range1={'男性客'}
                range2={'女性客'}
                value={mood5} setValue={setMood5}
              />
              <p className={Styles.desc}></p>

              <DefaultTitle title={'年齢層'} required={true} />
              <DefaultSlider
                range1={'若者多め'}
                range2={'中高年多め'}
                value={mood6} setValue={setMood6}
              />
              <p className={Styles.desc}></p>
            </div>
          </div>

        </div> */}

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check()}>保存</button>
        </div>
        
      </div>
    </div>

    {backStatus ?
      <BackModal
        companyId={company}
        recruitId={recruit}
        setBackStatus={setBackStatus}
      />
    : <></> }
    </>
  );
}

export default MemoriiWorksEditRecruit;
import Styles from './styles.module.css';
import React, { useState } from 'react'
import { v4 as uuid } from 'uuid';
import firebase from 'firebase/compat/app'
import { useHistory } from 'react-router-dom';

const Footer = () => {

  function addCompany() {
    var docId = uuid();

    firebase.firestore().collection('companies').doc(docId)
    .set({
      member_id: 'aux.',
      id: docId,
      name: 'aux.',
      logo : false,
      owner: [ 'cB3mv86u1eVh1e3xeQsoeijkzc93' ],
      administrator: [ 'cB3mv86u1eVh1e3xeQsoeijkzc93' ],
      member: [ 'cB3mv86u1eVh1e3xeQsoeijkzc93' ],
      invite: [ 'auxshige@gmail.com' ],
      recruits: [],
      stores: [],
      time: new Date(),
      recruitLimit: 0,
    })
    // info@buzontokyo.con
    .then(() => {
      // history.push(`/service/id=${docId}+page=1`);
      console.log('done');
    })
  }
  
  return (
    <>
    <button onClick={() => addCompany()}>会社追加</button>
    </>
  );
}

export default Footer;

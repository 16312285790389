import Styles from './styles.module.css';
import 'firebase/auth'
import React from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from "firebase/compat/app";
import DefaultTitle from '../../../package/parts/title';
import ErrorMessage from '../../../package/parts/error';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import DefaultInput1 from '../../../package/parts/1-input';
import BackModal from '../11.back/index';

const SalonTopic = () => {

  const history = useHistory();

  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var recruit = decodeURIComponent(link.slice(link.indexOf('recruitId=') + 10, link.length));
  
  const [ errorMessage, setErrorMessage ] = useState('');
  
  // 書類提出
  const [ document, setDocument ] = useState(false);
  // 履歴書の有無
  const [ resume, setResume ] = useState(false);
  // 質問欄の有無
  const [ question, setQuestion ] = useState(false);
  const [ questionCount, setQuestionCount ] = useState(0);
  // コーディネートの有無
  const [ coordinate, setCoordinate ] = useState(false);
  // その他の提出書類
  const [ otherDocument, setOtherDocument ] = useState(false);
  const [ otherDocumentCount, setOtherDocumentCount ] = useState(0);

  const [ question01, setQuestion01 ] = useState('');
  const [ question02, setQuestion02 ] = useState('');
  const [ question03, setQuestion03 ] = useState('');
  
  const [ otherDocument01, setOtherDocument01 ] = useState('');
  const [ otherDocument02, setOtherDocument02 ] = useState('');
  const [ otherDocument03, setOtherDocument03 ] = useState('');

  const [ backStatus, setBackStatus ] = useState(false);

  const [ purpose, setPurpose ] = useState(false);
  
  useEffect(() => {
    window.scrollTo(0, 0);

    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        firebase.firestore().collection("recruits").doc(recruit).get()
        .then((doc) => {
          setPurpose(doc.data().document_visit === undefined ? false : doc.data().document_visit);

          setDocument(doc.data().document_document === undefined ? false : doc.data().document_document);
          setResume(doc.data().document_resume === undefined ? false : doc.data().document_resume);
          setQuestion(doc.data().document_question === undefined ? false : doc.data().document_question);
          setCoordinate(doc.data().document_coordinate === undefined ? false : doc.data().document_coordinate);

          setQuestionCount(Number(doc.data().document_questionCount === undefined ? 0 : doc.data().document_questionCount));
          setQuestion01(doc.data().document_question01 === undefined ? '' : doc.data().document_question01);
          setQuestion02(doc.data().document_question02 === undefined ? '' : doc.data().document_question02);
          setQuestion03(doc.data().document_question03 === undefined ? '' : doc.data().document_question03);

          setOtherDocument(doc.data().document_otherDocument === undefined ? false : doc.data().document_otherDocument);
          setOtherDocumentCount(Number(doc.data().document_otherDocumentCount === undefined ? 0 : doc.data().document_otherDocumentCount));
          setOtherDocument01(doc.data().document_otherDocument01 === undefined ? '' : doc.data().document_otherDocument01);
          setOtherDocument02(doc.data().document_otherDocument02 === undefined ? '' : doc.data().document_otherDocument02);
          setOtherDocument03(doc.data().document_otherDocument03 === undefined ? '' : doc.data().document_otherDocument03);
        });
      } else {
        history.push('/company/login')
      }
    })

  }, [history, link]);

  function check() {

    window.scrollTo(0, 0);

    if (question) {
      if (questionCount === 0) {
        setErrorMessage('1.質問数を入力してください。');
      } else if (questionCount === 1) {
        if (question01 === '') {
          setErrorMessage('1.質問を入力してください。');
        } else {
          check1();
        }
      } else if (questionCount === 2) {
        if (question01 === '') {
          setErrorMessage('1.質問を入力してください。');
        } else if (question02 === '') {
          setErrorMessage('2.質問を入力してください。');
        } else {
          check1();
        }
      } else if (questionCount === 3) {
        if (question01 === '') {
          setErrorMessage('1.質問を入力してください。');
        } else if (question02 === '') {
          setErrorMessage('2.質問を入力してください。');
        } else if (question03 === '') {
          setErrorMessage('3.質問を入力してください。');
        } else {
          check1();
        }
      }
    } else {
      check1();
    }
  }

  function check1() {

    window.scrollTo(0, 0);

    if (otherDocument) {
      if (otherDocumentCount === 0) {
        setErrorMessage('その他の提出物の数を入力してください。');
      } else if (otherDocumentCount === 1) {
        if (otherDocument01 === '') {
          setErrorMessage('1.その他の提出物を入力してください。');
        } else {
          save(true);
        }
      } else if (otherDocumentCount === 2) {
        if (otherDocument01 === '') {
          setErrorMessage('1.その他の提出物を入力してください。');
        } else if (otherDocument02 === '') {
          setErrorMessage('2.その他の提出物を入力してください。');
        } else {
          save(true);
        }
      } else if (otherDocumentCount === 3) {
        if (otherDocument01 === '') {
          setErrorMessage('1.その他の提出物を入力してください。');
        } else if (otherDocument02 === '') {
          setErrorMessage('2.その他の提出物を入力してください。');
        } else if (otherDocument03 === '') {
          setErrorMessage('3.その他の提出物を入力してください。');
        } else {
          save(true);
        }
      }
    } else {
      save(true);
    }
  }

  function save(e) {
    firebase.firestore().collection("recruits").doc(recruit)
    .update({
      status_document: e,
      // 目的
      document_visit: purpose,
      // 全体
      document_document: document,
      document_resume: resume,
      document_question: question,
      // 質問
      document_coordinate: coordinate,
      document_questionCount: Number(questionCount),
      document_question01: question01,
      document_question02: question02,
      document_question03: question03,
      // その他
      document_otherDocument: otherDocument,
      document_otherDocumentCount: Number(otherDocumentCount),
      document_otherDocument01: otherDocument01,
      document_otherDocument02: otherDocument02,
      document_otherDocument03: otherDocument03,
      
      last_time: new Date(),
    }).then(() => {
      history.push(`/edit/id=${company}+page=2+recruitId=${recruit}`)
    })
  }

  return (
    <>
    <div className={Styles.box}>
      <div className={Styles.box_inline}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.contents}>

          <h5 className={Styles.title}>サロン見学</h5>

          <div className={Styles.radio_btn}>
            <div>
              <input type="radio" name='0' checked={purpose === true ? 'checked' : ''} onClick={() => setPurpose(true)} />
              <p>募集する</p>
            </div>
            <div>
              <input type="radio" name='0' checked={purpose === false ? 'checked' : ''} onClick={() => setPurpose(false)} />
              <p>募集しない</p>
            </div>
          </div>
          <p className={Styles.desc}>　</p>

        </div>
        
        <div className={Styles.contents}>
          <h5 className={Styles.title}>応募設定</h5>

          <DefaultTitle title={'書類提出'} required={true} />
          <div className={Styles.radio_btn}>
            <div>
              <input type="radio" name='1' checked={document === true ? 'checked' : ''} onClick={() => setDocument(true)} />
              <p>応募時に書類を求める</p>
            </div>
            <div>
              <input type="radio" name='1' checked={document === false ? 'checked' : ''} onClick={() => setDocument(false)} />
              <p>応募時に書類を求めない</p>
            </div>
          </div>
          <p className={Styles.desc}>　</p>

          {document ?
          <>
          <h5 className={Styles.title}>応募内容</h5>
          <DefaultTitle title={'履歴書の有無'} required={true} />
          <div className={Styles.radio_btn}>
            <div>
              <input name="2" type="radio" checked={resume === true ? 'checked' : ''} onClick={() => setResume(true)} />
              <p>提出を求める</p>
            </div>
            <div>
              <input name="2" type="radio" checked={resume === false ? 'checked' : ''} onClick={() => setResume(false)} />
              <p>提出を求めない</p>
            </div>
          </div>
          <p className={Styles.desc}>　</p>
          
          <DefaultTitle title={'追加質問の有無'} required={true} />
          <div className={Styles.radio_btn}>
            <div>
              <input name="3" type="radio" checked={question === true ? 'checked' : ''} onClick={() => setQuestion(true)} />
              <p>回答を求める</p>
            </div>
            <div>
              <input name="3" type="radio" checked={question === false ? 'checked' : ''} onClick={() => setQuestion(false)} />
              <p>回答を求めない</p>
            </div>
          </div>
          <p className={Styles.desc}>　</p>

          <DefaultTitle title={'コーディネート写真の有無'} required={true} />
          <div className={Styles.radio_btn}>
            <div>
              <input name="4" type="radio" checked={coordinate === true ? 'checked' : ''} onClick={() => setCoordinate(true)} />
              <p>提出を求める</p>
            </div>
            <div>
              <input name="4" type="radio" checked={coordinate === false ? 'checked' : ''} onClick={() => setCoordinate(false)} />
              <p>提出を求めない</p>
            </div>
          </div>
          <p className={Styles.desc}>　</p>

          <DefaultTitle title={'その他の提出書類'} required={true} />
          <div className={Styles.radio_btn}>
            <div>
              <input name="5" type="radio" checked={otherDocument === true ? 'checked' : ''} onClick={() => setOtherDocument(true)} />
              <p>提出を求める</p>
            </div>
            <div>
              <input name="5" type="radio" checked={otherDocument === false ? 'checked' : ''} onClick={() => setOtherDocument(false)} />
              <p>提出を求めない</p>
            </div>
          </div>
          <p className={Styles.desc}>　</p>
          </> : <></>}

        </div>

        {question && document ?
        <>
          <div className={Styles.contents}>
            <h5 className={Styles.title}>追加質問</h5>

            <DefaultTitle title={'質問数'} required={true} />
            <select className={Styles.select}
              value={questionCount} onChange={(e) => setQuestionCount(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1つ</option>
              <option value={2}>2つ</option>
              <option value={3}>3つ</option>
            </select>
            <p className={Styles.desc}>　</p>
            
            {questionCount === 1 || questionCount === 2 || questionCount === 3 ?
            <>
            <DefaultInput1
              title={'1.質問'} required={true} length={20} place={''} type={'text'}
              value={question01} setValue={setQuestion01}
              desc={'　'}
            />
            </> : <></> }
            
            {questionCount === 2 || questionCount === 3 ?
            <>
            <DefaultInput1
              title={'2.質問'} required={true} length={20} place={''} type={'text'}
              value={question02} setValue={setQuestion02}
              desc={'　'}
            />
            </> : <></> }
            
            {questionCount === 3 ?
            <>
            <DefaultInput1
              title={'3.質問'} required={true} length={20} place={''} type={'text'}
              value={question03} setValue={setQuestion03}
              desc={'　'}
            />
            </> : <></> }
          </div>
        </> : <></>}
        
        {otherDocument && document ?
        <>
        <div className={Styles.contents}>
          <h5 className={Styles.title}>その他の提出物</h5>

          <DefaultTitle title={'その他の提出物の数'} required={true} />
          <select className={Styles.select}
            value={otherDocumentCount} onChange={(e) => setOtherDocumentCount(Number(e.target.value))}>
            <option value={0}>未選択</option>
            <option value={1}>1つ</option>
            <option value={2}>2つ</option>
            <option value={3}>3つ</option>
          </select>
          <p className={Styles.desc}>　</p>
          
          {otherDocumentCount === 1 || otherDocumentCount === 2 || otherDocumentCount === 3 ?
          <DefaultInput1
            title={'1.提出書類'} required={true} length={20} place={''} type={'text'}
            value={otherDocument01} setValue={setOtherDocument01}
            desc={'　'}
          /> : <></> }
          
          {otherDocumentCount === 2 || otherDocumentCount === 3 ?
          <DefaultInput1
            title={'2.提出書類'} required={true} length={20} place={''} type={'text'}
            value={otherDocument02} setValue={setOtherDocument02}
            desc={'　'}
          /> : <></> }
          
          {otherDocumentCount === 3 ?
          <DefaultInput1
            title={'3.提出書類'} required={true} length={20} place={''} type={'text'}
            value={otherDocument03} setValue={setOtherDocument03}
            desc={'　'}
          />
          : <></> }
        </div>
        </> : <></>}

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check()}>保存</button>
        </div>

      </div>

    </div>

    {backStatus ?
      <BackModal
        companyId={company}
        recruitId={recruit}
        setBackStatus={setBackStatus}
      />
    : <></> }
    </>
  );
}

export default SalonTopic;
import React from 'react';
import firebase from 'firebase/compat/app';
import Styles from './styles.module.css';
import { FaApple } from 'react-icons/fa';
import { OAuthProvider } from 'firebase/auth';
import { useHistory } from 'react-router-dom';

const AuthApple = (props) => {

  const history = useHistory();

  async function handleAppleSignup () {
    const provider =  new OAuthProvider('apple.com');
    firebase.auth().signInWithPopup(provider)
    .then((result) => {
      history.push('/select');
    })
    .catch((error) => {
      props.setError(true);
    });
  }

  return (
    <>
    <div className={Styles.signIn_box} onClick={() => handleAppleSignup()}>
      <FaApple className={Styles.signIn_box_icon} />
      <h4>Appleアカウントでログインする</h4>
    </div>
    </>
  );
}

export default AuthApple;

import Styles from './styles.module.css';
import firebase from 'firebase/compat/app';
import React, { useState, useEffect } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { BsFillPersonFill } from 'react-icons/bs';
import { MdContentPasteSearch } from 'react-icons/md';
import { FiMail } from 'react-icons/fi';
import { FaPlus } from "react-icons/fa";
import { BiMessageDetail } from "react-icons/bi";
import { useHistory } from 'react-router-dom';
import Image1 from './../../../../image/works/employment/1.png';
import Image2 from './../../../../image/works/employment/2.png';
import Image3 from './../../../../image/works/employment/3.png';
import Image4 from './../../../../image/works/employment/4.png';
import Image5 from './../../../../image/works/employment/5.png';
import Image6 from './../../../../image/works/employment/6.png';
import NewGraduate from './../../../../image/works/new_graduate.png';
import Details from '../../package/info/index';

const MemoriiHome = (props) => {

  const history = useHistory();

  const [ recruitList, setRecruitList ] = useState([]);
  const [ applicantsList, setApplicantsList ] = useState([]);

  const [ recruitId, setRecruitId ] = useState('');
  const [ applicantId, setApplicantId ] = useState('');

  useEffect(() => {

    setRecruitList([]);
    getRecruits();
    // setRecruitList(tmp);

    // firebase.firestore().collection('recruits').where('companyId', '==', props.companyList.id).get()
    // .then(snapShot => {
    //   var tmp = [];
    //   var applicants = [];
    //   snapShot.forEach((doc) => {
    //     tmp.push({...doc.data(), id: doc.id});
    //     applicants.push(...doc.data().data_applicants);
    //   });

    //   setRecruitList(tmp);

    //   if (applicants.length !== 0) {
    //     getApplicants(applicants);
    //   }
    // });
  }, []);

  function getRecruits() {
    var applicants = [];
    for (let i = 0; i < props.companyList.recruits.length; i++) {
      console.log(props.companyList.recruits[i]);
      firebase.firestore().collection('recruits').doc(props.companyList.recruits[i]).get()
      .then((doc) => {
        setRecruitList((recruitList => [...recruitList, {...doc.data(), id: doc.id}]));

        if (doc.data().data_applicants !== undefined && doc.data().data_applicants.length !== 0) {
          applicants.push(...doc.data().data_applicants);
        }
        if (props.companyList.recruits.length === i + 1) {
          getApplicants(applicants);
        }
      });
    }
  }

  // 応募者データ
  function getApplicants(list) {
    setApplicantsList([]);

    var now = new Date();
    var start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 5, 0, 0, 0);
    var end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

    for (let i = 0; i < list.length; i++) {
      firebase.firestore().collection('applicants').doc(list[i]).get()
      .then((doc) => {
        console.log(i + ':' + doc.id);
        if (end > new Date(doc.data().time.seconds * 1000) && new Date(doc.data().time.seconds * 1000) >= start) {
          setApplicantsList((applicantsList => [...applicantsList, {...doc.data(), id: doc.id}]));
        }
      });
    }
  }

  return (
    <>
    <div className={Styles.recruit}>
      <h4 className={Styles.recruit_title}>採用状況の進捗</h4>

      {recruitList.map((data, index) =>
      <>
        {data.status === 1 ?
        <>
          <div className={Styles.recruit_box_title}>
            <img src={
              data.recruit_employmentStatus === '新卒採用（正社員）' ? Image1 :
              data.recruit_employmentStatus === '中途採用（正社員）' ? Image2 :
              data.recruit_employmentStatus === '中途採用（契約社員）' ? Image3 :
              data.recruit_employmentStatus === 'アルバイト・パート採用' ? Image4 :
              data.recruit_employmentStatus === '業務委託（フリーランス）' ? Image5 : Image6
            } />
            <h4>{data.store_title}</h4>
          </div>

          <div className={Styles.recruit_box_data}>
            <div className={Styles.recruit_box_data_before}>
              <div className={Styles.recruit_box_data_1}>
                <BsFillPersonFill className={Styles.recruit_box_data_1_icon} />
                <h4>PV数</h4>
                <h3>{data.data_pv.length}人</h3>
              </div>
              <FaPlus className={Styles.recruit_box_data_between_icon} />
              <div className={Styles.recruit_box_data_1}>
                <MdContentPasteSearch className={Styles.recruit_box_data_1_icon} />
                <h4>スカウト数</h4>
                <h3>-</h3>
              </div>
              <FaChevronRight className={Styles.recruit_box_data_between_icon} />
              <div className={Styles.recruit_box_data_1}>
                <FiMail className={Styles.recruit_box_data_1_icon} />
                <h4>応募者数</h4>
                <h3>{data.data_applicants.length}人</h3>
              </div>
              <FaChevronRight className={Styles.recruit_box_data_between_icon} />
              <div className={Styles.recruit_box_data_2}>
                <BsFillPersonFill className={Styles.recruit_box_data_2_icon} />
                <h4>採用数</h4>
                <h3>{data.data_recruitment.length}人</h3>
              </div>
            </div>
            <div className={Styles.recruit_box_data_3}>
              <BsFillPersonFill className={Styles.recruit_box_data_3_icon} />
              <h4>目標人数</h4>
              <h3>{data.store_goal}人</h3>
            </div>
          </div>

          {data.data_apply.length !== 0 && applicantsList.length !== 0 ?
          <>
            <h4 className={Styles.new_title} key={index}>New 新規応募者！</h4>
              <div className={Styles.new}>
                <div className={Styles.new_table_title}>
                  <h3>応募者</h3>
                  <h4>性別</h4>
                  <h5>応募日時</h5>
                  <div></div>
                </div>
                {applicantsList.map((app, index) =>
                <>
                  {data.id === app.recruitId ?
                    <div className={Styles.new_table_data} 
                      onClick={() => {setRecruitId(app.recruitId); setApplicantId(app.id);}}>
                      <div>
                        <img src={NewGraduate} />
                        <h3>{app.name}</h3>
                      </div>
                      <h4>{app.sex}</h4>
                      <h5>{new Date(app.time.seconds * 1000).getFullYear()}年{new Date(app.time.seconds * 1000).getMonth()+1}月{new Date(app.time.seconds * 1000).getDate()}日</h5>
                      <BiMessageDetail className={Styles.new_table_data_icon}
                        onClick={() => {history.push(`/works/service/id=${props.companyList.id}+page=6+recruit=${app.recruitId}+applicant=${app.id}`);}}
                      />
                    </div>
                  : <></> }
                </> )}
            </div>
          </> : <></> }

          <div style={{height : '30px'}}></div>
        </> : <></> }
      </> )}
    </div>

    {recruitId !== '' && applicantId !== '' ?
      <Details 
        recruitId={recruitId} setRecruitId={setRecruitId}
        applicantId={applicantId} setApplicantId={setApplicantId}
        userList={props.userList} />
    : <></> }
    </>
  );
}

export default MemoriiHome;

import './styles.css';
import React from 'react';
import { isMobile } from "react-device-detect";
import Pc from "./pc/index";
import Mobile from "./mobile/index";

const WorksNav = (props) => {

  return (
    <>
    {!isMobile ?
      <Pc
        mobile={props.mobile}
        navigation={props.navigation}
        userList={props.userList}
        companyList={props.companyList}
        setNavigation={props.setNavigation}
        authority={props.authority}
      />
    :
      <Mobile
        mobile={props.mobile}
        navigation={props.navigation}
        userList={props.userList}
        companyList={props.companyList}
        setNavigation={props.setNavigation}
        authority={props.authority}
      />
    }
    </>
  );
}

export default WorksNav;

import Styles from './styles.module.css';
import React from 'react';
import GoogleImage from './../../../../image/works/potal/header/app.png';
import AppImage from './../../../../image/works/potal/header/google.png';
import { useHistory } from 'react-router-dom';

const WebPortal = (props) => {

  const history = useHistory();

  return (
    <>
    <div className={Styles.modal}>
      <div>
        <div className={Styles.modal_black} onClick={() => props.setModal(false)}></div>
        <div className={Styles.modal_contents}>
          <h4>会員登録</h4>
          <p>求人保存を行う事で会員登録を行う必要があります。<br />登録は以下のボタンより行うことが出来ます。</p>
          <button onClick={() => history.push('/student/login')}>会員登録をする</button>

          <h5>アプリをダウンロード</h5>
          <img src={AppImage} alt='' />
          <img src={GoogleImage} alt='' />
        </div>
      </div>
    </div>
    </>
  );
}

export default WebPortal;

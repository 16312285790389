import Styles from './styles.module.css';
import React, { useState, useEffect } from "react";
import firebase from 'firebase/compat/app';
import DefaultImg from './../../../../image/works/default/home.png';
import { FaMap } from "react-icons/fa6";
import { FaMoneyBillWaveAlt } from "react-icons/fa";
import { FaBookmark } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa";
import SliderImage from "./images/index";
import { useHistory } from 'react-router-dom';

const WorksSiteWebItem = (props) => {

  const history = useHistory();

  const [ save, setSave ] = useState(false);

  useEffect(() => {
    if (props.userList.user_recruit_save !== undefined && Array.isArray(props.userList.user_recruit_save)) {
      setSave(props.userList.user_recruit_save.includes(props.recruitList.id));
    }
  }, []);

  const [ error, setError ] = useState(false);
  const [ apper, setApper ] = useState(false);

  function addList() {    

    if (props.userList.id !== undefined) {
      setSave(true);
      firebase.firestore().collection('recruits').doc(props.recruitList.id)
      .update({
        save: firebase.firestore.FieldValue.arrayUnion(props.userList.id),
      });
      firebase.firestore().collection('users').doc(props.userList.id)
      .update({
        user_recruit_save: firebase.firestore.FieldValue.arrayUnion(props.recruitList.id),
      });
    } else {
      props.setModal(true);
    }

  }

  function deleteList() {
  
    if (props.userList.id !== undefined) {
      setSave(false);
      firebase.firestore().collection('recruits').doc(props.recruitList.id)
      .update({
        save: firebase.firestore.FieldValue.arrayRemove(props.userList.id),
      });
      firebase.firestore().collection('users').doc(props.userList.id)
      .update({
        user_recruit_save: firebase.firestore.FieldValue.arrayRemove(props.recruitList.id),
      });
    } else {
      props.setModal(true);
    }

  }

  return (
    <>
    <div className={Styles.item}>
      <div className={Styles.title}>
        <div onClick={() => history.push(`/works/recruit/id=${props.recruitList.id}`)}>
          <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/companies_logo%2Fresize_images%2F${props.recruitList.companyId}_200x200?alt=media&token=`} alt=''
          onError={e => {
            e.target.onError = null;
            e.target.src = DefaultImg;
          }} />
          <p>{props.recruitList.store_name}</p>
        </div>
        {save ?
          <FaBookmark 
            style={{color: '#AD0200'}}
            className={Styles.bottom_icon}
            onClick={() => save ? deleteList() : addList()}
          />
          :
          <FaRegBookmark 
            style={{color: '#222222'}}
            className={Styles.bottom_icon}
            onClick={() => save ? deleteList() : addList()}
          />
        }
      </div>
      <h5 onClick={() => history.push(`/recruit/id=${props.recruitList.id}`)}>{props.recruitList.store_title}</h5>
      <p onClick={() => history.push(`/recruit/id=${props.recruitList.id}`)}>
        募集職種：{props.recruitList.recruit_employmentStatus}</p>
      <div className={Styles.image}>
        <SliderImage imageName={props.recruitList.store_imageName === undefined ? [] : props.recruitList.store_imageName} />
      </div>
      <div className={Styles.detail} onClick={() => history.push(`/recruit/id=${props.recruitList.id}`)}>
        <div>
          <FaMoneyBillWaveAlt className={Styles.detail_icon} />
          <p>
            給与：
            {props.recruitList.recruit_salary === '年俸' ?
            <>年俸：{props.recruitList.recruit_annualSalaryStart}万円~{props.recruitList.recruit_annualSalaryEnd}万円</>
            : props.recruitList.recruit_salary === '月給' ?
            <>月給：{props.recruitList.recruit_monthlySalaryStart}万円~{props.recruitList.recruit_monthlySalaryEnd}万円</> 
            : props.recruitList.recruit_salary === '日給' ?
            <>日給：{props.recruitList.recruit_dailyWageStart}円~{props.recruitList.recruit_dailyWageEnd}円</> 
            : props.recruitList.recruit_salary === '時給' ?
            <>時給：{props.recruitList.recruit_hourWageStart}円~{props.recruitList.recruit_hourWageEnd}円</> 
            : props.recruitList.recruit_salary === '完全報酬(業務委託のみ)' ?
            <>完全報酬(業務委託のみ)</> 
            : <></> }
          </p>
        </div>
        <div>
          <FaMap className={Styles.detail_icon} />
          <p>最寄り駅：{props.recruitList.place_stationTags[0]},{props.recruitList.place_stationTags[2]}</p>
        </div>
      </div>
      <div className={Styles.tags} onClick={() => history.push(`/recruit/id=${props.recruitList.id}`)}>
        {props.recruitList.store_hashTagList.map((data, index) =>
          <p>{data}</p>
        )}
      </div>
    </div>

    {error && apper ?
      <div className='works_modal'>
        <div>
          <div className='works_modal_black' onClick={() => setError(false)}></div>
          <div className='works_modal_login_error'>
            <h4>会員登録</h4>
            <p>
              アカウントにログイン出来ていません。<br />
              アカウントをお持ちでない方は新規登録、登録済みの方はログイン画面より認証を行なってください。
            </p>
            <div>
              <button style={{backgroundColor : 'white', color : '#AD0200'}} onClick={() => setApper(false)}>ログイン</button>
              <button style={{backgroundColor : 'white', color : '#AD0200'}} onClick={() => setApper(false)}>新規登録</button>
            </div>
          </div>
        </div>
      </div>
    : <></> }
    </>
  );
}

export default WorksSiteWebItem;



import DocumentMeta from 'react-document-meta';
import React, { useState, useLayoutEffect } from 'react';
import firebase from 'firebase/compat/app';
import Nav from './../header/index';
import { useHistory } from 'react-router-dom';
import Search from '../search/index';
import Styles from './styles.module.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Registration from './../registration/index';
import Slider from "./strongImage/index";
import Header from "./header/index";
import Point from "./point/index";
import Pentagon from "./pentagon/index";
import Person from "./person/index";
import Employment from "./employment/index";
import Number from "./number/index";
import Posts from "./posts/index";
import Interview from "./interviews/index";

const Footer = () => {


  const history = useHistory();  
  
  const [ user, setUser ] = useState(false);

  const [ companyId, setCompanyId ] = useState('');
  
  const [ recruitList, setRecruitList ] = useState([]);

  const [ modal, setModal ] = useState(false);

  var link = new URL(window.location).href;
  var recruit = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  const [ image1Title, setImage1Title ] = useState('');
  const [ image1Desc, setImage1Desc ] = useState('');

  const [ image2Title, setImage2Title ] = useState('');
  const [ image2Desc, setImage2Desc ] = useState('');

  const [ image3Title, setImage3Title ] = useState('');
  const [ image3Desc, setImage3Desc ] = useState('');

  const [ interview1Name, setInterview1Name ] = useState('');
  const [ interview1School, setInterview1School ] = useState('');
  const [ interview1Title, setInterview1Title ] = useState('');
  const [ interview1Desc, setInterview1Desc ] = useState('');

  const [ interview2Name, setInterview2Name ] = useState('');
  const [ interview2School, setInterview2School ] = useState('');
  const [ interview2Title, setInterview2Title ] = useState('');
  const [ interview2Desc, setInterview2Desc ] = useState('');

  const [ interview3Name, setInterview3Name ] = useState('');
  const [ interview3School, setInterview3School ] = useState('');
  const [ interview3Title, setInterview3Title ] = useState('');
  const [ interview3Desc, setInterview3Desc ] = useState('');

  const [ interview4Name, setInterview4Name ] = useState('');
  const [ interview4School, setInterview4School ] = useState('');
  const [ interview4Title, setInterview4Title ] = useState('');
  const [ interview4Desc, setInterview4Desc ] = useState('');

  const [ interview5Name, setInterview5Name ] = useState('');
  const [ interview5School, setInterview5School ] = useState('');
  const [ interview5Title, setInterview5Title ] = useState('');
  const [ interview5Desc, setInterview5Desc ] = useState('');

  const [ interview6Name, setInterview6Name ] = useState('');
  const [ interview6School, setInterview6School ] = useState('');
  const [ interview6Title, setInterview6Title ] = useState('');
  const [ interview6Desc, setInterview6Desc ] = useState('');

  const [ storeNumberTags, setStoreNumberTags ] = useState([]);
  const [ storeNumber1, setStoreNumber1 ] = useState(0);
  const [ storeNumber2, setStoreNumber2 ] = useState(0);
  const [ storeNumber3, setStoreNumber3 ] = useState(0);
  const [ storeNumber4, setStoreNumber4 ] = useState(0);
  const [ storeNumber5, setStoreNumber5 ] = useState(0);
  const [ storeNumber6, setStoreNumber6 ] = useState(0);
  const [ storeNumber7, setStoreNumber7 ] = useState(0);
  const [ storeNumber8, setStoreNumber8 ] = useState(0);
  const [ storeNumber9, setStoreNumber9 ] = useState(0);
  const [ storeNumber10, setStoreNumber10 ] = useState(0);
  const [ storeNumber11, setStoreNumber11 ] = useState(0);
  const [ storeNumber12, setStoreNumber12 ] = useState(0);
  const [ storeNumber13, setStoreNumber13 ] = useState(0);

  const [ image1FilesName, setImage1FilesName ] = useState([]);
  const [ image2FilesName, setImage2FilesName ] = useState([]);
  const [ image3FilesName, setImage3FilesName ] = useState([]);

  const [  interview, setInterview ] = useState(0);

  useLayoutEffect(() => {
    getData();
    update();
  }, [recruit]);

  function getData() {
    firebase.firestore().collection('recruits').doc(recruit).get()
    .then((doc) => {
      setRecruitList({...doc.data(), id: doc.id });
      setCompanyId(doc.data().companyId);

      setImage1Title(doc.data().recruit_image1Title === undefined ? [] : doc.data().recruit_image1Title);
      setImage1Desc(doc.data().recruit_image1Desc === undefined ? [] : doc.data().recruit_image1Desc);

      setImage2Title(doc.data().recruit_image2Title === undefined ? [] : doc.data().recruit_image2Title);
      setImage2Desc(doc.data().recruit_image2Desc === undefined ? [] : doc.data().recruit_image2Desc);

      setImage3Title(doc.data().recruit_image3Title === undefined ? [] : doc.data().recruit_image3Title);
      setImage3Desc(doc.data().recruit_image3Desc === undefined ? [] : doc.data().recruit_image3Desc);

      setInterview1Title(doc.data().recruit_interview1Title === undefined ? [] : doc.data().recruit_interview1Title);
      setInterview1Desc(doc.data().recruit_interview1Desc === undefined ? [] : doc.data().recruit_interview1Desc);

      setInterview2Title(doc.data().recruit_interview2Title === undefined ? [] : doc.data().recruit_interview2Title);
      setInterview2Desc(doc.data().recruit_interview2Desc === undefined ? [] : doc.data().recruit_interview2Desc);

      setInterview3Title(doc.data().recruit_interview3Title === undefined ? [] : doc.data().recruit_interview3Title);
      setInterview3Desc(doc.data().recruit_interview3Desc === undefined ? [] : doc.data().recruit_interview3Desc);

      setStoreNumberTags(doc.data().recruit_storeNumberTags === undefined ? [] : doc.data().recruit_storeNumberTags);
      setStoreNumber1(doc.data().recruit_storeNumber1 === undefined ? 0 : doc.data().recruit_storeNumber1);
      setStoreNumber2(doc.data().recruit_storeNumber2 === undefined ? 0 : doc.data().recruit_storeNumber2);
      setStoreNumber3(doc.data().recruit_storeNumber3 === undefined ? 0 : doc.data().recruit_storeNumber3);
      setStoreNumber4(doc.data().recruit_storeNumber4 === undefined ? 0 : doc.data().recruit_storeNumber4);
      setStoreNumber5(doc.data().recruit_storeNumber5 === undefined ? 0 : doc.data().recruit_storeNumber5);
      setStoreNumber6(doc.data().recruit_storeNumber6 === undefined ? 0 : doc.data().recruit_storeNumber6);
      setStoreNumber7(doc.data().recruit_storeNumber7 === undefined ? 0 : doc.data().recruit_storeNumber7);
      setStoreNumber8(doc.data().recruit_storeNumber8 === undefined ? 0 : doc.data().recruit_storeNumber8);
      setStoreNumber9(doc.data().recruit_storeNumber9 === undefined ? 0 : doc.data().recruit_storeNumber9);
      setStoreNumber10(doc.data().recruit_storeNumber10 === undefined ? 0 : doc.data().recruit_storeNumber10);
      setStoreNumber11(doc.data().recruit_storeNumber11 === undefined ? 0 : doc.data().recruit_storeNumber11);
      setStoreNumber12(doc.data().recruit_storeNumber12 === undefined ? 0 : doc.data().recruit_storeNumber12);
      setStoreNumber13(doc.data().recruit_storeNumber13 === undefined ? 0 : doc.data().recruit_storeNumber13);

      setImage1FilesName(doc.data().recruit_image1FilesName === undefined ? 0 : doc.data().recruit_image1FilesName);
      setImage2FilesName(doc.data().recruit_image2FilesName === undefined ? 0 : doc.data().recruit_image2FilesName);
      setImage3FilesName(doc.data().recruit_image3FilesName === undefined ? 0 : doc.data().recruit_image3FilesName);
      
      setInterview(doc.data().recruit_interviewCount === undefined ? 0 : doc.data().recruit_interviewCount);

      if (1 <= doc.data().recruit_interviewCount) {
        setInterview1Name(doc.data().recruit_interview1Name);
        setInterview1School(doc.data().recruit_interview1School);
        setInterview1Title(doc.data().recruit_interview1Title);
        setInterview1Desc(doc.data().recruit_interview1Desc);
      }

      if (2 <= doc.data().recruit_interviewCount) {
        setInterview2Name(doc.data().recruit_interview2Name);
        setInterview2School(doc.data().recruit_interview2School);
        setInterview2Title(doc.data().recruit_interview2Title);
        setInterview2Desc(doc.data().recruit_interview2Desc);
      }

      if (3 <= doc.data().recruit_interviewCount) {
        setInterview3Name(doc.data().recruit_interview3Name);
        setInterview3School(doc.data().recruit_interview3School);
        setInterview3Title(doc.data().recruit_interview3Title);
        setInterview3Desc(doc.data().recruit_interview3Desc);
      }

      if (4 <= doc.data().recruit_interviewCount) {
        setInterview4Name(doc.data().recruit_interview4Name);
        setInterview4School(doc.data().recruit_interview4School);
        setInterview4Title(doc.data().recruit_interview4Title);
        setInterview4Desc(doc.data().recruit_interview4Desc);
      }

      if (5 <= doc.data().recruit_interviewCount) {
        setInterview5Name(doc.data().recruit_interview5Name);
        setInterview5School(doc.data().recruit_interview5School);
        setInterview5Title(doc.data().recruit_interview5Title);
        setInterview5Desc(doc.data().recruit_interview5Desc);
      }

      if (6 <= doc.data().recruit_interviewCount) {
        setInterview6Name(doc.data().recruit_interview6Name);
        setInterview6School(doc.data().recruit_interview6School);
        setInterview6Title(doc.data().recruit_interview6Title);
        setInterview6Desc(doc.data().recruit_interview6Desc);
      }

    });
  }

  function update() {
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        firebase.firestore().collection('recruits').doc(recruit)
          .update({ data_pv: firebase.firestore.FieldValue.arrayUnion(new Date()), });
        setUser(true);
      } else {
        firebase.firestore().collection('recruits').doc(recruit)
          .update({ data_pv: firebase.firestore.FieldValue.arrayUnion(new Date()), });
      }
    })
  }

  function apply() {
    if (user) {
      history.push(`/applicants/id=${companyId}+page=1+recruitId=${recruit}`)
    } else {
      setModal(true);
    }
  }

  const meta = {
    title: `${recruitList.store_name === undefined ? '' : recruitList.store_name} | memorii works`,
    description: '美容学生と就活と美容室様の新卒採用を解決するための採用プラットフォーム。',
    canonical: 'https://memorii-works.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>

      <Nav />
      
      {recruitList.length !== 0 ?
        <div className={Styles.recruit}>
          <div className={Styles.left}>

            <Header 
              recruitList={recruitList}
              image={recruitList.store_imageName === undefined ? [] : recruitList.store_imageName} 
            />

            {/* ここにボタンが入る予定 */}

            <Point
              name={recruitList.store_name === undefined ? [] : recruitList.store_name}
              point1={recruitList.recruit_point1Title === undefined ? [] : recruitList.recruit_point1Title}
              point2={recruitList.recruit_point2Title === undefined ? [] : recruitList.recruit_point2Title}
              point3={recruitList.recruit_point3Title === undefined ? [] : recruitList.recruit_point3Title}
            />

            <Pentagon 
              pentagonTreatment={[
                recruitList.recruit_pentagonStyle6 === undefined ? 1 : recruitList.recruit_pentagonStyle6,
                recruitList.recruit_pentagonStyle1 === undefined ? 1 : recruitList.recruit_pentagonStyle1,
                recruitList.recruit_pentagonStyle2 === undefined ? 1 : recruitList.recruit_pentagonStyle2,
                recruitList.recruit_pentagonStyle3 === undefined ? 1 : recruitList.recruit_pentagonStyle3,
                recruitList.recruit_pentagonStyle4 === undefined ? 1 : recruitList.recruit_pentagonStyle4,
                recruitList.recruit_pentagonStyle5 === undefined ? 1 : recruitList.recruit_pentagonStyle5,
              ]}

              pentagonStyle={[
                recruitList.recruit_pentagonTreatment1 === undefined ? 1 : recruitList.recruit_pentagonTreatment1,
                recruitList.recruit_pentagonTreatment2 === undefined ? 1 : recruitList.recruit_pentagonTreatment2,
                recruitList.recruit_pentagonTreatment3 === undefined ? 1 : recruitList.recruit_pentagonTreatment3,
                recruitList.recruit_pentagonTreatment4 === undefined ? 1 : recruitList.recruit_pentagonTreatment4,
                recruitList.recruit_pentagonTreatment5 === undefined ? 1 : recruitList.recruit_pentagonTreatment5,
                recruitList.recruit_pentagonTreatment6 === undefined ? 1 : recruitList.recruit_pentagonTreatment6,
              ]}

              triangleTreatTitle={recruitList.recruit_pentagonStyleInput === undefined ? '' : recruitList.recruit_pentagonStyleInput}
            />

            <Number 
              storeNumberTags={storeNumberTags}
              storeNumber1={storeNumber1}
              storeNumber2={storeNumber2}
              storeNumber3={storeNumber3}
              storeNumber4={storeNumber4}
              storeNumber5={storeNumber5}
              storeNumber6={storeNumber6}
              storeNumber7={storeNumber7}
              storeNumber8={storeNumber8}
              storeNumber9={storeNumber9}
              storeNumber10={storeNumber10}
              storeNumber11={storeNumber11}
              storeNumber12={storeNumber12}
              storeNumber13={storeNumber13}
            />

            <div className={Styles.text}>
              <h3>写真で強みを知る</h3>

              {1 <= recruitList.recruit_imageCount ?
                <div className={Styles.image_appeal}>
                  <Slider imageName={image1FilesName} number={1} />
                  <h4>{image1Title}</h4>
                  <p>{image1Desc}</p>
                </div>
              : <></> }

              {2 <= recruitList.recruit_imageCount ?
                <div className={Styles.image_appeal}>
                <Slider imageName={image2FilesName} number={2} />
                  <h4>{image2Title}</h4>
                  <p>{image2Desc}</p>
                </div>
              : <></> }

              {3 <= recruitList.recruit_imageCount ?
                <div className={Styles.image_appeal}>
                <Slider imageName={image3FilesName} number={3} />
                  <h4>{image3Title}</h4>
                  <p>{image3Desc}</p>
                </div>
              : <></> }
            </div>

            <div className={Styles.text}>
              {/* <h3>先輩の声</h3> */}

              <Interview
                count={interview} recruit={recruit}
                name1={interview1Name} school1={interview1School} title1={interview1Title} desc1={interview1Desc}
                name2={interview2Name} school2={interview2School} title2={interview2Title} desc2={interview2Desc}
                name3={interview3Name} school3={interview3School} title3={interview3Title} desc3={interview3Desc}
                name4={interview4Name} school4={interview4School} title4={interview4Title} desc4={interview4Desc}
                name5={interview5Name} school5={interview5School} title5={interview5Title} desc5={interview5Desc}
                name6={interview6Name} school6={interview6School} title6={interview6Title} desc6={interview6Desc}
              />

            </div>

            <Person 
              bar1={recruitList.recruit_person1 === undefined ? 1 : recruitList.recruit_person1}
              bar2={recruitList.recruit_person2 === undefined ? 1 : recruitList.recruit_person2}
              bar3={recruitList.recruit_person3 === undefined ? 1 : recruitList.recruit_person3}
              bar4={recruitList.recruit_person4 === undefined ? 1 : recruitList.recruit_person4}
              bar5={recruitList.recruit_person5 === undefined ? 1 : recruitList.recruit_person5}
            />

            <Employment
              recruitList={recruitList}
            />

            <Posts 
              companyId={companyId}
            />

            <button onClick={() => apply()}>この求人に応募する</button>

          </div>

          <Search />

        </div>
      : <></> }

      {modal ?
        <Registration setModal={setModal} />
      : <></> }

    </DocumentMeta>
    </>
  );
}

export default Footer;

{/* <div className={Styles.text}>
  <h3>店舗情報</h3>

  <div className={Styles.store_info}>
    <div className={Styles.store_info_left}>
      <h4>基本情報</h4>
      <div>
        <div>
          <FaBuilding className={Styles.store_info_left_icon} />
          <h4>創業</h4>
          <h5>2023年</h5>
        </div>
        <div>
          <RiMapPinFill className={Styles.store_info_left_icon} />
          <h4>住所</h4>
          <h5>神奈川県横浜市港北区</h5>
        </div>
        <div>
          <FaPeopleGroup className={Styles.store_info_left_icon} />
          <h4>従業員数</h4>
          <h5>20名</h5>
        </div>
        <div>
          <FaChair className={Styles.store_info_left_icon} />
          <h4>席数</h4>
          <h5>25席</h5>
        </div>
        <div>
          <FaCalendarTimes className={Styles.store_info_left_icon} />
          <h4>定休日</h4>
          <h5>火曜日、木曜日</h5>
        </div>
        <div>
          <IoPersonSharp className={Styles.store_info_left_icon} />
          <h4>募集人数</h4>
          <h5>20人</h5>
        </div>
      </div>
    </div>

    <div className={Styles.store_info_right}>
      <h4>施術の割合</h4>
      <div className={Styles.pentagon_back}>
        <div className={Styles.pentagon}>
          <div className={Styles.triangle}>
            <div className={Styles.triangle1} style={{clipPath : triangleData1}}></div>
            <div className={Styles.triangleBorder1} style={{clipPath : squareData1}}></div>
            <div className={Styles.triangleDot1}>
              <div style={{marginTop : dotTopData1, marginLeft : dotLeftData1}}></div>
            </div>

            <div className={Styles.triangle2} style={{clipPath : triangleData2}}></div>
            <div className={Styles.triangleBorder2} style={{clipPath : squareData2}}></div>
            <div className={Styles.triangleDot2}>
              <div style={{marginTop : dotTopData2, marginLeft : dotLeftData2}}></div>
            </div>

            <div className={Styles.triangle3} style={{clipPath : triangleData3}}></div>
            <div className={Styles.triangleBorder3} style={{clipPath : squareData3}}></div>
            <div className={Styles.triangleDot3}>
              <div style={{marginTop : dotTopData3, marginLeft : dotLeftData3}}></div>
            </div>

            <div className={Styles.triangle4} style={{clipPath : triangleData4}}></div>
            <div className={Styles.triangleBorder4} style={{clipPath : squareData4}}></div>
            <div className={Styles.triangleDot4}>
              <div style={{marginTop : dotTopData4, marginLeft : dotLeftData4}}></div>
            </div>

            <div className={Styles.triangle5} style={{clipPath : triangleData5}}></div>
            <div className={Styles.triangleBorder5} style={{clipPath : squareData5}}></div>
            <div className={Styles.triangleDot5}>
              <div style={{marginTop : dotTopData5, marginLeft : dotLeftData5}}></div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div className={Styles.bar_1}>
    <h4>生活スタイル</h4>
    <div className={Styles.bar_status}>
      <div className={Styles.bar_status_title}>
        <h5>インドア</h5>
        <h5>アウトドア</h5>
      </div>
      <div className={Styles.bar_status_bar_1}>
        <div style={{backgroundColor : recruitList.store_mood1 === 1 ? '#448374' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood1 === 2 ? '#448374' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood1 === 3 ? '#448374' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood1 === 4 ? '#448374' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood1 === 5 ? '#448374' : ''}}></div>
      </div>
    </div>
  </div>
  <div className={Styles.bar_2}>
    <h4>生活スタイル</h4>
    <div className={Styles.bar_status}>
      <div className={Styles.bar_status_title}>
        <h5>インドア</h5>
        <h5>アウトドア</h5>
      </div>
      <div className={Styles.bar_status_bar_2}>
        <div style={{backgroundColor : recruitList.store_mood2 === 1 ? '#FFA452' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood2 === 2 ? '#FFA452' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood2 === 3 ? '#FFA452' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood2 === 4 ? '#FFA452' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood2 === 5 ? '#FFA452' : ''}}></div>
      </div>
    </div>
  </div>
  <div className={Styles.bar_3}>
    <h4>生活スタイル</h4>
    <div className={Styles.bar_status}>
      <div className={Styles.bar_status_title}>
        <h5>インドア</h5>
        <h5>アウトドア</h5>
      </div>
      <div className={Styles.bar_status_bar_3}>
        <div style={{backgroundColor : recruitList.store_mood3 === 1 ? '#39C8ED' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood3 === 2 ? '#39C8ED' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood3 === 3 ? '#39C8ED' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood3 === 4 ? '#39C8ED' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood3 === 5 ? '#39C8ED' : ''}}></div>
      </div>
    </div>
  </div>
  <div className={Styles.bar_4}>
    <h4>生活スタイル</h4>
    <div className={Styles.bar_status}>
      <div className={Styles.bar_status_title}>
        <h5>インドア</h5>
        <h5>アウトドア</h5>
      </div>
      <div className={Styles.bar_status_bar_4}>
        <div style={{backgroundColor : recruitList.store_mood4 === 1 ? '#FE6970' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood4 === 2 ? '#FE6970' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood4 === 3 ? '#FE6970' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood4 === 4 ? '#FE6970' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood4 === 5 ? '#FE6970' : ''}}></div>
      </div>
    </div>
  </div>
  <div className={Styles.bar_5}>
    <h4>生活スタイル</h4>
    <div className={Styles.bar_status}>
      <div className={Styles.bar_status_title}>
        <h5>インドア</h5>
        <h5>アウトドア</h5>
      </div>
      <div className={Styles.bar_status_bar_5}>
        <div style={{backgroundColor : recruitList.store_mood5 === 1 ? '#7F5EBB' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood5 === 2 ? '#7F5EBB' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood5 === 3 ? '#7F5EBB' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood5 === 4 ? '#7F5EBB' : ''}}></div>
        <div style={{backgroundColor : recruitList.store_mood5 === 5 ? '#7F5EBB' : ''}}></div>
      </div>
    </div>
  </div>
</div> */}

{/* <div>
  <div className={Styles.overview_title}>
    <div>1</div>
    <h4>店舗スタイル</h4>
  </div>
  <div>
    <div className={Styles.desc_title}>
      <p>伝統的</p>
      <p>革新的</p>
    </div>
    <div className={Styles.desc_bar}>
      {recruitList.store_mood1 === 1 ?
        <img src={sliderBar_1} alt='' />
        : recruitList.store_mood1 === 2 ?
        <img src={sliderBar_2} alt='' />
        : recruitList.store_mood1 === 3 ?
        <img src={sliderBar_3} alt='' />
        : recruitList.store_mood1 === 4 ?
        <img src={sliderBar_4} alt='' />
        :
        <img src={sliderBar_5} alt='' />
      }
    </div>
  </div>
</div>
<div>
  <div className={Styles.overview_title}>
    <div>2</div>
    <h4>求める人材</h4>
  </div>
  <div>
    <div className={Styles.desc_title}>
      <p>接客重視</p>
      <p>技術重視</p>
    </div>
    <div className={Styles.desc_bar}>
      {recruitList.store_mood2 === 1 ?
        <img src={sliderBar_1} alt='' />
        : recruitList.store_mood2 === 2 ?
        <img src={sliderBar_2} alt='' />
        : recruitList.store_mood2 === 3 ?
        <img src={sliderBar_3} alt='' />
        : recruitList.store_mood2 === 4 ?
        <img src={sliderBar_4} alt='' />
        :
        <img src={sliderBar_5} alt='' />
      }
    </div>
  </div>
</div>
<div>
  <div className={Styles.overview_title}>
    <div>3</div>
    <h4>ワークライフバランス</h4>
  </div>
  <div>
    <div className={Styles.desc_title}>
      <p>プライベート重視</p>
      <p>仕事重視</p>
    </div>
    <div className={Styles.desc_bar}>
      {recruitList.store_mood3 === 1 ?
        <img src={sliderBar_1} alt='' />
        : recruitList.store_mood3 === 2 ?
        <img src={sliderBar_2} alt='' />
        : recruitList.store_mood3 === 3 ?
        <img src={sliderBar_3} alt='' />
        : recruitList.store_mood3 === 4 ?
        <img src={sliderBar_4} alt='' />
        :
        <img src={sliderBar_5} alt='' />
      }
    </div>
  </div>
</div>
<div>
  <div className={Styles.overview_title}>
    <div>4</div>
    <h4>従業員の雰囲気</h4>
  </div>
  <div>
    <div className={Styles.desc_title}>
      <p>みんなでわいわい</p>
      <p>しっかり集中</p>
    </div>
    <div className={Styles.desc_bar}>
      {recruitList.store_mood4 === 1 ?
        <img src={sliderBar_1} alt='' />
        : recruitList.store_mood4 === 2 ?
        <img src={sliderBar_2} alt='' />
        : recruitList.store_mood4 === 3 ?
        <img src={sliderBar_3} alt='' />
        : recruitList.store_mood4 === 4 ?
        <img src={sliderBar_4} alt='' />
        :
        <img src={sliderBar_5} alt='' />
      }
    </div>
  </div>
</div>
<div>
  <div className={Styles.overview_title}>
    <div>5</div>
    <h4>男女比率</h4>
  </div>
  <div>
    <div className={Styles.desc_title}>
      <p>男性客</p>
      <p>女性客</p>
    </div>
    <div className={Styles.desc_bar}>
      {recruitList.store_mood5 === 1 ?
        <img src={sliderBar_1} alt='' />
        : recruitList.store_mood5 === 2 ?
        <img src={sliderBar_2} alt='' />
        : recruitList.store_mood5 === 3 ?
        <img src={sliderBar_3} alt='' />
        : recruitList.store_mood5 === 4 ?
        <img src={sliderBar_4} alt='' />
        :
        <img src={sliderBar_5} alt='' />
      }
    </div>
  </div>
</div>
<div>
  <div className={Styles.overview_title}>
    <div>6</div>
    <h4>年齢層</h4>
  </div>
  <div>
    <div className={Styles.desc_title}>
      <p>若者多め</p>
      <p>中高年多め</p>
    </div>
    <div className={Styles.desc_bar}>
      {recruitList.store_mood6 === 1 ?
        <img src={sliderBar_1} alt='' />
        : recruitList.store_mood6 === 2 ?
        <img src={sliderBar_2} alt='' />
        : recruitList.store_mood6 === 3 ?
        <img src={sliderBar_3} alt='' />
        : recruitList.store_mood6 === 4 ?
        <img src={sliderBar_4} alt='' />
        :
        <img src={sliderBar_5} alt='' />
      }
    </div>
  </div>
</div> */}

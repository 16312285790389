import './styles.css';
import React from 'react'
import { useHistory } from 'react-router-dom';

const WorksNavMobile = (props) => {
  
  const history = useHistory();

  return (
    <>
    <div className='works_nav_mobile'>

      <div className='works_nav_mobile_item' 
        style={props.navigation === 4 ? {backgroundColor : '#192651'} : {}}
        onClick={() => {history.push(`/service/id=${props.companyList.id}+page=4`); props.setNavigation(4)}}>
        <p style={props.navigation === 4 ? {color : 'white', fontWeight : 'bold'} : {}}>応募者管理</p>
      </div>

      <div className='works_nav_mobile_item' 
        style={props.navigation === 5 ? {backgroundColor : '#192651'} : {}}
        onClick={() => {history.push(`/service/id=${props.companyList.id}+page=5`); props.setNavigation(5)}}>
        <p style={props.navigation === 5 ? {color : 'white', fontWeight : 'bold'} : {}}>スケジュール</p>
      </div>

    </div>
    </>
  );
}

export default WorksNavMobile;

import DocumentMeta from 'react-document-meta';
import Styles from './styles.module.css';
import { useHistory } from 'react-router-dom';
import Student from './../../../image/works/top/student.png';
import Company from './../../../image/works/top/company.png';
import { FaAngleRight } from "react-icons/fa6";
import { isMobile } from "react-device-detect";

const WorksTop = () => {

  const history = useHistory();

  const meta = {
    title: 'memorii works | 美容業界の新卒マッチングサービス',
    description: '美容室へ就職される美容学生へ向けて行きたい美容室が見つかる就活サイト、美容学生を採用したい方へ向けて新卒採用・管理が行えるサービス',
    canonical: 'https://memorii-works.com',
    meta: { charset: 'utf-8', }
  };
  
  return (
    <>
    <DocumentMeta {...meta}></DocumentMeta>
    {!isMobile ?
    <div className={Styles.top}>
      <div className={Styles.logo}>
        <div></div>
        <h4>memorii works</h4>
      </div>
      <div className={Styles.contents}>
        <div className={Styles.student}>
          <div onClick={() => history.push('/student')}>
            <img src={Student} className={Styles.student_image} alt='memorii works' />
            <h4>美容学生向け求人サイト</h4>
            <h5>美容室へ就職される美容学生へ向けて<br />行きたい美容室が見つかる就活サイト</h5>
            <div className={Styles.button}>
              <h5>美容学生の方はこちら</h5>
              <FaAngleRight className={Styles.button_icon} />
            </div>
          </div>
        </div>
        <div className={Styles.store}>
          <div onClick={() => history.push('/company')}>
            <img src={Company} className={Styles.store_image} alt='memorii works' />
            <h4>採用担当者様向けサービス</h4>
            <h5>美容学生を採用したい方へ向けて<br />新卒採用・管理が行えるサービス</h5>
            <div className={Styles.button}>
              <h5>担当者様の方はこちら</h5>
              <FaAngleRight className={Styles.button_icon} />
            </div>
          </div>
        </div>
      </div>
    </div>
    :
    <div className={Styles.top}>
      <div className={Styles.logo_mobile}>
        <div></div>
        <h4>memorii works</h4>
      </div>
      <div className={Styles.contents_mobile}>
        <div className={Styles.student_mobile}>
          <div onClick={() => history.push('/student')}>
            <h4>美容学生向け求人サイト</h4>
            <h5>美容室へ就職される美容学生へ向けて<br />行きたい美容室が見つかる就活サイト</h5>
            <div>
              <h5>美容学生の方はこちら</h5>
              <FaAngleRight className={Styles.button_icon} />
            </div>
          </div>
        </div>
        <div className={Styles.store_mobile}>
          <div onClick={() => history.push('/company')}>
            <h4>採用担当者様向けサービス</h4>
            <h5>美容学生を採用したい方へ向けて<br />新卒採用・管理が行えるサービス</h5>
            <div>
              <h5>担当者様の方はこちら</h5>
              <FaAngleRight className={Styles.button_icon} />
            </div>
          </div>
        </div>
      </div>
    </div>
    }
    </>
  );
}

export default WorksTop;

import Styles from './styles.module.css';

const WorksManageNav = (props) => {

  return (
    <>
    {props.unSelect ?
    <div className={Styles.box}>
      <div>
        <h4 style={props.selectFlow === 10000 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}} 
          onClick={() => props.setSelectFlow(10000)}>一覧({props.count[0]})</h4>

        <h4 style={props.selectFlow === 1 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => props.setSelectFlow(1)}>1次選考({props.count[1]})</h4>

        <h4 style={props.selectFlow === 2 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => props.setSelectFlow(2)}>2次選考({props.count[2]})</h4>

        <h4 style={props.selectFlow === 3 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => props.setSelectFlow(3)}>3次選考({props.count[3]})</h4>

        <h4 style={props.selectFlow === 4 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => props.setSelectFlow(4)}>4次選考({props.count[4]})</h4>

        <h4 style={props.selectFlow === 5 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => props.setSelectFlow(5)}>5次選考({props.count[5]})</h4>

        <h4 style={props.selectFlow === 6 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => props.setSelectFlow(6)}>採用({props.count[6]})</h4>

        <h4 style={props.selectFlow === 7 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
          onClick={() => props.setSelectFlow(7)}>不採用({props.count[7]})</h4>
      </div>
    </div>
    :
      <div className={Styles.box}>
        <div>
          <h4 style={props.selectFlow === 10000 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}} 
            onClick={() => props.setSelectFlow(10000)}>一覧({props.count[0]})</h4>

          {1 <= props.selection && props.selection <= 5 ?
            <h4 style={props.selectFlow === 1 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
              onClick={() => props.setSelectFlow(1)}>1次選考({props.count[1]})</h4>
          : <></> }

          {2 <= props.selection && props.selection <= 5 ?
            <h4 style={props.selectFlow === 2 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
              onClick={() => props.setSelectFlow(2)}>2次選考({props.count[2]})</h4>
          : <></> }

          {3 <= props.selection && props.selection <= 5 ?
            <h4 style={props.selectFlow === 3 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
              onClick={() => props.setSelectFlow(3)}>3次選考({props.count[3]})</h4>
          : <></> }

          {4 <= props.selection && props.selection <= 5 ?
            <h4 style={props.selectFlow === 4 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
              onClick={() => props.setSelectFlow(4)}>4次選考({props.count[4]})</h4>
          : <></> }

          {5 <= props.selection ?
            <h4 style={props.selectFlow === 5 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
              onClick={() => props.setSelectFlow(5)}>5次選考({props.count[5]})</h4>
          : <></> }

          <h4 style={props.selectFlow === 6 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
            onClick={() => props.setSelectFlow(6)}>採用({props.count[6]})</h4>

          <h4 style={props.selectFlow === 7 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
            onClick={() => props.setSelectFlow(7)}>不採用({props.count[7]})</h4>
        </div>
        
        {props.storeList !== undefined && props.storeList.place_storeCount !== 0 ?
        <select value={props.selectStore} onChange={(e) => props.setSelectStore(e.target.value)}>
          <option value=''>未選択</option>
          {1 <= props.storeList.place_storeCount && props.storeList.place_storeCount <= 5 ?
            <option value={props.storeList.place_store1Name}>{props.storeList.place_store1Name}</option>
          : <></> }
          {2 <= props.storeList.place_storeCount && props.storeList.place_storeCount <= 5 ?
            <option value={props.storeList.place_store2Name}>{props.storeList.place_store2Name}</option>
          : <></> }
          {3 <= props.storeList.place_storeCount && props.storeList.place_storeCount <= 5 ?
            <option value={props.storeList.place_store3Name}>{props.storeList.place_store3Name}</option>
          : <></> }
          {4 <= props.storeList.place_storeCount && props.storeList.place_storeCount <= 5 ?
            <option value={props.storeList.place_store4Name}>{props.storeList.place_store4Name}</option>
          : <></> }
          {5 <= props.storeList.place_storeCount && props.storeList.place_storeCount <= 5 ?
            <option value={props.storeList.place_store5Name}>{props.storeList.place_store5Name}</option>
          : <></> }
        </select> : <></> }
      </div>
    }
  </>
  );
}

export default WorksManageNav;

import React from 'react';
import Styles from './styles.module.css';

const DefaultInput = (props) => {

  return (
    <>
    <div className={Styles.title}>
      <p>{props.range1}</p>
      <p>{props.range2}</p>
    </div>
    <div className={Styles.bar}>
      <input className='' type="range" class="form-range" id="customRange1" min={1} max={5}
        value={props.value} onChange={(e) => props.setValue(Number(e.target.value))} />
    </div>
    </>
  );
}

export default DefaultInput;

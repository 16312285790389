import authStyles from './styles.module.css';
import inputStyles from './input.module.css';
import React, { useState } from 'react';
import Icon from './../../../../image/icon/company.png';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import Header from '../header/index';
import { isMobile } from "react-device-detect";

const WorksLogin = () => {
  
  const history = useHistory();

  const [ send, setSend ] = useState(false);
  const [ registerEmail, setRegisterEmail ] = useState('');

  const [ error, seterror ] = useState(false);

  function check() {
    seterror('');
    if (registerEmail === '') {
      seterror('mail');
    } else {
      passwordReset();
    }
  }

  const passwordReset = () => {
    firebase.auth().sendPasswordResetEmail(registerEmail).then(() => { setSend(true); })
  };

  return (
    <>
    <Header />

    <div className={authStyles.background}>
      <div className={authStyles.contents}>

        {!isMobile ?
          <div className={authStyles.contents_icon}>
            <img src={Icon} alt='' />
            <h1>memorii recruit</h1>
          </div>
        : <></> }

        <h2 className={authStyles.contents_title}>パスワード リセット</h2>
        
        {send ?
        <>
          <div className={authStyles.contents_message}>
            メールアドレスに届いたURLより<br />パスワードを変更してください。
          </div>
        </>
        :
        <>
          <p className={inputStyles.input_title}>メールアドレス</p>
          <div className={inputStyles.input_input}>
            <input placeholder='memorii@trankllc.com' value={registerEmail} onChange={(e) => setRegisterEmail(e.target.value)} />
          </div>
          <p className={inputStyles.input_error} style={error === 'mail' ? { color : '#AD0200' } : { color : '#EBEBEB' }}>
            ※メールアドレスを入力してください。
          </p>

          <button className={authStyles.contents_btn} onClick={() => check()}>リセット</button>

          <p className={authStyles.contents_link} onClick={() => history.push(`/company/signin`)}>アカウントをお持ちではない方はこちら</p>
          <p className={authStyles.contents_link} onClick={() => history.push(`/company/login`)}>アカウントをお持ちの方はこちら</p>
        </> }

      </div>
    </div>
    </>
  );
}

export default WorksLogin;
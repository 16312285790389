import Styles from './styles.module.css';
import React from 'react';
import Icon from "./../../../../image/icon/company.png";
import { useHistory } from 'react-router-dom';

const Policy = () => {

  const history = useHistory();

  return (
    <>
    <div className={Styles.nav}>
      <div className={Styles.nav_icon} onClick={() => history.push('/')}>
        <img src={Icon} alt='' />
        <p>memorii</p>
      </div>
      <div className={Styles.nav_text}>
        <p onClick={() => history.push('/student')}>美容学生</p>
        <p onClick={() => history.push('/company')}>事業者様</p>
        <p onClick={() => history.push('/contact')}>問い合わせ</p>
      </div>
    </div>
    <div className={Styles.policy}>
      <div>
        <h4>プライバシーポリシー</h4>
        <p>
          トランク合同会社（以下、「当社」といいます。）は、memorii works(以下、「本サービス」といいます。) をご利用される皆様(以下、「ユーザー」といいます。) のプライバシーを尊重し、個人情報の保護に最新の注意を払ったうえで、本サービスの管理・運用を行ってまいります。<br />
          具体的には、以下の定めに基づいた個人情報の管理を行いますので、同意をいただいたうえで個人情報をご提供いただきますようお願いいたします。<br />
          <br />
          <strong>【１．事業者情報】</strong><br />
          法人名：トランク合同会社<br />
          住所：〒231-0005　神奈川県横浜市中区本町4丁目41 Ｄ’グラフォート横浜 405<br />
          代表者：大林 亜治英<br />
          <br />
          ・本サービス個人情報保護管理者：事業部 部長<br />
          連絡先メールアドレス：info@trankllc.com<br />
          <br />
          <strong>【２．個人情報】</strong><br />
          個人情報とは、ユーザー個人に関する情報であり、当該情報を構成する氏名、住所、その他の記述等によりユーザー個人を識別できるものといいます。また、その情報のみでは識別できない場合でも、他の情報との照合が容易であり、結果的としてユーザー個人を識別できるものも個人情報に含まれます。<br />
          <br />
          <strong>【３．個人情報の利用目的】</strong><br />
          当社は本サービスの提供にあたり、ユーザーの個人情報を以下に定める目的の範囲内で、利用します。<br />
          ・ユーザーの個人認証・個人ユーザー向けサービスの提供<br />
          ・求人企業が掲載する求人広告へ応募・閲覧等を行った場合の、広告掲載企業が選考で使用する情報提供<br />
          ・本サービス利用時の連絡・各種お知らせ等の配信<br />
          ・本サービス利用時のサポート・お問い合わせ対応<br />
          ・本サービス経由で入社したユーザーの入退社等に係る確認<br />
          ・本サービスのサービス精度向上・新サービスの開発に関する統計及び分析<br />
          ・当社ウェブサイトを含めた、各種媒体への掲載<br />
          ・アンケートの実施・プレゼント送付を含めたキャンペーンの実施<br />
          ・美容関連企業への、求人広告掲載に関する加工情報の提供<br />
          ・学術研究の用に供する情報の提供<br />
          <br />
          <strong>【4．個人情報の任意性】</strong><br />
          本サービスのいかなる個人情報においても、提供は本人の任意とします。しかし、情報の不足により本サービスが提供できない可能性や、求人応募時に評価が低下する可能性がございます。<br />
          <br />
          <strong>【5．個人データを安全に管理するための措置】</strong><br />
          当社はユーザーから提供された個人情報を正確かつ最新の内容に保つよう努め、不正なアクセス・改ざん・漏えい・滅失及び毀損から保護するため、技術面において必要な対策を継続して行うよう努めます。なお、個人情報はサービス終了後も一定期間の保管を行いますが、保管期間が経過した情報については速やかに削除するよう努めます。<br />
          <br />
          <strong>【６．個人データの第三者提供について】</strong><br />
          当社は法令及びガイドラインに別段の定めがある場合を除き、同意を得ないで第三者に個人情報を提供することは致しません。また、当サービスを通じてユーザーが企業に個人情報を送信された場合、送信先企業が定める個人情報保護管理規定に従い、個人情報の管理を行います。なお、以下の場合は、ユーザーへの同意を得ずに個人情報を提供する可能性があります。<br />
          <br />
          ・法令に基づき、提供に応じなければならない場合<br />
          ・ユーザーにより、本サービス及び第三者に明らかな不利益が生じると判断した場合<br />
          ・公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難な場合<br />
          ・国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合<br />
          ・ユーザー本人より明らかに第三者への開示を求められた場合<br />
          ・合併その他の事由による事業の承継に伴って、承継前の利用目的の範囲で個人情報の提供が行われる場合<br />
          <br />
          <strong>【７．個人情報処理の外部委託】</strong>
          当社は、個人情報に関わる業務遂行を目的として、統計処理等の業務とともに個人情報処理を外部に委託する場合があります。委託に関しては、十分な個人情報保護水準を確保していることを条件として委託先を選定し、機密保持契約を締結したうえで行ないます。
          【８．統計処理されたデータの利用】
          当社は、提供を受けた個人情報をもとに、個人を特定できないよう加工した統計データを作成することがあります。個人を特定できない統計データについては、当社は何ら制限なく利用することができるものとします。

          <strong>【９．個人情報の委託】</strong>
          当社は、人種、信条、社会的身分、病歴、犯罪の経歴、犯罪の被害にあった事実等の要配慮個人情報については、原則として取得することはいたしません。しかし、ユーザーが自ら提供した場合はこの限りではありません。

          <strong>【１０．個人情報の開示、訂正等の拒否権】</strong>
          ユーザーは、当社に対し、個人情報の利用目的の通知、登録した個人情報の開示、訂正・追加・削除、利用又は提供の拒否権を求めることができ、拒否権の依頼を受けた場合は当社規定に則り、速やかな対応に努めます。

          <strong>【１１．情報の自動取得】</strong>
          当社は、本サービス利用時における以下の情報をユーザーから自動で取得する場合があります。

          ・当社は、ユーザーのプライバシーの保護、利便性の向上、広告の配信および統計データの取得のため、Cookieを使用します。また、CookieやJavaScript等の技術を利用して、ご提供いただいた情報のうち、年齢や性別、職業、居住地域など個人が特定できない属性情報（組み合わせることによっても個人が特定できないものに限られます）や端末情報、本サービスサイト内におけるユーザーの行動履歴（アクセスしたURL、コンテンツ、参照順等）およびスマートフォン等利用時の、ユーザーの承諾・申込みに基づく位置情報を取得することがあります。ただし、Cookie及び行動履歴等には個人情報は一切含まれません。<br />
          ・当社はユーザーがログインして本サービスを利用した場合には、個人を特定したうえで、当該ログイン以前からの行動履歴等を用いて、広告・コンテンツ等の配信・表示および本サービスの提供をする場合があります。こちらの広告等の配信停止については、下記の個人情報管理の問い合わせ先にご連絡ください。<br />
          <br />
          <strong>【１２．SSL（Secure Socket Layer）について】</strong>
          当社のWebサイトはSSLに対応しており、WebブラウザとWebサーバーとの通信を暗号化しています。ユーザーが入力する氏名や住所、電話番号などの個人情報は自動的に暗号化されます。<br />
          <br />
          <strong>【１３．免責事項】</strong>
          当社本サービスに掲載されている情報の正確性には万全を期していますが、ユーザーが本サービスの情報を用いて行う一切の行為に関して、一切の責任を負わないものとします。
          当社は、ユーザーが本サービスを利用したことにより生じたユーザーの損害及びユーザーが第三者に与えた損害に関して、一切の責任を負わないものとします。<br />
          <br />
          <strong>【１４．著作権・肖像権】</strong><br />
          当社Webサイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されています。無断での使用や転用は禁止されています。<br />
          <br />
          <strong>【１５．プライバシーポリシーの変更】</strong><br />
          当社は、本プライバシーポリシーを随時変更することができるものとします。変更後の内容は、本サービスにおいて掲載するものとし、掲載後１ヵ月経過した時点で本サービスを継続して利用しているユーザーは、変更後のプライバシーポリシーに同意したものとみなします。<br />
          <br />
          <strong>【１６．プライバシーポリシーの制定日及び改定日】</strong><br />
          制定：2024年1月25日<br />
          <br />
          <strong>【１７．お問い合わせ先】</strong><br />
          個人情報に関するお問い合わせは、本サービスの事務局、又は1.に記載の個人情報保護管理者にご連絡ください。<br />
        </p>
      </div>
    </div>
    </>
  );
}

export default Policy;



import './styles.css';
import React from 'react';
import Icon from "./../../../../image/works/icon.png";
import { IoDocumentTextOutline } from "react-icons/io5";
import { useHistory } from 'react-router-dom';

const MemoriiWorksLandingHeader = () => {

  const history = useHistory();

  return (
    <>
    <div className='works_landing'>
      <div className='works_landing_nav' onClick={() => history.push('/')}>
        <img src={Icon} alt='memorii works' />
        <h4>memorii works</h4>
      </div>
      <div className='works_landing_link'>
        <div onClick={() => history.push('/contact')}>
          <IoDocumentTextOutline className='works_landing_link_img' />
          <p>サービス資料</p>
        </div>
        <button onClick={() => history.push('/company/login')}>ログイン</button>
      </div>
    </div>
    </>
  );
}

export default MemoriiWorksLandingHeader;

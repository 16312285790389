import Styles from './styles.module.css';
// import './styles.css';
import firebase from 'firebase/compat/app';
import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore'

const MemoriiHome = (props) => {

  // taskデータ
  const [ tasks, setTasks ] = useState([]);
  const [ day1, setDay1 ] = useState(new Date());
  const [ day2, setDay2 ] = useState(new Date());
  const [ day3, setDay3 ] = useState(new Date());
  const [ day4, setDay4 ] = useState(new Date());
  const [ day5, setDay5 ] = useState(new Date());
  const [ day6, setDay6 ] = useState(new Date());
  const [ day7, setDay7 ] = useState(new Date());

  useEffect(() => {
    var today = new Date();
    getTask(today.getFullYear(), today.getMonth(), today.getDate() - 1);
  }, []);

  // タスク取得
  function getTask(years, month, day) {

    const docRef = collection(firebase.firestore(), 'applicants')
    const queryRef = query(docRef, where('companyId', '==', props.companyList.id))
    const unsub = onSnapshot(queryRef, snapshot => {

      var date0 = new Date(years, month, day);
      var date1 = new Date(years, month, day + 1);
      var date2 = new Date(years, month, day + 2);
      var date3 = new Date(years, month, day + 3);
      var date4 = new Date(years, month, day + 4);
      var date5 = new Date(years, month, day + 5);
      var date6 = new Date(years, month, day + 6);
      var date7 = new Date(years, month, day + 7);
      var date8 = new Date(years, month, day + 8);

      setDay1(date1);
      setDay2(date2);
      setDay3(date3);
      setDay4(date4);
      setDay5(date5);
      setDay6(date6);
      setDay7(date7);

      var day1 = 0;
      var day2 = 0;
      var day3 = 0;
      var day4 = 0;
      var day5 = 0;
      var day6 = 0;
      var day7 = 0;

      snapshot.docs.forEach(doc => {
        var dataTime1Before = new Date(doc.data().examination1_schedule[0].seconds * 1000);
        var dataTime2Before = new Date(doc.data().examination2_schedule[0].seconds * 1000);
        var dataTime3Before = new Date(doc.data().examination3_schedule[0].seconds * 1000);
        var dataTime4Before = new Date(doc.data().examination4_schedule[0].seconds * 1000);
        var dataTime5Before = new Date(doc.data().examination5_schedule[0].seconds * 1000);

        // 日曜日
        if (date1 <= dataTime1Before && dataTime1Before <= date2) {
          day1 = day1 + 1;
        }

        if (date1 <= dataTime2Before && dataTime2Before <= date2) {
          day1 = day1 + 1;
        }

        if (date1 <= dataTime3Before && dataTime3Before <= date2) {
          day1 = day1 + 1;
        }

        if (date1 <= dataTime4Before && dataTime4Before <= date2) {
          day1 = day1 + 1;
        }

        if (date1 <= dataTime5Before && dataTime5Before <= date2) {
          day1 = day1 + 1;
        }

        // 月曜日
        if (date2 <= dataTime1Before && dataTime1Before <= date3) {
          day2 = day2 + 1;
        }

        if (date2 <= dataTime2Before && dataTime2Before <= date3) {
          day2 = day2 + 1;
        }

        if (date2 <= dataTime3Before && dataTime3Before <= date3) {
          day2 = day2 + 1;
        }

        if (date2 <= dataTime4Before && dataTime4Before <= date3) {
          day2 = day2 + 1;
        }

        if (date2 <= dataTime5Before && dataTime5Before <= date3) {
          day2 = day2 + 1;
        }

        // 火曜日
        if (date3 <= dataTime1Before && dataTime1Before <= date4) {
          day3 = day3 + 1;
        }
        
        if (date3 <= dataTime2Before && dataTime2Before <= date4) {
          day3 = day3 + 1;
        }

        if (date3 <= dataTime3Before && dataTime3Before <= date4) {
          day3 = day3 + 1;
        }

        if (date3 <= dataTime4Before && dataTime4Before <= date4) {
          day3 = day3 + 1;
        }

        if (date3 <= dataTime5Before && dataTime5Before <= date4) {
          day3 = day3 + 1;
        }

        // 水曜日
        if (date4 <= dataTime1Before && dataTime1Before <= date5) {
          day4 = day4 + 1;
        }

        if (date4 <= dataTime2Before && dataTime2Before <= date5) {
          day4 = day4 + 1;
        }

        if (date4 <= dataTime3Before && dataTime3Before <= date5) {
          day4 = day4 + 1;
        }

        if (date4 <= dataTime4Before && dataTime4Before <= date5) {
          day4 = day4 + 1;
        }

        if (date4 <= dataTime5Before && dataTime5Before <= date5) {
          day4 = day4 + 1;
        }

        // 木曜日
        if (date5 <= dataTime1Before && dataTime1Before <= date6) {
          day5 = day5 + 1;
        }

        if (date5 <= dataTime2Before && dataTime2Before <= date6) {
          day5 = day5 + 1;
        }

        if (date5 <= dataTime3Before && dataTime3Before <= date6) {
          day5 = day5 + 1;
        }

        if (date5 <= dataTime4Before && dataTime4Before <= date6) {
          day5 = day5 + 1;
        }

        if (date5 <= dataTime5Before && dataTime5Before <= date6) {
          day5 = day5 + 1;
        }

        // 金曜日
        if (date6 <= dataTime1Before && dataTime1Before <= date7) {
          day6 = day6 + 1;
        }

        if (date6 <= dataTime2Before && dataTime2Before <= date7) {
          day6 = day6 + 1;
        }

        if (date6 <= dataTime3Before && dataTime3Before <= date7) {
          day6 = day6 + 1;
        }

        if (date6 <= dataTime4Before && dataTime4Before <= date7) {
          day6 = day6 + 1;
        }

        if (date6 <= dataTime5Before && dataTime5Before <= date7) {
          day6 = day6 + 1;
        }

        // 土曜日
        if (date7 <= dataTime1Before && dataTime1Before <= date8) {
          day7 = day7 + 1;
        }
        
        if (date7 <= dataTime2Before && dataTime2Before <= date8) {
          day7 = day7 + 1;
        }
        
        if (date7 <= dataTime3Before && dataTime3Before <= date8) {
          day7 = day7 + 1;
        }
        
        if (date7 <= dataTime4Before && dataTime4Before <= date8) {
          day7 = day7 + 1;
        }
        
        if (date7 <= dataTime5Before && dataTime5Before <= date8) {
          day7 = day7 + 1;
        }
      });

      setTasks([
        day1,
        day2,
        day3,
        day4,
        day5,
        day6,
        day7,
      ]);
    });

    return () => unsub();
  }

  return (
    <>
    <div className={Styles.task}>
      <h4 className={Styles.task_title_top}>1週間のタスク</h4>
      <div>
        <div className={Styles.task_title}>
          <h4>日程</h4>
          <h4>件数</h4>
        </div>
        <div className={Styles.task_data}>
          <h4>{day1.getFullYear()}年{day1.getMonth() + 1}月{day1.getDate()}日({day1.getDay() === 0 ? '日' : day1.getDay() === 1 ? '月' : day1.getDay() === 2 ? '火' : day1.getDay() === 3 ? '水' : day1.getDay() === 4 ? '木' : day1.getDay() === 5 ? '金' : '土' })</h4>
          <h4>{tasks[0]}件</h4>
        </div>
        <div className={Styles.task_data}>
          <h4>{day2.getFullYear()}年{day2.getMonth() + 1}月{day2.getDate()}日({day2.getDay() === 0 ? '日' : day2.getDay() === 1 ? '月' : day2.getDay() === 2 ? '火' : day2.getDay() === 3 ? '水' : day2.getDay() === 4 ? '木' : day2.getDay() === 5 ? '金' : '土' })</h4>
          <h4>{tasks[1]}件</h4>
        </div>
        <div className={Styles.task_data}>
          <h4>{day3.getFullYear()}年{day3.getMonth() + 1}月{day3.getDate()}日({day3.getDay() === 0 ? '日' : day3.getDay() === 1 ? '月' : day3.getDay() === 2 ? '火' : day3.getDay() === 3 ? '水' : day3.getDay() === 4 ? '木' : day3.getDay() === 5 ? '金' : '土' })</h4>
          <h4>{tasks[2]}件</h4>
        </div>
        <div className={Styles.task_data}>
          <h4>{day4.getFullYear()}年{day4.getMonth() + 1}月{day4.getDate()}日({day4.getDay() === 0 ? '日' : day4.getDay() === 1 ? '月' : day4.getDay() === 2 ? '火' : day4.getDay() === 3 ? '水' : day4.getDay() === 4 ? '木' : day4.getDay() === 5 ? '金' : '土' })</h4>
          <h4>{tasks[3]}件</h4>
        </div>
        <div className={Styles.task_data}>
          <h4>{day5.getFullYear()}年{day5.getMonth() + 1}月{day5.getDate()}日({day5.getDay() === 0 ? '日' : day5.getDay() === 1 ? '月' : day5.getDay() === 2 ? '火' : day5.getDay() === 3 ? '水' : day5.getDay() === 4 ? '木' : day5.getDay() === 5 ? '金' : '土' })</h4>
          <h4>{tasks[4]}件</h4>
        </div>
        <div className={Styles.task_data}>
          <h4>{day6.getFullYear()}年{day6.getMonth() + 1}月{day6.getDate()}日({day6.getDay() === 0 ? '日' : day6.getDay() === 1 ? '月' : day6.getDay() === 2 ? '火' : day6.getDay() === 3 ? '水' : day6.getDay() === 4 ? '木' : day6.getDay() === 5 ? '金' : '土' })</h4>
          <h4>{tasks[5]}件</h4>
        </div>
        <div className={Styles.task_data} style={{borderRadius: '0 0 5px 5px'}}>
          <h4>{day7.getFullYear()}年{day7.getMonth() + 1}月{day7.getDate()}日({day7.getDay() === 0 ? '日' : day7.getDay() === 1 ? '月' : day7.getDay() === 2 ? '火' : day7.getDay() === 3 ? '水' : day7.getDay() === 4 ? '木' : day7.getDay() === 5 ? '金' : '土' })</h4>
          <h4>{tasks[6]}件</h4>
        </div>
      </div>
    </div>
    </>
  );
}

export default MemoriiHome;

import './styles.css';
import React from 'react';
import { isMobile } from "react-device-detect";
import Pc from "./pc/index";
import Mobile from "./mobile/index";

const WorksInfo = (props) => {

  return (
    <>
    {!isMobile ?
      <Pc
        setHistory={props.setHistory}
        recruitId={props.recruitId}
        applicantId={props.applicantId}
        setRecruitId={props.setRecruitId}
        setApplicantId={props.setApplicantId}
        userList={props.userList}
        navigation={props.navigation}
        companyList={props.companyList}
      />
    :
      <Mobile 
        recruitId={props.recruitId}
        applicantId={props.applicantId}
        setRecruitId={props.setRecruitId}
        setApplicantId={props.setApplicantId}
        userList={props.userList}
        navigation={props.navigation}
        companyList={props.companyList}
      />
    }
    </>
  );
}

export default WorksInfo;



import React from 'react';
import Styles from './styles.module.css';

const DefaultInput1 = (props) => {

  return (
    <>
    <div className={Styles.title}>
      <div>
        <p>{props.title}</p>
        <div className={ props.required ? Styles.title_yes : Styles.title_no }>{props.required ? '※必須' : '任意'}</div>
      </div>
      {props.length === 0 ? <></> :
        <p>{props.value.length}/{props.length}</p>
      }
    </div>
    <select className={Styles.select} value={props.value} onChange={(e) => props.setValue(e.target.value)}>
      {props.list.map((data, index) =>
        <option value={data === '未選択' ? '' : data} index={index}>{data}</option>
      )}
    </select>
    <p className={Styles.desc}>{props.desc}</p>
    </>
  );
}

export default DefaultInput1;

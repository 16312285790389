import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaPen } from "react-icons/fa";
import firebase from 'firebase/compat/app';
import Icon from "./../../../image/icon/company.png";
import DefaultTitle from '../../service/package/parts/title/index';
import DefaultImage from './../../../image/works/default.png';
import DefaultInput1 from './../../service/package/parts/1-input/index';
import DefaultSelect1 from './../../service/package/parts/1-select/index';
import ErrorMessage from './../../service/package/parts/error';

const WorksMakeAccount = () => {

  const history = useHistory();

  const [ uid, setUid ] = useState('');

  const [ name, setName ] = useState('');
  const [ job, setJob ] = useState('');

  const [ errorMessage, setErrorMessage ] = useState('');

  const [ styleImageFile, setStyleImageFile ] = useState('');
  const [ styleImageFilePath, setStyleImageFilePath ] = useState('');

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUid(user.uid);
      } else {
        history.push('/company/login');
      }
    });
  }, []);

  const styleImages = e => {
    if (e.target.files[0]) {
      setStyleImageFile(URL.createObjectURL(e.target.files[0]));
      setStyleImageFilePath(e.target.files[0]);
    }
  }

  function save() {

    if (name === '') {
      setErrorMessage('名前を入力してください。')
    } else if (job === '') {
      setErrorMessage('職業を選択してください。')
    } else {
      firebase.firestore().collection('users').doc(uid)
      .set({
        user_name: name,
        user_like_count : 0,
        user_award : 0,
        user_likes : [],
        user_text : '',
        user_uid : uid,
        user_createdAt : new Date(),
        user_scout : false,
        user_address : [],
        user_address_postalCode: [],
        user_badge: 0,
        user_business: "美容師",
        user_recruit: false,
        user_token: "",
        user_connection : [],
        user_connection_count : 0,
        user_connection_permission : [],
        user_connection_request : [],
        user_likes_list : [],
        user_login : '',
        user_login_count : [],
        user_notification : [],
        user_notification_confiram : false,
        user_post_like_count : 0,
        user_post_view_count : 0,
        user_views : '',
        user_post_count : 0,
        user_recruit_save: [],
        user_instagram : '',
        user_tiktok : '',
        memorii_score : 0,
      });
  
      firebase.storage().ref().child(`/profiles/${uid}`).put(styleImageFilePath)
      .then(() => {
        if (job === '美容師') {
          history.push('/select')
        } else {
          history.push('/')
        }
      });
    }

  }

  return (
    <>
    {/* <Header /> */}
    <div className={Styles.header}>

      <div className={Styles.header_logo} onClick={() => history.push('/landing')}>
        <img src={Icon} alt='' />
        <h4>memorii works</h4>
      </div>
      
    </div>

    <div className={Styles.box}>

      {errorMessage !== '' ?
        <ErrorMessage message={errorMessage} />
      : <></> }

      <div className={Styles.box_inline}>
        
        <div className={Styles.title}>
          <h4>アカウント作成</h4>
          <button onClick={() => save()}>作成</button>
        </div>

        <p className={Styles.desc}>※社内の人にも伝わる設定を行ってください。</p>

        <DefaultTitle title={'プロフィール写真'} required={false} />

        <div className={Styles.make_image}>
          <FaPen className={Styles.make_image_pen} />
          <input type="file" onChange={styleImages} />
          {styleImageFile === '' ?
            <img className={Styles.make_image_file} src={DefaultImage} alt='' />
          : 
            <img className={Styles.make_image_file} src={styleImageFile} alt='' />
          }
        </div>

        <DefaultInput1
          title={'名前'} required={true} length={20} place={'メモリー 太郎'} type={'text'}
          value={name} setValue={setName}
          desc={'※アカウント名を入力してください。'}
        />

      </div>
    </div>
    </>
  );
}

export default WorksMakeAccount;



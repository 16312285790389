import styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Station from "./station/index";
import Style from "./style/index";

const WorksWebSearch = (props) => {
  
  const history = useHistory();  

  const [ pref, setPref ] = useState('');
  const [ station, setStation ] = useState([]);
  const [ salary, setSalary ] = useState('');
  const [ post, setPost ] = useState('');
  const [ style, setStyle ] = useState([]);

  const [ switchPref, setSwitchPref ] = useState(false);
  const [ switchStyle, setSwitchStyle ] = useState(false);

  var link = new URL(window.location).href;
  var linkPlace = decodeURIComponent(link.slice(link.indexOf('search=') + 7, link.indexOf('+salary'))).split(',');
  var linkSalary = decodeURIComponent(link.slice(link.indexOf('+salary=') + 8, link.indexOf('+post')));
  var linkPost = decodeURIComponent(link.slice(link.indexOf('+post=') + 6, link.indexOf('+style=')));
  var linkStyle = decodeURIComponent(link.slice(link.indexOf('+style=') + 7, link.indexOf('+page='))).split(',');

  useEffect(() => {
    if (link.indexOf('+salary=') !== -1) {
      setPref(linkPlace[0]);
      linkPlace.shift();
      setStation(linkPlace);
      setSalary(Number(linkSalary));
      setPost(linkPost);
      setStyle(linkStyle);
    };
  }, [])

  function search() {
    history.push(`/results/search=${pref},${station}+salary=${salary}+post=${post}+style=${style}+page=0`);
    window.location.reload();
  }

  return (
    <>
    <div className={styles.box}>
      <div>
        <h4>エリア指定</h4>

        <select className={styles.select_box} onChange={(e) => setPref(e.target.value)} value={pref}>
          <option value="">都道府県</option>
          <optgroup label="北海道">
            <option value="北海道">北海道</option>
          </optgroup>
          <optgroup label="東北">
            <option value="青森県">青森県</option>
            <option value="岩手県">岩手県</option>
            <option value="宮城県">宮城県</option>
            <option value="秋田県">秋田県</option>
            <option value="山形県">山形県</option>
            <option value="福島県">福島県</option>
          </optgroup>
          <optgroup label="関東">
            <option value="茨城県">茨城県</option>
            <option value="栃木県">栃木県</option>
            <option value="群馬県">群馬県</option>
            <option value="埼玉県">埼玉県</option>
            <option value="千葉県">千葉県</option>
            <option value="東京都">東京都</option>
            <option value="神奈川県">神奈川県</option>
            <option value="山梨県">山梨県</option>
            <option value="長野県 ">長野県 </option>
          </optgroup>
          <optgroup label="北陸">
            <option value="新潟県">新潟県</option>
            <option value="富山県">富山県</option>
            <option value="石川県">石川県</option>
            <option value="福井県">福井県</option>
          </optgroup>
          <optgroup label="中部">
            <option value="岐阜県">岐阜県</option>
            <option value="静岡県">静岡県</option>
            <option value="愛知県">愛知県</option>
            <option value="三重県">三重県</option>
          </optgroup>
          <optgroup label="近畿">
            <option value="滋賀県">滋賀県</option>
            <option value="京都府">京都府</option>
            <option value="大阪府">大阪府</option>
            <option value="兵庫県">兵庫県</option>
            <option value="奈良県">奈良県</option>
            <option value="和歌山県">和歌山県</option>
          </optgroup>
          <optgroup label="中国">
            <option value="鳥取県">鳥取県</option>
            <option value="島根県">島根県</option>
            <option value="岡山県">岡山県</option>
            <option value="広島県">広島県</option>
            <option value="山口県">山口県</option>
          </optgroup>
          <optgroup label="四国">
            <option value="徳島県">徳島県</option>
            <option value="香川県">香川県</option>
            <option value="愛媛県">愛媛県</option>
            <option value="高知県">高知県</option>
          </optgroup>
          <optgroup label="九州">
            <option value="福岡県">福岡県</option>
            <option value="佐賀県">佐賀県</option>
            <option value="長崎県">長崎県</option>
            <option value="熊本県">熊本県</option>
            <option value="大分県">大分県</option>
            <option value="宮崎県">宮崎県</option>
            <option value="鹿児島県">鹿児島県</option>
          </optgroup>
          <optgroup label="沖縄">
            <option value="沖縄県">沖縄県</option>
          </optgroup>
        </select>

        <h5 onClick={() => setSwitchPref(true)}>路線を選択する</h5>

        <p>{station.map((data, index) => <>{data},</> )}</p>

        <h4>給与（月給）</h4>

        <select className={styles.select_box} onChange={(e) => setSalary(e.target.value)} value={salary}>
          <option value=''>給与</option>
          <option value='16'>16万円以上</option>
          <option value='17'>17万円以上</option>
          <option value='18'>18万円以上</option>
          <option value='19'>19万円以上</option>
          <option value='20'>20万円以上</option>
          <option value='21'>21万円以上</option>
          <option value='23'>23万円以上</option>
          <option value='24'>24万円以上</option>
          <option value='25'>25万円以上</option>
          <option value='26'>26万円以上</option>
          <option value='27'>27万円以上</option>
          <option value='28'>28万円以上</option>
          <option value='29'>29万円以上</option>
          <option value='30'>30万円以上</option>
          <option value='35'>35万円以上</option>
          <option value='45'>45万円以上</option>
        </select>

        {/* <h4>職種</h4>

        <div className={styles.employee}>
          <div>
            <input 
              name="post" type="radio" checked={post === '新卒採用（正社員）' ? 'checked' : ''} 
              onClick={() => setPost('新卒採用（正社員）')} />
            <p>新卒採用（正社員）</p>
          </div>
          <div>
            <input 
              name="post" type="radio" checked={post === '新卒採用（契約社員）' ? 'checked' : ''} 
              onClick={() => setPost('新卒採用（契約社員）')} />
            <p>新卒採用（契約社員）</p>
          </div>
          <div>
            <input 
              name="post" type="radio" checked={post === '中途採用（正社員）' ? 'checked' : ''} 
              onClick={() => setPost('中途採用（正社員）')} />
            <p>中途採用（正社員）</p>
          </div>
          <div>
            <input 
              name="post" type="radio" checked={post === '中途採用（契約社員）' ? 'checked' : ''} 
              onClick={() => setPost('中途採用（契約社員）')} />
            <p>中途採用（契約社員）</p>
          </div>
          <div>
            <input 
              name="post" type="radio" checked={post === 'アルバイト・パート採用	' ? 'checked' : ''} 
              onClick={() => setPost('アルバイト・パート採用	')} />
            <p>アルバイト・パート採用	</p>
          </div>
          <div>
            <input 
              name="post" type="radio" checked={post === '業務委託（フリーランス）' ? 'checked' : ''} 
              onClick={() => setPost('業務委託（フリーランス）')} />
            <p>業務委託（フリーランス）</p>
          </div>
          <div>
            <input 
              name="post" type="radio" checked={post === 'インターンシップ' ? 'checked' : ''} 
              onClick={() => setPost('インターンシップ')} />
            <p>インターンシップ</p>
          </div>
        </div> */}

        <h4>スタイル</h4>

        <div className={styles.employee}>
          <div>
            <input type="checkbox"
              checked={style.includes('カジュアル') ? 'checked' : ''}
              onChange={(e) => {
                e.target.checked ? setStyle([...style, 'カジュアル']) : setStyle(style.filter((v, index) => (v !== 'カジュアル')))
              }}/>
            <p>カジュアル</p>
          </div>
          <div>
            <input type="checkbox"
              checked={style.includes('きれいめ') ? 'checked' : ''}
              onChange={(e) => {
                e.target.checked ? setStyle([...style, 'きれいめ']) : setStyle(style.filter((v, index) => (v !== 'きれいめ')))
              }}/>
            <p>きれいめ</p>
          </div>
          <div>
            <input type="checkbox"
              checked={style.includes('原宿') ? 'checked' : ''}
              onChange={(e) => {
                e.target.checked ? setStyle([...style, '原宿']) : setStyle(style.filter((v, index) => (v !== '原宿')))
              }}/>
            <p>原宿</p>
          </div>
          <div>
            <input type="checkbox"
              checked={style.includes('コンサバ') ? 'checked' : ''}
              onChange={(e) => {
                e.target.checked ? setStyle([...style, 'コンサバ']) : setStyle(style.filter((v, index) => (v !== 'コンサバ')))
              }}/>
            <p>コンサバ</p>
          </div>
          <div>
            <input type="checkbox"
              checked={style.includes('フェミニン') ? 'checked' : ''}
              onChange={(e) => {
                e.target.checked ? setStyle([...style, 'フェミニン']) : setStyle(style.filter((v, index) => (v !== 'フェミニン')))
              }}/>
            <p>フェミニン</p>
          </div>
          <div>
            <input type="checkbox"
              checked={style.includes('モード') ? 'checked' : ''}
              onChange={(e) => {
                e.target.checked ? setStyle([...style, 'モード']) : setStyle(style.filter((v, index) => (v !== 'モード')))
              }}/>
            <p>モード</p>
          </div>
          <div>
            <input type="checkbox"
              checked={style.includes('セクシー') ? 'checked' : ''}
              onChange={(e) => {
                e.target.checked ? setStyle([...style, 'セクシー']) : setStyle(style.filter((v, index) => (v !== 'セクシー')))
              }}/>
            <p>セクシー</p>
          </div>
          <div>
            <input type="checkbox"
              checked={style.includes('ストリート') ? 'checked' : ''}
              onChange={(e) => {
                e.target.checked ? setStyle([...style, 'ストリート']) : setStyle(style.filter((v, index) => (v !== 'ストリート')))
              }}/>
            <p>ストリート</p>
          </div>
          <div>
            <input type="checkbox"
              checked={style.includes('韓国') ? 'checked' : ''}
              onChange={(e) => {
                e.target.checked ? setStyle([...style, '韓国']) : setStyle(style.filter((v, index) => (v !== '韓国')))
              }}/>
            <p>韓国</p>
          </div>
          <div>
            <input type="checkbox"
              checked={style.includes('ガーリー') ? 'checked' : ''}
              onChange={(e) => {
                e.target.checked ? setStyle([...style, 'ガーリー']) : setStyle(style.filter((v, index) => (v !== 'ガーリー')))
              }}/>
            <p>ガーリー</p>
          </div>
          <div>
            <input type="checkbox"
              checked={style.includes('エスニック') ? 'checked' : ''}
              onChange={(e) => {
                e.target.checked ? setStyle([...style, 'エスニック']) : setStyle(style.filter((v, index) => (v !== 'エスニック')))
              }}/>
            <p>エスニック</p>
          </div>
          <div>
            <input type="checkbox"
              checked={style.includes('ナチュラル') ? 'checked' : ''}
              onChange={(e) => {
                e.target.checked ? setStyle([...style, 'ナチュラル']) : setStyle(style.filter((v, index) => (v !== 'ナチュラル')))
              }}/>
            <p>ナチュラル</p>
          </div>
        </div>

        <h5 onClick={() => setSwitchStyle(true)}>全てのタグを見る</h5>

        <p>{style.map((data, index) => <>{data},</> )}</p>

        <button onClick={() => search()}>検索</button>

        <h6 onClick={() => {setPref(''); setStation([]); setSalary(''); setPost(''); setStyle([]);}}>クリア</h6>

      </div>

    </div>

    {switchPref ?
      <Station pref={pref} setPref={setPref} setSwitchPref={setSwitchPref} station={station} setStation={setStation} />
    : <></> }

    {switchStyle ?
      <Style style={style} setStyle={setStyle} setSwitchStyle={setSwitchStyle} />
    : <></> }
    </>
  );
}

export default WorksWebSearch;

{/* 
<h4>給与体系</h4>
<div className='memorii_recruit_result_navigation_radio'>
  <div>
    <input id="overtime" type="radio" name="overtime" />
    <p>月給</p>
  </div>
  <div>
    <input id="overtime" type="radio" name="overtime" />
    <p>日給</p>
  </div>
  <div>
    <input id="overtime" type="radio" name="overtime" />
    <p>時給</p>
  </div>
  <div>
    <input id="overtime" type="radio" name="overtime" />
    <p>年俸</p>
  </div>
  <div>
    <input id="overtime" type="radio" name="overtime" />
    <p>完全成功報酬</p>
  </div>
</div> */}



import './styles.css';

const Help = () => {
  
  return (
    <>
    <div className='works_landing_QA'>
      <h4>よくあるご質問</h4>
      <div className='works_landing_QA_box'>
        <div className='works_landing_QA_q' style={{borderRadius: '3px 3px 0 0'}}>
          <h3>Q</h3>
          <h4>このシステムの導入にかかる費用はございますか？</h4>
        </div>
        <div className='works_landing_QA_a'>
          <h3>A</h3>
          <h4>導入費用は完全無料で提案しております。また、導入にかかる工数を最小限に抑えるため担当者が記者の管理方法をお伝えいたしますので、ご安心いただければと思います。</h4>
        </div>
        <div className='works_landing_QA_q'>
          <h3>Q</h3>
          <h4>どのような方が対象のサービスですか？</h4>
        </div>
        <div className='works_landing_QA_a'>
          <h3>A</h3>
          <h4>本サービスは美容学生を採用したい事業者様と採用は上手く進んでいるが選考や応募者管理に時間が取られている事業者様を対象としているサービスでございます。</h4>
        </div>
        <div className='works_landing_QA_q'>
          <h3>Q</h3>
          <h4>費用は月額いくらですか？</h4>
        </div>
        <div className='works_landing_QA_a' style={{borderBottom : 'none'}}>
          <h3>A</h3>
          <h4>事業者様のご状況に合わせたプランでの提供となっているため、一律ではございませんので、お問い合わせよりご回答いたします。</h4>
        </div>
        {/* <div className='works_landing_QA_q'>
          <h3>Q</h3>
          <h4>一度不採択になってしまったのですが、申請は可能でしょうか？</h4>
        </div>
        <div className='works_landing_QA_a' style={{borderBottom : 'none'}}>
          <h3>A</h3>
          <h4>一度不採択になってしまったお客様や他社様にお断りされたお客様へも、弊社では申請サポートを行っております。不採択理由や計画書の完成具合に応じた値引き等へも対応しておりますので、弊社と共に採択をつかみ取りましょう。</h4>
        </div> */}
      </div>
    </div>
    </>
  );
}

export default Help;

import Styles from './styles.module.css';

const Footer = (props) => {

  return (
    <>
    <div className={Styles.text}>
      <h3>数字で見る</h3>

      <div className={Styles.score}>
        <div>
          <h4>
            {/* 
              recruit_storeNumber1  創業から
              recruit_storeNumber2  平均勤続年数
              recruit_storeNumber3  店舗数
              recruit_storeNumber4  男女比
              recruit_storeNumber5  採用人数
              recruit_storeNumber6  最短デビュー
              recruit_storeNumber7  平均顧客単価
              recruit_storeNumber8  アシスタント給与
              recruit_storeNumber9  引越し金
              recruit_storeNumber10 平均年齢
              recruit_storeNumber11 リピート率
              recruit_storeNumber12 コンテスト受賞数
              recruit_storeNumber13 地方出身者割合
            */}

            {props.storeNumberTags[0] === '創業から' ? `${props.storeNumber1}年`
              : props.storeNumberTags[0] === '平均勤続年数' ? `${props.storeNumber2}年`
              : props.storeNumberTags[0] === '店舗数' ? `${props.storeNumber3}店舗`
              : props.storeNumberTags[0] === '男女比' ? `${props.storeNumber4}`
              : props.storeNumberTags[0] === '採用人数' ? `${props.storeNumber5}人`
              : props.storeNumberTags[0] === '最短デビュー' ? `${props.storeNumber6}ヶ月`
              : props.storeNumberTags[0] === '平均顧客単価' ? `${props.storeNumber7}円`
              : props.storeNumberTags[0] === 'アシスタント給与' ? `${props.storeNumber8}万円`
              : props.storeNumberTags[0] === '引越し金' ? `${props.storeNumber9}万円`
              : props.storeNumberTags[0] === '平均年齢' ? `${props.storeNumber10}歳`
              : props.storeNumberTags[0] === 'リピート率' ? `${props.storeNumber11}％`
              : props.storeNumberTags[0] === 'コンテスト受賞数' ? `${props.storeNumber12}回`
              : props.storeNumberTags[0] === '地方出身者割合' ? `${props.storeNumber13}％` : '-'
            }
          </h4>
          <h3>{props.storeNumberTags[0]}</h3>
        </div>
        <div>
          <h4>
            {props.storeNumberTags[1] === '創業から' ? `${props.storeNumber1}年`
              : props.storeNumberTags[1] === '平均勤続年数' ? `${props.storeNumber2}年`
              : props.storeNumberTags[1] === '店舗数' ? `${props.storeNumber3}店`
              : props.storeNumberTags[1] === '男女比' ? `${props.storeNumber4}`
              : props.storeNumberTags[1] === '採用人数' ? `${props.storeNumber5}人`
              : props.storeNumberTags[1] === '最短デビュー' ? `${props.storeNumber6}ヶ月`
              : props.storeNumberTags[1] === '平均顧客単価' ? `${props.storeNumber7}円`
              : props.storeNumberTags[1] === 'アシスタント給与' ? `${props.storeNumber8}万円`
              : props.storeNumberTags[1] === '引越し金' ? `${props.storeNumber9}万円`
              : props.storeNumberTags[1] === '平均年齢' ? `${props.storeNumber10}歳`
              : props.storeNumberTags[1] === 'リピート率' ? `${props.storeNumber11}％`
              : props.storeNumberTags[1] === 'コンテスト受賞数' ? `${props.storeNumber12}回`
              : props.storeNumberTags[1] === '地方出身者割合' ? `${props.storeNumber13}％` : '-'
            }
          </h4>
          <h3>{props.storeNumberTags[1]}</h3>
        </div>
        <div>
          <h4>
            {props.storeNumberTags[2] === '創業から' ? `${props.storeNumber1}年`
              : props.storeNumberTags[2] === '平均勤続年数' ? `${props.storeNumber2}年`
              : props.storeNumberTags[2] === '店舗数' ? `${props.storeNumber3}店`
              : props.storeNumberTags[2] === '男女比' ? `${props.storeNumber4}`
              : props.storeNumberTags[2] === '採用人数' ? `${props.storeNumber5}人`
              : props.storeNumberTags[2] === '最短デビュー' ? `${props.storeNumber6}ヶ月`
              : props.storeNumberTags[2] === '平均顧客単価' ? `${props.storeNumber7}円`
              : props.storeNumberTags[2] === 'アシスタント給与' ? `${props.storeNumber8}万円`
              : props.storeNumberTags[2] === '引越し金' ? `${props.storeNumber9}万円`
              : props.storeNumberTags[2] === '平均年齢' ? `${props.storeNumber10}歳`
              : props.storeNumberTags[2] === 'リピート率' ? `${props.storeNumber11}％`
              : props.storeNumberTags[2] === 'コンテスト受賞数' ? `${props.storeNumber12}回`
              : props.storeNumberTags[2] === '地方出身者割合' ? `${props.storeNumber13}％` : '-'
            }
          </h4>
          <h3>{props.storeNumberTags[2]}</h3>
        </div>
        <div>
          <h4>
            {props.storeNumberTags[3] === '創業から' ? `${props.storeNumber1}年`
              : props.storeNumberTags[3] === '平均勤続年数' ? `${props.storeNumber2}年`
              : props.storeNumberTags[3] === '店舗数' ? `${props.storeNumber3}店`
              : props.storeNumberTags[3] === '男女比' ? `${props.storeNumber4}`
              : props.storeNumberTags[3] === '採用人数' ? `${props.storeNumber5}人`
              : props.storeNumberTags[3] === '最短デビュー' ? `${props.storeNumber6}ヶ月`
              : props.storeNumberTags[3] === '平均顧客単価' ? `${props.storeNumber7}円`
              : props.storeNumberTags[3] === 'アシスタント給与' ? `${props.storeNumber8}万円`
              : props.storeNumberTags[3] === '引越し金' ? `${props.storeNumber9}万円`
              : props.storeNumberTags[3] === '平均年齢' ? `${props.storeNumber10}歳`
              : props.storeNumberTags[3] === 'リピート率' ? `${props.storeNumber11}％`
              : props.storeNumberTags[3] === 'コンテスト受賞数' ? `${props.storeNumber12}回`
              : props.storeNumberTags[3] === '地方出身者割合' ? `${props.storeNumber13}％` : '-'
            }
          </h4>
          <h3>{props.storeNumberTags[3]}</h3>
        </div>
      </div>

    </div>
    </>
  );
}

export default Footer;

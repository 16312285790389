import Styles from './styles.module.css';
import React from 'react';
import firebase from 'firebase/compat/app';
import { useHistory } from 'react-router-dom';

const MemoriiWorksRecruitDeleteRecruit = (props) => {

  const history = useHistory();

  function edit() {
    props.setEditStatus([])
    firebase.firestore().collection('recruits').doc(props.editStatus[1]).update({ status: 3 })
      .then(() => { history.push(`/edit/id=${props.editStatus[0]}+page=2+recruitId=${props.editStatus[1]}`) });
  }

  return (
    <>
    <div className={Styles.modal}>
      <div>
        <div className={Styles.modal_black} onClick={() => props.setEditStatus([])}></div>
        <div className={Styles.modal_contents}>
          <h4>求人を編集しますか？</h4>
          <p>
            編集を行う場合は、再審査を行う必要があります。<br />
            ※編集された求人は再度審査を行うため、再掲載までに2～3営業日を要します。
          </p>
          <div>
            <button style={{backgroundColor : 'white', color : '#AD0200'}} onClick={() => props.setEditStatus([])}>キャンセル</button>
            <button style={{backgroundColor : '#AD0200', color : 'white'}} onClick={() => edit()}>編集する</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default MemoriiWorksRecruitDeleteRecruit;

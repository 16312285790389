import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';

const MemoriiWorksHomeAnalytics = (props) => {

  const [ standardDate, setStandardDate ] = useState([]);
  const [ period, setPeriod ] = useState(false);

  // 求人データ
  const [ choiceRecruit, setChoiceRecruit ] = useState(1);
  const [ recruitMemory1, setRecruitMemory1 ] = useState(6);
  const [ recruitMemory2, setrecruitMemory2 ] = useState(6);
  const [ recruitMemory3, setRecruitMemory3 ] = useState(6);

  // 求人データ
  const [ recruitPvCount, setRecruitPvCount ] = useState([]);
  const [ recruitSaveCount, setRecruitSaveCount ] = useState([]);
  const [ recruitApplyCount, setRecruitApplyCount ] = useState([]);

  useEffect(() => {
    if (props.recruitList.length !== 0) {
      getAnalytics(props.recruitList);
    }
  }, []);

  function getAnalytics(recruitList) {

    var years = new Date().getFullYear();
    var month = new Date().getMonth();
    var day = new Date().getDate();

    var day1 = new Date(years, month, day);
    var day2 = new Date(years, month, day - 1);
    var day3 = new Date(years, month, day - 2);
    var day4 = new Date(years, month, day - 3);
    var day5 = new Date(years, month, day - 4);
    var day6 = new Date(years, month, day - 5);
    var day7 = new Date(years, month, day - 6);
    var day8 = new Date(years, month, day - 7);
    var day9 = new Date(years, month, day - 8);
    var day10 = new Date(years, month, day - 9);
    var day11 = new Date(years, month, day - 10);
    var day12 = new Date(years, month, day - 11);
    var day13 = new Date(years, month, day - 12);
    var day14 = new Date(years, month, day - 13);
    var day15 = new Date(years, month, day - 14);
    var day16 = new Date(years, month, day - 15);
    var day17 = new Date(years, month, day - 16);
    var day18 = new Date(years, month, day - 17);
    var day19 = new Date(years, month, day - 18);
    var day20 = new Date(years, month, day - 19);
    var day21 = new Date(years, month, day - 20);
    var day22 = new Date(years, month, day - 21);
    var day23 = new Date(years, month, day - 22);
    var day24 = new Date(years, month, day - 23);
    var day25 = new Date(years, month, day - 24);
    var day26 = new Date(years, month, day - 25);
    var day27 = new Date(years, month, day - 26);
    var day28 = new Date(years, month, day - 27);
    var day29 = new Date(years, month, day - 28);
    var day30 = new Date(years, month, day - 29);
    var day31 = new Date(years, month, day - 30);

    setStandardDate([
      day2, day3, day4, day5, day6, day7, day8, day9, day10, day11, day12, day13, day14, day15, day16, day17, day18, day19, day20, day21, day22, day23, day24, day25, day26, day27, day28, day29, day30, day31,
    ]);

    var date = [
      day1, day2, day3, day4, day5, day6, day7, day8, day9, day10, day11, day12, day13, day14, day15, day16, day17, day18, day19, day20, day21, day22, day23, day24, day25, day26, day27, day28, day29, day30, 
    ]
    
    getRecruitDate(recruitList, date);
  }

  function getRecruitDate(recruit, date) {

    var pvTmp = [];
    var saveTmp = [];
    var applyTmp = [];

    for (let index = 0; index < recruit.length; index++) {
      for (let i = 0; i < recruit[index].data_pv.length; i++) {
        pvTmp.push(new Date(recruit[index].data_pv[i].seconds * 1000));
      }
      for (let i = 0; i < recruit[index].data_save.length; i++) {
        saveTmp.push(new Date(recruit[index].data_save[i].seconds * 1000));
      }
      for (let i = 0; i < recruit[index].data_apply.length; i++) {
        applyTmp.push(new Date(recruit[index].data_apply[i].seconds * 1000));
      }
      if (recruit.length === index + 1) {
        calcRecruit(1, pvTmp, date);
        calcRecruit(2, saveTmp, date);
        calcRecruit(3, applyTmp, date); 
      }
    }
  }

  function calcRecruit(number, data, date) {

    var count1 = 0;
    var count2 = 0;
    var count3 = 0;
    var count4 = 0;
    var count5 = 0;
    var count6 = 0;
    var count7 = 0;
    var count8 = 0;
    var count9 = 0;
    var count10 = 0;
    var count11 = 0;
    var count12 = 0;
    var count13 = 0;
    var count14 = 0;
    var count15 = 0;
    var count16 = 0;
    var count17 = 0;
    var count18 = 0;
    var count19 = 0;
    var count20 = 0;
    var count21 = 0;
    var count22 = 0;
    var count23 = 0;
    var count24 = 0;
    var count25 = 0;
    var count26 = 0;
    var count27 = 0;
    var count28 = 0;
    var count29 = 0;
    var count30 = 0;

    for (let i = 0; i < data.length; i++){
      
      if (date[0] > data[i] && data[i] >= date[1]) {
        count1 = count1 + 1;
      }
      
      if (date[1] > data[i] && data[i] >= date[2]) {
        count2 = count2 + 1;
      }
      
      if (date[2] > data[i] && data[i] >= date[3]) {
        count3 = count3 + 1;
      }
      
      if (date[3] > data[i] && data[i] >= date[4]) {
        count4 = count4 + 1;
      }
      
      if (date[4] > data[i] && data[i] >= date[5]) {
        count5 = count5 + 1;
      }
      
      if (date[5] > data[i] && data[i] >= date[6]) {
        count6 = count6 + 1;
      }
      
      if (date[6] > data[i] && data[i] >= date[7]) {
        count7 = count7 + 1;
      }
      
      if (date[7] > data[i] && data[i] >= date[8]) {
        count8 = count8 + 1;
      }
      
      if (date[8] > data[i] && data[i] >= date[9]) {
        count9 = count9 + 1;
      }
      
      if (date[9] > data[i] && data[i] >= date[10]) {
        count10 = count10 + 1;
      }
      
      if (date[10] > data[i] && data[i] >= date[11]) {
        count11 = count11 + 1;
      }
      
      if (date[11] > data[i] && data[i] >= date[12]) {
        count12 = count12 + 1;
      }
      
      if (date[12] > data[i] && data[i] >= date[13]) {
        count13 = count13 + 1;
      }
      
      if (date[13] > data[i] && data[i] >= date[14]) {
        count14 = count14 + 1;
      }
      
      if (date[14] > data[i] && data[i] >= date[15]) {
        count15 = count15 + 1;
      }
      
      if (date[15] > data[i] && data[i] >= date[16]) {
        count16 = count16 + 1;
      }
      
      if (date[16] > data[i] && data[i] >= date[17]) {
        count17 = count17 + 1;
      }
      
      if (date[17] > data[i] && data[i] >= date[18]) {
        count18 = count18 + 1;
      }
      
      if (date[18] > data[i] && data[i] >= date[19]) {
        count19 = count19 + 1;
      }
      
      if (date[19] > data[i] && data[i] >= date[20]) {
        count20 = count20 + 1;
      }
      
      if (date[20] > data[i] && data[i] >= date[21]) {
        count21 = count21 + 1;
      }
      
      if (date[21] > data[i] && data[i] >= date[22]) {
        count22 = count22 + 1;
      }
      
      if (date[22] > data[i] && data[i] >= date[23]) {
        count23 = count23 + 1;
      }
      
      if (date[23] > data[i] && data[i] >= date[24]) {
        count24 = count24 + 1;
      }
      
      if (date[24] > data[i] && data[i] >= date[25]) {
        count25 = count25 + 1;
      }
      
      if (date[25] > data[i] && data[i] >= date[26]) {
        count26 = count26 + 1;
      }
      
      if (date[26] > data[i] && data[i] >= date[27]) {
        count27 = count27 + 1;
      }
      
      if (date[27] > data[i] && data[i] >= date[28]) {
        count28 = count28 + 1;
      }
      
      if (date[28] > data[i] && data[i] >= date[29]) {
        count29 = count29 + 1;
      }
      
      if (date[29] > data[i] && data[i] >= date[30]) {
        count30 = count30 + 1;
      }

    }

    if (number === 1) {
      setRecruitPvCount([
        count1, count2, count3, count4, count5, count6, count7, count8, count9, count10, count11, count12, count13, count14, count15, count16, count17, count18, count19, count20, count21, count22, count23, count24, count25, count26, count27, count28, count29, count30, 
      ]);

      var max = Math.max(count1, count2, count3, count4, count5, count6, count7, count8, count9, count10, count11, count12, count13, count14, count15, count16, count17, count18, count19, count20, count21, count22, count23, count24, count25, count26, count27, count28, count29, count30,)

      for (let i = 0; i < 10000; i++) {
        if (i * 6 <= max && max <= (i + 1) * 6) {
          return setRecruitMemory1((i + 1) * 6);
        }
      }
    }

    if (number === 2) {
      setRecruitSaveCount([
        count1, count2, count3, count4, count5, count6, count7, count8, count9, count10, count11, count12, count13, count14, count15, count16, count17, count18, count19, count20, count21, count22, count23, count24, count25, count26, count27, count28, count29, count30, 
      ]);

      var max = Math.max(count1, count2, count3, count4, count5, count6, count7, count8, count9, count10, count11, count12, count13, count14, count15, count16, count17, count18, count19, count20, count21, count22, count23, count24, count25, count26, count27, count28, count29, count30,)

      for (let i = 0; i < 10000; i++) {
        if (i * 6 <= max && max <= (i + 1) * 6) {
          return setrecruitMemory2((i + 1) * 6);
        }
      }
    }

    if (number === 3) {
      setRecruitApplyCount([
        count1, count2, count3, count4, count5, count6, count7, count8, count9, count10, count11, count12, count13, count14, count15, count16, count17, count18, count19, count20, count21, count22, count23, count24, count25, count26, count27, count28, count29, count30, 
      ]);

      var max = Math.max(count1, count2, count3, count4, count5, count6, count7, count8, count9, count10, count11, count12, count13, count14, count15, count16, count17, count18, count19, count20, count21, count22, count23, count24, count25, count26, count27, count28, count29, count30,)

      for (let i = 0; i < 10000; i++) {
        if (i * 6 <= max && max <= (i + 1) * 6) {
          return setRecruitMemory3((i + 1) * 6);
        }
      }
    }
  }

  return (
    <>
    <div className={Styles.home_progress}>
      <div>
        <h4 style={period ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}} onClick={() => setPeriod(true)}>1ヶ月</h4>
        <h4 style={!period ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}} onClick={() => setPeriod(false)}>1週間</h4>
      </div>
    </div>

    <div className={Styles.analytics}>
      <div className={Styles.analytics_title}>
        <h4>求人のアナリティクス</h4>
        <div>
          <div className={Styles.analytics_tabs}
            onClick={() => {setChoiceRecruit(1);}} style={{opacity: choiceRecruit !== 1 ? '.3' : '1'}}>
            <div style={{backgroundColor : '#D14F60'}}></div>
            <p>PV数</p>
          </div>
          <div className={Styles.analytics_tabs}
            onClick={() => {setChoiceRecruit(2);}} style={{opacity: choiceRecruit !== 2 ? '.3' : '1'}}>
            <div style={{backgroundColor : '#59C47C'}}></div>
            <p>保存数</p>
          </div>
          <div className={Styles.analytics_tabs}
            onClick={() => {setChoiceRecruit(3);}} style={{opacity: choiceRecruit !== 3 ? '.3' : '1'}}>
            <div style={{backgroundColor : '#FCDC4A'}}></div>
            <p>応募数</p>
          </div>
        </div>
      </div>
      <div className={Styles.analytics_date}>
        <div className={Styles.analytics_date_unit}>
          <div>
            {choiceRecruit === 1 ?
            <>
              <p>{recruitMemory1}</p>
              <p>{recruitMemory1 / 6 * 5}</p>
              <p>{recruitMemory1 / 6 * 4}</p>
              <p>{recruitMemory1 / 6 * 3}</p>
              <p>{recruitMemory1 / 6 * 2}</p>
              <p>{recruitMemory1 / 6}</p>
            </>
            : choiceRecruit === 2 ?
            <>
              <p>{recruitMemory2}</p>
              <p>{recruitMemory2 / 6 * 5}</p>
              <p>{recruitMemory2 / 6 * 4}</p>
              <p>{recruitMemory2 / 6 * 3}</p>
              <p>{recruitMemory2 / 6 * 2}</p>
              <p>{recruitMemory2 / 6}</p>
            </>
            :
            <>
              <p>{recruitMemory3}</p>
              <p>{recruitMemory3 / 6 * 5}</p>
              <p>{recruitMemory3 / 6 * 4}</p>
              <p>{recruitMemory3 / 6 * 3}</p>
              <p>{recruitMemory3 / 6 * 2}</p>
              <p>{recruitMemory3 / 6}</p>
            </>
            }
            <p>0</p>
          </div>
        </div>
        <div className={Styles.analytics_date_graph}>
          {standardDate.length !== 0 ?
          <>
            {period ?
            <>
              {/* データ：1年 */}
              <div className={Styles.analytics_date_graph_year}>
                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[28] : choiceRecruit === 2 ? recruitSaveCount[28] : recruitApplyCount[28]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[28] : choiceRecruit === 2 ? recruitSaveCount[28] : recruitApplyCount[28]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[27] : choiceRecruit === 2 ? recruitSaveCount[27] : recruitApplyCount[27]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[27] : choiceRecruit === 2 ? recruitSaveCount[27] : recruitApplyCount[27]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[26] : choiceRecruit === 2 ? recruitSaveCount[26] : recruitApplyCount[26]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[26] : choiceRecruit === 2 ? recruitSaveCount[26] : recruitApplyCount[26]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[25] : choiceRecruit === 2 ? recruitSaveCount[25] : recruitApplyCount[25]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[25] : choiceRecruit === 2 ? recruitSaveCount[25] : recruitApplyCount[25]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[24] : choiceRecruit === 2 ? recruitSaveCount[24] : recruitApplyCount[24]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[24] : choiceRecruit === 2 ? recruitSaveCount[24] : recruitApplyCount[24]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[23] : choiceRecruit === 2 ? recruitSaveCount[23] : recruitApplyCount[23]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[23] : choiceRecruit === 2 ? recruitSaveCount[23] : recruitApplyCount[23]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[22] : choiceRecruit === 2 ? recruitSaveCount[22] : recruitApplyCount[22]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[22] : choiceRecruit === 2 ? recruitSaveCount[22] : recruitApplyCount[22]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[21] : choiceRecruit === 2 ? recruitSaveCount[21] : recruitApplyCount[21]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[21] : choiceRecruit === 2 ? recruitSaveCount[21] : recruitApplyCount[21]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[20] : choiceRecruit === 2 ? recruitSaveCount[20] : recruitApplyCount[20]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[20] : choiceRecruit === 2 ? recruitSaveCount[20] : recruitApplyCount[20]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[19] : choiceRecruit === 2 ? recruitSaveCount[19] : recruitApplyCount[19]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[19] : choiceRecruit === 2 ? recruitSaveCount[19] : recruitApplyCount[19]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[18] : choiceRecruit === 2 ? recruitSaveCount[18] : recruitApplyCount[18]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[18] : choiceRecruit === 2 ? recruitSaveCount[18] : recruitApplyCount[18]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[17] : choiceRecruit === 2 ? recruitSaveCount[17] : recruitApplyCount[17]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[17] : choiceRecruit === 2 ? recruitSaveCount[17] : recruitApplyCount[17]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[16] : choiceRecruit === 2 ? recruitSaveCount[16] : recruitApplyCount[16]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[16] : choiceRecruit === 2 ? recruitSaveCount[16] : recruitApplyCount[16]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[15] : choiceRecruit === 2 ? recruitSaveCount[15] : recruitApplyCount[15]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[15] : choiceRecruit === 2 ? recruitSaveCount[15] : recruitApplyCount[15]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[14] : choiceRecruit === 2 ? recruitSaveCount[14] : recruitApplyCount[14]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[14] : choiceRecruit === 2 ? recruitSaveCount[14] : recruitApplyCount[14]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[13] : choiceRecruit === 2 ? recruitSaveCount[13] : recruitApplyCount[13]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[13] : choiceRecruit === 2 ? recruitSaveCount[13] : recruitApplyCount[13]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[12] : choiceRecruit === 2 ? recruitSaveCount[12] : recruitApplyCount[12]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[12] : choiceRecruit === 2 ? recruitSaveCount[12] : recruitApplyCount[12]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[11] : choiceRecruit === 2 ? recruitSaveCount[11] : recruitApplyCount[11]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[11] : choiceRecruit === 2 ? recruitSaveCount[11] : recruitApplyCount[11]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[10] : choiceRecruit === 2 ? recruitSaveCount[10] : recruitApplyCount[10]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[10] : choiceRecruit === 2 ? recruitSaveCount[10] : recruitApplyCount[10]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[9] : choiceRecruit === 2 ? recruitSaveCount[9] : recruitApplyCount[9]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[9] : choiceRecruit === 2 ? recruitSaveCount[9] : recruitApplyCount[9]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[8] : choiceRecruit === 2 ? recruitSaveCount[8] : recruitApplyCount[8]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[8] : choiceRecruit === 2 ? recruitSaveCount[8] : recruitApplyCount[8]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[8] : choiceRecruit === 2 ? recruitSaveCount[8] : recruitApplyCount[8]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[8] : choiceRecruit === 2 ? recruitSaveCount[8] : recruitApplyCount[8]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[7] : choiceRecruit === 2 ? recruitSaveCount[7] : recruitApplyCount[7]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[7] : choiceRecruit === 2 ? recruitSaveCount[7] : recruitApplyCount[7]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[6] : choiceRecruit === 2 ? recruitSaveCount[6] : recruitApplyCount[6]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[6] : choiceRecruit === 2 ? recruitSaveCount[6] : recruitApplyCount[6]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[5] : choiceRecruit === 2 ? recruitSaveCount[5] : recruitApplyCount[5]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[5] : choiceRecruit === 2 ? recruitSaveCount[5] : recruitApplyCount[5]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[4] : choiceRecruit === 2 ? recruitSaveCount[4] : recruitApplyCount[4]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[4] : choiceRecruit === 2 ? recruitSaveCount[4] : recruitApplyCount[4]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[3] : choiceRecruit === 2 ? recruitSaveCount[3] : recruitApplyCount[3]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[3] : choiceRecruit === 2 ? recruitSaveCount[3] : recruitApplyCount[3]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[2] : choiceRecruit === 2 ? recruitSaveCount[2] : recruitApplyCount[2]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[2] : choiceRecruit === 2 ? recruitSaveCount[2] : recruitApplyCount[2]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[1] : choiceRecruit === 2 ? recruitSaveCount[1] : recruitApplyCount[1]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[1] : choiceRecruit === 2 ? recruitSaveCount[1] : recruitApplyCount[1]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[0] : choiceRecruit === 2 ? recruitSaveCount[0] : recruitApplyCount[0]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[0] : choiceRecruit === 2 ? recruitSaveCount[0] : recruitApplyCount[0]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>
              </div>
            </> :
            <>
              {/* データ：1週間 */}
              <div className={Styles.analytics_date_graph_week}>
                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[6] : choiceRecruit === 2 ? recruitSaveCount[6] : recruitApplyCount[6]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[6] : choiceRecruit === 2 ? recruitSaveCount[6] : recruitApplyCount[6]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[5] : choiceRecruit === 2 ? recruitSaveCount[5] : recruitApplyCount[5]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[5] : choiceRecruit === 2 ? recruitSaveCount[5] : recruitApplyCount[5]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[4] : choiceRecruit === 2 ? recruitSaveCount[4] : recruitApplyCount[4]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[4] : choiceRecruit === 2 ? recruitSaveCount[4] : recruitApplyCount[4]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[3] : choiceRecruit === 2 ? recruitSaveCount[3] : recruitApplyCount[3]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[3] : choiceRecruit === 2 ? recruitSaveCount[3] : recruitApplyCount[3]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[2] : choiceRecruit === 2 ? recruitSaveCount[2] : recruitApplyCount[2]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[2] : choiceRecruit === 2 ? recruitSaveCount[2] : recruitApplyCount[2]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[1] : choiceRecruit === 2 ? recruitSaveCount[1] : recruitApplyCount[1]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[1] : choiceRecruit === 2 ? recruitSaveCount[1] : recruitApplyCount[1]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>

                <div style={{height : `calc(300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[0] : choiceRecruit === 2 ? recruitSaveCount[0] : recruitApplyCount[0]})`,
                  marginTop : `calc(300px - 300px / 
                    ${choiceRecruit === 1 ? recruitMemory1 : choiceRecruit === 2 ? recruitMemory2 : recruitMemory3} * 
                    ${choiceRecruit === 1 ? recruitPvCount[0] : choiceRecruit === 2 ? recruitSaveCount[0] : recruitApplyCount[0]})`,
                  backgroundColor : choiceRecruit === 1 ? '#D14F60' : choiceRecruit === 2 ? '#59C47C' : '#FCDC4A',}}></div>
              </div>
            </> }
          </> : <></> }

          {standardDate.length !== 0 ?
          <>
            {period ?
            // 期間：1年
            <div className={Styles.analytics_date_graph_year_bottom}>
              <p>{standardDate[29].getDate()}日</p>
              <p>{standardDate[28].getDate()}日</p>
              <p>{standardDate[27].getDate()}日</p>
              <p>{standardDate[26].getDate()}日</p>
              <p>{standardDate[25].getDate()}日</p>
              <p>{standardDate[24].getDate()}日</p>
              <p>{standardDate[23].getDate()}日</p>
              <p>{standardDate[22].getDate()}日</p>
              <p>{standardDate[21].getDate()}日</p>
              <p>{standardDate[20].getDate()}日</p>
              <p>{standardDate[19].getDate()}日</p>
              <p>{standardDate[18].getDate()}日</p>
              <p>{standardDate[17].getDate()}日</p>
              <p>{standardDate[16].getDate()}日</p>
              <p>{standardDate[15].getDate()}日</p>
              <p>{standardDate[14].getDate()}日</p>
              <p>{standardDate[13].getDate()}日</p>
              <p>{standardDate[12].getDate()}日</p>
              <p>{standardDate[11].getDate()}日</p>
              <p>{standardDate[10].getDate()}日</p>
              <p>{standardDate[9].getDate()}日</p>
              <p>{standardDate[8].getDate()}日</p>
              <p>{standardDate[7].getDate()}日</p>
              <p>{standardDate[6].getDate()}日</p>
              <p>{standardDate[5].getDate()}日</p>
              <p>{standardDate[4].getDate()}日</p>
              <p>{standardDate[3].getDate()}日</p>
              <p>{standardDate[2].getDate()}日</p>
              <p>{standardDate[1].getDate()}日</p>
              <p>{standardDate[0].getDate()}日</p>
            </div>
            :
            // 期間：1週間
            <div className={Styles.analytics_date_graph_week_bottom}>
              <p>{standardDate[6].getDate()}日</p>
              <p>{standardDate[5].getDate()}日</p>
              <p>{standardDate[4].getDate()}日</p>
              <p>{standardDate[3].getDate()}日</p>
              <p>{standardDate[2].getDate()}日</p>
              <p>{standardDate[1].getDate()}日</p>
              <p>{standardDate[0].getDate()}日</p>
            </div> }
          </> : <></> }
        </div>
      </div>
    </div>
    </>
  );
}

export default MemoriiWorksHomeAnalytics;

import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import Details from '../../package/info/pc/index';

const MemoriiWorksMessage = (props) => {
  
  const [ sendText, setSendText ] = useState('');

  const [ recruitId, setRecruitId ] = useState('');
  const [ applicantId, setApplicantId ] = useState('');

  function send() {
    firebase.firestore().collection('chat').doc(props.chatList.id).collection('message').doc()
    .set({
      message_send: props.myUid,
      message_receive: props.uid,
      message_text: sendText,
      message_time: new Date(),
      message_id: props.chatList.id,
    });

    var badge = {};
    var key = `${props.uid}_badge`;
    var badge_name = props.uid + '_badge';

    badge[key] = props.chatList[`${badge_name}`] + 1;

    firebase.firestore().collection('chat').doc(props.chatList.id)
    .update({
      chat_last: sendText,
      ...badge
    });

    firebase.firestore().collection('users').doc(props.uid)
    .update({
      user_badge: firebase.firestore.FieldValue.increment(1),
    });

    setSendText('');
  }

  return (
    <>
    <div className={Styles.message_title}>
      <div>
      <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/resume%2F${props.uid}_${props.recruitId}?alt=media&token=`} />
        <h5>{props.name}</h5>
      </div>
      <h4 onClick={() => { setRecruitId(props.recruitId); setApplicantId(props.applicantId);} }>情報</h4>
    </div>

    <div className={Styles.message_message}>
      {props.message.map((data, index) =>
      <>
        {data.message_send === props.myUid ?
          <div className={Styles.message_message_sender}>
            <p>
              {data.read === true ? '既読' : '未読'}<br />
              {new Date(data.message_time.seconds * 1000).getFullYear()}年
              {new Date(data.message_time.seconds * 1000).getMonth() + 1}月
              {new Date(data.message_time.seconds * 1000).getDate()}日
            </p>
            <h4>{data.message_text}</h4>
          </div>
          :
          <div className={Styles.message_message_received}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/resume%2F${props.uid}_${props.recruitId}?alt=media&token=`} />
            <h4>{data.message_text}</h4>
            <p>
              {new Date(data.message_time.seconds * 1000).getFullYear()}年
              {new Date(data.message_time.seconds * 1000).getMonth() + 1}月
              {new Date(data.message_time.seconds * 1000).getDate()}日
            </p>
          </div>
        }
      </> )}
    </div>

    <div className={Styles.message_input}>
      <div>
        <textarea placeholder='初めまして！採用担当の伊藤忠商事です。'
          value={sendText}
          onChange={(e) => {
            e.target.style.height = '0px';
            e.target.style.height = e.target.scrollHeight + 'px';
            setSendText(e.target.value);
          }}/>
        <button onClick={() => send()}>送信</button>
      </div>
    </div>

    {recruitId !== '' && applicantId !== '' && props.userList.length !== 0 && props.companyList.length !== 0 ?
      <Details 
        recruitId={recruitId} applicantId={applicantId} 
        setRecruitId={setRecruitId} setApplicantId={setApplicantId}
        userList={props.userList}
        companyList={props.companyList}
      />
    : <></> }

    </>
  );
}

export default MemoriiWorksMessage;
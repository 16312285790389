import Styles from './styles.module.css';
import 'firebase/auth';
import firebase from 'firebase/compat/app';
import { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import Evaluation from "./evaluation/index";
import Bar from "./bar/index";
import Summary from "./summary/index";
import { useHistory } from 'react-router-dom';

const MemoriiWorksManage = (props) => {

  const history = useHistory();

  const [ examData, setExamData ] = useState([]);
  const [ progress, setProgress ] = useState(props.applicantsList.examination);

  useEffect(() => {
    getEvaluation(props.applicantsList.examination);
  }, [props.applicantsList]);

  function getEvaluation(v) {
    const docRef = collection(firebase.firestore(), `/applicants/${props.applicantsList.id}/examination`)
    const unsub = onSnapshot(docRef, snapshot => {
      var tmp = [];
      snapshot.docs.forEach(doc => {
        if (Number(v) === doc.data().evaluation) {
          tmp.push({...doc.data(), id: doc.id})
        }
      })
      setExamData(tmp);
    })
    return () => unsub();
  }

  function updateExamination(value) {
    console.log(props.applicantsList.id);
    firebase.firestore().collection("applicants").doc(props.applicantsList.id).update({ examination: Number(value) })
    .then(() => getEvaluation(value));
  }

  return (
    <>
      {props.recruitList.exam_selection !== '' ?
        <div>
          <h5 className={Styles.title}>選考</h5>
          <select className={Styles.select} value={progress} 
            onChange={(e) => {setProgress(Number(e.target.value)); updateExamination(e.target.value)}}>
            {1 <= Number(props.recruitList.exam_selection) && Number(props.recruitList.exam_selection) <= 5 ?
              <option value={1}>1次選考</option>
            : <></> }
            {2 <= Number(props.recruitList.exam_selection) && Number(props.recruitList.exam_selection) <= 5 ?
              <option value={2}>2次選考</option>
            : <></> }
            {3 <= Number(props.recruitList.exam_selection) && Number(props.recruitList.exam_selection) <= 5 ?
              <option value={3}>3次選考</option>
            : <></> }
            {4 <= Number(props.recruitList.exam_selection) && Number(props.recruitList.exam_selection) <= 5 ?
              <option value={4}>4次選考</option>
            : <></> }
            {5 <= Number(props.recruitList.exam_selection) && Number(props.recruitList.exam_selection) <= 5 ?
              <option value={5}>5次選考</option>
            : <></> }
            <option value={6}>採用</option>
            <option value={7}>不採用</option>
          </select>

          <h5 className={Styles.title}>全体評価</h5>
          <div className={Styles.table}>

            {props.recruitList.length !== 0 ?
            <div className={Styles.all}>
              <p className={Styles.all_header}>審査員</p>
              <Bar count={Number(props.recruitList[`exam_selection${progress}Count`])} />
            </div> : <></> }

            {examData.map((data, index) =>
            <>
              <Summary 
                image={data.uid}
                name={data.name}
                count={Number(props.recruitList[`exam_selection${data.evaluation}Count`])}
                result1={Number(data.evaluation1Score)}
                result2={Number(data.evaluation2Score)}
                result3={Number(data.evaluation3Score)}
                result4={Number(data.evaluation4Score)}
                result5={Number(data.evaluation5Score)}
                result6={Number(data.evaluation6Score)}
                result7={Number(data.evaluation7Score)}
                result8={Number(data.evaluation8Score)}
              />
            </> )}
          </div>

          <h5 className={Styles.title}>審査員の評価・コメント</h5>
          {examData.map((data, index) =>
          <>
            {data.evaluation === progress ?
            <>
              <div className={Styles.items}>
                <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${data.uid}_200x200?alt=media&token`} />
                <p>{data.name}</p>
              </div>

              {1 <= Number(props.recruitList[`exam_selection${progress}Count`]) && Number(props.recruitList[`exam_selection${progress}Count`]) <= 8 ?
                <Evaluation count={1} title={props.recruitList[`exam_selection${progress}Text1`]} score={data.evaluation1Score} comment={data.evaluation1Comment} />
              : <></> }
              {2 <= Number(props.recruitList[`exam_selection${progress}Count`]) && Number(props.recruitList[`exam_selection${progress}Count`]) <= 8 ?
                <Evaluation count={2} title={props.recruitList[`exam_selection${progress}Text2`]} score={data.evaluation2Score} comment={data.evaluation2Comment} />
              : <></> }
              {3 <= Number(props.recruitList[`exam_selection${progress}Count`]) && Number(props.recruitList[`exam_selection${progress}Count`]) <= 8 ?
                <Evaluation count={3} title={props.recruitList[`exam_selection${progress}Text3`]} score={data.evaluation3Score} comment={data.evaluation3Comment} />
              : <></> }
              {4 <= Number(props.recruitList[`exam_selection${progress}Count`]) && Number(props.recruitList[`exam_selection${progress}Count`]) <= 8 ?
                <Evaluation count={4} title={props.recruitList[`exam_selection${progress}Text4`]} score={data.evaluation4Score} comment={data.evaluation4Comment} />
              : <></> }
              {5 <= Number(props.recruitList[`exam_selection${progress}Count`]) && Number(props.recruitList[`exam_selection${progress}Count`]) <= 8 ?
                <Evaluation count={5} title={props.recruitList[`exam_selection${progress}Text5`]} score={data.evaluation5Score} comment={data.evaluation5Comment} />
              : <></> }
              {6 <= Number(props.recruitList[`exam_selection${progress}Count`]) && Number(props.recruitList[`exam_selection${progress}Count`]) <= 8 ?
                <Evaluation count={6} title={props.recruitList[`exam_selection${progress}Text6`]} score={data.evaluation6Score} comment={data.evaluation6Comment} />
              : <></> }
              {7 <= Number(props.recruitList[`exam_selection${progress}Count`]) && Number(props.recruitList[`exam_selection${progress}Count`]) <= 8 ?
                <Evaluation count={7} title={props.recruitList[`exam_selection${progress}Text7`]} score={data.evaluation7Score} comment={data.evaluation7Comment} />
              : <></> }
              {8 <= Number(props.recruitList[`exam_selection${progress}Count`]) && Number(props.recruitList[`exam_selection${progress}Count`]) <= 8 ?
                <Evaluation count={8} title={props.recruitList[`exam_selection${progress}Text8`]} score={data.evaluation8Score} comment={data.evaluation8Comment} />
              : <></> }

            </> : <></> }

          </> )}

          <div className={Styles.border}></div>

          <button className={Styles.history} onClick={() => props.setHistory(true)}>評価の履歴</button>

          <div style={{height: '50px'}}></div>

        </div>
      :
        <div>
          <p className={Styles.setting_desc}>
            現在、選考評価の設定が行われていません。<br />応募者の設定を行う場合は以下の選考評価の設定ボタンより評価設定を行ってください。</p>
          <button className={Styles.setting_btn}
            onClick={() => history.push(`/edit/evaluation/id=${props.recruitList.companyId}+page=2+storeId=${props.recruitList.storeId}+recruitId=${props.recruitList.id}`)}
          >選考評価の設定</button>
          <p className={Styles.setting_attention}>※選考評価の設定は「求人管理画面 {">"} 求人 {">"} 詳細ボタン {">"} 評価設定」より設定を行うことが出来ます。</p>
        </div>
      }
    </>
  );
}

export default MemoriiWorksManage;

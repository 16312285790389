import './styles.css';
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { FaAward } from "react-icons/fa6";

const MemoriiWorksPackagePhoto = (props) => {

  const [ photoList, setPhotoList ] = useState([]);
  const [ score, setScore ] = useState(0);

  useEffect(() => {
    
    firebase.firestore().collection('posts').where('post_uid', '==', props.uid).orderBy("post_time", 'asc')
    .get().then(snapShot => {
      var tmp = [];
      snapShot.forEach((doc) => {
        tmp.push({...doc.data(), id: doc.id })
      });
      setPhotoList(tmp);
    });

    firebase.firestore().collection("users").doc(props.uid).get()
    .then((doc) => {
      setScore(doc.data().memorii_score);
    })

  }, []);

  return (
    <>
    <div className='works_service_info_score'>
      <FaAward className='works_service_info_score_icon' />
      <h4>memorii score</h4>
      <p>{score}</p>
    </div>

    <h5 className='works_service_info_post_title'>投稿済み作品撮り</h5>
    {photoList.map((photo, index) =>
      <img className='works_service_info_post' key={index} src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/image%2Fresize_images%2F${photo.id}_500x500?alt=media&token=`} alt="美容学生作品撮り" />
    )}
    </>
  );
}

export default MemoriiWorksPackagePhoto;



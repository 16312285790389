import './styles.css';
import React from 'react';
import { useHistory } from 'react-router-dom';

const MemoriiWorksLanding = () => {

  const history = useHistory();

  return (
    <>
    <div className='works_landing_header'>
      <div className='works_landing_header_badge'>
        <p>美容業界に特化したサービス</p>
      </div>
      <h4>「新卒採用の最大化」と<br />採用コストを最小化するサービス</h4>
      <div className='works_landing_header_btn'>
        <button onClick={() => history.push('/contact')}>無料トライアル受付中</button>
        <button onClick={() => history.push('/contact')}>料金表ダウンロード</button>
      </div>
      <div className='works_landing_header_screen'></div>
    </div>
    </>
  );
}

export default MemoriiWorksLanding;

import React from 'react';
import Styles from './styles.module.css';

const DefaultInput1 = (props) => {

  return (
    <>
    <div className={Styles.title}>
      <div>
        <p>{props.title}</p>
        <div className={ props.required ? Styles.title_yes : Styles.title_no }>{props.required ? '※必須' : '任意'}</div>
      </div>
      <p>{props.value === 0 ? '0' : props.value.length} /{props.length}</p>
    </div>
    <textarea className={Styles.textarea} maxlength={props.length}
      placeholder={props.place} value={props.value} onChange={(e) => props.setValue(e.target.value)} />
    <p className={Styles.desc}>{props.desc}</p>
    </>
  );
}

export default DefaultInput1;

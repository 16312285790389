import Styles from './styles.module.css';
import React from 'react';
import firebase from 'firebase/compat/app';

const MemoriiWorksRecruitDeleteRecruit = (props) => {

  function Delete() {
    firebase.firestore().collection('companies').doc(props.companyList.id)
      .update({ recruits: firebase.firestore.FieldValue.arrayRemove(props.recruitId) });
    firebase.firestore().collection('recruits').doc(props.recruitId).delete().then(() => { props.setMakeDoc(0) });
  }

  return (
    <>
    <div className={Styles.modal}>
      <div>
        <div className={Styles.modal_black} onClick={() => props.setMakeDoc(0)}></div>
        <div className={Styles.modal_contents}>
          <h4>求人を削除しますか？</h4>
          <p>
            応募者情報を含む全てのデータが削除されます。<br />
            復元はできませんのでご注意ください。
          </p>
          <div>
            <button style={{backgroundColor : 'white', color : '#AD0200'}} onClick={() => props.setMakeDoc(0)}>キャンセル</button>
            <button style={{backgroundColor : '#AD0200', color : 'white'}} onClick={() => Delete()}>削除する</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default MemoriiWorksRecruitDeleteRecruit;



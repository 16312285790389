import authStyles from './styles.module.css';
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import Icon from './../../../../image/icon/company.png';
import { getAuth } from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import Header from '../header/index';
import { sendEmailVerification } from "firebase/auth";

const WorksConfirm = () => {

  const history = useHistory();

  const [ confirm, setConfirm ] = useState(true);
  
  useEffect(() => {
    // firebase.auth().onAuthStateChanged((user) => {
    //   if (user) {
    //     if (user.auth.currentUser.emailVerified) {
    //       return history.push('/company/login');
    //     }
    //   } else {
    //     history.push('/company/login');
    //   }
    // });
  }, []);

  function send() {
    const auth = getAuth();
    sendEmailVerification(auth.currentUser).then(() => { setConfirm(false); });
  }
  
  return (
    <>
    <Header />

    <div className={authStyles.background}>
      <div className={authStyles.contents}>

        <div className={authStyles.contents_icon}>
          <img src={Icon} alt='memorii works' />
          <h1>memorii works</h1>
        </div>

        <h2 className={authStyles.contents_title}>メールアドレス 未認証</h2>

        <div className={authStyles.contents_message}>
          <p>
          ご登録いただいたメールアドレスに届いたURLより<br />本登録を完了してください。
          </p>
        </div>

        <button className={authStyles.contents_btn}
          onClick={() => confirm ? send() : ''} style={confirm ? {backgroundColor: '#AD0200'} : {backgroundColor: '#EBEBEB'}}>
          {confirm ? 'メールを再送信する' : 'メールを再送信しました'}
        </button>

        <p className={authStyles.contents_link} onClick={() => window.location.reload()}>
          本登録完了済み
        </p>

      </div>
    </div>
    </>
  );
}

export default WorksConfirm;


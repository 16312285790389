import Styles from './styles.module.css';
import React from 'react';
import { MdError } from 'react-icons/md';

const SalonTopic = (props) => {

  return (
    <>
    <div className={Styles.box}>
      <MdError className={Styles.box_icon} />
      <h4>エラー：{props.message}</h4>
    </div>
    </>
  );
}

export default SalonTopic;

import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react'
import html2canvas from 'html2canvas';

const { jsPDF } = require('jspdf');

const SalonTopic = (props) => {

  const [ str, setStr ] = useState({});

  useEffect(() => {
    const large_image = `https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/resume%2Fresize_images%2F${props.applicantsList.uid}_${props.applicantsList.recruitId}_500x500?alt=media&token=2bdf5a1a-1956-433f-bda0-e2b8a7afa2a8`;
  
    toBase64Url(large_image, function(base64Url){
      setStr(base64Url);
    });
  }, []);
  
  function toBase64Url(url, callback){
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  const pdf = () => {
    const doc = new jsPDF({ 
      orientation: 'p',
      // orientation: 'l',
      format: 'a4',
      // width: '1000px',
      // heigh: '1410px',
      // width: '1410px',
      // heigh: '1000px',
    });
    (async() => {
      const elem = document.getElementById('output1');
      await html2canvas(elem, {scale: 5}).then(function(canvas) {
        const dataURI = canvas.toDataURL('image/jpeg');
        document.body.appendChild(canvas);
        const width = doc.internal.pageSize.width;
        doc.addImage(dataURI, 'JPEG', 0, 0, width, 0);
      });
      doc.save('test.pdf');
    })();
  }

  return (
    <>
    {props.recruitList.document_resume ?
    <>
      <div className={Styles.box} id='output1'>
        <div className={Styles.grid_1}>
          <div className={Styles.grid_1_1} onClick={() => pdf()}>履歴書</div>
          <div className={Styles.grid_1_2}>{new Date(props.applicantsList.time.seconds * 1000).getFullYear()}年{(new Date(props.applicantsList.time.seconds * 1000).getMonth() + 1)}月{new Date(props.applicantsList.time.seconds * 1000).getDate()}日</div>
          <div className={Styles.grid_1_3}>
            <img src={str} alt='' />
          </div>
          <div className={Styles.grid_1_4}>フリガナ　{props.applicantsList.nameHurigana}</div>
          <div className={Styles.grid_1_5}>氏名　{props.applicantsList.name}</div>
          <div className={Styles.grid_1_6}>
            {props.applicantsList.birthdayYears}年{props.applicantsList.birthdayMonth}月{props.applicantsList.birthdayDays}日
            （満 {props.applicantsList.age}歳）</div>
          <div className={Styles.grid_1_7}>性別</div>
          <div className={Styles.grid_1_8}>{props.applicantsList.sex}</div>
          <div className={Styles.grid_1_9}>血液型：{props.applicantsList.bloodType}</div>

          <div className={Styles.grid_1_10}>フリガナ　{props.applicantsList.addressHurigana}</div>
          <div className={Styles.grid_1_11}>〒　{props.applicantsList.postCode.substr(0,3)}-{props.applicantsList.postCode.substr(3,4)}</div>
          <div className={Styles.grid_1_12}>現住所　{props.applicantsList.address}</div>
          <div className={Styles.grid_1_13}>電話：{props.applicantsList.phoneNumber}　メール：{props.applicantsList.mailAddress}</div>

          <div className={Styles.grid_1_14}>年</div>
          <div className={Styles.grid_1_15}>月</div>
          <div className={Styles.grid_1_16}>学歴・職歴</div>
          <div className={Styles.grid_1_17} style={props.applicantsList.history1 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.historyYears1}
          </div>
          <div className={Styles.grid_1_18} style={props.applicantsList.history1 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.historyMonth1}
          </div>
          <div className={Styles.grid_1_19} style={props.applicantsList.history1 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.history1}
          </div>
          <div className={Styles.grid_1_20} style={props.applicantsList.history2 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.historyYears2}
          </div>
          <div className={Styles.grid_1_21} style={props.applicantsList.history2 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.historyMonth2}
          </div>
          <div className={Styles.grid_1_22} style={props.applicantsList.history2 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.history2}
          </div>
          <div className={Styles.grid_1_23} style={props.applicantsList.history3 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.historyYears3}
          </div>
          <div className={Styles.grid_1_24} style={props.applicantsList.history3 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.historyMonth3}
          </div>
          <div className={Styles.grid_1_25} style={props.applicantsList.history3 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.history3}
          </div>
          <div className={Styles.grid_1_26} style={props.applicantsList.history4 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.historyYears4}
          </div>
          <div className={Styles.grid_1_27} style={props.applicantsList.history4 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.historyMonth4}
          </div>
          <div className={Styles.grid_1_28} style={props.applicantsList.history4 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.history4}
          </div>
          <div className={Styles.grid_1_29} style={props.applicantsList.history5 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.historyYears5}
          </div>
          <div className={Styles.grid_1_30} style={props.applicantsList.history5 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.historyMonth5}
          </div>
          <div className={Styles.grid_1_31} style={props.applicantsList.history5 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.history5}
          </div>
          <div className={Styles.grid_1_32} style={props.applicantsList.history6 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.historyYears6}
          </div>
          <div className={Styles.grid_1_33} style={props.applicantsList.history6 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.historyMonth6}
          </div>
          <div className={Styles.grid_1_34} style={props.applicantsList.history6 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.history1 === '' ? '' : props.applicantsList.history6}
          </div>

          <div className={Styles.grid_1_35}></div>

          <div className={Styles.grid_1_36}>年</div>
          <div className={Styles.grid_1_37}>月</div>
          <div className={Styles.grid_1_38}>資格取得・受賞歴</div>
          <div className={Styles.grid_1_39} style={props.applicantsList.award1 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.award1 === '' ? '' : props.applicantsList.awardYears1} 
          </div>
          <div className={Styles.grid_1_40} style={props.applicantsList.award1 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.award1 === '' ? '' : props.applicantsList.awardMonth1} 
          </div>
          <div className={Styles.grid_1_41} style={props.applicantsList.award1 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.award1 === '' ? '' : props.applicantsList.award1} 
          </div>
          <div className={Styles.grid_1_42} style={props.applicantsList.award2 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.award2 === '' ? '' : props.applicantsList.awardYears2} 
          </div>
          <div className={Styles.grid_1_43} style={props.applicantsList.award2 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.award2 === '' ? '' : props.applicantsList.awardMonth2} 
          </div>
          <div className={Styles.grid_1_44} style={props.applicantsList.award2 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.award2 === '' ? '' : props.applicantsList.award2} 
          </div>
          <div className={Styles.grid_1_45} style={props.applicantsList.award3 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.award3 === '' ? '' : props.applicantsList.awardYears3} 
          </div>
          <div className={Styles.grid_1_46} style={props.applicantsList.award3 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.award3 === '' ? '' : props.applicantsList.awardMonth3} 
          </div>
          <div className={Styles.grid_1_47} style={props.applicantsList.award3 === '' ? {backgroundColor : '#cccccc'} : {}}>
            {props.applicantsList.award3 === '' ? '' : props.applicantsList.award3} 
          </div>
        </div>
      </div>

      <div className={Styles.box}>
        <div class={Styles.grid_2}>
          <div className={Styles.grid_2_1}>希望店舗</div>
          <div className={Styles.grid_2_2}>
            {1 <= props.applicantsList.hopeStore.length && props.applicantsList.hopeStore.length <= 5 ?
            <>
              1.{props.applicantsList.hopeStore[0]}
            </> : <></> }
            {2 <= props.applicantsList.hopeStore.length && props.applicantsList.hopeStore.length <= 5 ?
            <>
              、2.{props.applicantsList.hopeStore[1]}
            </> : <></> }
            {3 <= props.applicantsList.hopeStore.length && props.applicantsList.hopeStore.length <= 5 ?
            <>
              、3.{props.applicantsList.hopeStore[2]}
            </> : <></> }
            {4 <= props.applicantsList.hopeStore.length && props.applicantsList.hopeStore.length <= 5 ?
            <>
              、4.{props.applicantsList.hopeStore[3]}
            </> : <></> }
            {5 <= props.applicantsList.hopeStore.length && props.applicantsList.hopeStore.length <= 5 ?
            <>
              、5.{props.applicantsList.hopeStore[4]}
            </> : <></> }
          </div>
          <div className={Styles.grid_2_3}></div>
          <div className={Styles.grid_2_4}>媒体名</div>
          <div className={Styles.grid_2_5}>アカウント名</div>
          <div className={Styles.grid_2_6}>フォロワー数</div>
          <div className={Styles.grid_2_7}>Instagram</div>
          <div className={Styles.grid_2_8}>{props.applicantsList.instagram}</div>
          <div className={Styles.grid_2_9}>{props.applicantsList.instagramFollower}人</div>
          <div className={Styles.grid_2_10}>Tiktok</div>
          <div className={Styles.grid_2_11}>{props.applicantsList.tiktok}</div>
          <div className={Styles.grid_2_12}>{props.applicantsList.tiktokFollower}人</div>
          <div className={Styles.grid_2_13}>X（Twitter）</div>
          <div className={Styles.grid_2_14}>{props.applicantsList.twitter}</div>
          <div className={Styles.grid_2_15}>{props.applicantsList.twitterFollower}人</div>
          <div className={Styles.grid_2_16}></div>
          <div className={Styles.grid_2_17}>志望動機</div>
          <div className={Styles.grid_2_18}>{props.applicantsList.motivation}</div>
          <div className={Styles.grid_2_19}>自己PR</div>
          <div className={Styles.grid_2_20}>{props.applicantsList.prText}</div>
        </div>
      </div>
    </> : <></> }

    {props.recruitList.document_coordinate ?
      <div className={Styles.box}>
        <div class={Styles.grid_3}>
          <div className={Styles.grid_3_1}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/coordination%2Fresize_images%2F${props.applicantsList.recruitId}_${props.applicantsList.uid}_1_750x750?alt=media&token=`} alt='' />
          </div>
          <div className={Styles.grid_3_2}>{props.applicantsList.coordinate1Title}</div>
          <div className={Styles.grid_3_3}>{props.applicantsList.coordinate1Point}</div>
          <div className={Styles.grid_3_4}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/coordination%2Fresize_images%2F${props.applicantsList.recruitId}_${props.applicantsList.uid}_2_750x750?alt=media&token=`} alt='' />
          </div>
          <div className={Styles.grid_3_5}>{props.applicantsList.coordinate2Title}</div>
          <div className={Styles.grid_3_6}>{props.applicantsList.coordinate2Point}</div>
          <div className={Styles.grid_3_7}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/coordination%2Fresize_images%2F${props.applicantsList.recruitId}_${props.applicantsList.uid}_3_750x750?alt=media&token=`} alt='' />
          </div>
          <div className={Styles.grid_3_8}>{props.applicantsList.coordinate3Title}</div>
          <div className={Styles.grid_3_9}>{props.applicantsList.coordinate3Point}</div>
        </div>
      </div>
    : <></> }

    {props.recruitList.document_question ?
      <div className={Styles.box}>
        <div class={Styles.grid_4}>

          <div className={Styles.grid_4_1} 
            style={1 <= props.recruitList.document_questionCount && props.recruitList.document_questionCount <= 3 ? {} : {backgroundColor : '#cccccc'}}>
            {1 <= props.recruitList.document_questionCount && props.recruitList.document_questionCount <= 3 ? `${props.recruitList.document_question01}` : '' }
          </div>
          <div className={Styles.grid_4_2} 
            style={1 <= props.recruitList.document_questionCount && props.recruitList.document_questionCount <= 3 ? {} : {backgroundColor : '#cccccc'}}>
            {1 <= props.recruitList.document_questionCount && props.recruitList.document_questionCount <= 3 ? `${props.applicantsList.question01Text}` : '' }
          </div>

          <div className={Styles.grid_4_3} 
            style={2 <= props.recruitList.document_questionCount && props.recruitList.document_questionCount <= 3 ? {} : {backgroundColor : '#cccccc'}}>
            {2 <= props.recruitList.document_questionCount && props.recruitList.document_questionCount <= 3 ? `${props.recruitList.document_question02}` : '' }
          </div>
          <div className={Styles.grid_4_4} 
            style={2 <= props.recruitList.document_questionCount && props.recruitList.document_questionCount <= 3 ? {} : {backgroundColor : '#cccccc'}}>
            {2 <= props.recruitList.document_questionCount && props.recruitList.document_questionCount <= 3 ? `${props.applicantsList.question02Text}` : '' }
          </div>

          <div className={Styles.grid_4_5} 
            style={props.recruitList.document_questionCount === 3 ? {} : {backgroundColor : '#cccccc'}}>
            {props.recruitList.document_questionCount === 3 ? `${props.recruitList.document_question03}` : '' }
          </div>
          <div className={Styles.grid_4_6} 
            style={props.recruitList.document_questionCount === 3 ? {} : {backgroundColor : '#cccccc'}}>
            {props.recruitList.document_questionCount === 3 ? `${props.applicantsList.question03Text}` : '' }
          </div>

        </div>
      </div>
    : <></> }

    {props.recruitList.document_otherDocument ?
      <div className={Styles.box}>
        <div class={Styles.grid_5}>

          <div className={Styles.grid_5_1} 
            style={1 <= props.recruitList.document_otherDocumentCount && props.recruitList.document_otherDocumentCount <= 3 ? {} : {backgroundColor : '#cccccc'}}>
            {1 <= props.recruitList.document_otherDocumentCount && props.recruitList.document_otherDocumentCount <= 3 ?
              `${props.recruitList.document_otherDocument01}` : "" }
          </div>
          <div className={Styles.grid_5_2} 
            style={1 <= props.recruitList.document_otherDocumentCount && props.recruitList.document_otherDocumentCount <= 3 ? {} : {backgroundColor : '#cccccc'}}>
            {1 <= props.recruitList.document_otherDocumentCount && props.recruitList.document_otherDocumentCount <= 3 ?
              <a href={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/document%2F${props.applicantsList.recruitId}_${props.applicantsList.uid}_1?alt=media&token=`} target="_blank">プレビュー</a>
            : <></> }
          </div>

          <div className={Styles.grid_5_3} 
            style={2 <= props.recruitList.document_otherDocumentCount && props.recruitList.document_otherDocumentCount <= 3 ? {} : {backgroundColor : '#cccccc'}}>
            {2 <= props.recruitList.document_otherDocumentCount && props.recruitList.document_otherDocumentCount <= 3 ?
              `${props.recruitList.document_otherDocument02}` : "" }
          </div>
          <div className={Styles.grid_5_4} style={2 <= props.recruitList.document_otherDocumentCount && props.recruitList.document_otherDocumentCount <= 3 ? {} : {backgroundColor : '#cccccc'}}>
            {2 <= props.recruitList.document_otherDocumentCount && props.recruitList.document_otherDocumentCount <= 3 ?
              <a href={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/document%2F${props.applicantsList.recruitId}_${props.applicantsList.uid}_2?alt=media&token=`} target="_blank">プレビュー</a>
            : <></> }
          </div>

          <div className={Styles.grid_5_5} 
            style={props.recruitList.document_otherDocumentCount === 3 ? {} : {backgroundColor : '#cccccc'}}>
            {3 <= props.recruitList.document_otherDocumentCount && props.recruitList.document_otherDocumentCount <= 3 ?
              `${props.recruitList.document_otherDocument03}` : "" } 
          </div>
          <div className={Styles.grid_5_6} style={props.recruitList.document_otherDocumentCount === 3 ? {} : {backgroundColor : '#cccccc'}}>
            {props.recruitList.document_otherDocumentCount === 3 ?
              <a href={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/document%2F${props.applicantsList.recruitId}_${props.applicantsList.uid}_3?alt=media&token=`} target="_blank">プレビュー</a>
            : <></> }
          </div>

        </div>
      </div>
    : <></> }
    </>
  );
}

export default SalonTopic;



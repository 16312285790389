import React from 'react';
import './styles.css';

const DefaultInput = (props) => {

  return (
    <>
    <div className='memorii_recruit_body_edit_area_content_item_title'>
      <p>{props.title}</p>
      <div className={
        props.required ? 'memorii_recruit_body_edit_area_content_item_yes' : 'memorii_recruit_body_edit_area_content_item_no'
      }>
      {props.required ? '※必須' : '任意'}</div>
    </div>
    </>
  );
}

export default DefaultInput;

import React from 'react';
import Styles from './styles.module.css';
import { FaRegHandPointRight } from "react-icons/fa6";


const Footer = (props) => {

  return (
    <>
      <div className={Styles.point}>
        <div>
          <FaRegHandPointRight className={Styles.point_icon} />
          <h3>{props.name} の見どころはこの3つ！</h3>
        </div>
        <hr></hr>
        <ul>
          <li><span>{props.point1}</span></li>
          <li><span>{props.point2}</span></li>
          <li><span>{props.point3}</span></li>
        </ul>
      </div>
    </>
  );
}

export default Footer;


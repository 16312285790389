import Styles from './styles.module.css';
import React from 'react';
import Icon from './../../../../../image/icon_white.png';
import { useHistory } from 'react-router-dom';

const Footer = () => {

  const history = useHistory(); 

  return (
    <div className={Styles.box}>
      <div className={Styles.box_left}>
        <img src={Icon} alt='' />
        <h4>memorii work</h4>
      </div>
      <div className={Styles.box_right}>
        <h4 onClick={() => history.push('/student/login')}>ログイン</h4>
        <h4 onClick={() => history.push('/company')}>法人の方はこちら</h4>
        <h4 onClick={() => history.push('/contact')}>お問い合わせ</h4>
      </div>
    </div>    
  )
};

export default Footer;

 
import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react'
import firebase from 'firebase/compat/app';
import Resume from './resume/index';
import Photo from './photo/index';
import Manage from './manage/index';

const SalonTopic = (props) => {

  const [ resumeAppearSwich, setResumeAppearSwich ] = useState(1);

  const [ recruitList, setRecruitList ] = useState([]);
  const [ applicantsList, setApplicantsList ] = useState([]);

  useEffect(() => {

    if (!props.companyList.administrator.includes(props.userList.id)) {
      setResumeAppearSwich(2);
    }

    firebase.firestore().collection('recruits').doc(props.recruitId).get()
    .then((doc) => {
      setRecruitList({...doc.data(), id: doc.id});
    });

    firebase.firestore().collection('applicants').doc(props.applicantId).get()
    .then((doc) => {
      setApplicantsList({...doc.data(), id: doc.id});
    });

  }, [props.recruitId, props.applicantId]);

  return (
    <>
    <div className={Styles.box}>
      <div>
        <div className={Styles.box_black} onClick={() => { props.setRecruitId(''); props.setApplicantId(''); }}></div>
        <div className={Styles.box_info}>
          
          <div className={Styles.progress}>
            {props.companyList.administrator.includes(props.userList.id) ?
              <h4 style={resumeAppearSwich === 1 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
                onClick={() => setResumeAppearSwich(1)}>評価</h4>
            : <></> }
            <h4 style={resumeAppearSwich === 2 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
              onClick={() => setResumeAppearSwich(2)}>履歴書</h4>
            <h4 style={resumeAppearSwich === 3 ? {borderBottom : '3px solid #AD0200', color: '#222222'} : {}}
              onClick={() => setResumeAppearSwich(3)}>memorii photo</h4>
          </div>

          {resumeAppearSwich === 1 && recruitList.length !== 0  && applicantsList.length !== 0 && props.userList.length !== 0 && props.companyList.administrator.includes(props.userList.id) ?
            <Manage recruitList={recruitList} applicantsList={applicantsList} navigation={props.navigation} userList={props.userList}
              setHistory={props.setHistory} />
          : <></> }

          {resumeAppearSwich === 2 && recruitList.length !== 0  && applicantsList.length !== 0 && props.userList.length !== 0 ?
            <Resume recruitList={recruitList} applicantsList={applicantsList} navigation={props.navigation} userList={props.userList} />
          : <></> }

          {resumeAppearSwich === 3 && recruitList.length !== 0  && applicantsList.length !== 0 && props.userList.length !== 0 ?
            <Photo uid={applicantsList.uid} />
          : <></> }

        </div>
      </div>
    </div>
    </>
  );
}

export default SalonTopic;



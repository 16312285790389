import Styles from './styles.module.css';
import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import DefaultInput1 from './../../package/parts/1-input';

const WorksAccountEdit = (props) => {

  const [ name, setName ] = useState('');
  const [ mail, setMail ] = useState('');

  function check() {
    if (name !== '' && mail !== '') {
      invite();
    }
  }

  function invite() {
    firebase.firestore().collection('companies').doc(props.companyList.id)
    .update({
      invite: firebase.firestore.FieldValue.arrayUnion(mail)
    }).then(() => mails());
  }

  function mails() {
    
    firebase.firestore().collection('works-mails').doc()
    .set({
      'to': mail,
      'message': {
        'text': '',
        'subject': `${name}様 memorii worksに招待されました。`,
        'html': 
        `以下の手順に従って、アカウントの登録を完了してください。<br />
        <br />
        memorii works登録手順 <br />
        <br />
        ① 新規登録手順<br />
        以下のURLからアカウントの新規登録<br />
        URL：https://memorii-works.com/company/signin<br />
        <br />
        ② 登録完了手順<br />
        新規登録完了後にmemorii worksより、メールに届いたURLをクリック<br />
        <br />
        ③ ログイン手順<br />
        以下のURLからアカウントのログイン<br />
        URL：https://memorii-works.com/company/login`,
      },
    }).then(() => props.setInvite(false));
  }

  return (
    <>
    <div className={Styles.modal}>
      <div>
        <div className={Styles.modal_black} onClick={() => props.setInvite(false)}></div>
        <div className={Styles.modal_contents}>

          <div className={Styles.modal_contents_title}>
            <h4>メンバー招待</h4>
            <button onClick={() => check()}>招待</button>
          </div>

          <DefaultInput1
            title={'名前'} required={true} length={20} place={'メモリー 太郎'} type={'text'}
            value={name} setValue={setName}
            desc={'※招待者名を入力してください。'}
          />

          <DefaultInput1
            title={'メールアドレス'} required={true} length={40} place={'memorii-works@trankllc.com'} type={'text'}
            value={mail} setValue={setMail}
            desc={'※メールアドレスを入力してください。'}
          />

        </div>
      </div>
    </div>
    </>
  );
}

export default WorksAccountEdit;

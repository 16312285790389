import Styles from './styles.module.css';
import React, { useState } from 'react';
// import DefaultTitle from '../../package/parts/title';
import firebase from 'firebase/compat/app';
// import DefaultImage from './../../../../../image/works/default/account.png';
// import { FaPen } from "react-icons/fa";
import DefaultSelect1 from './../../package/parts/1-select';

const WorksAccountEdit = (props) => {

  const [ management, setManagement ] = useState('');

  function check() {
    if (management === '') {
      props.setManageId('')
    } else {
      invite();
    }
  }

  function invite() {

    if (management === '管理者') {
      firebase.firestore().collection('companies').doc(props.companyList.id)
      .update({
        administrator: firebase.firestore.FieldValue.arrayUnion(props.manageId),
      }).then(() => props.setManageId(''));
    } else if (management === '審査員') {
      firebase.firestore().collection('companies').doc(props.companyList.id)
      .update({
        administrator: firebase.firestore.FieldValue.arrayRemove(props.manageId),
      }).then(() => props.setManageId(''));
    }
  }

  return (
    <>
    <div className={Styles.box}>
      <div>
        <div className={Styles.box_black} onClick={() => props.setManageId('')}></div>
        <div className={Styles.contents}>

          <div className={Styles.title}>
            <h4>権限変更</h4>
            <button onClick={() => check()}>変更</button>
          </div>

          <DefaultSelect1
            title={'権限'} required={false} length={0} place={''} type={''}
            value={management} setValue={setManagement}
            list={props.check ? ['未選択', '管理者', ] : ['未選択', '管理者', '審査員', ]}
            desc={'※権限を入力してください。'}
          />

        </div>
      </div>
    </div>
    </>
  );
}

export default WorksAccountEdit;

import React, { useState, useEffect } from 'react';
import Styles from './styles.module.css';
import Header from "../../../service/2.recruit/2.edit/6.header/index";
import firebase from "firebase/compat/app";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useHistory } from 'react-router-dom';

const MemoriiWorksDane = () => {

  const history = useHistory();
  const [ companyName, setCompanyName ] = useState('');

  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.length));

  useEffect(() => {
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        getData();
      } else {
        history.push('/company/login')
      }
    })
  }, [link]);

  function getData() {
    window.scrollTo(0, 0);

    firebase.firestore().collection("companies").doc(company).get()
    .then((doc) => {
      setCompanyName(doc.data().name);
    });
  }

  return (
    <>
    <Header />
    
    <div className={Styles.box}>
      <div className={Styles.box_inline}>
        <h4>応募完了</h4>

        <p>{companyName}への応募が完了いたしました。<br />選考日程や連絡については、memorii photoを経由して担当者より連絡がありますので、連絡をお待ちください。</p>

        <a href='https://apps.apple.com/app/id1578278781'>
          <button>アプリ ダウンロード</button>
        </a>
        <a href='https://memorii-works.com/'><p>他の求人を確認する</p></a>
      </div>
    </div>
    </>
  );
}

export default MemoriiWorksDane;

import './styles.css';
import React from 'react';
import { isMobile } from "react-device-detect";
import NavPc from "./pc/index";
import NavMobile from "./mobile/index";

const MemoriiWorksNavigation = (props) => {

  return (
    <>
    {!isMobile ?
      <NavPc 
        userList={props.userList}
        companyList={props.companyList}
        profileSwitch={props.profileSwitch}
        setProfileSwitch={props.setProfileSwitch}
        companySwitch={props.companySwitch}
        setCompanySwitch={props.setCompanySwitch}
      />
    :
      <NavMobile
        userList={props.userList}
        companyList={props.companyList}
        profileSwitch={props.profileSwitch}
        setProfileSwitch={props.setProfileSwitch}
        companySwitch={props.companySwitch}
        setCompanySwitch={props.setCompanySwitch}
      />
    }
    </>
  );
}

export default MemoriiWorksNavigation;

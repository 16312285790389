import Styles from './styles.module.css';
import Items from './items/index';
import firebase from 'firebase/compat/app';
import { useState, useEffect } from 'react';
import { FaSearch } from "react-icons/fa";
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import Navigation from "./nav/index";
import History from "./../../package/info/pc/history/index";

const MemoriiWorksManage = (props) => {

  const [ history, setHistory ] = useState(false);

  const [ choiceRecruit, setChoiceRecruit ] = useState(0);

  const [ applicantsList, setApplicantsList ] = useState([]);
  const [ applicantsListTrue, setApplicantsListTrue ] = useState([]);
  const [ applicantsListFalse, setApplicantsListFalse ] = useState([]);
  const [ progressCount, setProgressCount ] = useState([]);

  const [ searchText, setSearchText ] = useState('');
  const [ choiceNavigation, setChoiceNavigation ] = useState(10000);

  const [ selectStore, setSelectStore ] = useState('');

  useEffect(() => {
    getRecruit();
    setChoiceRecruit(1000);
  }, []);

  function getRecruit() {
    if (props.recruitList.length !== 0) {
      // setRecruitPage(props.recruitList);
    }
    if (props.recruitListOnlyId.length !== 0) {
      getApplicants(props.recruitListOnlyId);
    }
  }

  function getApplicants(ids) {
    const docRef = collection(firebase.firestore(), 'applicants')
    const queryRef = query(docRef, where('recruitId', 'in', ids))
    const unsub = onSnapshot(queryRef, snapshot => {
      var progressCount0 = 0;
      var progressCount1 = 0;
      var progressCount2 = 0;
      var progressCount3 = 0;
      var progressCount4 = 0;
      var progressCount5 = 0;
      var progressCount6 = 0;
      var progressCount7 = 0;

      var tmp = [];
      var tmpTrue = [];
      var tmpFalse = [];
      snapshot.docs.forEach(doc => {
        progressCount0 = progressCount0 + 1;
        if (doc.data().examination === 1) {
          progressCount1 = progressCount1 + 1
        } else if (doc.data().examination === 2) {
          progressCount2 = progressCount2 + 1
        } else if (doc.data().examination === 3) {
          progressCount3 = progressCount3 + 1
        } else if (doc.data().examination === 4) {
          progressCount4 = progressCount4 + 1
        } else if (doc.data().examination === 5) {
          progressCount5 = progressCount5 + 1
        } else if (doc.data().examination === 6) {
          progressCount6 = progressCount6 + 1
        } else if (doc.data().examination === 7) {
          progressCount7 = progressCount7 + 1
        }
        
        tmp.push({...doc.data(), id: doc.id});
        if (doc.data().save.includes(props.userList.user_uid)) {
          tmpTrue.push({...doc.data(), id: doc.id});
        } else {
          tmpFalse.push({...doc.data(), id: doc.id});
        }
      })
      setApplicantsListTrue(tmpTrue);
      setApplicantsListFalse(tmpFalse);
      setProgressCount([
        progressCount0, progressCount1, progressCount2, progressCount3, progressCount4, progressCount5, progressCount6, progressCount7, 
      ]);
    })

    return () => unsub();
  }

  const isAllIncludes = (arr, target) => arr.some(el => target.includes(el));

  return (
    <>
    <div className='works_service'>

      {props.recruitList.length !== 0 ?
        <Navigation 
          // 未選択 or not
          unSelect={choiceRecruit === 1000}
          // 選考評価数
          selection={choiceRecruit === 1000 ? 0 : Number(props.recruitList[choiceRecruit].exam_selection)}
          // 各選考ごとのデータ数
          count={progressCount}
          // 選考フロー選択
          selectFlow={choiceNavigation} setSelectFlow={setChoiceNavigation}
          // 希望店舗リスト
          storeList={choiceRecruit === 1000 ? [] : props.recruitList[choiceRecruit]}
          // 希望店舗
          selectStore={selectStore} setSelectStore={setSelectStore}          
        />
      : <></> }

      <div className={Styles.manage}>
        <div className={Styles.manage_header}>
          <div className={Styles.manage_header_word}>
            <select 
              onChange={(e) => {
                if (e.target.value === '')  {
                  setChoiceRecruit(1000); getApplicants(props.recruitListOnlyId);
                } else {
                  setChoiceRecruit(Number(e.target.value)); getApplicants([props.recruitList[Number(e.target.value)].id]);
                }
              }}>
              <option value=''>全ての求人</option>
              {props.recruitList.map((data, index) =>
                <option value={index} key={index}>{data.store_title}</option>
              )}
            </select>
            <div>
              <FaSearch className={Styles.manage_header_word_icon} />
              <input placeholder='応募者名 検索' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            </div>
          </div>
          <div className={Styles.manage_table}>
            <p className={Styles.manage_table_1}>応募者名</p>
            <p className={Styles.manage_table_2}>性別</p>
            <p className={Styles.manage_table_3}>応募日時</p>
            <p className={Styles.manage_table_5}>希望店舗</p>
            <p className={Styles.manage_table_4}>メモ</p>
            <p className={Styles.manage_table_6}>審査</p>
            <p className={Styles.manage_table_6}>保存</p>
            {props.companyList.administrator.includes(props.userList.id) ?
              <p className={Styles.manage_table_7}>連絡</p>
            : <></> }
          </div>
        </div>

        {applicantsListTrue.map((data, index) =>
        <>
          {data.examination === choiceNavigation && isAllIncludes([searchText], data.name + data.managerMemo) ?
          <>
            {selectStore === '' ?
              <Items data={data} save={true} companyList={props.companyList} userList={props.userList} navigation={choiceNavigation}
                setApplicantsList={setApplicantsList} setHistory={setHistory} />
            : data.hopeStore !== undefined && selectStore === data.hopeStore[0] ?
              <Items data={data} save={true} companyList={props.companyList} userList={props.userList} navigation={choiceNavigation}
                setApplicantsList={setApplicantsList} setHistory={setHistory} />
            : <></> }
          </> : <></> }

          {choiceNavigation === 10000 && isAllIncludes([searchText], data.name + data.managerMemo) ?
          <>
            {selectStore === '' ?
              <Items data={data} save={true} companyList={props.companyList} userList={props.userList} navigation={choiceNavigation}
                setApplicantsList={setApplicantsList} setHistory={setHistory} />
            : data.hopeStore !== undefined && selectStore === data.hopeStore[0] ?
              <Items data={data} save={true} companyList={props.companyList} userList={props.userList} navigation={choiceNavigation}
                setApplicantsList={setApplicantsList} setHistory={setHistory} />
            : <></> }
          </> : <></> }
        </> )}
        
        {applicantsListFalse.map((data, index) =>
        <>
          {data.examination === choiceNavigation && isAllIncludes([searchText], data.name + data.managerMemo) ?
          <>
            {selectStore === '' ?
              <Items data={data} save={false} companyList={props.companyList} userList={props.userList} navigation={choiceNavigation} 
                setApplicantsList={setApplicantsList} setHistory={setHistory} />
              : data.hopeStore !== undefined && selectStore === data.hopeStore[0] ?
              <Items data={data} save={false} companyList={props.companyList} userList={props.userList} navigation={choiceNavigation} 
                setApplicantsList={setApplicantsList} setHistory={setHistory} />
            : <></> }
          </> : <></> }

          {choiceNavigation === 10000 && isAllIncludes([searchText], data.name + data.managerMemo) ?
          <>
            {selectStore === '' ?
              <Items data={data} save={false} companyList={props.companyList} userList={props.userList} navigation={choiceNavigation} 
                setApplicantsList={setApplicantsList} setHistory={setHistory} />
            : data.hopeStore !== undefined && selectStore === data.hopeStore[0] ?
              <Items data={data} save={false} companyList={props.companyList} userList={props.userList} navigation={choiceNavigation} 
                setApplicantsList={setApplicantsList} setHistory={setHistory} />
            : <></> }
          </> : <></> }
        </> )}
      </div>
    </div>

    {history && props.recruitList.length !== 0 && applicantsList.length !== 0 ?
      <History recruitList={props.recruitList} applicantsList={applicantsList} setHistory={setHistory} />
    : <></> }
    </>
  );
}

export default MemoriiWorksManage;

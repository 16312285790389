import authStyles from './styles.module.css';
import inputStyles from './input.module.css';
import firebase from 'firebase/compat/app';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../header/index';
import { FaEye } from "react-icons/fa";
import Modal from '../../../service/package/parts/pop-modal/index';
import ErrorMessage from './../../../service/package/parts/error';

const WorksLogin = () => {
  
  const history = useHistory();

  const [ errorMessage, setErrorMessage ] = useState('');

  const [ errorAuth, setErrorAuth ] = useState(false);
  const [ errorId, setErrorId ] = useState(false);

  const [ worksId, setWorksId ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');

  const [ passwordEye, setPasswordEye ] = useState(false);

  useEffect(() => {
    // firebase.auth().onAuthStateChanged((user) => {
    //   if (user) {
    //     if (user.auth.currentUser.emailVerified) {
    //       companyAuth();
    //     } else {
    //       history.push('/company/confirm');
    //     }
    //   }
    // });
  }, [history]);

  function check() {
    setErrorAuth(false);
    setErrorId(false);

    if (worksId === '') {
      setErrorMessage('会員IDを入力してください。');
    } else if (email <= 7) {
      setErrorMessage('メールアドレスを入力してください。');
    } else if (password.length <= 7) {
      setErrorMessage('パスワードを7文字以上入力してください。');
    } else {
      Register();
    }
  }

  const Register = async () => {
    await firebase.auth().signInWithEmailAndPassword(email, password)
      .then(() => 
        success(email)
      ).catch((error) => {
        setErrorAuth(true);
      });
  };

  function success(email) {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (user.auth.currentUser.emailVerified) {
          companyAuth(user.uid, email);
        } else {
          history.push('/company/confirm');
        }
      }
    });
  }

  function companyAuth(uid, email) {
    firebase.firestore().collection('companies').where('member_id', '==', worksId).get()
    .then(snapShot => {
      snapShot.forEach((docs) => {
        if (docs.exists) {
          
          // メールアドレス削除
          if (docs.data().invite.includes(email)) {
            firebase.firestore().collection('companies').doc(docs.id)
            .update({ 
              invite: firebase.firestore.FieldValue.arrayRemove(email),
              member: firebase.firestore.FieldValue.arrayUnion(uid),
            });
          }

          // アカウント有無
          if (docs.data().member.includes(uid) || docs.data().invite.includes(email)) {
            console.log(2);
            firebase.firestore().collection('users').doc(uid).get()
            .then((doc) => {
              if (doc.exists) {
                history.push(`/service/id=${docs.id}+page=1`);
              } else {
                firebase.firestore().collection('users').doc(uid)
                .set({
                  user_name: '',
                  user_like_count : 0,
                  user_award : 0,
                  user_likes : [],
                  user_text : '',
                  user_uid : uid,
                  user_createdAt : new Date(),
                  user_scout : false,
                  user_address : [],
                  user_address_postalCode: [],
                  user_badge: 0,
                  user_business: "美容師",
                  user_recruit: false,
                  user_token: "",
                  user_connection : [],
                  user_connection_count : 0,
                  user_connection_permission : [],
                  user_connection_request : [],
                  user_likes_list : [],
                  user_login : '',
                  user_login_count : [],
                  user_notification : [],
                  user_notification_confiram : false,
                  user_post_like_count : 0,
                  user_post_view_count : 0,
                  user_views : '',
                  user_post_count : 0,
                  user_recruit_save: [],
                  user_instagram : '',
                  user_tiktok : '',
                  memorii_score : 0,
                }).then(() => {
                  history.push(`/service/id=${docs.id}+page=1`);
                });
              }
            });
          } else if (!docs.data().member.includes(uid) && !docs.data().invite.includes(email)) {
            console.log(3);
            setErrorId(true);
          }
        } else {
          setErrorId(true);
        }
      });
    });
  }

  return (
    <>
    <Header />
    <div className={authStyles.background}>

      {errorMessage !== '' ?
        <ErrorMessage message={errorMessage} />
      : <></> }

      <div className={authStyles.contents}>

        <h2 className={authStyles.contents_title}>ログイン</h2>

        <p className={inputStyles.input_title}>会員ID</p>
        <div className={inputStyles.input_input}>
          <input placeholder='nk12q3ja' value={worksId} onChange={(e) => setWorksId(e.target.value)} />
        </div>
        
        <p className={inputStyles.input_title}>メールアドレス</p>
        <div className={inputStyles.input_input}>
          <input placeholder='memorii@trankllc.com' value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        
        <p className={inputStyles.input_title}>パスワード</p>
        <div className={inputStyles.input_input}>
          <input placeholder='Memorii*' type={passwordEye ? '' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} />
          <FaEye className={inputStyles.input_icon} onClick={() => setPasswordEye(!passwordEye)} />
        </div>

        <button className={authStyles.contents_btn} onClick={() => check()}>ログインする</button>

        <p className={authStyles.contents_link} onClick={() => history.push('/company/signin')}>
          アカウントをお持ちではない方はこちら
        </p>
        <p className={authStyles.contents_link} onClick={() => history.push('/company/reset')}>
          パスワードをお忘れの方はこちら
        </p>

      </div>
    </div>

    {errorAuth ?
      <Modal 
        title={'ログイン エラー'}
        desc={'メールアドレス、もしくはパスワードが間違っています。\nまた、アカウント登録がお済みではない方は「アカウントをお持ちではない方はこちら」より、アカウントを作成してください。'}
        setError={setErrorAuth}
      />
    : <></> }
    
    {errorId ?
      <Modal 
        title={'認証 エラー'}
        desc={'メールアドレス、パスワードの内容と会員IDが一致していません。ログインを再度試す、もしくは弊社までお問い合わせください。'}
        setError={setErrorId}
      />
    : <></> }
    </>
  );
}

export default WorksLogin;
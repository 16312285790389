import Styles from './styles.module.css';
import 'firebase/auth'
import React from 'react-router-dom';
import { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import { useHistory } from 'react-router-dom';
import ErrorMessage from '../../../../package/parts/error/index';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import BackModal from '../11.back/index';
import Header from '../../../2.edit/6.header/index';
import DefaultTitle from '../../../../package/parts/title/index';
import Image1 from './../../../../../../image/works/salonType/1.png';
import Image2 from './../../../../../../image/works/salonType/2.png';
import Image3 from './../../../../../../image/works/salonType/3.png';
import Image4 from './../../../../../../image/works/salonType/4.png';
import Image5 from './../../../../../../image/works/salonType/5.png';
import Image6 from './../../../../../../image/works/salonType/6.png';
import Image7 from './../../../../../../image/works/salonType/7.png';
import Image8 from './../../../../../../image/works/salonType/8.png';

const MemoriiWorksEditRecruit = (props) => {
  
  const history = useHistory();

  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var store = decodeURIComponent(link.slice(link.indexOf('storeId=') + 8, link.length));

  const [ errorMessage, setErrorMessage ] = useState('');

  const [ region, setRegion ] = useState('');
  const [ strengths, setStrengths ] = useState('');
  const [ treatment, setTreatment ] = useState('');

  const [ backStatus, setBackStatus ] = useState(false);

  const [ statusPentagon, setStatusPentagon ] = useState(false);
  const [ statusPlace, setStatusPlace ] = useState(false);
  // const [ statusType, setStatusType ] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {

        firebase.firestore().collection("stores").doc(store)
        .get().then((doc) => {
          setRegion(doc.data().region === undefined ? false : doc.data().region);
          setStrengths(doc.data().strengths === undefined ? false : doc.data().strengths);
          setTreatment(doc.data().treatment === undefined ? false : doc.data().treatment);

          setStatusPentagon(doc.data().status_pentagon === undefined ? false : doc.data().status_pentagon);
          setStatusPlace(doc.data().status_place === undefined ? false : doc.data().status_place);
        });

      } else {
        history.push('/company/login')
      }
    })

  }, [history, store])

  function check() {
    window.scrollTo(0, 0);
    save(true);
  }

  function save(e) {
    firebase.firestore().collection("stores").doc(store)
    .update({
      status: statusPlace && statusPentagon && e,
      status_type: e,

      region: region,
      strengths: strengths,
      treatment: treatment,

      type: 
        region & strengths & treatment ? '有名サロン' :  
        region & strengths & !treatment ? 'トレンドサロン' :
        region & !strengths & treatment ? 'おしゃれサロン' :
        region & !strengths & !treatment ? 'ビューティーサロン' :
        !region & strengths & treatment ? 'オールラウンドサロン' :
        !region & strengths & !treatment ? '愛されサロン' :
        !region & !strengths & treatment ? '密着サロン' :
        !region & !strengths & !treatment ? 'こだわりサロン' :
        '',

    }).then(() => {
      history.push(`/edit/storeSetting/id=${company}+page=2`);
    });
  }

  return (
    <>

    <Header />

    <div className={Styles.box}>
      <div className={Styles.box_inline}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.contents}>

          <h5 className={Styles.title}>サロンタイプ診断</h5>
          
          <DefaultTitle title={'立地'} required={true} />

          <div className={Styles.radio_btn}>
            <div>
              <input type="radio" name='1' checked={region === true ? 'checked' : ''} onClick={() => setRegion(true)} />
              <p>都会型サロン</p>
            </div>
            <div>
              <input type="radio" name='1' checked={region === false ? 'checked' : ''} onClick={() => setRegion(false)} />
              <p>地域型サロン</p>
            </div>
          </div>
          
          <DefaultTitle title={'強み'} required={true} />

          <div className={Styles.radio_btn}>
            <div>
              <input type="radio" name='2' checked={strengths === true ? 'checked' : ''} onClick={() => setStrengths(true)} />
              <p>集客力</p>
            </div>
            <div>
              <input type="radio" name='2' checked={strengths === false ? 'checked' : ''} onClick={() => setStrengths(false)} />
              <p>技術力</p>
            </div>
          </div>
          
          <DefaultTitle title={'施術幅'} required={true} />

          <div className={Styles.radio_btn}>
            <div>
              <input type="radio" name='3' checked={treatment === true ? 'checked' : ''} onClick={() => setTreatment(true)} />
              <p>オールマイティー</p>
            </div>
            <div>
              <input type="radio" name='3' checked={treatment === false ? 'checked' : ''} onClick={() => setTreatment(false)} />
              <p>専門的</p>
            </div>
          </div>

        </div>

        <div className={Styles.contents}>

          <h5 className={Styles.title}>サロンタイプ</h5>
          
          {
            region & strengths & treatment ? <img src={Image1} alt='' /> :
            region & strengths & !treatment ? <img src={Image2} alt='' /> :
            region & !strengths & treatment ? <img src={Image3} alt='' /> :
            region & !strengths & !treatment ? <img src={Image4} alt='' /> :
            !region & strengths & treatment ? <img src={Image5} alt='' /> :
            !region & strengths & !treatment ? <img src={Image6} alt='' /> :
            !region & !strengths & treatment ? <img src={Image7} alt='' /> :
            !region & !strengths & !treatment ? <img src={Image8} alt='' /> : <></>
          }

        </div>

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check()}>保存</button>
        </div>
        
      </div>
    </div>

    {backStatus ?
      <BackModal
        companyId={company}
        recruitId={store}
        setBackStatus={setBackStatus}
      />
    : <></> }
    </>
  );
}

export default MemoriiWorksEditRecruit;
import Styles from './styles.module.css';
import React from 'react'
import { useHistory } from 'react-router-dom';

const WorksEdidBack = (props) => {

  const history = useHistory();

  function Delete() {
    history.push(`/edit/id=${props.companyId}+page=2+recruitId=${props.recruitId}`)
  }

  return (
    <>
    <div className={Styles.modal}>
      <div>
        <div className={Styles.modal_black} onClick={() => props.setBackStatus(false)}></div>
        <div className={Styles.modal_contents}>
          <h4>保存せずに戻りますか？</h4>
          <p>
            保存せずに画面を終了した場合、編集記録は残りませんので今一度ご確認ください。<br />
          </p>
          <div>
            <button style={{backgroundColor : 'white', color : '#AD0200'}} onClick={() => props.setBackStatus(false)}>キャンセル</button>
            <button style={{backgroundColor : '#AD0200', color : 'white'}} onClick={() => Delete()}>戻る</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default WorksEdidBack;



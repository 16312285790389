import Styles from './styles.module.css';
import 'firebase/auth'

const MemoriiWorksManage = (props) => {

  var image = 'https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F' + props.image + '_200x200?alt=media&token=';

  return (
    <>
    <div className={Styles.box}>
      <img src={image} alt='' />
      {1 <= props.count && props.count <= 8 ?
        <p>{props.result1 === 4 ? '◎' : props.result1 === 3 ? '○' : props.result1 === 2 ? '△' : '×'}</p>
      : <></> }
      {2 <= props.count && props.count <= 8 ?
        <p>{props.result2 === 4 ? '◎' : props.result2 === 3 ? '○' : props.result2 === 2 ? '△' : '×'}</p>
      : <></> }
      {3 <= props.count && props.count <= 8 ?
        <p>{props.result3 === 4 ? '◎' : props.result3 === 3 ? '○' : props.result3 === 2 ? '△' : '×'}</p>
      : <></> }
      {4 <= props.count && props.count <= 8 ?
        <p>{props.result4 === 4 ? '◎' : props.result4 === 3 ? '○' : props.result4 === 2 ? '△' : '×'}</p>
      : <></> }
      {5 <= props.count && props.count <= 8 ?
        <p>{props.result5 === 4 ? '◎' : props.result5 === 3 ? '○' : props.result5 === 2 ? '△' : '×'}</p>
      : <></> }
      {6 <= props.count && props.count <= 8 ?
        <p>{props.result6 === 4 ? '◎' : props.result6 === 3 ? '○' : props.result6 === 2 ? '△' : '×'}</p>
      : <></> }
      {7 <= props.count && props.count <= 8 ?
        <p>{props.result7 === 4 ? '◎' : props.result7 === 3 ? '○' : props.result7 === 2 ? '△' : '×'}</p>
      : <></> }
      {8 <= props.count && props.count <= 8 ?
        <p>{props.result8 === 4 ? '◎' : props.result8 === 3 ? '○' : props.result8 === 2 ? '△' : '×'}</p>
      : <></> }
    </div>
    </>
  );
}

export default MemoriiWorksManage;

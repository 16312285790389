import Styles from './styles.module.css';
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Help = (props) => {

  const settings = {
    dots: false,
    arrows: true,
    fade: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 4000,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
    <Slider {...settings}  className={Styles.slider}>
      {props.imageName.length >= 1 ?
        <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${props.imageName[0]}_1080x1080?alt=media&token=?${new Date().getTime()}`} alt='' />
      : <></> }
      {props.imageName.length >= 2 ?
        <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${props.imageName[1]}_1080x1080?alt=media&token=?${new Date().getTime()}`} alt='' />
      : <></> }
      {props.imageName.length >= 3 ?
        <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${props.imageName[2]}_1080x1080?alt=media&token=?${new Date().getTime()}`} alt='' />
      : <></> }
      {props.imageName.length >= 4 ?
        <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${props.imageName[3]}_1080x1080?alt=media&token=?${new Date().getTime()}`} alt='' />
      : <></> }
      {props.imageName.length >= 5 ?
        <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${props.imageName[4]}_1080x1080?alt=media&token=?${new Date().getTime()}`} alt='' />
      : <></> }
    </Slider>
    </>
  );

}

export default Help;
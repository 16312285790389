import Styles from './styles.module.css';
import firebase from 'firebase/compat/app';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const MemoPanel = (props) => {

  const history = useHistory();

  const [ memo, setMemo ] = useState('');

  console.log(props.memo[2])

  useEffect(() => {
    setMemo(props.memo[2]);
  }, []);

  function save() {
    firebase.firestore().collection('applicants').doc(props.memo[0])
    .update({
      managerMemo: memo,
    }).then(() => props.setMemo([]));
  }

  return (
    <>
    <div className={Styles.box}>
      <div>
        <div className={Styles.box_black} onClick={() => save()}></div>
        <div className={Styles.box_contents}>
          <h4>メモ： {props.memo[1]}</h4>
          <textarea value={memo} onChange={(e) => setMemo(e.target.value)} />
          <div>
            <button style={{backgroundColor : 'white', color : '#AD0200'}} onClick={() => props.setMemo([])}>キャンセル</button>
            <button style={{backgroundColor : '#AD0200', color : 'white'}} onClick={() => save()}>保存</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default MemoPanel;

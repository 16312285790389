import Styles from './styles.module.css';
import React from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../6.header/index';
import firebase from "firebase/compat/app"
import ErrorMessage from '../../../../package/parts/error';
import DefaultInput1 from '../../../../package/parts/1-input';
import BackModal from './../../11.back/index';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const EditStore = () => {

  const history = useHistory();

  // リンク
  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var recruit = decodeURIComponent(link.slice(link.indexOf('recruitId=') + 10, link.length));

  var time = new Date();

  const [ errorMessage, setErrorMessage ] = useState('');
  
  // 哲学
  const [ point1Title, setPoint1Title ] = useState('');
  const [ point2Title, setPoint2Title ] = useState('');
  const [ point3Title, setPoint3Title ] = useState('');

  // 戻るボタン
  const [ backStatus, setBackStatus ] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        if (company !== '' && recruit !== '') {
          firebase.firestore().collection("recruits").doc(recruit).get()
          .then((doc) => {
            setPoint1Title(doc.data().recruit_point1Title === undefined ? '' : doc.data().recruit_point1Title);
            setPoint2Title(doc.data().recruit_point2Title === undefined ? '' : doc.data().recruit_point2Title);
            setPoint3Title(doc.data().recruit_point3Title === undefined ? '' : doc.data().recruit_point3Title);
          });
        }
      } else {
        history.push('/company/login')
      }
    })
  }, [history, company, recruit]);

  function check() {
    window.scrollTo(0, 0);
    if (point1Title === '') {
      setErrorMessage('No.1 魅力のタイトルを入力してください。');
    } else if (point2Title === '') {
      setErrorMessage('No.2 魅力のタイトルを入力してください。');
    } else if (point3Title === '') {
      setErrorMessage('No.3 魅力のタイトルを入力してください。');
    } else {
      save(true);
    }
  }

  async function save(e) {

    firebase.firestore().collection("recruits").doc(recruit)
    .update({
      status_point: e,

      recruit_point1Title: point1Title,
      recruit_point2Title: point2Title,
      recruit_point3Title: point3Title,

      last_time: new Date(),
    }).then(() => {
      history.push(`/edit/id=${company}+page=2+recruitId=${recruit}`);
    })
  }
  
  return (
    <>
    <Header />

    <div className={Styles.store}>
      <div className={Styles.store_box}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>会社の魅力3選</h5>

          <DefaultInput1
            title={'No.1 魅力'} required={true} length={40} place={''} type={'text'}
            value={point1Title} setValue={setPoint1Title}
            desc={''}
          />

          <DefaultInput1
            title={'No.2 魅力'} required={true} length={40} place={''} type={'text'}
            value={point2Title} setValue={setPoint2Title}
            desc={''}
          />

          <DefaultInput1
            title={'No.3 魅力'} required={true} length={40} place={''} type={'text'}
            value={point3Title} setValue={setPoint3Title}
            desc={''}
          />
          
        </div>

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check()}>保存</button>
        </div>

      </div>
    </div>

    {backStatus ?
      <BackModal
        companyId={company}
        recruitId={recruit}
        setBackStatus={setBackStatus}
      />
    : <></> }

    </>
  );
}

export default EditStore;

import './styles.css';
import React from 'react';
import { IoDocumentTextOutline } from "react-icons/io5";
import What1 from './../../../../image/works/landing/what_1.png';
import What2 from './../../../../image/works/landing/what_2.png';
import What3 from './../../../../image/works/landing/what_3.png';
import What4 from './../../../../image/works/landing/what_4.png';
import What5 from './../../../../image/works/landing/what_5.png';
import What6 from './../../../../image/works/landing/what_6.png';

const MemoriiWorksLanding = () => {

  return (
    <>
    <div className='works_landhing_what'>
      <h4>他にも、こんな機能が盛りだくさん</h4>
      <p>求人応募から採用までを一括管理できるサービスですが、実際には以下のような機能が備わっており、<br />美容業界に特有の課題に対応した機能がたくさんあります。</p>
      <div>
        <div>
          <div className='works_landhing_what_bar'></div>
          <div className='works_landhing_what_title'>
            <IoDocumentTextOutline className='works_landhing_what_icon' style={{color: '#279A30'}} />
            <h4 style={{color: '#279A30'}}>履歴書の生成・保管</h4>
          </div>
          <p>求人応募時に記載した情報を履歴書に変換し、保管・PDFでの書きも対応してます。</p>
          <img src={What1} alt='' />
        </div>
        <div>
          <div className='works_landhing_what_bar'></div>
          <div className='works_landhing_what_title'>
            <IoDocumentTextOutline className='works_landhing_what_icon' style={{color: '#FFD04F'}} />
            <h4 style={{color: '#FFD04F'}}>店舗情報/求人管理</h4>
          </div>
          <p>求人を管理する事ができ、求人ごとに応募書類や評価項目を設定する事ができます。</p>
          <img src={What2} alt='' />
        </div>
        <div>
          <div className='works_landhing_what_bar'></div>
          <div className='works_landhing_what_title'>
            <IoDocumentTextOutline className='works_landhing_what_icon' style={{color: '#2E81B0'}} />
            <h4 style={{color: '#2E81B0'}}>作品・写真管理</h4>
          </div>
          <p>美容室のスタイルや技術力を写真を通じて、美容学生に伝える事ができます。</p>
          <img src={What3} alt='' />
        </div>
        <div>
          <div className='works_landhing_what_bar'></div>
          <div className='works_landhing_what_title'>
            <IoDocumentTextOutline className='works_landhing_what_icon' style={{color: '#CC435A'}} />
            <h4 style={{color: '#CC435A'}}>応募者管理</h4>
          </div>
          <p>各求人・選考ごとに応募者を確認する事ができ、履歴書や選考評価を行うことできます。</p>
          <img src={What4} alt='' />
        </div>
        <div>
          <div className='works_landhing_what_bar'></div>
          <div className='works_landhing_what_title'>
            <IoDocumentTextOutline className='works_landhing_what_icon' style={{color: '#342F88'}} />
            <h4 style={{color: '#342F88'}}>スケジュール</h4>
          </div>
          <p>選考スケジュールを確認出来る画面で、応募者や社内での日程調整に活用できます。</p>
          <img src={What5} alt='' />
        </div>
        <div>
          <div className='works_landhing_what_bar'></div>
          <div className='works_landhing_what_title'>
            <IoDocumentTextOutline className='works_landhing_what_icon' style={{color: '#E94B1A'}} />
            <h4 style={{color: '#E94B1A'}}>メッセージ</h4>
          </div>
          <p>応募者とのやり取りを行えるほか、調整した日程やメモ等を画面横に書き込めます。</p>
          <img src={What6} alt='' />
        </div>
      </div>
    </div>
    </>
  );
}

export default MemoriiWorksLanding;

import Styles from './../styles.module.css';
import React from 'react';
import firebase from "firebase/compat/app";
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';

const Footer = (props) => {

  const history = useHistory();

  function before() {
    history.push(`/recruit/id=${props.recruitList.id}`);
  }

  function next() {
    if (props.recruitList.document_document) {
      if (props.recruitList.document_resume) {
        props.setProgress(2);
      } else if (props.recruitList.document_question) {
        props.setProgress(3);
      } else if (props.recruitList.document_coordinate) {
        props.setProgress(4);
      } else if (props.recruitList.document_otherDocument) {
        props.setProgress(5);
      }
    } else {
      sendDocNone();
    }
  }
  
  function sendDocNone() {
    
    var docId = uuid();

    firebase.firestore().collection('applicants').doc(docId)
    .set({
      recruitId: props.recruitList.id,
      uid: props.uid,
      companyId: props.companyList.id,

      // 証明写真
      name: '',
      nameHurigana: '',
      birthdayYears: '',
      birthdayMonth: '',
      birthdayDays: '',
      age: '',
      sex: '',
      bloodType: '',
      postCode: '',
      address: '',
      addressHurigana: '',
      phoneNumber: '',
      mailAddress: '',
      instagram: '',
      instagramFollower: 0,
      twitter: '',
      twitterFollower: 0,
      tiktok: '',
      tiktokFollower: 0,

      history1: '',
      historyYears1: '',
      historyMonth1: '',
      history2: '',
      historyYears2: '',
      historyMonth2: '',
      history3: '',
      historyYears3: '',
      historyMonth3: '',
      history4: '',
      historyYears4: '',
      historyMonth4: '',
      history5: '',
      historyYears5: '',
      historyMonth5: '',
      history6: '',
      historyYears6: '',
      historyMonth6: '',

      award1: '',
      awardYears1: '',
      awardMonth1: '',
      award2: '',
      awardYears2: '',
      awardMonth2: '',
      award3: '',
      awardYears3: '',
      awardMonth3: '',
      award4: '',
      awardYears4: '',
      awardMonth4: '',
      
      // 希望店舗
      hopeStore: '',
      motivation: '',
      prText: '',
      
      // 質問集
      question01Text: '',
      question02Text: '',
      question03Text: '',
      question04Text: '',
      question05Text: '',
      
      // コーディネート
      coordinate1Point: '',
      coordinate1Title: '',
      coordinate2Point: '',
      coordinate2Title: '',
      coordinate3Point: '',
      coordinate3Title: '',
      
      // 評価スケジュール
      examination: 1,
      examination1_schedule: [ new Date( 2024, 1, 1, ), new Date( 2024, 1, 1, ) ],
      examination2_schedule: [ new Date( 2024, 1, 1, ), new Date( 2024, 1, 1, ) ],
      examination3_schedule: [ new Date( 2024, 1, 1, ), new Date( 2024, 1, 1, ) ],
      examination4_schedule: [ new Date( 2024, 1, 1, ), new Date( 2024, 1, 1, ) ],
      examination5_schedule: [ new Date( 2024, 1, 1, ), new Date( 2024, 1, 1, ) ],

      memo: '',
      managerMemo: '',
      time: new Date(),
      last: new Date(),
      lastMessage: '',

      save: [],
    });

    var badge1 = {};
    var key1 = `${props.uid}_badge`;
    badge1[key1] = 0;

    var badge2 = {};
    var key2 = `${props.companyList.id}_badge`;
    badge2[key2] = 0;

    var badge3 = {};
    var key3 = props.uid;
    badge3[key3] = [ 'props.name', props.userList.id ];

    var badge4 = {};
    var key4 = props.companyList.id;
    badge4[key4] = [ props.companyList.name, props.companyList.id ];

    firebase.firestore().collection('chat').doc(docId)
    .set({
      chat_name: [ props.uid, props.recruitList.id ],
      chat_last: '',
      chat_time: new Date(),
      chat_id: docId,
      applicantId: docId,
      lsat_send: props.recruitList.id,
      companyId: props.companyList.id,
      recruitId: props.recruitList.id,
      ...badge1,
      ...badge2,
      ...badge3,
      ...badge4,
    }).then(() => {
      firebase.firestore().collection('chat').doc(docId).collection('message').doc()
      .set({
        id: docId,
        message_receive: props.uid,
        message_send: props.companyList.id,
        message_text: `この度は、${props.companyList.name}にご応募くださり、ありがとうございます。\n担当者より、ご連絡いたしますので、今後ともよろしくお願いします。`,
        message_time: new Date(),
      });
    });
    
    firebase.firestore().collection('recruits').doc(props.recruitList.id)
    .update({
      data_applicants: firebase.firestore.FieldValue.arrayUnion(props.uid)
    }).then(() => {
      history.push(`/done/id=${props.companyList.id}`);
    });
  }

  return (
    <>
    <div className={Styles.box}>
      <div className={Styles.box_inline}>
        <h5 className={Styles.title}>応募確認画面</h5>

        <table className={Styles.table}>
          <tr>
            <th style={{width : '100px'}}><strong><p>店舗名</p></strong></th>
            <td><p>{props.companyList.name}{props.recruitList.store_name}</p></td>
          </tr>
          <tr>
            <th style={{width : '100px'}}><strong><p>募集職種</p></strong></th>
            <td><p>{props.recruitList.recruit_employmentStatus}</p></td>
          </tr>
        </table>
      </div>

      <div className={Styles.save}>
        <button className={Styles.save_back} onClick={() => before()}>戻る</button>
        <button className={Styles.save_save} onClick={() => next()}>次へ</button>
      </div>

    </div>
    </>
  );
}

export default Footer;

import Styles from './styles.module.css';
import mbti1 from './../../../../../image/works/mbti/1.png';

const Footer = (props) => {

  return (
    <>
    <div className={Styles.person}>

      <h5>求める人物像</h5>

      {/* <div className={Styles.person_mbti}>
        <img src={mbti1} />
        <div>
          <h4>建築家 INTJ-A / INTJ-T</h4>
          <p>提唱者（INFJ型）は最もまれな性格タイプですが自身が立ち上がり、ものごとを改善したいと感じます。お金や地位を得ることを成功とは考えていず、人助けをしたり、世の中にポジティブな変化をもたらしたり、達成感を得たりすることを成功と考えるタイプです。</p>
        </div>
      </div> */}

      <div className={Styles.person_bar} style={{backgroundColor : '#e8f9f5'}}>
        <h4>行動指針</h4>
        <div>
          <div className={Styles.bar_status_title}>
            <h5>まずは考える</h5>
            <h5>とにかく行動</h5>
          </div>
          
          <div className={Styles.bar_status_bar}>
            <div style={{backgroundColor : props.bar1 === 1 ? '#448374' : '#b9e6db'}}></div>
            <div style={{backgroundColor : props.bar1 === 2 ? '#448374' : '#b9e6db'}}></div>
            <div style={{backgroundColor : props.bar1 === 3 ? '#448374' : '#b9e6db'}}></div>
            <div style={{backgroundColor : props.bar1 === 4 ? '#448374' : '#b9e6db'}}></div>
            <div style={{backgroundColor : props.bar1 === 5 ? '#448374' : '#b9e6db'}}></div>
          </div>
        </div>
      </div>

      <div className={Styles.person_bar} style={{backgroundColor : '#fff5ec'}}>
        <h4>重視する項目</h4>
        <div className={Styles.bar_status}>
          <div className={Styles.bar_status_title}>
            <h5>再現性</h5>
            <h5>創造性</h5>
          </div>
          <div className={Styles.bar_status_bar}>
            <div style={{backgroundColor : props.bar2 === 1 ? '#FFA452' : '#FEDDAC'}}></div>
            <div style={{backgroundColor : props.bar2 === 2 ? '#FFA452' : '#FEDDAC'}}></div>
            <div style={{backgroundColor : props.bar2 === 3 ? '#FFA452' : '#FEDDAC'}}></div>
            <div style={{backgroundColor : props.bar2 === 4 ? '#FFA452' : '#FEDDAC'}}></div>
            <div style={{backgroundColor : props.bar2 === 5 ? '#FFA452' : '#FEDDAC'}}></div>
          </div>
        </div>
      </div>

      <div className={Styles.person_bar} style={{backgroundColor : '#e8faff'}}>
        <h4>アピールポイント</h4>
        <div className={Styles.bar_status}>
          <div className={Styles.bar_status_title}>
            <h5>コミュニーケーション</h5>
            <h5>技術</h5>
          </div>
          <div className={Styles.bar_status_bar}>
            <div style={{backgroundColor : props.bar3 === 1 ? '#39C8ED' : '#C6EEFF'}}></div>
            <div style={{backgroundColor : props.bar3 === 2 ? '#39C8ED' : '#C6EEFF'}}></div>
            <div style={{backgroundColor : props.bar3 === 3 ? '#39C8ED' : '#C6EEFF'}}></div>
            <div style={{backgroundColor : props.bar3 === 4 ? '#39C8ED' : '#C6EEFF'}}></div>
            <div style={{backgroundColor : props.bar3 === 5 ? '#39C8ED' : '#C6EEFF'}}></div>
          </div>
        </div>
      </div>

      <div className={Styles.person_bar} style={{backgroundColor : '#ffedef'}}>
        <h4>施術スタイル</h4>
        <div className={Styles.bar_status}>
          <div className={Styles.bar_status_title}>
            <h5>オールマイティー</h5>
            <h5>専門性</h5>
          </div>
          <div className={Styles.bar_status_bar}>
            <div style={{backgroundColor : props.bar4 === 1 ? '#FE6970' : '#FFD8DA'}}></div>
            <div style={{backgroundColor : props.bar4 === 2 ? '#FE6970' : '#FFD8DA'}}></div>
            <div style={{backgroundColor : props.bar4 === 3 ? '#FE6970' : '#FFD8DA'}}></div>
            <div style={{backgroundColor : props.bar4 === 4 ? '#FE6970' : '#FFD8DA'}}></div>
            <div style={{backgroundColor : props.bar4 === 5 ? '#FE6970' : '#FFD8DA'}}></div>
          </div>
        </div>
      </div>

      <div className={Styles.person_bar} style={{backgroundColor : '#f6f1ff'}}>
        <h4>キャリアプラン</h4>
        <div className={Styles.bar_status}>
          <div className={Styles.bar_status_title}>
            <h5>チームリーダー</h5>
            <h5>トッププレイヤー</h5>
          </div>
          <div className={Styles.bar_status_bar}>
            <div style={{backgroundColor : props.bar5 === 1 ? '#7F5EBB' : '#DED6ED'}}></div>
            <div style={{backgroundColor : props.bar5 === 2 ? '#7F5EBB' : '#DED6ED'}}></div>
            <div style={{backgroundColor : props.bar5 === 3 ? '#7F5EBB' : '#DED6ED'}}></div>
            <div style={{backgroundColor : props.bar5 === 4 ? '#7F5EBB' : '#DED6ED'}}></div>
            <div style={{backgroundColor : props.bar5 === 5 ? '#7F5EBB' : '#DED6ED'}}></div>
          </div>
        </div>
      </div>

    </div>
    </>
  );
}

export default Footer;

import Styles from './../styles.module.css';
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import ErrorMessage from '../../../service/package/parts/error/index';
import DefaultTitle from '../../../service/package/parts/title/index';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import DefaultInput1 from '../../../service/package/parts/1-input';
import DefaultTextarea1 from '../../../service/package/parts/1-textarea';

const WorksSiteCoordinate = (props) => {

  const [ errorMessage, setErrorMessage ] = useState('');

  function before() {
    if (props.recruitList.document_question) {
      props.setProgress(3);
    } else if (props.recruitList.document_resume) {
      props.setProgress(2);
    } else {
      props.setProgress(1);
    }
  }

  function next() {
    if (props.recruitList.document_otherDocument) {
      props.setProgress(5);
    } else {
      props.setProgress(6);
    }
  }

  const Coordinate1Image = e => {
    if (e.target.files[0]) {
      props.setCoordinate1File(URL.createObjectURL(e.target.files[0]));
      firebase.storage().ref().child(`/coordination/${props.recruitList.id}_${props.uid}_1`).put(e.target.files[0]);
    }
  }

  const Coordinate2Image = e => {
    if (e.target.files[0]) {
      props.setCoordinate2File(URL.createObjectURL(e.target.files[0]));
      firebase.storage().ref().child(`coordination/${props.recruitList.id}_${props.uid}_2`).put(e.target.files[0]);
    }
  }

  const Coordinate3Image = e => {
    if (e.target.files[0]) {
      props.setCoordinate3File(URL.createObjectURL(e.target.files[0]));
      firebase.storage().ref().child(`coordination/${props.recruitList.id}_${props.uid}_3`).put(e.target.files[0]);
    }
  }

  function checkCoordinate() {

    window.scrollTo(0, 0);

    if (props.coordinate1File === '') {
      setErrorMessage('コーディネート①の写真を添付してください。');
    } else if (props.coordinate1Title === '') {
      setErrorMessage('コーディネート①のタイトルを入力してください。');
    } else if (props.coordinate1Point === '') {
      setErrorMessage('コーディネート①のポイントを入力してください。');
    } else if (props.coordinate2File === '') {
      setErrorMessage('コーディネート②の写真を添付してください。');
    } else if (props.coordinate2Title === '') {
      setErrorMessage('コーディネート②のタイトルを入力してください。');
    } else if (props.coordinate2Point === '') {
      setErrorMessage('コーディネート②のポイントを入力してください。');
    } else if (props.coordinate3File === '') {
      setErrorMessage('コーディネート③の写真を添付してください。');
    } else if (props.coordinate3Title === '') {
      setErrorMessage('コーディネート③のタイトルを入力してください。');
    } else if (props.coordinate3Point === '') {
      setErrorMessage('コーディネート③のポイントを入力してください。');
    } else {
      next();
    }
  }

  return (
    <>
    <div className={Styles.box}>

      {errorMessage !== '' ?
        <ErrorMessage message={errorMessage} />
      : <></> }

      <div className={Styles.box_inline}>
        <h5 className={Styles.title}>写真添付</h5>

        <DefaultTitle title={'①コーディネート：写真'} required={true} />
        <div className={Styles.idPhoto}>
          {props.coordinate1File === '' ?
            <div className={Styles.idPhoto_input}>
              <p>コーディネートを添付する</p>
              <input type='file' onChange={Coordinate1Image} alt='' />
            </div> :
            <div className={Styles.idPhoto_image}>
              <p>コーディネートを変更する</p>
              <img src={props.coordinate1File} alt='' />
              <input type='file' onChange={Coordinate1Image} alt='' />
            </div>
          }
          <div className={Styles.idPhoto_image}>
            <img src='https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/coordination%2Fresize_images%2Fsample1_500x500.webp?alt=media&token=' alt='' />
          </div>
        </div>
        <p className='memorii_recruit_body_page_company_edit_input_area_desc'>　</p>

        <DefaultInput1
          title={'①コーディネート：タイトル'} required={true} length={20} place={'春の思い出'} type={'text'}
          value={props.coordinate1Title} setValue={props.setCoordinate1Title}
          desc={'　'}
        />

        <DefaultTextarea1
          title={'①コーディネート：ポイント'} required={true} length={150} type={'text'}
          place={'自分が幼いころ着ていた服をリメイクし、着用しました。ピンク色の記事が目立っていたので、イエローカラーをメイクのベースにすることで春の季節感を表現しました。'}
          value={props.coordinate1Point} setValue={props.setCoordinate1Point}
          desc={'　'}
        />

        <DefaultTitle title={'②コーディネート：写真'} required={true} />
        <div className={Styles.idPhoto}>
          {props.coordinate2File === '' ?
            <div className={Styles.idPhoto_input}>
              <p>コーディネートを添付する</p>
              <input type='file' onChange={Coordinate2Image} alt='' />
            </div> :
            <div className={Styles.idPhoto_image}>
              <p>コーディネートを変更する</p>
              <img src={props.coordinate2File} alt='' />
              <input type='file' onChange={Coordinate2Image} alt='' />
            </div>
          }
          <div className={Styles.idPhoto_image}>
            <img src='https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/coordination%2Fresize_images%2Fsample1_500x500.webp?alt=media&token=' alt='' />
          </div>
        </div>
        <p className='memorii_recruit_body_page_company_edit_input_area_desc'>　</p>

        <DefaultInput1
          title={'②コーディネート：タイトル'} required={true} length={20} place={'陰'} type={'text'}
          value={props.coordinate2Title} setValue={props.setCoordinate2Title}
          desc={'　'}
        />

        <DefaultTextarea1
          title={'②コーディネート：ポイント'} required={true} length={150} type={'text'}
          place={'自分の顔だちを最大限生かせるよう、前髪を重くセットすることで憂いを帯びた雰囲気にしました。また、グレーのアウターと黒のマフラーを着用することで陰影の中にメリハリを持たせました。'}
          value={props.coordinate2Point} setValue={props.setCoordinate2Point}
          desc={'　'}
        />

        <DefaultTitle title={'③コーディネート：写真'} required={true} />
        <div className={Styles.idPhoto}>
          {props.coordinate3File === '' ?
            <div className={Styles.idPhoto_input}>
              <p>コーディネートを添付する</p>
              <input type='file' onChange={Coordinate3Image} />
            </div> :
            <div className={Styles.idPhoto_image}>
              <p>コーディネートを変更する</p>
              <img src={props.coordinate3File} alt='' />
              <input type='file' onChange={Coordinate3Image} />
            </div>
          }
          <div className={Styles.idPhoto_image}>
            <img src='https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/coordination%2Fresize_images%2Fsample1_500x500.webp?alt=media&token=' alt='' />
          </div>
        </div>
        <p className='memorii_recruit_body_page_company_edit_input_area_desc'>　</p>

        <DefaultInput1
          title={'③コーディネート：タイトル'} required={true} length={20} place={"90's　Tokyo"} type={'text'}
          value={props.coordinate3Title} setValue={props.setCoordinate3Title}
          desc={'　'}
        />

        <DefaultTextarea1
          title={'③コーディネート：ポイント'} required={true} length={150} type={'text'}
          place={'ワイドデニムやキャップを活用し、90年代に流行したスケーターカルチャーを表現しました。藍染のポシェットを小物にすることで、日本の服装だと表現しています。'}
          value={props.coordinate3Point} setValue={props.setCoordinate3Point}
          desc={'　'}
        />

      </div>

      <div className={Styles.save}>
        <button className={Styles.save_back} onClick={() => before()}>戻る</button>
        <button className={Styles.save_save} onClick={() => checkCoordinate()}>次へ</button>
      </div>  

    </div>
    </>
  );
}

export default WorksSiteCoordinate;

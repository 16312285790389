import Styles from './styles.module.css';
import React from 'react';

const Footer = (props) => {

  var 北海道 = [
    'JR札沼線',
    'JR千歳線',
    'JR函館本線',
    'JR室蘭本線',
    'JR石勝線',
    'JR日高本線',
    'JR留萌本線',
    'JR根室本線',
    'JR富良野線',
    'JR宗谷本線',
    'JR石北本線',
    'JR釧網本線',
    '北海道新幹線',
    '地下鉄東西線',
    '地下鉄東豊線',
    '地下鉄南北線',
    '函館市電湯川線',
    '函館市電宝来谷地頭線',
    '札幌市電',
    '道南いさりび鉄道',
  ]

  var 青森県 = [
    'JR奥羽本線',
    'JR八戸線',
    'JR五能線',
    'JR大湊線',
    'JR津軽線',
    '東北新幹線',
    '北海道新幹線',
  ]

  var 宮城県 = [
    'JR常磐線',
    'JR東北本線',
    'JR仙石線',
    'JR仙山線',
    'JR陸羽東線',
    'JR石巻線',
    'JR大船渡線',
    'JR気仙沼線',
    '東北新幹線',
    '仙台市地下鉄南北線',
    '仙台市地下鉄東西線',
    '阿武隈急行',
    '仙台空港鉄道',
  ]

  var 岩手県 = [
    'JR東北本線',
    'JR八戸線',
    'JR田沢湖線',
    'JR釜石線',
    'JR山田線',
    'JR北上線',
    'JR花輪線',
    'JR大船渡線',
    '東北新幹線',
    '秋田新幹線',
    'いわて銀河鉄道線',
    '三陸鉄道リアス線',
  ]

  var 秋田県 = [
    'JR奥羽本線',
    'JR羽越本線',
    'JR五能線',
    'JR田沢湖線',
    'JR北上線',
    'JR花輪線',
    'JR男鹿線',
    '秋田新幹線',
    '秋田内陸縦貫鉄道',
    '由利高原鉄道',
  ]
  
  var 山形県 = [
    'JR奥羽本線',
    'JR仙山線',
    'JR羽越本線',
    'JR左沢線',
    'JR陸羽東線',
    'JR陸羽西線',
    'JR米坂線',
    '山形新幹線',
    '山形鉄道フラワー長井線',
  ]

  var 福島県 = [
    'JR常磐線',
    'JR東北本線',
    'JR奥羽本線',
    'JR水郡線',
    'JR磐越西線',
    'JR磐越東線',
    'JR只見線',
    '東北新幹線',
    '山形新幹線',
    '野岩鉄道',
    '阿武隈急行',
    '福島交通飯坂線',
    '会津鉄道',
  ]

  var 東京都 = [
    'JR山手線',
    'JR京浜東北線',
    'JR東海道本線',
    'JR常磐線',
    'JR南武線',
    'JR横浜線',
    'JR横須賀線',
    'JR中央線',
    'JR青梅線',
    'JR五日市線',
    'JR武蔵野線',
    'JR八高線',
    'JR埼京線',
    'JR高崎線',
    'JR宇都宮線',
    'JR総武線',
    'JR総武線快速',
    'JR京葉線',
    '湘南新宿ライン宇須',
    '湘南新宿ライン高海',
    '東海道新幹線',
    '上越新幹線',
    '北陸新幹線',
    '東北新幹線',
    '東京メトロ銀座線',
    '東京メトロ丸ノ内線',
    '東京メトロ日比谷線',
    '東京メトロ東西線',
    '東京メトロ千代田線',
    '東京メトロ有楽町線',
    '東京メトロ半蔵門線',
    '東京メトロ南北線',
    '東京メトロ副都心線',
    '西武有楽町線',
    '西武新宿線',
    '西武池袋線',
    '西武拝島線',
    '西武山口線',
    '西武国分寺線',
    '西武多摩川線',
    '西武多摩湖線',
    '西武西武園線',
    '西武豊島線',
    '東武東上線',
    '東武伊勢崎線',
    '東武亀戸線',
    '東武大師線',
    '東急東横線',
    '東急田園都市線',
    '東急池上線',
    '東急目黒線',
    '東急多摩川線',
    '東急大井町線',
    '東急世田谷線',
    '都営浅草線',
    '都営三田線',
    '都営新宿線',
    '都営大江戸線',
    '都電荒川線',
    '日暮里・舎人ライナー',
    '京王井の頭線',
    '京王競馬場線',
    '京王新線',
    '京王線',
    '京王動物園線',
    '京王相模原線',
    '京王高尾線',
    '京成押上線',
    '京成金町線',
    '京成本線',
    '京急本線',
    '京急空港線',
    '小田急線',
    '小田急多摩線',
    '多摩都市モノレール',
    'りんかい線',
    '北総線',
    '東京モノレール',
    '新交通ゆりかもめ',
    '埼玉高速鉄道',
    'つくばエクスプレス',
    '成田スカイアクセス',
  ]

  var 神奈川県 = [
    '東急大井町線',
    '東急東横線',
    '東急田園都市線',
    '東急こどもの国線',
    '東急目黒線',
    '東急新横浜線',
    'JR横須賀線',
    'JR京浜東北線',
    'JR根岸線',
    'JR鶴見線',
    'JR東海道本線',
    'JR御殿場線',
    'JR南武線',
    'JR横浜線',
    'JR相模線',
    'JR中央線',
    '湘南新宿ライン宇須',
    '湘南新宿ライン高海',
    '東海道新幹線',
    'JR埼京線',
    '京王相模原線',
    '京急本線',
    '京急大師線',
    '京急逗子線',
    '京急久里浜線',
    '小田急線',
    '小田急小田原線',
    '小田急多摩線',
    '小田急江ノ島線',
    '相鉄本線',
    '相鉄いずみ野線',
    '相鉄新横浜線',
    'ブルーライン',
    'グリーンライン',
    'みなとみらい線',
    'シーサイドライン',
    '江ノ島電鉄線',
    '湘南モノレール',
    '箱根登山鉄道',
    '箱根登山鉄道鋼索線',
    '伊豆箱根大雄山線',
  ]

  var 埼玉県 = [
    'JR京浜東北線',
    'JR埼京線',
    'JR川越線',
    'JR高崎線',
    'JR宇都宮線',
    'JR武蔵野線',
    'JR八高線',
    '湘南新宿ライン宇須',
    '湘南新宿ライン高海',
    '上越新幹線',
    '北陸新幹線',
    '東北新幹線',
    '東京メトロ有楽町線',
    '東京メトロ副都心線',
    '西武新宿線',
    '西武池袋線',
    '西武狭山線',
    '西武山口線',
    '西武秩父線',
    '東武東上線',
    '東武越生線',
    '東武伊勢崎線',
    '東武日光線',
    '東武野田線',
    '埼玉高速鉄道',
    'つくばエクスプレス',
    '埼玉新都市交通伊奈線',
    '秩父鉄道',
  ]

  var 千葉県 = [
    'JR総武線',
    'JR常磐線',
    'JR成田線',
    'JR武蔵野線',
    'JR総武線快速',
    'JR総武本線',
    'JR京葉線',
    'JR鹿島線',
    'JR外房線',
    'JR東金線',
    'JR内房線',
    'JR久留里線',
    '東京メトロ東西線',
    '都営新宿線',
    '京成本線',
    '京成千葉線',
    '京成千原線',
    '新京成線',
    '東武野田線',
    '北総線',
    'つくばエクスプレス',
    '千葉都市モノレール',
    '流鉄流山線',
    '東葉高速鉄道',
    'ユーカリが丘線',
    '小湊鉄道線',
    'いすみ鉄道',
    '銚子電気鉄道',
    '芝山鉄道',
    '成田スカイアクセス',
  ]

  var 茨城県 = [
    'JR宇都宮線',
    'JR常磐線',
    'JR鹿島線',
    'JR水郡線',
    'JR水戸線',
    '湘南新宿ライン宇須',
    'つくばエクスプレス',
    '関東鉄道竜ヶ崎線',
    'ひたちなか海浜鉄道',
    '真岡鉄道',
    '鹿島臨海鉄道',
  ]

  var 栃木県 = [
    'JR宇都宮線',
    'JR東北本線',
    'JR烏山線',
    'JR日光線',
    'JR水戸線',
    'JR両毛線',
    '湘南新宿ライン宇須',
    '東北新幹線',
    '東武伊勢崎線',
    '東武佐野線',
    '東武日光線',
    '東武宇都宮線',
    '東武鬼怒川線',
    '宇都宮芳賀ライトレール線',
    '真岡鉄道',
    'わたらせ渓谷鉄道',
    '野岩鉄道',
  ]

  var 群馬県 = [
    'JR高崎線',
    'JR八高線',
    'JR上越線',
    'JR吾妻線',
    'JR両毛線',
    'JR信越本線',
    '湘南新宿ライン高海',
    '上越新幹線',
    '北陸新幹線',
    '東武伊勢崎線',
    '東武小泉線',
    '東武佐野線',
    '東武桐生線',
    '東武日光線',
    'わたらせ渓谷鉄道',
    '上毛電鉄',
    '上信電鉄',
  ]

  var 新潟県 = [
    'JR上越線',
    'JR白新線',
    'JR信越本線',
    'JR飯山線',
    'JR越後線',
    'JR弥彦線',
    'JR大糸線',
    'JR磐越西線',
    'JR只見線',
    'JR米坂線',
    'JR羽越本線',
    '上越新幹線',
    '北陸新幹線',
    '北越急行ほくほく線',
    'しなの鉄道北しなの線',
    '妙高はねうまライン',
    '日本海ひすいライン',
    'あいの風とやま鉄道',
  ]

  var 富山県 = [
    'JR高山本線',
    'JR城端線',
    'JR氷見線',
    '北陸新幹線',
    '富山地鉄本線',
    '富山地鉄立山線',
    '富山地鉄不二越上滝',
    '富山地鉄富山市内線',
    '富山地鉄富山都心線',
    '富山地鉄富山港線',
    '万葉線',
    '黒部峡谷鉄道',
    'あいの風とやま鉄道',
  ]

  var 石川県 = [
    'JR北陸本線',
    'JR七尾線',
    '北陸新幹線',
    '北陸鉄道石川線',
    '北陸鉄道浅野川線',
    'のと鉄道・七尾線',
    'ＩＲいしかわ鉄道',
    'あいの風とやま鉄道',
  ]

  var 福井県 = [
    'JR北陸本線',
    'JR小浜線',
    'JR越美北線',
    '福井鉄道福武線',
    'えちぜん鉄道三国線',
    'えちぜん鉄道勝山線',
  ]

  var 山梨県 = [
    'JR中央線',
    'JR身延線',
    'JR小海線',
    '富士急行',
  ]

  var 長野県 = [
    'JR中央線',
    'JR篠ノ井線',
    'JR信越本線',
    'JR飯山線',
    'JR飯田線',
    'JR小海線',
    'JR大糸線',
    '北陸新幹線',
    '長野電鉄長野線',
    'しなの鉄道',
    'しなの鉄道北しなの線',
    '松本電気鉄道',
    '上田電鉄別所線',
  ]

  var 愛知県 = [
    'JR東海道本線',
    'JR関西本線',
    'JR中央本線',
    'JR飯田線',
    'JR武豊線',
    '東海道新幹線',
    '近鉄名古屋線',
    '地下鉄東山線',
    '地下鉄鶴舞線',
    '地下鉄名城線',
    '地下鉄桜通線',
    '地下鉄名港線',
    '地下鉄上飯田線',
    '名鉄名古屋本線',
    '名鉄常滑線',
    '名鉄河和線',
    '名鉄知多新線',
    '名鉄三河線',
    '名鉄西尾線',
    '名鉄蒲郡線',
    '名鉄豊川線',
    '名鉄豊田線',
    '名鉄瀬戸線',
    '名鉄犬山線',
    '名鉄小牧線',
    '名鉄広見線',
    '名鉄津島線',
    '名鉄尾西線',
    '名鉄築港線',
    '名鉄空港線',
    '愛知環状鉄道',
    '東海交通事業城北線',
    '豊橋鉄道東田本線',
    '豊橋鉄道渥美線',
    'リニモ',
    'あおなみ線',
    'ゆとりーとライン',
  ]

  var 岐阜県 = [
    'JR東海道本線',
    'JR中央本線',
    'JR高山本線',
    'JR太多線',
    '東海道新幹線',
    '名鉄名古屋本線',
    '名鉄犬山線',
    '名鉄各務原線',
    '名鉄広見線',
    '名鉄竹鼻線',
    '名鉄羽島線',
    '樽見鉄道',
    '長良川鉄道',
    '明知鉄道',
    '養老鉄道養老線',
  ]

  var 静岡県 = [
    'JR東海道本線',
    'JR東海道本線',
    'JR御殿場線',
    'JR身延線',
    'JR飯田線',
    'JR伊東線',
    '東海道新幹線',
    '伊豆箱根鉄道駿豆線',
    '伊豆急行',
    '遠州鉄道',
    '静岡鉄道静岡清水線',
    '天竜浜名湖鉄道',
    '岳南電車',
    '大井川鐵道本線',
    '大井川鐵道井川線',
  ]

  var 三重県 = [
    'JR草津線',
    'JR関西本線',
    'JR紀勢本線',
    'JR名松線',
    'JR参宮線',
    '近鉄大阪線',
    '近鉄名古屋線',
    '近鉄山田線',
    '近鉄鳥羽線',
    '近鉄湯の山線',
    '近鉄鈴鹿線',
    '近鉄志摩線',
    '伊勢鉄道',
    '三岐鉄道北勢線',
    '三岐鉄道三岐線',
    '伊賀鉄道伊賀線',
    '養老鉄道養老線',
    '四日市あすなろう鉄道',
  ]

  var 大阪府 = [
    'JR大阪環状線',
    'JR東海道本線',
    'JR片町線',
    'JR桜島線',
    'JR関西本線',
    'JR阪和線',
    'JR関西空港線',
    'JR福知山線',
    'JR東西線',
    'JRおおさか東線',
    '東海道新幹線',
    '近鉄けいはんな線',
    '近鉄奈良線',
    '近鉄難波線',
    '近鉄大阪線',
    '近鉄信貴線',
    '近鉄南大阪線',
    '近鉄長野線',
    '近鉄道明寺線',
    '近鉄西信貴ケーブル',
    '地下鉄御堂筋線',
    '地下鉄谷町線',
    '地下鉄四つ橋線',
    '南港ポートタウン線',
    '地下鉄中央線',
    '地下鉄千日前線',
    '地下鉄堺筋線',
    '地下鉄長堀鶴見緑地線',
    '地下鉄今里筋線',
    '北大阪急行',
    '南海本線',
    '南海空港線',
    '南海高師浜線',
    '南海多奈川線',
    '南海高野線',
    '南海汐見橋線',
    '泉北高速鉄道',
    '阪急神戸線',
    '阪急宝塚線',
    '阪急箕面線',
    '阪急千里線',
    '阪急京都線',
    '大阪モノレール本線',
    '大阪モノレール彩都線',
    '京阪本線',
    '京阪交野線',
    '京阪中之島線',
    '阪堺電気軌道上町線',
    '阪堺電気軌道阪堺線',
    '阪神本線',
    '阪神電鉄阪神なんば線',
    '能勢電鉄妙見線',
    '水間鉄道',
  ]

  var 兵庫県 = [
    'JR東海道本線',
    'JR山陽本線',
    'JR赤穂線',
    'JR和田岬線',
    'JR加古川線',
    'JR福知山線',
    'JR山陰本線',
    'JR播但線',
    'JR姫新線',
    'JR東西線',
    '山陽新幹線',
    '阪急神戸線',
    '阪急伊丹線',
    '阪急今津線',
    '阪急甲陽線',
    '阪急宝塚線',
    '阪神本線',
    '阪神電鉄阪神なんば線',
    '阪神武庫川線',
    '能勢電鉄妙見線',
    '能勢電鉄日生線',
    '神戸電鉄有馬線',
    '神戸電鉄三田線',
    '神戸電鉄公園都市線',
    '神戸電鉄粟生線',
    'ポートアイランド線',
    '六甲アイランド線',
    '地下鉄西神・山手線',
    '地下鉄海岸線',
    '地下鉄北神線',
    '山陽電鉄本線',
    '山陽電鉄網干線',
    '神戸高速鉄道東西線',
    '神戸高速鉄道南北線',
    '智頭急行',
    '北条鉄道北条線',
    '京都丹後鉄道宮豊線',
  ]

  var 京都府 = [
    'JR東海道本線',
    'JR片町線',
    'JR湖西線',
    'JR奈良線',
    'JR関西本線',
    'JR福知山線',
    'JR山陰本線',
    'JR舞鶴線',
    'JR小浜線',
    '東海道新幹線',
    '近鉄京都線',
    '阪急京都線',
    '阪急嵐山線',
    '京阪鴨東線',
    '京阪本線',
    '京阪宇治線',
    '京阪京津線',
    '京阪男山ケーブル',
    '京福電鉄嵐山本線',
    '京福電鉄北野線',
    '地下鉄烏丸線',
    '地下鉄東西線',
    '叡山電鉄叡山本線',
    '叡山電鉄鞍馬線',
    '嵯峨野観光鉄道',
    '京都丹後鉄道宮福線',
    '京都丹後鉄道宮舞線',
    '京都丹後鉄道宮豊線',
  ]

  var 滋賀県 = [
    'JR東海道本線',
    'JR北陸本線',
    'JR湖西線',
    'JR草津線',
    '東海道新幹',
    '京阪石山坂本線',
    '京阪京津',
    '近江鉄道八日市線',
    '近江鉄道本線',
    '近江鉄道多賀',
    '信楽高原鐵',
  ]

  var 奈良県 = [
    'JR奈良線',
    'JR関西本線',
    'JR桜井線',
    'JR和歌山線',
    '近鉄生駒鋼索線',
    '近鉄けいはんな線',
    '近鉄奈良線',
    '近鉄生駒線',
    '近鉄大阪線',
    '近鉄南大阪線',
    '近鉄京都線',
    '近鉄橿原線',
    '近鉄天理線',
    '近鉄田原本線',
    '近鉄御所線',
    '近鉄吉野線',
  ]

  var 和歌山県 = [
    'JR阪和線',
    'JR和歌山線',
    'JR紀勢本線',
    '南海本線',
    '南海加太線',
    '南海和歌山港線',
    '南海高野線',
    '南海高野山ケーブル',
    'わかやま電鉄貴志川線',
    '紀州鉄道',
  ]

  var 広島県 = [
    'JR山陽本線',
    'JR可部線',
    'JR福塩線',
    'JR芸備線',
    'JR呉線',
    'JR木次線',
    '山陽新幹線',
  ]

  var 岡山県 = [
    'JR山陽本線',
    'JR本四備讃線',
    'JR宇野線',
    'JR赤穂線',
    'JR姫新線',
    'JR芸備線',
    'JR吉備線',
    'JR津山線',
    'JR因美線',
    'JR伯備線',
    '山陽新幹線',
    '智頭急行',
    '水島臨海鉄道',
    '井原鉄道',
    '岡山電軌清輝橋線',
    '岡山電軌東山本線',
  ]

  var 鳥取県 = [
    'JR山陰本線',
    'JR境線',
    'JR因美線',
    'JR伯備線',
    '智頭急行',
    '若桜鉄道',
  ]

  var 島根県 = [
    'JR山陰本線',
    'JR山口線',
    'JR木次線',
    '一畑電気鉄道松江線',
    '一畑電車大社線',
  ]

  var 山口県 = [
    'JR山陽本線',
    'JR山陰本線',
    'JR山口線',
    'JR宇部線',
    'JR岩徳線',
    'JR小野田線',
    'JR美祢線',
    '山陽新幹線',
    '錦川鉄道錦川清流線',
  ]

  var 香川県 = [
    'JR予讃線',
    'JR本四備讃線',
    'JR高徳線',
    'JR土讃線',
    '高松琴平電鉄琴平線',
    '高松琴平電鉄長尾線',
    '高松琴平電鉄志度線',
  ]

  var 徳島県 = [
    'JR高徳線',
    'JR土讃線',
    'JR鳴門線',
    'JR牟岐線',
    'JR徳島線',
    '阿佐海岸鉄道阿佐東線',
  ]

  var 愛媛県 = [
    'JR予讃線',
    'JR内子線',
    'JR予土線',
    '伊予鉄道横河原線',
    '伊予鉄道郡中線',
    '伊予鉄道環状線',
    '伊予鉄道高浜線',
    '伊予鉄道本町線',
    '伊予鉄道城南線',
  ]

  var 高知県 = [
    'JR土讃線',
    'JR予土線',
    '阿佐海岸鉄道阿佐東線',
    'とさでん交通後免線',
    'とさでん交通桟橋線',
    'とさでん交通伊野線',
    '土佐くろしお鉄道阿佐線',
    '土佐くろしお鉄道宿毛線',
  ]

  var 福岡県 = [
    'JR山陽本線',
    'JR鹿児島本線',
    'JR日豊本線',
    'JR筑肥線',
    'JR篠栗線',
    'JR香椎線',
    'JR久大本線',
    'JR博多南線',
    'JR筑豊本線',
    'JR日田彦山線',
    'JR後藤寺線',
    '山陽新幹線',
    '九州新幹線',
    '地下鉄空港線',
    '地下鉄七隈線',
    '地下鉄箱崎線',
    '西鉄天神大牟田線',
    '西鉄貝塚線',
    '西鉄太宰府線',
    '西鉄甘木線',
    '北九州モノレール',
    '筑豊電気鉄道',
    '平成筑豊鉄道',
    '門司港レトロ観光線',
    '甘木鉄道',
  ]

  var 佐賀県 = [
    'JR鹿児島本線',
    'JR筑肥線',
    'JR長崎本線',
    'JR佐世保線',
    'JR唐津線',
    '九州新幹線',
    '西九州新幹線',
    '甘木鉄道',
    '松浦鉄道西九州線',
  ]

  var 長崎県 = [
    'JR大村線',
    'JR長崎本線',
    'JR佐世保線',
    '西九州新幹線',
    '長崎電軌本線',
    '長崎電軌桜町支線',
    '長崎電軌大浦支線',
    '長崎電軌蛍茶屋支線',
    '松浦鉄道西九州線',
    '島原鉄道',
  ]

  var 熊本県 = [
    'JR鹿児島本線',
    'JR豊肥本線',
    'JR肥薩線',
    'JR三角線',
    '九州新幹線',
    '熊本市電健軍線',
    '熊本市電上熊本線',
    '熊本電気鉄道',
    'くま川鉄道',
    '南阿蘇鉄道',
    '肥薩おれんじ鉄道',
  ]

  var 大分県 = [
    'JR日豊本線',
    'JR豊肥本線',
    'JR久大本線',
  ]

  var 宮崎県 = [
    'JR日豊本線',
    'JR肥薩線',
    'JR日南線',
    'JR吉都線',
    'JR宮崎空港線',
  ]

  var 鹿児島県 = [
    'JR鹿児島本線',
    'JR日豊本線',
    'JR指宿枕崎線',
    'JR肥薩線',
    'JR日南線',
    'JR吉都線',
    '九州新幹線',
    '鹿児島市電谷山線',
    '鹿児島市電唐湊線',
    '肥薩おれんじ鉄道',
  ]

  var 沖縄県 = [
    '沖縄都市モノレール',
  ]

  var 全国 = [
    'JR札沼線',
    'JR千歳線',
    'JR函館本線',
    'JR室蘭本線',
    'JR石勝線',
    'JR日高本線',
    'JR留萌本線',
    'JR根室本線',
    'JR富良野線',
    'JR宗谷本線',
    'JR石北本線',
    'JR釧網本線',
    '北海道新幹線',
    '地下鉄東西線',
    '地下鉄東豊線',
    '地下鉄南北線',
    '函館市電湯川線',
    '函館市電宝来谷地頭線',
    '札幌市電',
    '道南いさりび鉄道',
    'JR奥羽本線',
    'JR八戸線',
    'JR五能線',
    'JR大湊線',
    'JR津軽線',
    '東北新幹線',
    '北海道新幹線',
    'JR常磐線',
    'JR東北本線',
    'JR仙石線',
    'JR仙山線',
    'JR陸羽東線',
    'JR石巻線',
    'JR大船渡線',
    'JR気仙沼線',
    '東北新幹線',
    '仙台市地下鉄南北線',
    '仙台市地下鉄東西線',
    '阿武隈急行',
    '仙台空港鉄道',
    'JR東北本線',
    'JR八戸線',
    'JR田沢湖線',
    'JR釜石線',
    'JR山田線',
    'JR北上線',
    'JR花輪線',
    'JR大船渡線',
    '東北新幹線',
    '秋田新幹線',
    'いわて銀河鉄道線',
    '三陸鉄道リアス線',
    'JR奥羽本線',
    'JR羽越本線',
    'JR五能線',
    'JR田沢湖線',
    'JR北上線',
    'JR花輪線',
    'JR男鹿線',
    '秋田新幹線',
    '秋田内陸縦貫鉄道',
    '由利高原鉄道',
    'JR奥羽本線',
    'JR仙山線',
    'JR羽越本線',
    'JR左沢線',
    'JR陸羽東線',
    'JR陸羽西線',
    'JR米坂線',
    '山形新幹線',
    '山形鉄道フラワー長井線',
    'JR常磐線',
    'JR東北本線',
    'JR奥羽本線',
    'JR水郡線',
    'JR磐越西線',
    'JR磐越東線',
    'JR只見線',
    '東北新幹線',
    '山形新幹線',
    '野岩鉄道',
    '阿武隈急行',
    '福島交通飯坂線',
    '会津鉄道',
    'JR山手線',
    'JR京浜東北線',
    'JR東海道本線',
    'JR常磐線',
    'JR南武線',
    'JR横浜線',
    'JR横須賀線',
    'JR中央線',
    'JR青梅線',
    'JR五日市線',
    'JR武蔵野線',
    'JR八高線',
    'JR埼京線',
    'JR高崎線',
    'JR宇都宮線',
    'JR総武線',
    'JR総武線快速',
    'JR京葉線',
    '湘南新宿ライン宇須',
    '湘南新宿ライン高海',
    '東海道新幹線',
    '上越新幹線',
    '北陸新幹線',
    '東北新幹線',
    '東京メトロ銀座線',
    '東京メトロ丸ノ内線',
    '東京メトロ日比谷線',
    '東京メトロ東西線',
    '東京メトロ千代田線',
    '東京メトロ有楽町線',
    '東京メトロ半蔵門線',
    '東京メトロ南北線',
    '東京メトロ副都心線',
    '西武有楽町線',
    '西武新宿線',
    '西武池袋線',
    '西武拝島線',
    '西武山口線',
    '西武国分寺線',
    '西武多摩川線',
    '西武多摩湖線',
    '西武西武園線',
    '西武豊島線',
    '東武東上線',
    '東武伊勢崎線',
    '東武亀戸線',
    '東武大師線',
    '東急東横線',
    '東急田園都市線',
    '東急池上線',
    '東急目黒線',
    '東急多摩川線',
    '東急大井町線',
    '東急世田谷線',
    '都営浅草線',
    '都営三田線',
    '都営新宿線',
    '都営大江戸線',
    '都電荒川線',
    '日暮里・舎人ライナー',
    '京王井の頭線',
    '京王競馬場線',
    '京王新線',
    '京王線',
    '京王動物園線',
    '京王相模原線',
    '京王高尾線',
    '京成押上線',
    '京成金町線',
    '京成本線',
    '京急本線',
    '京急空港線',
    '小田急線',
    '小田急多摩線',
    '多摩都市モノレール',
    'りんかい線',
    '北総線',
    '東京モノレール',
    '新交通ゆりかもめ',
    '埼玉高速鉄道',
    'つくばエクスプレス',
    '成田スカイアクセス',
    '東急大井町線',
    '東急東横線',
    '東急田園都市線',
    '東急こどもの国線',
    '東急目黒線',
    '東急新横浜線',
    'JR横須賀線',
    'JR京浜東北線',
    'JR根岸線',
    'JR鶴見線',
    'JR東海道本線',
    'JR御殿場線',
    'JR南武線',
    'JR横浜線',
    'JR相模線',
    'JR中央線',
    '湘南新宿ライン宇須',
    '湘南新宿ライン高海',
    '東海道新幹線',
    'JR埼京線',
    '京王相模原線',
    '京急本線',
    '京急大師線',
    '京急逗子線',
    '京急久里浜線',
    '小田急線',
    '小田急小田原線',
    '小田急多摩線',
    '小田急江ノ島線',
    '相鉄本線',
    '相鉄いずみ野線',
    '相鉄新横浜線',
    'ブルーライン',
    'グリーンライン',
    'みなとみらい線',
    'シーサイドライン',
    '江ノ島電鉄線',
    '湘南モノレール',
    '箱根登山鉄道',
    '箱根登山鉄道鋼索線',
    '伊豆箱根大雄山線',
    'JR京浜東北線',
    'JR埼京線',
    'JR川越線',
    'JR高崎線',
    'JR宇都宮線',
    'JR武蔵野線',
    'JR八高線',
    '湘南新宿ライン宇須',
    '湘南新宿ライン高海',
    '上越新幹線',
    '北陸新幹線',
    '東北新幹線',
    '東京メトロ有楽町線',
    '東京メトロ副都心線',
    '西武新宿線',
    '西武池袋線',
    '西武狭山線',
    '西武山口線',
    '西武秩父線',
    '東武東上線',
    '東武越生線',
    '東武伊勢崎線',
    '東武日光線',
    '東武野田線',
    '埼玉高速鉄道',
    'つくばエクスプレス',
    '埼玉新都市交通伊奈線',
    '秩父鉄道',
    'JR総武線',
    'JR常磐線',
    'JR成田線',
    'JR武蔵野線',
    'JR総武線快速',
    'JR総武本線',
    'JR京葉線',
    'JR鹿島線',
    'JR外房線',
    'JR東金線',
    'JR内房線',
    'JR久留里線',
    '東京メトロ東西線',
    '都営新宿線',
    '京成本線',
    '京成千葉線',
    '京成千原線',
    '新京成線',
    '東武野田線',
    '北総線',
    'つくばエクスプレス',
    '千葉都市モノレール',
    '流鉄流山線',
    '東葉高速鉄道',
    'ユーカリが丘線',
    '小湊鉄道線',
    'いすみ鉄道',
    '銚子電気鉄道',
    '芝山鉄道',
    '成田スカイアクセス',
    'JR宇都宮線',
    'JR常磐線',
    'JR鹿島線',
    'JR水郡線',
    'JR水戸線',
    '湘南新宿ライン宇須',
    'つくばエクスプレス',
    '関東鉄道竜ヶ崎線',
    'ひたちなか海浜鉄道',
    '真岡鉄道',
    '鹿島臨海鉄道',
    'JR宇都宮線',
    'JR東北本線',
    'JR烏山線',
    'JR日光線',
    'JR水戸線',
    'JR両毛線',
    '湘南新宿ライン宇須',
    '東北新幹線',
    '東武伊勢崎線',
    '東武佐野線',
    '東武日光線',
    '東武宇都宮線',
    '東武鬼怒川線',
    '宇都宮芳賀ライトレール線',
    '真岡鉄道',
    'わたらせ渓谷鉄道',
    '野岩鉄道',
    'JR高崎線',
    'JR八高線',
    'JR上越線',
    'JR吾妻線',
    'JR両毛線',
    'JR信越本線',
    '湘南新宿ライン高海',
    '上越新幹線',
    '北陸新幹線',
    '東武伊勢崎線',
    '東武小泉線',
    '東武佐野線',
    '東武桐生線',
    '東武日光線',
    'わたらせ渓谷鉄道',
    '上毛電鉄',
    '上信電鉄',
    'JR上越線',
    'JR白新線',
    'JR信越本線',
    'JR飯山線',
    'JR越後線',
    'JR弥彦線',
    'JR大糸線',
    'JR磐越西線',
    'JR只見線',
    'JR米坂線',
    'JR羽越本線',
    '上越新幹線',
    '北陸新幹線',
    '北越急行ほくほく線',
    'しなの鉄道北しなの線',
    '妙高はねうまライン',
    '日本海ひすいライン',
    'あいの風とやま鉄道',
    'JR高山本線',
    'JR城端線',
    'JR氷見線',
    '北陸新幹線',
    '富山地鉄本線',
    '富山地鉄立山線',
    '富山地鉄不二越上滝',
    '富山地鉄富山市内線',
    '富山地鉄富山都心線',
    '富山地鉄富山港線',
    '万葉線',
    '黒部峡谷鉄道',
    'あいの風とやま鉄道',
    'JR北陸本線',
    'JR七尾線',
    '北陸新幹線',
    '北陸鉄道石川線',
    '北陸鉄道浅野川線',
    'のと鉄道・七尾線',
    'ＩＲいしかわ鉄道',
    'あいの風とやま鉄道',
    'JR北陸本線',
    'JR小浜線',
    'JR越美北線',
    '福井鉄道福武線',
    'えちぜん鉄道三国線',
    'えちぜん鉄道勝山線',
    'JR中央線',
    'JR身延線',
    'JR小海線',
    '富士急行',
    'JR中央線',
    'JR篠ノ井線',
    'JR信越本線',
    'JR飯山線',
    'JR飯田線',
    'JR小海線',
    'JR大糸線',
    '北陸新幹線',
    '長野電鉄長野線',
    'しなの鉄道',
    'しなの鉄道北しなの線',
    '松本電気鉄道',
    '上田電鉄別所線',
    'JR東海道本線',
    'JR関西本線',
    'JR中央本線',
    'JR飯田線',
    'JR武豊線',
    '東海道新幹線',
    '近鉄名古屋線',
    '地下鉄東山線',
    '地下鉄鶴舞線',
    '地下鉄名城線',
    '地下鉄桜通線',
    '地下鉄名港線',
    '地下鉄上飯田線',
    '名鉄名古屋本線',
    '名鉄常滑線',
    '名鉄河和線',
    '名鉄知多新線',
    '名鉄三河線',
    '名鉄西尾線',
    '名鉄蒲郡線',
    '名鉄豊川線',
    '名鉄豊田線',
    '名鉄瀬戸線',
    '名鉄犬山線',
    '名鉄小牧線',
    '名鉄広見線',
    '名鉄津島線',
    '名鉄尾西線',
    '名鉄築港線',
    '名鉄空港線',
    '愛知環状鉄道',
    '東海交通事業城北線',
    '豊橋鉄道東田本線',
    '豊橋鉄道渥美線',
    'リニモ',
    'あおなみ線',
    'ゆとりーとライン',
    'JR東海道本線',
    'JR中央本線',
    'JR高山本線',
    'JR太多線',
    '東海道新幹線',
    '名鉄名古屋本線',
    '名鉄犬山線',
    '名鉄各務原線',
    '名鉄広見線',
    '名鉄竹鼻線',
    '名鉄羽島線',
    '樽見鉄道',
    '長良川鉄道',
    '明知鉄道',
    '養老鉄道養老線',
    'JR東海道本線',
    'JR東海道本線',
    'JR御殿場線',
    'JR身延線',
    'JR飯田線',
    'JR伊東線',
    '東海道新幹線',
    '伊豆箱根鉄道駿豆線',
    '伊豆急行',
    '遠州鉄道',
    '静岡鉄道静岡清水線',
    '天竜浜名湖鉄道',
    '岳南電車',
    '大井川鐵道本線',
    '大井川鐵道井川線',
    'JR草津線',
    'JR関西本線',
    'JR紀勢本線',
    'JR名松線',
    'JR参宮線',
    '近鉄大阪線',
    '近鉄名古屋線',
    '近鉄山田線',
    '近鉄鳥羽線',
    '近鉄湯の山線',
    '近鉄鈴鹿線',
    '近鉄志摩線',
    '伊勢鉄道',
    '三岐鉄道北勢線',
    '三岐鉄道三岐線',
    '伊賀鉄道伊賀線',
    '養老鉄道養老線',
    '四日市あすなろう鉄道',
    'JR大阪環状線',
    'JR東海道本線',
    'JR片町線',
    'JR桜島線',
    'JR関西本線',
    'JR阪和線',
    'JR関西空港線',
    'JR福知山線',
    'JR東西線',
    'JRおおさか東線',
    '東海道新幹線',
    '近鉄けいはんな線',
    '近鉄奈良線',
    '近鉄難波線',
    '近鉄大阪線',
    '近鉄信貴線',
    '近鉄南大阪線',
    '近鉄長野線',
    '近鉄道明寺線',
    '近鉄西信貴ケーブル',
    '地下鉄御堂筋線',
    '地下鉄谷町線',
    '地下鉄四つ橋線',
    '南港ポートタウン線',
    '地下鉄中央線',
    '地下鉄千日前線',
    '地下鉄堺筋線',
    '地下鉄長堀鶴見緑地線',
    '地下鉄今里筋線',
    '北大阪急行',
    '南海本線',
    '南海空港線',
    '南海高師浜線',
    '南海多奈川線',
    '南海高野線',
    '南海汐見橋線',
    '泉北高速鉄道',
    '阪急神戸線',
    '阪急宝塚線',
    '阪急箕面線',
    '阪急千里線',
    '阪急京都線',
    '大阪モノレール本線',
    '大阪モノレール彩都線',
    '京阪本線',
    '京阪交野線',
    '京阪中之島線',
    '阪堺電気軌道上町線',
    '阪堺電気軌道阪堺線',
    '阪神本線',
    '阪神電鉄阪神なんば線',
    '能勢電鉄妙見線',
    '水間鉄道',
    'JR東海道本線',
    'JR山陽本線',
    'JR赤穂線',
    'JR和田岬線',
    'JR加古川線',
    'JR福知山線',
    'JR山陰本線',
    'JR播但線',
    'JR姫新線',
    'JR東西線',
    '山陽新幹線',
    '阪急神戸線',
    '阪急伊丹線',
    '阪急今津線',
    '阪急甲陽線',
    '阪急宝塚線',
    '阪神本線',
    '阪神電鉄阪神なんば線',
    '阪神武庫川線',
    '能勢電鉄妙見線',
    '能勢電鉄日生線',
    '神戸電鉄有馬線',
    '神戸電鉄三田線',
    '神戸電鉄公園都市線',
    '神戸電鉄粟生線',
    'ポートアイランド線',
    '六甲アイランド線',
    '地下鉄西神・山手線',
    '地下鉄海岸線',
    '地下鉄北神線',
    '山陽電鉄本線',
    '山陽電鉄網干線',
    '神戸高速鉄道東西線',
    '神戸高速鉄道南北線',
    '智頭急行',
    '北条鉄道北条線',
    '京都丹後鉄道宮豊線',
    'JR東海道本線',
    'JR片町線',
    'JR湖西線',
    'JR奈良線',
    'JR関西本線',
    'JR福知山線',
    'JR山陰本線',
    'JR舞鶴線',
    'JR小浜線',
    '東海道新幹線',
    '近鉄京都線',
    '阪急京都線',
    '阪急嵐山線',
    '京阪鴨東線',
    '京阪本線',
    '京阪宇治線',
    '京阪京津線',
    '京阪男山ケーブル',
    '京福電鉄嵐山本線',
    '京福電鉄北野線',
    '地下鉄烏丸線',
    '地下鉄東西線',
    '叡山電鉄叡山本線',
    '叡山電鉄鞍馬線',
    '嵯峨野観光鉄道',
    '京都丹後鉄道宮福線',
    '京都丹後鉄道宮舞線',
    '京都丹後鉄道宮豊線',
    'JR東海道本線',
    'JR北陸本線',
    'JR湖西線',
    'JR草津線',
    '東海道新幹',
    '京阪石山坂本線',
    '京阪京津',
    '近江鉄道八日市線',
    '近江鉄道本線',
    '近江鉄道多賀',
    '信楽高原鐵',
    'JR奈良線',
    'JR関西本線',
    'JR桜井線',
    'JR和歌山線',
    '近鉄生駒鋼索線',
    '近鉄けいはんな線',
    '近鉄奈良線',
    '近鉄生駒線',
    '近鉄大阪線',
    '近鉄南大阪線',
    '近鉄京都線',
    '近鉄橿原線',
    '近鉄天理線',
    '近鉄田原本線',
    '近鉄御所線',
    '近鉄吉野線',
    'JR阪和線',
    'JR和歌山線',
    'JR紀勢本線',
    '南海本線',
    '南海加太線',
    '南海和歌山港線',
    '南海高野線',
    '南海高野山ケーブル',
    'わかやま電鉄貴志川線',
    '紀州鉄道',
    'JR山陽本線',
    'JR可部線',
    'JR福塩線',
    'JR芸備線',
    'JR呉線',
    'JR木次線',
    '山陽新幹線',
    'JR山陽本線',
    'JR本四備讃線',
    'JR宇野線',
    'JR赤穂線',
    'JR姫新線',
    'JR芸備線',
    'JR吉備線',
    'JR津山線',
    'JR因美線',
    'JR伯備線',
    '山陽新幹線',
    '智頭急行',
    '水島臨海鉄道',
    '井原鉄道',
    '岡山電軌清輝橋線',
    '岡山電軌東山本線',
    'JR山陰本線',
    'JR境線',
    'JR因美線',
    'JR伯備線',
    '智頭急行',
    '若桜鉄道',
    'JR山陰本線',
    'JR山口線',
    'JR木次線',
    '一畑電気鉄道松江線',
    '一畑電車大社線',
    'JR山陽本線',
    'JR山陰本線',
    'JR山口線',
    'JR宇部線',
    'JR岩徳線',
    'JR小野田線',
    'JR美祢線',
    '山陽新幹線',
    '錦川鉄道錦川清流線',
    'JR予讃線',
    'JR本四備讃線',
    'JR高徳線',
    'JR土讃線',
    '高松琴平電鉄琴平線',
    '高松琴平電鉄長尾線',
    '高松琴平電鉄志度線',
    'JR高徳線',
    'JR土讃線',
    'JR鳴門線',
    'JR牟岐線',
    'JR徳島線',
    '阿佐海岸鉄道阿佐東線',
    'JR予讃線',
    'JR内子線',
    'JR予土線',
    '伊予鉄道横河原線',
    '伊予鉄道郡中線',
    '伊予鉄道環状線',
    '伊予鉄道高浜線',
    '伊予鉄道本町線',
    '伊予鉄道城南線',
    'JR土讃線',
    'JR予土線',
    '阿佐海岸鉄道阿佐東線',
    'とさでん交通後免線',
    'とさでん交通桟橋線',
    'とさでん交通伊野線',
    '土佐くろしお鉄道阿佐線',
    '土佐くろしお鉄道宿毛線',
    'JR山陽本線',
    'JR鹿児島本線',
    'JR日豊本線',
    'JR筑肥線',
    'JR篠栗線',
    'JR香椎線',
    'JR久大本線',
    'JR博多南線',
    'JR筑豊本線',
    'JR日田彦山線',
    'JR後藤寺線',
    '山陽新幹線',
    '九州新幹線',
    '地下鉄空港線',
    '地下鉄七隈線',
    '地下鉄箱崎線',
    '西鉄天神大牟田線',
    '西鉄貝塚線',
    '西鉄太宰府線',
    '西鉄甘木線',
    '北九州モノレール',
    '筑豊電気鉄道',
    '平成筑豊鉄道',
    '門司港レトロ観光線',
    '甘木鉄道',
    'JR鹿児島本線',
    'JR筑肥線',
    'JR長崎本線',
    'JR佐世保線',
    'JR唐津線',
    '九州新幹線',
    '西九州新幹線',
    '甘木鉄道',
    '松浦鉄道西九州線',
    'JR大村線',
    'JR長崎本線',
    'JR佐世保線',
    '西九州新幹線',
    '長崎電軌本線',
    '長崎電軌桜町支線',
    '長崎電軌大浦支線',
    '長崎電軌蛍茶屋支線',
    '松浦鉄道西九州線',
    '島原鉄道',
    'JR鹿児島本線',
    'JR豊肥本線',
    'JR肥薩線',
    'JR三角線',
    '九州新幹線',
    '熊本市電健軍線',
    '熊本市電上熊本線',
    '熊本電気鉄道',
    'くま川鉄道',
    '南阿蘇鉄道',
    '肥薩おれんじ鉄道',
    'JR日豊本線',
    'JR豊肥本線',
    'JR久大本線',
    'JR日豊本線',
    'JR肥薩線',
    'JR日南線',
    'JR吉都線',
    'JR宮崎空港線',
    'JR鹿児島本線',
    'JR日豊本線',
    'JR指宿枕崎線',
    'JR肥薩線',
    'JR日南線',
    'JR吉都線',
    '九州新幹線',
    '鹿児島市電谷山線',
    '鹿児島市電唐湊線',
    '肥薩おれんじ鉄道',
    '沖縄都市モノレール',
  ]

  return (
    <>
    <div className={Styles.station}>
      <div>
        <div className={Styles.background} onClick={() => props.setSwitchPref(false)}></div>
        <div className={Styles.contents}>
          <div className={Styles.title}>
            <h5>路線選択</h5>
            <div>
              <button onClick={() => props.setStation([])}>クリア</button>
              <button onClick={() => props.setSwitchPref(false)}>検索</button>
            </div>
          </div>
          <select onChange={(e) => props.setPref(e.target.value)} value={props.pref}>
            <optgroup label="北海道">
              <option value="北海道">北海道</option>
            </optgroup>
            <optgroup label="東北">
              <option value="青森県">青森県</option>
              <option value="岩手県">岩手県</option>
              <option value="宮城県">宮城県</option>
              <option value="秋田県">秋田県</option>
              <option value="山形県">山形県</option>
              <option value="福島県">福島県</option>
            </optgroup>
            <optgroup label="関東">
              <option value="茨城県">茨城県</option>
              <option value="栃木県">栃木県</option>
              <option value="群馬県">群馬県</option>
              <option value="埼玉県">埼玉県</option>
              <option value="千葉県">千葉県</option>
              <option value="東京都">東京都</option>
              <option value="神奈川県">神奈川県</option>
              <option value="山梨県">山梨県</option>
              <option value="長野県 ">長野県 </option>
            </optgroup>
            <optgroup label="北陸">
              <option value="新潟県">新潟県</option>
              <option value="富山県">富山県</option>
              <option value="石川県">石川県</option>
              <option value="福井県">福井県</option>
            </optgroup>
            <optgroup label="中部">
              <option value="岐阜県">岐阜県</option>
              <option value="静岡県">静岡県</option>
              <option value="愛知県">愛知県</option>
              <option value="三重県">三重県</option>
            </optgroup>
            <optgroup label="近畿">
              <option value="滋賀県">滋賀県</option>
              <option value="京都府">京都府</option>
              <option value="大阪府">大阪府</option>
              <option value="兵庫県">兵庫県</option>
              <option value="奈良県">奈良県</option>
              <option value="和歌山県">和歌山県</option>
            </optgroup>
            <optgroup label="中国">
              <option value="鳥取県">鳥取県</option>
              <option value="島根県">島根県</option>
              <option value="岡山県">岡山県</option>
              <option value="広島県">広島県</option>
              <option value="山口県">山口県</option>
            </optgroup>
            <optgroup label="四国">
              <option value="徳島県">徳島県</option>
              <option value="香川県">香川県</option>
              <option value="愛媛県">愛媛県</option>
              <option value="高知県">高知県</option>
            </optgroup>
            <optgroup label="九州">
              <option value="福岡県">福岡県</option>
              <option value="佐賀県">佐賀県</option>
              <option value="長崎県">長崎県</option>
              <option value="熊本県">熊本県</option>
              <option value="大分県">大分県</option>
              <option value="宮崎県">宮崎県</option>
              <option value="鹿児島県">鹿児島県</option>
            </optgroup>
            <optgroup label="沖縄">
              <option value="沖縄県">沖縄県</option>
            </optgroup>
          </select>
          <p>{props.pref}の路線を表示</p>
          <div className={Styles.radio}>
            <div>
              {props.pref === '北海道' ?
                <>
                  {北海道.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '青森県' ?
                <>
                  {青森県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '宮城県' ?
                <>
                  {宮城県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '岩手県' ?
                <>
                  {岩手県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '秋田県' ?
                <>
                  {秋田県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '山形県' ?
                <>
                  {山形県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '福島県' ?
                <>
                  {福島県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '東京都' ?
                <>
                  {東京都.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '神奈川県' ?
                <>
                  {神奈川県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '埼玉県' ?
                <>
                  {埼玉県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '千葉県' ?
                <>
                  {千葉県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '茨城県' ?
                <>
                  {茨城県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '栃木県' ?
                <>
                  {栃木県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '群馬県' ?
                <>
                  {群馬県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '新潟県' ?
                <>
                  {新潟県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '富山県' ?
                <>
                  {富山県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '石川県' ?
                <>
                  {石川県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '福井県' ?
                <>
                  {福井県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '山梨県' ?
                <>
                  {山梨県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '長野県' ?
                <>
                  {長野県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '愛知県' ?
                <>
                  {愛知県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '岐阜県' ?
                <>
                  {岐阜県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '静岡県' ?
                <>
                  {静岡県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '三重県' ?
                <>
                  {三重県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '大阪府' ?
                <>
                  {大阪府.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '兵庫県' ?
                <>
                  {兵庫県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '京都府' ?
                <>
                  {京都府.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '滋賀県' ?
                <>
                  {滋賀県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '奈良県' ?
                <>
                  {奈良県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '和歌山県' ?
                <>
                  {和歌山県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '広島県' ?
                <>
                  {広島県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '岡山県' ?
                <>
                  {岡山県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '鳥取県' ?
                <>
                  {鳥取県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '島根県' ?
                <>
                  {島根県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '山口県' ?
                <>
                  {山口県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '香川県' ?
                <>
                  {香川県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '徳島県' ?
                <>
                  {徳島県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '愛媛' ?
                <>
                  {愛媛県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '高知県' ?
                <>
                  {高知県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '福岡県' ?
                <>
                  {福岡県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '佐賀県' ?
                <>
                  {佐賀県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '長崎県' ?
                <>
                  {長崎県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '熊本県' ?
                <>
                  {熊本県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '大分県' ?
                <>
                  {大分県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '宮崎県' ?
                <>
                  {宮崎県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '鹿児島県' ?
                <>
                  {鹿児島県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                : props.pref === '沖縄県' ?
                <>
                  {沖縄県.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
                :
                <>
                  {全国.map((data, index) =>
                    <div>
                      <input type="checkbox"
                        checked={props.station.indexOf(data) !== -1 ? 'checked' : ''}
                        onChange={(e) => {
                          e.target.checked ? props.setStation([...props.station, data]) : props.setStation(props.station.filter((v, index) => (v !== data)))
                        }}/>
                      <p>{data}</p>
                    </div>
                  )}
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Footer;

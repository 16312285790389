import Styles from './styles.module.css';
import React from 'react'
import Icon from './../../../../image/student_icon.png';
import { useHistory } from 'react-router-dom';

const Footer = () => {

  const history = useHistory(); 

  return (
    <>
    <div className={Styles.nav} onClick={() => history.push('/')}>
      <div className={Styles.nav_logo}>
        <img src={Icon} alt='' />
        <h4>memorii salon</h4>
      </div>
      <div className={Styles.nav_link}>
        <h5 onClick={() => history.push('/company')}>法人の方はこちら</h5>
        <h5 onClick={() => history.push('/student/login')}>ログイン</h5>
        <button>お問い合わせ</button>
      </div>
    </div>
    </>
  );
}

export default Footer;

import Styles from './../styles.module.css';
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import ErrorMessage from '../../../service/package/parts/error/index';
import DefaultTitle from '../../../service/package/parts/title/index';

const WorksSiteCoordinate = (props) => {

  const [ errorMessage, setErrorMessage ] = useState('');


  function before() {
    if (props.recruitList.document_coordinate) {
      props.setProgress(4);
    } else if (props.recruitList.document_question) {
      props.setProgress(3);
    } else if (props.recruitList.document_resume) {
      props.setProgress(2);
    } else {
      props.setProgress(1);
    }
  }

  const Document1Image = e => {
    if (e.target.files[0]) {
      props.setDocument1File(URL.createObjectURL(e.target.files[0]))
      firebase.storage().ref().child(`document/${props.recruitList.id}_${props.uid}_1`).put(e.target.files[0]);
    }
  }

  const Document2Image = e => {
    if (e.target.files[0]) {
      props.setDocument2File(URL.createObjectURL(e.target.files[0]))
      firebase.storage().ref().child(`document/${props.recruitList.id}_${props.uid}_2`).put(e.target.files[0]);
    }
  }

  const Document3Image = e => {
    if (e.target.files[0]) {
      props.setDocument3File(URL.createObjectURL(e.target.files[0]))
      firebase.storage().ref().child(`document/${props.recruitList.id}_${props.uid}_3`).put(e.target.files[0]);
    }
  }

  function check() {

    window.scrollTo(0, 0);

    console.log(props.recruitList.document_otherDocumentCount);
    if (props.recruitList.document_otherDocumentCount === 1) {
      if (props.document1File === '') {
        setErrorMessage('その他の書類①を入力してください。');
      } else {
        props.setProgress(6);
      }
    }

    if (props.recruitList.document_otherDocumentCount === 2) {
      if (props.document1File === '') {
        setErrorMessage('その他の書類①を入力してください。');
      } else if (props.document2File === '') {
        setErrorMessage('その他の書類②を入力してください。');
      } else {
        props.setProgress(6);
      }
    }

    if (props.recruitList.document_otherDocumentCount === 3) {
      if (props.document1File === '') {
        setErrorMessage('その他の書類①を添付してください。');
      } else if (props.document2File === '') {
        setErrorMessage('その他の書類②を添付してください。');
      } else if (props.document3File === '') {
        setErrorMessage('その他の書類③を添付してください。');
      } else {
        props.setProgress(6);
      }
    }
  }

  return (
    <>
    <div className={Styles.box}>

      {errorMessage !== '' ?
        <ErrorMessage message={errorMessage} />
      : <></> }

      <div className={Styles.box_inline}>
        <h5 className={Styles.title}>その他の提出書類</h5>

        {1 <= props.recruitList.document_otherDocumentCount && props.recruitList.document_otherDocumentCount <= 3 ?
        <>
          <DefaultTitle title={`①添付書類：${props.recruitList.document_otherDocument01}`} required={true} />
          {props.document1File === '' ?
          <>
            <div className={Styles.document}>
              <p>書類を添付してください。</p>
              <input type='file' onChange={Document1Image} />
            </div>
          </> : 
          <>
            <div className={Styles.document_file_display}>
              <div className={Styles.document_file}>
                <p>書類を変更する。</p>
                <input type='file' onChange={Document1Image} />
              </div>
              <a href={props.document1File} target="_blank" without rel="noreferrer" >
                <p>プレビュー</p>
              </a>
            </div>
          </> }
          <p className='memorii_recruit_body_page_company_edit_input_area_desc'>　</p>
        </> : <></> }

        {2 <= props.recruitList.document_otherDocumentCount && props.recruitList.document_otherDocumentCount <= 3 ?
        <>
          <DefaultTitle title={`②添付書類：${props.recruitList.document_otherDocument02}`} required={true} />
          {props.document2File === '' ?
          <>
            <div className={Styles.document}>
              <p>書類を添付してください。</p>
              <input type='file' onChange={Document2Image} />
            </div>
          </> : 
          <>
            <div className={Styles.document_file_display}>
              <div className={Styles.document_file}>
                <p>書類を変更する。</p>
                <input type='file' onChange={Document2Image} />
              </div>
              <a href={props.document2File} target="_blank" without rel="noreferrer" >
                <p>プレビュー</p>
              </a>
            </div>
          </> }
          <p className='memorii_recruit_body_page_company_edit_input_area_desc'>　</p>
        </> : <></> }
        
        {props.recruitList.document_otherDocumentCount === 3 ?
        <>
          <DefaultTitle title={`③添付書類：${props.recruitList.document_otherDocument03}`} required={true} />
          {props.document3File === '' ?
          <>
            <div className={Styles.document}>
              <p>書類を添付してください。</p>
              <input type='file' onChange={Document3Image} />
            </div>
          </> : 
          <>
            <div className={Styles.document_file_display}>
              <div className={Styles.document_file}>
                <p>書類を変更する。</p>
                <input type='file' onChange={Document3Image} />
              </div>
              <a href={props.document3File} target="_blank" without rel="noreferrer" >
                <p>プレビュー</p>
              </a>
            </div>
          </> }
          <p className='memorii_recruit_body_page_company_edit_input_area_desc'>　</p>
        </> : <></> }

      </div>

      <div className={Styles.save}>
        <button className={Styles.save_back} onClick={() => before()}>戻る</button>
        <button className={Styles.save_save} onClick={() => check()}>次へ</button>
      </div>

    </div>
    </>
  );
}

export default WorksSiteCoordinate;

import React from 'react';
import Styles from './styles.module.css';

const DefaultInput1 = (props) => {

  return (
    <>
    <div className={Styles.title}>
      <div>
        <p>{props.title}</p>
        <div className={ props.required ? Styles.title_yes : Styles.title_no }>{props.required ? '※必須' : '任意'}</div>
      </div>
      {props.length === 0 ? <></> :
        <p>{props.value.length}/{props.length}</p>
      }
    </div>
    <div className={Styles.history}>
      <input placeholder={props.place1} type='text' value={props.value1} 
        onChange={(e) => {
          if (e.target.value.slice(-1) === '1' || e.target.value.slice(-1) === '2' || e.target.value.slice(-1) === '3' || e.target.value.slice(-1) === '4' || e.target.value.slice(-1) === '5' || e.target.value.slice(-1) === '6' || e.target.value.slice(-1) === '7' || e.target.value.slice(-1) === '8' || e.target.value.slice(-1) === '9' || e.target.value.slice(-1) === '0' || e.target.value.slice(-1) === '') {
            props.setValue1(e.target.value);
          }
        }} />
      <p>年</p>
      <input placeholder={props.place2} type='text' value={props.value2} onChange={(e) => props.setValue2(e.target.value)} />
      <p>月</p>
    </div>
    <p className={Styles.desc}>{props.desc}</p>
    </>
  );
}

export default DefaultInput1;

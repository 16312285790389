import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import PostPhoto from './1.post/index';
import EditPhoto from './2.edit/index';
import firebase from 'firebase/compat/app';
import { FaEye } from "react-icons/fa";
import { FaBookmark } from "react-icons/fa";
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore';
import WaitImg from '../../../image/works/wait.png';

const Photo = (props) => {

  const [ companyId, setCompanyId ] = useState('');
  const [ editList, setEditList ] = useState([]);
  const [ photoList, setPhotoList ] = useState([]);

  useEffect(() => {
    getPhotos();
  }, []);

  function getPhotos() {
    const docRef = collection(firebase.firestore(), 'posts')
    const queryRef = query(docRef, where('companyId', '==', props.companyList.id))
    const unsub = onSnapshot(queryRef, snapshot => {
      var tmp = [];
      snapshot.docs.forEach(doc => {
        tmp.push({...doc.data(), id: doc.id});
      })
      setPhotoList(tmp);
    })
    return () => unsub();
  }

  return (
    <>
    <div className='works_service'>

      <div className={Styles.photo_title}>
        <div>
          <h4>{props.companyList.name}</h4>
        </div>
        <button onClick={() => setCompanyId(props.companyList.id)}>画像を投稿する</button>
      </div>

      <div className={Styles.photo_contents}>
        {photoList.map((photo, index) =>
        <div className={Styles.photo_contents_box} onClick={() => setEditList(photo)} key={index}>
          <div>
            <div className={Styles.photo_contents_box_background}></div>
            <img className={Styles.photo_contents_box_image}
              src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/image%2Fresize_images%2F${photo.id}_500x500?alt=media&token=?${new Date().getTime()}`}
              onError={e => {
                e.target.onError = null;
                e.target.src = WaitImg;
              }} />
            <div className={Styles.photo_contents_box_data}>
              <FaEye  className={Styles.photo_contents_box_data_icon} />
              <h4>{photo.post_liker.length}</h4>
              <FaBookmark  className={Styles.photo_contents_box_data_icon} />
              <h4>{photo.post_views.length}</h4>
            </div>
          </div>
        </div> )}
      </div>

    </div>

    {/* 画像投稿 */}
    {companyId !== '' ?
      <PostPhoto userList={props.userList} companyId={companyId} setCompanyId={setCompanyId} />
    : <></> }

    {/* 投稿編集 */}
    {editList.length !== 0 ?
      <EditPhoto editList={editList} setEditList={setEditList} />
    : <></> }
    </>
  );
}

export default Photo;

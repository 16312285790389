import { isMobile } from "react-device-detect";
import Pc from "./pc/index";
import Mobile from "./mobile/index";

const SalonTopic = (props) => {

  return (
    <>
    {!isMobile ?
      <Pc userList={props.userList} companyList={props.companyList} />
      :
      <Mobile userList={props.userList} companyList={props.companyList} />
    }
    </>
  );
}

export default SalonTopic;




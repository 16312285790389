import Styles from './../styles.module.css';
import React, { useState } from 'react';
import ErrorMessage from '../../../service/package/parts/error/index';
import DefaultTextarea1 from '../../../service/package/parts/1-textarea';

const Footer = (props) => {

  const [ errorMessage, setErrorMessage ] = useState('');

  function before() {
    if (props.recruitList.document_resume) {
      props.setProgress(2);
    } else {
      props.setProgress(1);
    }
  }

  function next() {
    if (props.recruitList.document_coordinate) {
      props.setProgress(4);
    } else if (props.recruitList.document_otherDocument) {
      props.setProgress(5);
    } else {
      props.setProgress(6);
    }
  }

  function check() {

    window.scrollTo(0, 0);

    if (props.recruitList.document_questionCount === 1) {
      if (props.question01Input === '') {
        setErrorMessage('1つ目の回答を入力してください。');
      } else {
        next();
      }
    }

    if (props.recruitList.document_questionCount === 2) {
      if (props.question01Input === '') {
        setErrorMessage('1つ目の回答を入力してください。');
      } else if (props.question02Input === '') {
        setErrorMessage('2つ目の回答を入力してください。');
      } else {
        next();
      }
    }

    if (props.recruitList.document_questionCount === 3) {
      if (props.question01Input === '') {
        setErrorMessage('1つ目の回答を入力してください。');
      } else if (props.question02Input === '') {
        setErrorMessage('2つ目の回答を入力してください。');
      } else if (props.question03Input === '') {
        setErrorMessage('3つ目の回答を入力してください。');
      } else {
        next();
      }
    }
  }

  return (
    <>
    <div className={Styles.box}>

      {errorMessage !== '' ?
        <ErrorMessage message={errorMessage} />
      : <></> }

      <div className={Styles.box_inline}>
        <h5 className={Styles.title}>追加質問</h5>

        {1 <= props.recruitList.document_questionCount && props.recruitList.document_questionCount <= 3 ?
          <DefaultTextarea1
            title={props.recruitList.document_question01} required={true} length={400} type={'text'} place={''} 
            value={props.question01Input} setValue={props.setQuestion01Input}
            desc={'　'}
          />
        : <></> }

        {2 <= props.recruitList.document_questionCount && props.recruitList.document_questionCount <= 3 ?
          <DefaultTextarea1
            title={props.recruitList.document_question02} required={true} length={400} type={'text'} place={''} 
            value={props.question02Input} setValue={props.setQuestion02Input}
            desc={'　'}
          />
        : <></> }

        {3 === props.recruitList.document_questionCount ?
          <DefaultTextarea1
            title={props.recruitList.document_question03} required={true} length={400} type={'text'} place={''} 
            value={props.question03Input} setValue={props.setQuestion03Input}
            desc={'　'}
          />
        : <></> }
      </div>
      
      <div className={Styles.save}>
        <button className={Styles.save_back} onClick={() => before()}>戻る</button>
        <button className={Styles.save_save} onClick={() => check()}>次へ</button>
      </div>
      
    </div>
    </>
  );
}

export default Footer;

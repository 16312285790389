import Styles from './styles.module.css';
import React from 'react';
import firebase from 'firebase/compat/app';
import { useHistory } from 'react-router-dom';

const MemoriiWorksRecruitDeleteRecruit = (props) => {

  const history = useHistory();

  function delteStore() {
    props.setDeleteStore("");

    var storeId = props.deleteStore;

    firebase.firestore().collection('stores').doc(storeId).delete();
    firebase.firestore().collection('recruits').where('place_stores', 'array-contains', `${storeId}`)
    .get().then(snapShot => {
      snapShot.forEach((doc) => {
        console.log(doc.id);
        firebase.firestore().collection('recruits').doc(doc.id)
        .update({ place_stores: firebase.firestore.FieldValue.arrayRemove(storeId) });
      });
    });
  }

  return (
    <>
    <div className={Styles.modal}>
      <div>
        <div className={Styles.modal_black} onClick={() => props.setDeleteStore('')}></div>
        <div className={Styles.modal_contents}>
          <h4>店舗を削除しますか？</h4>
          <p>
            店舗を削除した場合、復元する事はできません。<br />
          </p>
          <div>
            <button style={{backgroundColor : 'white', color : '#AD0200'}} onClick={() => props.setDeleteStore('')}>キャンセル</button>
            <button style={{backgroundColor : '#AD0200', color : 'white'}} onClick={() => delteStore()}>削除する</button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default MemoriiWorksRecruitDeleteRecruit;

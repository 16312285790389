import Styles from './styles.module.css';
import 'firebase/auth'
import React from 'react-router-dom';
import { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import { useHistory } from 'react-router-dom';
import DefaultTitle from '../../../../package/parts/title/index';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import BackModal from '../11.back/index';
import Header from '../../../2.edit/6.header/index';
import DefaultInput1 from '../../../../package/parts/1-input';
import ErrorMessage from '../../../../package/parts/error';

const MemoriiWorksEditRecruit = (props) => {

  const history = useHistory();
  const [ errorMessage, setErrorMessage ] = useState('');

  // 雰囲気
  const [ pentagonTreatment1, setPentagonTreatment1 ] = useState(1);
  const [ pentagonTreatment2, setPentagonTreatment2 ] = useState(1);
  const [ pentagonTreatment3, setPentagonTreatment3 ] = useState(1);
  const [ pentagonTreatment4, setPentagonTreatment4 ] = useState(1);
  const [ pentagonTreatment5, setPentagonTreatment5 ] = useState(1);
  const [ pentagonTreatment6, setPentagonTreatment6 ] = useState(1);

  // 雰囲気
  const [ pentagonStyle1, setPentagonStyle1 ] = useState(1);
  const [ pentagonStyle2, setPentagonStyle2 ] = useState(1);
  const [ pentagonStyle3, setPentagonStyle3 ] = useState(1);
  const [ pentagonStyle4, setPentagonStyle4 ] = useState(1);
  const [ pentagonStyle5, setPentagonStyle5 ] = useState(1);
  const [ pentagonStyle6, setPentagonStyle6 ] = useState(1);
  const [ pentagonStyleInput, setPentagonStyleInput ] = useState('');
  
  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var store = decodeURIComponent(link.slice(link.indexOf('storeId=') + 8, link.length));

  // const [ statusPentagon, setStatusPentagon ] = useState(false);
  const [ statusPlace, setStatusPlace ] = useState(false);
  const [ statusType, setStatusType ] = useState(false);

  useEffect(() => {

    window.scrollTo(0, 0);
    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {

        firebase.firestore().collection("stores").doc(store)
        .get().then((doc) => {
          
          setPentagonTreatment1(doc.data().pentagonTreatment1 === undefined ? 1 : doc.data().pentagonTreatment1);
          setPentagonTreatment2(doc.data().pentagonTreatment2 === undefined ? 1 : doc.data().pentagonTreatment2);
          setPentagonTreatment3(doc.data().pentagonTreatment3 === undefined ? 1 : doc.data().pentagonTreatment3);
          setPentagonTreatment4(doc.data().pentagonTreatment4 === undefined ? 1 : doc.data().pentagonTreatment4);
          setPentagonTreatment5(doc.data().pentagonTreatment5 === undefined ? 1 : doc.data().pentagonTreatment5);
          setPentagonTreatment6(doc.data().pentagonTreatment6 === undefined ? 1 : doc.data().pentagonTreatment6);
          setPentagonStyleInput(doc.data().pentagonStyleInput === undefined ? '' : doc.data().pentagonStyleInput);

          setPentagonStyle1(doc.data().pentagonStyle1 === undefined ? 1 : doc.data().pentagonStyle1);
          setPentagonStyle2(doc.data().pentagonStyle2 === undefined ? 1 : doc.data().pentagonStyle2);
          setPentagonStyle3(doc.data().pentagonStyle3 === undefined ? 1 : doc.data().pentagonStyle3);
          setPentagonStyle4(doc.data().pentagonStyle4 === undefined ? 1 : doc.data().pentagonStyle4);
          setPentagonStyle5(doc.data().pentagonStyle5 === undefined ? 1 : doc.data().pentagonStyle5);
          setPentagonStyle6(doc.data().pentagonStyle6 === undefined ? 1 : doc.data().pentagonStyle6);

          // setStatusPentagon(doc.data().status_pentagon === undefined ? false : doc.data().status_pentagon);
          setStatusPlace(doc.data().status_place === undefined ? false : doc.data().status_place);
          setStatusType(doc.data().status_type === undefined ? false : doc.data().status_type);

        });
      } else {
        history.push('/company/login')
      }
    })

  }, [history, store])

  function check() {
    window.scrollTo(0, 0);

    if (pentagonTreatment1 === 0) {
      setErrorMessage('カットの点数を記載してください。');
    } else if (pentagonTreatment2 === 0) {
      setErrorMessage('カラーの点数を記載してください。');
    } else if (pentagonTreatment3 === 0) {
      setErrorMessage('パーマの点数を記載してください。');
    } else if (pentagonTreatment4 === 0) {
      setErrorMessage('縮毛矯正の点数を記載してください。');
    } else if (pentagonTreatment5 === 0) {
      setErrorMessage('ヘアメイクを記載してください。');
    } else if (pentagonTreatment6 === 0) {
      setErrorMessage('その他の点数を記載してください。');
    } else if (19 <= (Number(pentagonTreatment1) + Number(pentagonTreatment2) + Number(pentagonTreatment3) + Number(pentagonTreatment4) + Number(pentagonTreatment5) + Number(pentagonTreatment6))) {
      setErrorMessage('得意な施術の合計を18点以下にしてください');
    } else if (pentagonStyle1 === 0) {
      setErrorMessage('かっこいいの点数を記載してください。');
    } else if (pentagonStyle2 === 0) {
      setErrorMessage('トレンドの点数を記載してください。');
    } else if (pentagonStyle3 === 0) {
      setErrorMessage('ナチュラルの点数を記載してください。');
    } else if (pentagonStyle4 === 0) {
      setErrorMessage('クリエイティブの点数を記載してください。');
    } else if (pentagonStyle5 === 0) {
      setErrorMessage('エレガントを記載してください。');
    } else if (pentagonStyleInput === '') {
      setErrorMessage('自由記載を記載してください。');
    } else if (pentagonStyle6 === 0) {
      setErrorMessage('自由記載点数を記載してください。');
    } else if (19 <= (Number(pentagonStyle1) + Number(pentagonStyle2) + Number(pentagonStyle3) + Number(pentagonStyle4) + Number(pentagonStyle5) + Number(pentagonStyle6))) {
      setErrorMessage('施術スタイルの合計を18点以下にしてください');
    } else {
      save(true);
    }

  }

  var hash = [];

  if (Number(pentagonTreatment1) === 4 || Number(pentagonTreatment1) === 5) {
    hash.push("カットが得意");
  }

  if (Number(pentagonTreatment2) === 4 || Number(pentagonTreatment2) === 5) {
    hash.push("カラーが得意");
  }

  if (Number(pentagonTreatment3) === 4 || Number(pentagonTreatment3) === 5) {
    hash.push("パーマが得意");
  }

  if (Number(pentagonTreatment4) === 4 || Number(pentagonTreatment4) === 5) {
    hash.push("縮毛矯正が得意");
  }

  if (Number(pentagonTreatment5) === 4 || Number(pentagonTreatment5) === 5) {
    hash.push("ヘアメイクが得意");
  }

  if (Number(pentagonStyle1) === 4 || Number(pentagonStyle1) === 5) {
    hash.push("かっこいい施術多め");
  }

  if (Number(pentagonStyle2) === 4 || Number(pentagonStyle2) === 5) {
    hash.push("ナチュラルな施術多め");
  }

  if (Number(pentagonStyle3) === 4 || Number(pentagonStyle3) === 5) {
    hash.push("トレンド感のある施術多め");
  }

  if (Number(pentagonStyle4) === 4 || Number(pentagonStyle4) === 5) {
    hash.push("クリエイティブな施術多め");
  }

  if (Number(pentagonStyle5) === 4 || Number(pentagonStyle5) === 5) {
    hash.push("エレガントな施術多め");
  }

  function save(e) {
    firebase.firestore().collection("stores").doc(store)
    .update({
      status: statusPlace && statusType && e,
      status_pentagon: e,

      pentagonTreatment1: Number(pentagonTreatment1),
      pentagonTreatment2: Number(pentagonTreatment2),
      pentagonTreatment3: Number(pentagonTreatment3),
      pentagonTreatment4: Number(pentagonTreatment4),
      pentagonTreatment5: Number(pentagonTreatment5),
      pentagonTreatment6: Number(pentagonTreatment6),

      pentagonStyle1: Number(pentagonStyle1),
      pentagonStyle2: Number(pentagonStyle2),
      pentagonStyle3: Number(pentagonStyle3),
      pentagonStyle4: Number(pentagonStyle4),
      pentagonStyle5: Number(pentagonStyle5),
      pentagonStyle6: Number(pentagonStyle6),
      pentagonStyleInput: pentagonStyleInput,

      pentagonHash: hash,

      last_time: new Date(),
    }).then(() => {
      history.push(`/edit/storeSetting/id=${company}+page=2`);
    });
  }

  const [ backStatus, setBackStatus ] = useState(false);

  return (
    <>

    <Header />

    <div className={Styles.box}>
      <div className={Styles.box_inline}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.contents}>
          <h5 className={Styles.title}>得意な施術（現状：{pentagonTreatment1 + pentagonTreatment2 + pentagonTreatment3 + pentagonTreatment4 + pentagonTreatment5 + pentagonTreatment6}点）</h5>
          
          <div className={Styles.store_contents}>

            <div className={Styles.pentagon_results}>
              <div>
                <h4>{pentagonTreatment1}点</h4>
                <p>カット</p>
              </div>
              <div>
                <h4>{pentagonTreatment2}点</h4>
                <p>カラー</p>
              </div>
              <div>
                <h4>{pentagonTreatment3}点</h4>
                <p>パーマ</p>
              </div>
              <div>
                <h4>{pentagonTreatment4}点</h4>
                <p>縮毛矯正</p>
              </div>
              <div>
                <h4>{pentagonTreatment5}点</h4>
                <p>ヘアメイク</p>
              </div>
              <div>
                <h4>{pentagonTreatment6}点</h4>
                <p>その他</p>
              </div>
            </div>

            <DefaultTitle title={'カット'} required={true} />

            <select className={Styles.select}
              value={pentagonTreatment1} onChange={(e) => setPentagonTreatment1(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1点</option>
              <option value={2}>2点</option>
              <option value={3}>3点</option>
              <option value={4}>4点</option>
              <option value={5}>5点</option>
            </select>
            <p className={Styles.desc}>　</p>

            <DefaultTitle title={'カラー'} required={true} />

            <select className={Styles.select}
              value={pentagonTreatment2} onChange={(e) => setPentagonTreatment2(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1点</option>
              <option value={2}>2点</option>
              <option value={3}>3点</option>
              <option value={4}>4点</option>
              <option value={5}>5点</option>
            </select>
            <p className={Styles.desc}>　</p>

            <DefaultTitle title={'パーマ'} required={true} />

            <select className={Styles.select}
              value={pentagonTreatment3} onChange={(e) => setPentagonTreatment3(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1点</option>
              <option value={2}>2点</option>
              <option value={3}>3点</option>
              <option value={4}>4点</option>
              <option value={5}>5点</option>
            </select>
            <p className={Styles.desc}>　</p>

            <DefaultTitle title={'縮毛矯正'} required={true} />

            <select className={Styles.select}
              value={pentagonTreatment4} onChange={(e) => setPentagonTreatment4(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1点</option>
              <option value={2}>2点</option>
              <option value={3}>3点</option>
              <option value={4}>4点</option>
              <option value={5}>5点</option>
            </select>
            <p className={Styles.desc}>　</p>

            <DefaultTitle title={'ヘアメイク'} required={true} />

            <select className={Styles.select}
              value={pentagonTreatment5} onChange={(e) => setPentagonTreatment5(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1点</option>
              <option value={2}>2点</option>
              <option value={3}>3点</option>
              <option value={4}>4点</option>
              <option value={5}>5点</option>
            </select>
            <p className={Styles.desc}>　</p>

            <DefaultTitle title={'その他'} required={true} />

            <select className={Styles.select}
              value={pentagonTreatment6} onChange={(e) => setPentagonTreatment6(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1点</option>
              <option value={2}>2点</option>
              <option value={3}>3点</option>
              <option value={4}>4点</option>
              <option value={5}>5点</option>
            </select>
            <p className={Styles.desc}>　</p>

          </div>

        </div>

        <div className={Styles.contents}>
          <h5 className={Styles.title}>得意なスタイル（現状：{pentagonStyle1 + pentagonStyle2 + pentagonStyle3 + pentagonStyle4 + pentagonStyle5 + pentagonStyle6}点）</h5>
          
          <div className={Styles.store_contents}>

            <div className={Styles.pentagon_results}>
              <div>
                <h4>{pentagonStyle1}点</h4>
                <p>かっこいい</p>
              </div>
              <div>
                <h4>{pentagonStyle2}点</h4>
                <p>トレンド</p>
              </div>
              <div>
                <h4>{pentagonStyle3}点</h4>
                <p>ナチュラル</p>
              </div>
              <div>
                <h4>{pentagonStyle4}点</h4>
                <p>クリエイティブ</p>
              </div>
              <div>
                <h4>{pentagonStyle5}点</h4>
                <p>エレガント</p>
              </div>
              <div>
                <h4>{pentagonStyle6}点</h4>
                <p>{pentagonStyleInput === '' ? '自由記載' : pentagonStyleInput}</p>
              </div>
            </div>

            <DefaultTitle title={'かっこいい'} required={true} />

            <select className={Styles.select}
              value={pentagonStyle1} onChange={(e) => setPentagonStyle1(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1点</option>
              <option value={2}>2点</option>
              <option value={3}>3点</option>
              <option value={4}>4点</option>
              <option value={5}>5点</option>
            </select>
            <p className={Styles.desc}>　</p>

            <DefaultTitle title={'トレンド'} required={true} />

            <select className={Styles.select}
              value={pentagonStyle2} onChange={(e) => setPentagonStyle2(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1点</option>
              <option value={2}>2点</option>
              <option value={3}>3点</option>
              <option value={4}>4点</option>
              <option value={5}>5点</option>
            </select>
            <p className={Styles.desc}>　</p>

            <DefaultTitle title={'ナチュラル'} required={true} />

            <select className={Styles.select}
              value={pentagonStyle3} onChange={(e) => setPentagonStyle3(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1点</option>
              <option value={2}>2点</option>
              <option value={3}>3点</option>
              <option value={4}>4点</option>
              <option value={5}>5点</option>
            </select>
            <p className={Styles.desc}>　</p>

            <DefaultTitle title={'クリエイティブ'} required={true} />

            <select className={Styles.select}
              value={pentagonStyle4} onChange={(e) => setPentagonStyle4(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1点</option>
              <option value={2}>2点</option>
              <option value={3}>3点</option>
              <option value={4}>4点</option>
              <option value={5}>5点</option>
            </select>
            <p className={Styles.desc}>　</p>

            <DefaultTitle title={'エレガント'} required={true} />

            <select className={Styles.select}
              value={pentagonStyle5} onChange={(e) => setPentagonStyle5(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1点</option>
              <option value={2}>2点</option>
              <option value={3}>3点</option>
              <option value={4}>4点</option>
              <option value={5}>5点</option>
            </select>
            <p className={Styles.desc}>　</p>

            <DefaultInput1
              title={'自由記載'} required={true} length={8} place={''} type={'text'}
              value={pentagonStyleInput} setValue={setPentagonStyleInput}
              desc={''}
            />

            <select className={Styles.select}
              value={pentagonStyle6} onChange={(e) => setPentagonStyle6(Number(e.target.value))}>
              <option value={0}>未選択</option>
              <option value={1}>1点</option>
              <option value={2}>2点</option>
              <option value={3}>3点</option>
              <option value={4}>4点</option>
              <option value={5}>5点</option>
            </select>
            <p className={Styles.desc}>　</p>

          </div>

        </div>

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check()}>保存</button>
        </div>
        
      </div>
    </div>

    {backStatus ?
      <BackModal
        companyId={company}
        recruitId={store}
        setBackStatus={setBackStatus}
      />
    : <></> }
    </>
  );
}

export default MemoriiWorksEditRecruit;
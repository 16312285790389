import Styles from './styles.module.css';
import Details from './../../package/info/index';
import { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

const WorksCalenderMobile = (props) => {

  const [ todayTask, setTodayTask ] = useState([]);

  // 右のタブ系
  const [ recruitId, setRecruitId ] = useState('');
  const [ applicantId, setApplicantId ] = useState('');

  useEffect(() => {
    // var today = new Date();
    // var day = today.getDay();

    getCalendar();
  }, []);

  function getCalendar() {

    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    var tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);

    const docRef = collection(firebase.firestore(), 'applicants')
    const queryRef = query(docRef, where('companyId', '==', props.companyList.id))
    const unsub = onSnapshot(queryRef, snapshot => {

      var TodayTmp = [];

      snapshot.docs.forEach(doc => {

        var dataTime1Before = new Date(doc.data().examination1_schedule[0].seconds * 1000);
        var dataTime1After = new Date(doc.data().examination1_schedule[1].seconds * 1000);
        var dataTime2Before = new Date(doc.data().examination2_schedule[0].seconds * 1000);
        var dataTime2After = new Date(doc.data().examination2_schedule[1].seconds * 1000);
        var dataTime3Before = new Date(doc.data().examination3_schedule[0].seconds * 1000);
        var dataTime3After = new Date(doc.data().examination3_schedule[1].seconds * 1000);
        var dataTime4Before = new Date(doc.data().examination4_schedule[0].seconds * 1000);
        var dataTime4After = new Date(doc.data().examination4_schedule[1].seconds * 1000);
        var dataTime5Before = new Date(doc.data().examination5_schedule[0].seconds * 1000);
        var dataTime5After = new Date(doc.data().examination5_schedule[1].seconds * 1000);

        // 本日のタスク
        if (today <= dataTime1Before && dataTime1Before <= tomorrow) {
          TodayTmp.push([ dataTime1Before, dataTime1After, ((dataTime1After - dataTime1Before) / (1000 * 60) / 15), `1次選考：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (today <= dataTime2Before && dataTime2Before <= tomorrow) {
          TodayTmp.push([ dataTime2Before, dataTime2After, ((dataTime2After - dataTime2Before) / (1000 * 60) / 15), `2次選考：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (today <= dataTime3Before && dataTime3Before <= tomorrow) {
          TodayTmp.push([ dataTime3Before, dataTime3After, ((dataTime3After - dataTime3Before) / (1000 * 60) / 15), `3次選考：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (today <= dataTime4Before && dataTime4Before <= tomorrow) {
          TodayTmp.push([ dataTime4Before, dataTime4After, ((dataTime4After - dataTime4Before) / (1000 * 60) / 15), `4次選考：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }

        if (today <= dataTime5Before && dataTime5Before <= tomorrow) {
          TodayTmp.push([ dataTime5Before, dataTime5After, ((dataTime5After - dataTime5Before) / (1000 * 60) / 15), `5次選考：${doc.data().name}`, doc.data().recruitId, doc.id, ]);
        }
      })

      TodayTmp.sort( (x, y) => (x[0]) - (y[0]), )
      getTodayArray(TodayTmp);
    })
    return () => {
      unsub();
    };
  }

  function getTodayArray(data) {
    data.sort((a, b) =>  Date.parse(a[0]) - Date.parse(b[0]));
    setTodayTask(data);
  }

  return (
    <>
    <div className='works_service_mobile'>
      <div className={Styles.contents}>

        <div className={Styles.contents_title}>
          <h4>本日のタスク</h4>
        </div>

        <div className={Styles.contents_task}>
          {todayTask.map((data, i) =>
          <div onClick={() => {setRecruitId(data[4]); setApplicantId(data[5]);}}>
            <p>{data[0].getHours()}時{data[0].getMinutes() == 0 ? '00' : data[0].getMinutes()}分 ~ {data[1].getHours()}時{data[1].getMinutes() == 0 ? '00' : data[1].getMinutes()}分</p>
            <h4>{data[3]}</h4>
          </div> )}
        </div>
      </div>
    </div>
    
    {recruitId !== '' && applicantId !== '' && props.userList.length !== 0 && props.companyList.length !== 0 ?
      <Details recruitId={recruitId} applicantId={applicantId} setRecruitId={setRecruitId} setApplicantId={setApplicantId}
        userList={props.userList} companyList={props.companyList}
      />
    : <></> }

    {/* {recruitId !== '' && applicantId !== '' ?
      <Details recruitId={recruitId} applicantId={applicantId} setRecruitId={setRecruitId} setApplicantId={setApplicantId} userList={props.userList} navigation={props.navigation} setHistory={props.setHistory} />
    : <></> } */}

    </>
  );
}

export default WorksCalenderMobile;




import { Splide, SplideSlide } from "@splidejs/react-splide";
import Styles from "./styles.module.css";
import '@splidejs/splide/css';

const Slider = (props) => {

  return (
    <>
      <Splide
        aria-label="私のお気に入りの画像集"
        options={{
          perPage: 1,
          // autoplay: true,
          interval: 3000,
        }}
      >
        {1 <= props.imageName.length ?
          <SplideSlide>
            <img className={Styles.slideImg} src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/recruitImage${props.number}%2Fresize_images%2F${props.imageName[0]}_1080x1080?alt=media&token=`}
              alt="美容室 求人 memorii works" />
          </SplideSlide>
        : <></> }
        {2 <= props.imageName.length ?
          <SplideSlide>
            <img className={Styles.slideImg} src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/recruitImage${props.number}%2Fresize_images%2F${props.imageName[1]}_1080x1080?alt=media&token=`}
              alt="美容室 求人 memorii works" />
          </SplideSlide>
        : <></> }
        {3 <= props.imageName.length ?
          <SplideSlide>
            <img className={Styles.slideImg} src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/recruitImage${props.number}%2Fresize_images%2F${props.imageName[2]}_1080x1080?alt=media&token=`}
              alt="美容室 求人 memorii works" />
          </SplideSlide>
        : <></> }
      </Splide>
    </>
  );
}

export default Slider;

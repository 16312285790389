import Styles from './styles.module.css';
import React from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../../6.header/index';
import firebase from "firebase/compat/app"
import ErrorMessage from '../../../../package/parts/error';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import DefaultInput1 from '../../../../package/parts/1-input';
import DefaultTextarea1 from '../../../../package/parts/1-textarea';
import DefaultImage from './../../../../../../image/works/wait.png';
import BackModal from '../../11.back/index';
import DefaultTitle from '../../../../package/parts/title';

const EditStore = () => {
  
  const history = useHistory();

  // リンク
  var link = new URL(window.location).href;
  var company = decodeURIComponent(link.slice(link.indexOf('id=') + 3, link.indexOf('+page')));
  var recruit = decodeURIComponent(link.slice(link.indexOf('recruitId=') + 10, link.length));

  var time = new Date();

  const [ errorMessage, setErrorMessage ] = useState('');

  const [ interviewCount, setiInterviewCount ] = useState('');

  const [ interview1Name, setInterview1Name ] = useState('');
  const [ interview1School, setInterview1School ] = useState('');
  const [ interview1Title, setInterview1Title ] = useState('');
  const [ interview1Desc, setInterview1Desc ] = useState('');
  const [ interview1Image, setInterview1Image ] = useState(false);

  const [ interview2Name, setInterview2Name ] = useState('');
  const [ interview2School, setInterview2School ] = useState('');
  const [ interview2Title, setInterview2Title ] = useState('');
  const [ interview2Desc, setInterview2Desc ] = useState('');
  const [ interview2Image, setInterview2Image ] = useState(false);

  const [ interview3Name, setInterview3Name ] = useState('');
  const [ interview3School, setInterview3School ] = useState('');
  const [ interview3Title, setInterview3Title ] = useState('');
  const [ interview3Desc, setInterview3Desc ] = useState('');
  const [ interview3Image, setInterview3Image ] = useState(false);

  const [ interview4Name, setInterview4Name ] = useState('');
  const [ interview4School, setInterview4School ] = useState('');
  const [ interview4Title, setInterview4Title ] = useState('');
  const [ interview4Desc, setInterview4Desc ] = useState('');
  const [ interview4Image, setInterview4Image ] = useState(false);

  const [ interview5Name, setInterview5Name ] = useState('');
  const [ interview5School, setInterview5School ] = useState('');
  const [ interview5Title, setInterview5Title ] = useState('');
  const [ interview5Desc, setInterview5Desc ] = useState('');
  const [ interview5Image, setInterview5Image ] = useState(false);

  const [ interview6Name, setInterview6Name ] = useState('');
  const [ interview6School, setInterview6School ] = useState('');
  const [ interview6Title, setInterview6Title ] = useState('');
  const [ interview6Desc, setInterview6Desc ] = useState('');
  const [ interview6Image, setInterview6Image ] = useState(false);

  // 戻るボタン
  const [ backStatus, setBackStatus ] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    return onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        if (company !== '' && recruit !== '') {
          firebase.firestore().collection("recruits").doc(recruit).get()
          .then((doc) => {
            setiInterviewCount(doc.data().recruit_interviewCount === undefined ? '' : doc.data().recruit_interviewCount);

            setInterview1Name(doc.data().recruit_interview1Name === undefined ? '' : doc.data().recruit_interview1Name);
            setInterview1School(doc.data().recruit_interview1School === undefined ? '' : doc.data().recruit_interview1School);
            setInterview1Title(doc.data().recruit_interview1Title === undefined ? '' : doc.data().recruit_interview1Title);
            setInterview1Desc(doc.data().recruit_interview1Desc === undefined ? '' : doc.data().recruit_interview1Desc);
            setInterview1Image(doc.data().recruit_interview1Image === undefined ? false : doc.data().recruit_interview1Image);

            setInterview2Name(doc.data().recruit_interview2Name === undefined ? '' : doc.data().recruit_interview2Name);
            setInterview2School(doc.data().recruit_interview2School === undefined ? '' : doc.data().recruit_interview2School);
            setInterview2Title(doc.data().recruit_interview2Title === undefined ? '' : doc.data().recruit_interview2Title);
            setInterview2Desc(doc.data().recruit_interview2Desc === undefined ? '' : doc.data().recruit_interview2Desc);
            setInterview2Image(doc.data().recruit_interview2Image === undefined ? false : doc.data().recruit_interview2Image);

            setInterview3Name(doc.data().recruit_interview3Name === undefined ? '' : doc.data().recruit_interview3Name);
            setInterview3School(doc.data().recruit_interview3School === undefined ? '' : doc.data().recruit_interview3School);
            setInterview3Image(doc.data().recruit_interview3Image === undefined ? false : doc.data().recruit_interview3Image);
            setInterview3Title(doc.data().recruit_interview3Title === undefined ? '' : doc.data().recruit_interview3Title);
            setInterview3Desc(doc.data().recruit_interview3Desc === undefined ? '' : doc.data().recruit_interview3Desc);

            setInterview4Name(doc.data().recruit_interview4Name === undefined ? '' : doc.data().recruit_interview4Name);
            setInterview4School(doc.data().recruit_interview4School === undefined ? '' : doc.data().recruit_interview4School);
            setInterview4Image(doc.data().recruit_interview4Image === undefined ? false : doc.data().recruit_interview4Image);
            setInterview4Title(doc.data().recruit_interview4Title === undefined ? '' : doc.data().recruit_interview4Title);
            setInterview4Desc(doc.data().recruit_interview4Desc === undefined ? '' : doc.data().recruit_interview4Desc);

            setInterview5Name(doc.data().recruit_interview5Name === undefined ? '' : doc.data().recruit_interview5Name);
            setInterview5School(doc.data().recruit_interview5School === undefined ? '' : doc.data().recruit_interview5School);
            setInterview5Image(doc.data().recruit_interview5Image === undefined ? false : doc.data().recruit_interview5Image);
            setInterview5Title(doc.data().recruit_interview5Title === undefined ? '' : doc.data().recruit_interview5Title);
            setInterview5Desc(doc.data().recruit_interview5Desc === undefined ? '' : doc.data().recruit_interview5Desc);

            setInterview6Name(doc.data().recruit_interview6Name === undefined ? '' : doc.data().recruit_interview6Name);
            setInterview6School(doc.data().recruit_interview6School === undefined ? '' : doc.data().recruit_interview6School);
            setInterview6Image(doc.data().recruit_interview6Image === undefined ? false : doc.data().recruit_interview6Image);
            setInterview6Title(doc.data().recruit_interview6Title === undefined ? '' : doc.data().recruit_interview6Title);
            setInterview6Desc(doc.data().recruit_interview6Desc === undefined ? '' : doc.data().recruit_interview6Desc);
          });
        }
      } else {
        history.push('/company/login')
      }
    })
  }, [history, company, recruit]);

  function check1(v) {
    window.scrollTo(0, 0);

    if (1 <= Number(interviewCount)) {
      if (!interview1Image && Image1File === '') {
        setErrorMessage('No.1 インタビューの写真を添付してください。');
      } else if (interview1Name === '') {
        setErrorMessage('No.1 インタビューの氏名を入力してください。');
      } else if (interview1Title === '') {
        setErrorMessage('No.1 インタビューの役職を入力してください。');
      } else if (interview1Desc === '') {
        setErrorMessage('No.1 インタビューの詳細を入力してください。');
      } else {
        check2(v);
      }
    } else {
      save(v);
    }
  }

  function check2(v) {
    window.scrollTo(0, 0);

    if (2 <= Number(interviewCount)) {
      if (!interview2Image && Image2File === '') {
        setErrorMessage('No.2 インタビューの写真を添付してください。');
      } else if (interview2Name === '') {
        setErrorMessage('No.2 インタビューの氏名を入力してください。');
      } else if (interview2Title === '') {
        setErrorMessage('No.2 インタビューの役職を入力してください。');
      } else if (interview2Desc === '') {
        setErrorMessage('No.2 インタビューの詳細を入力してください。');
      } else {
        check3(v);
      }
    } else {
      save(v);
    }
  }

  function check3(v) {
    window.scrollTo(0, 0);

    if (3 <= Number(interviewCount)) {
      if (!interview3Image && Image3File === '') {
        setErrorMessage('No.3 インタビューの写真を添付してください。');
      } else if (interview3Name === '') {
        setErrorMessage('No.3 インタビューの氏名を入力してください。');
      } else if (interview3Title === '') {
        setErrorMessage('No.3 インタビューの役職を入力してください。');
      } else if (interview3Desc === '') {
        setErrorMessage('No.3 インタビューの詳細を入力してください。');
      } else {
        check4(v);
      }
    } else {
      save(v);
    }
  }

  function check4(v) {
    window.scrollTo(0, 0);

    if (4 <= Number(interviewCount)) {
      if (!interview4Image && Image4File === '') {
        setErrorMessage('No.4 インタビューの写真を添付してください。');
      } else if (interview4Name === '') {
        setErrorMessage('No.4 インタビューの氏名を入力してください。');
      } else if (interview4Title === '') {
        setErrorMessage('No.4 インタビューの役職を入力してください。');
      } else if (interview4Desc === '') {
        setErrorMessage('No.4 インタビューの詳細を入力してください。');
      } else {
        check5(v);
      }
    } else {
      save(v);
    }
  }

  function check5(v) {
    window.scrollTo(0, 0);

    if (5 <= Number(interviewCount)) {
      if (!interview5Image && Image5File === '') {
        setErrorMessage('No.5 インタビューの写真を添付してください。');
        } else if (interview5Name === '') {
        setErrorMessage('No.5 インタビューの氏名を入力してください。');
      } else if (interview5Title === '') {
        setErrorMessage('No.5 インタビューの役職を入力してください。');
      } else if (interview5Desc === '') {
        setErrorMessage('No.5 インタビューの詳細を入力してください。');
      } else {
        check6(v);
      }
    } else {
      save(v);
    }
  }

  function check6(v) {
    window.scrollTo(0, 0);

    if (6 <= Number(interviewCount)) {
      if (!interview1Image && Image1File === '') {
        setErrorMessage('No.6 インタビューの写真を添付してください。');
      } else if (interview6Name === '') {
        setErrorMessage('No.6 インタビューの氏名を入力してください。');
      } else if (interview1Title === '') {
        setErrorMessage('No.6 インタビューの役職を入力してください。');
      } else if (interview1Desc === '') {
        setErrorMessage('No.6 インタビューの詳細を入力してください。');
      } else {
        save(v);
      }
    } else {
      save(v);
    }
  }

  async function save(e) {

    firebase.firestore().collection("recruits").doc(recruit)
    .update({
      status_interview: e,

      recruit_interviewCount: interviewCount,

      recruit_interview1Name: interview1Name,
      recruit_interview1Title: interview1Title,
      recruit_interview1Desc: interview1Desc,
      recruit_interview1Image: Image1File !== '' || interview1Image ? true : false, 

      recruit_interview2Name: interview2Name,
      recruit_interview2Title: interview2Title,
      recruit_interview2Desc: interview2Desc,
      recruit_interview2Image: Image2File !== '' || interview2Image ? true : false,

      recruit_interview3Name: interview3Name,
      recruit_interview3Title: interview3Title,
      recruit_interview3Desc: interview3Desc,
      recruit_interview3Image: Image3File !== '' || interview3Image ? true : false,

      recruit_interview4Name: interview4Name,
      recruit_interview4Title: interview4Title,
      recruit_interview4Desc: interview4Desc,
      recruit_interview4Image: Image4File !== '' || interview3Image ? true : false,

      recruit_interview5Name: interview5Name,
      recruit_interview5Title: interview5Title,
      recruit_interview5Desc: interview5Desc,
      recruit_interview5Image: Image5File !== '' || interview5Image ? true : false,

      recruit_interview6Name: interview6Name,
      recruit_interview6Title: interview6Title,
      recruit_interview6Desc: interview6Desc,
      recruit_interview6Image: Image6File !== '' || interview6Image ? true : false,

      last_time: new Date(),
    }).then(() => {
      history.push(`/edit/id=${company}+page=2+recruitId=${recruit}`);
    });

    if (Image1FilePath !== '') {
      firebase.storage().ref().child(`/interview/${recruit}_1`).put(Image1FilePath);
    }

    if (Image2FilePath !== '') {
      firebase.storage().ref().child(`/interview/${recruit}_2`).put(Image2FilePath);
    }

    if (Image3FilePath !== '') {
      firebase.storage().ref().child(`/interview/${recruit}_3`).put(Image3FilePath);
    }

    if (Image4FilePath !== '') {
      firebase.storage().ref().child(`/interview/${recruit}_4`).put(Image4FilePath);
    }

    if (Image5FilePath !== '') {
      firebase.storage().ref().child(`/interview/${recruit}_5`).put(Image5FilePath);
    }

    if (Image6FilePath !== '') {
      firebase.storage().ref().child(`/interview/${recruit}_6`).put(Image6FilePath);
    }

  }

  const [ Image1File, setImage1File ] = useState('');
  const [ Image1FilePath, setImage1FilePath ] = useState('');

  const Images1 = e => {
    if (e.target.files[0]) {
      setImage1File(URL.createObjectURL(e.target.files[0]));
      setImage1FilePath(e.target.files[0]);
    }
  }

  const [ Image2File, setImage2File ] = useState('');
  const [ Image2FilePath, setImage2FilePath ] = useState('');

  const Images2 = e => {
    if (e.target.files[0]) {
      setImage2File(URL.createObjectURL(e.target.files[0]));
      setImage2FilePath(e.target.files[0]);
    }
  }

  const [ Image3File, setImage3File ] = useState('');
  const [ Image3FilePath, setImage3FilePath ] = useState('');

  const Images3 = e => {
    if (e.target.files[0]) {
      setImage3File(URL.createObjectURL(e.target.files[0]));
      setImage3FilePath(e.target.files[0]);
    }
  }

  const [ Image4File, setImage4File ] = useState('');
  const [ Image4FilePath, setImage4FilePath ] = useState('');

  const Images4 = e => {
    if (e.target.files[0]) {
      setImage4File(URL.createObjectURL(e.target.files[0]));
      setImage4FilePath(e.target.files[0]);
    }
  }

  const [ Image5File, setImage5File ] = useState('');
  const [ Image5FilePath, setImage5FilePath ] = useState('');

  const Images5 = e => {
    if (e.target.files[0]) {
      setImage5File(URL.createObjectURL(e.target.files[0]));
      setImage5FilePath(e.target.files[0]);
    }
  }

  const [ Image6File, setImage6File ] = useState('');
  const [ Image6FilePath, setImage6FilePath ] = useState('');

  const Images6 = e => {
    if (e.target.files[0]) {
      setImage6File(URL.createObjectURL(e.target.files[0]));
      setImage6FilePath(e.target.files[0]);
    }
  }
  
  return (
    <>
    <Header />

    <div className={Styles.store}>
      <div className={Styles.store_box}>

        {errorMessage !== '' ?
          <ErrorMessage message={errorMessage} />
        : <></> }

        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>記載数</h5>

          <DefaultTitle title={'記載数'} required={true} />
          <select className={Styles.select}
            value={interviewCount} onChange={(e) => setiInterviewCount(Number(e.target.value))}>
            <option value={0}>未選択</option>
            <option value={2}>2つ</option>
            <option value={4}>4つ</option>
            <option value={6}>6つ</option>
          </select>
          <p className={Styles.desc}>　</p>

        </div>

        {1 <= Number(interviewCount) && Number(interviewCount) <= 6 ?
        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.1 インタビュー</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>従業員写真</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            <div class={Styles.store_input_btn}>
              <label>
                写真添付
                <input type="file" onChange={Images1} />
              </label>
            </div>
          </div>

          <div className={Styles.image_prev}>
            {Image1File !== '' ?
              <img className={Styles.make_image_file} src={Image1File} alt='' />
            : interview1Image ?
              <img className={Styles.make_image_file} alt=''
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruit}_1_500x500?alt=media&token=?${new Date().getTime()}`} 
                onError={e => {
                  e.target.onError = null;
                  e.target.src = DefaultImage;
                }}/>
            : 
              <p className={Styles.desc}>写真が添付されていません。</p>
            }
          </div>

          <DefaultInput1
            title={'氏名'} required={true} length={20} place={''} type={'text'}
            value={interview1Name} setValue={setInterview1Name}
            desc={''}
          />

          <DefaultInput1
            title={'役職'} required={true} length={20} place={''} type={'text'}
            value={interview1Title} setValue={setInterview1Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={interview1Desc} setValue={setInterview1Desc}
            desc={''}
          />

        </div> : <></> }

        {2 <= Number(interviewCount) && Number(interviewCount) <= 6 ?
        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.2 インタビュー</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>従業員写真</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            <div class={Styles.store_input_btn}>
              <label>
                写真添付
                <input type="file" onChange={Images2} />
              </label>
            </div>
          </div>

          <div className={Styles.image_prev}>
            {Image2File !== '' ?
              <img className={Styles.make_image_file} src={Image2File} alt='' />
            : interview2Image ?
              <img className={Styles.make_image_file} alt=''
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruit}_2_500x500?alt=media&token=?${new Date().getTime()}`} 
                onError={e => {
                  e.target.onError = null;
                  e.target.src = DefaultImage;
                }}/>
            : 
              <p className={Styles.desc}>写真が添付されていません。</p>
            }
          </div>

          <DefaultInput1
            title={'氏名'} required={true} length={20} place={''} type={'text'}
            value={interview2Name} setValue={setInterview2Name}
            desc={''}
          />

          <DefaultInput1
            title={'役職'} required={true} length={20} place={''} type={'text'}
            value={interview2Title} setValue={setInterview2Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={interview2Desc} setValue={setInterview2Desc}
            desc={''}
          />

        </div> : <></> }

        {3 <= Number(interviewCount) && Number(interviewCount) <= 6 ?
        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.3 インタビュー</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>従業員写真</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            <div class={Styles.store_input_btn}>
              <label>
                写真添付
                <input type="file" onChange={Images3} />
              </label>
            </div>
          </div>

          <div className={Styles.image_prev}>
            {Image3File !== '' ?
              <img className={Styles.make_image_file} src={Image3File} alt='' />
            : interview3Image ?
              <img className={Styles.make_image_file} alt=''
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruit}_3_500x500?alt=media&token=?${new Date().getTime()}`} 
                onError={e => {
                  e.target.onError = null;
                  e.target.src = DefaultImage;
                }}/>
            : 
              <p className={Styles.desc}>写真が添付されていません。</p>
            }
          </div>

          <DefaultInput1
            title={'氏名'} required={true} length={20} place={''} type={'text'}
            value={interview3Name} setValue={setInterview3Name}
            desc={''}
          />

          <DefaultInput1
            title={'役職'} required={true} length={20} place={''} type={'text'}
            value={interview3Title} setValue={setInterview3Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={interview3Desc} setValue={setInterview3Desc}
            desc={''}
          />

        </div> : <></> }

        {4 <= Number(interviewCount) && Number(interviewCount) <= 6 ?
        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.4 インタビュー</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>従業員写真</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            <div class={Styles.store_input_btn}>
              <label>
                写真添付
                <input type="file" onChange={Images4} />
              </label>
            </div>
          </div>

          <div className={Styles.image_prev}>
            {Image4File !== '' ?
              <img className={Styles.make_image_file} src={Image4File} alt='' />
            : interview4Image ?
              <img className={Styles.make_image_file} alt=''
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruit}_4_500x500?alt=media&token=?${new Date().getTime()}`} 
                onError={e => {
                  e.target.onError = null;
                  e.target.src = DefaultImage;
                }}/>
            : 
              <p className={Styles.desc}>写真が添付されていません。</p>
            }
          </div>

          <DefaultInput1
            title={'氏名'} required={true} length={20} place={''} type={'text'}
            value={interview4Name} setValue={setInterview4Name}
            desc={''}
          />

          <DefaultInput1
            title={'役職'} required={true} length={20} place={''} type={'text'}
            value={interview4Title} setValue={setInterview4Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={interview4Desc} setValue={setInterview4Desc}
            desc={''}
          />

        </div> : <></> }

        {5 <= Number(interviewCount) && Number(interviewCount) <= 6 ?
        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.5 インタビュー</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>従業員写真</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            <div class={Styles.store_input_btn}>
              <label>
                写真添付
                <input type="file" onChange={Images5} />
              </label>
            </div>
          </div>

          <div className={Styles.image_prev}>
            {Image5File !== '' ?
              <img className={Styles.make_image_file} src={Image5File} alt='' />
            : interview5Image ?
              <img className={Styles.make_image_file} alt=''
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruit}_5_500x500?alt=media&token=?${new Date().getTime()}`} 
                onError={e => {
                  e.target.onError = null;
                  e.target.src = DefaultImage;
                }}/>
            : 
              <p className={Styles.desc}>写真が添付されていません。</p>
            }
          </div>

          <DefaultInput1
            title={'氏名'} required={true} length={20} place={''} type={'text'}
            value={interview5Name} setValue={setInterview5Name}
            desc={''}
          />

          <DefaultInput1
            title={'役職'} required={true} length={20} place={''} type={'text'}
            value={interview5Title} setValue={setInterview5Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={interview5Desc} setValue={setInterview5Desc}
            desc={''}
          />

        </div> : <></> }

        {6 <= Number(interviewCount) && Number(interviewCount) <= 6 ?
        <div className={Styles.store_contents}>

          <h5 className={Styles.store_title}>No.6 インタビュー</h5>

          <div className={Styles.store_input_photo}>
            <div className={Styles.store_input_photo_title}>
              <p>従業員写真</p>
              <div className={Styles.store_input_photo_yes}>※必須</div>
            </div>

            <div class={Styles.store_input_btn}>
              <label>
                写真添付
                <input type="file" onChange={Images6} />
              </label>
            </div>
          </div>

          <div className={Styles.image_prev}>
            {Image6File !== '' ?
              <img className={Styles.make_image_file} src={Image6File} alt='' />
            : interview6Image ?
              <img className={Styles.make_image_file} alt=''
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/interview%2Fresize_images%2F${recruit}_6_500x500?alt=media&token=?${new Date().getTime()}`} 
                onError={e => {
                  e.target.onError = null;
                  e.target.src = DefaultImage;
                }}/>
            : 
              <p className={Styles.desc}>写真が添付されていません。</p>
            }
          </div>

          <DefaultInput1
            title={'氏名'} required={true} length={20} place={''} type={'text'}
            value={interview6Name} setValue={setInterview6Name}
            desc={''}
          />

          <DefaultInput1
            title={'役職'} required={true} length={20} place={''} type={'text'}
            value={interview6Title} setValue={setInterview6Title}
            desc={''}
          />

          <DefaultTextarea1
            title={'詳細'} required={true} length={100} type={'text'}
            place={''}
            value={interview6Desc} setValue={setInterview6Desc}
            desc={''}
          />

        </div> : <></> }

        <div className={Styles.save_box}>
          <button className={Styles.save_box_now} onClick={() => setBackStatus(true)}>戻る</button>
          <button className={Styles.save_box_now} onClick={() => save(false)}>一時保存</button>
          <button className={Styles.save_box_save} onClick={() => check1(true)}>保存</button>
        </div>

      </div>
    </div>

    {backStatus ?
      <BackModal
        companyId={company}
        recruitId={recruit}
        setBackStatus={setBackStatus}
      />
    : <></> }

    </>
  );
}

export default EditStore;

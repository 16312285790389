import Styles from './styles.module.css';
import React, { useState } from 'react';
import DefaultTitle from '../../package/parts/title';
import firebase from 'firebase/compat/app';
import DefaultImage from './../../../../image/works/default/account.png';
import { FaPen } from "react-icons/fa";

const WorksAccountEdit = (props) => {

  const [ name, setName ] = useState(props.userList.user_name);
  const [ errorName, setErrorName ] = useState(false);

  const [ styleImageFile, setStyleImageFile ] = useState('');
  const [ styleImageFilePath, setStyleImageFilePath ] = useState('');

  const styleImages = e => {
    if (e.target.files[0]) {
      setStyleImageFile(URL.createObjectURL(e.target.files[0]));
      setStyleImageFilePath(e.target.files[0]);
    }
  }

  function save() {
    firebase.firestore().collection('users').doc(props.userList.id)
    .update({
      user_name: name,
    });
    firebase.storage().ref().child(`/profiles/${props.userList.id}`).put(styleImageFilePath).then(() => props.setAccountEdit(false));
  }

  return (
    <>
    <div className={Styles.box}>
      <div>
        <div className={Styles.box_black} onClick={() => props.setAccountEdit(false)}></div>
        <div className={Styles.box_contents}>

          <div className={Styles.title}>
            <h4>アカウント編集</h4>
            <button onClick={() => name === '' ? setErrorName(true) : save()}>保存</button>
          </div>

          <p className={Styles.desc}>※社内の人にも伝わる設定を行ってください。</p>

          <DefaultTitle title={'プロフィール写真'} required={false} />

          <div className={Styles.make_image}>
            <FaPen className={Styles.make_image_pen} />
            <input type="file" onChange={styleImages} />
            {styleImageFile === '' ?
              <img className={Styles.make_image_file} alt=''
                src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${props.userList.id}_500x500?alt=media&token=?${new Date().getTime()}`} 
                onError={e => {
                  e.target.onError = null;
                  e.target.src = DefaultImage;
                }}/>
            : 
              <img className={Styles.make_image_file} src={styleImageFile} alt='' />
            }
          </div>

          <div className={Styles.title}>
            <div>
              <p>名前</p>
              <div className={Styles.title_yes}>※必須</div>
            </div>
            <p>{name.length} / 20</p>
          </div>
          <input className={Styles.input} placeholder={'メモリー 太郎'} maxLength={20} type='text' 
            value={name} onChange={(e) => setName(e.target.value)} />
          <p className={Styles.desc} style={errorName ? { color : '#AD0200', fontWeight : 'bold' } : {}}>※アカウント名を入力してください。</p>

        </div>
      </div>
    </div>
    </>
  );
}

export default WorksAccountEdit;

import Styles from './styles.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'firebase/auth'
import Items from './items/index';
import firebase from 'firebase/compat/app';
import { useState, useEffect } from 'react';
import { FaSearch } from "react-icons/fa";
import { collection, onSnapshot, query, where } from 'firebase/firestore';

const MemoriiWorksManage = (props) => {

  
  const [ history, setHistory ] = useState(false);

  const [ choiceRecruit, setChoiceRecruit ] = useState(0);

  const [ applicantsList, setApplicantsList ] = useState([]);
  const [ applicantsListTrue, setApplicantsListTrue ] = useState([]);
  const [ applicantsListFalse, setApplicantsListFalse ] = useState([]);
  const [ progressCount, setProgressCount ] = useState([]);

  const [ searchText, setSearchText ] = useState('');
  const [ choiceNavigation, setChoiceNavigation ] = useState(10000);

  const [ selectStore, setSelectStore ] = useState('');

  useEffect(() => {
    getRecruit();
    setChoiceRecruit(1000);
  }, []);

  function getRecruit() {
    if (props.recruitList.length !== 0) {
      // setprops.recruitList(props.recruitList);
    }
    if (props.recruitListOnlyId.length !== 0) {
      getApplicants(props.recruitListOnlyId);
    }
  }

  function getApplicants(ids) {
    const docRef = collection(firebase.firestore(), 'applicants')
    const queryRef = query(docRef, where('recruitId', 'in', ids))
    const unsub = onSnapshot(queryRef, snapshot => {
      var progressCount0 = 0;
      var progressCount1 = 0;
      var progressCount2 = 0;
      var progressCount3 = 0;
      var progressCount4 = 0;
      var progressCount5 = 0;
      var progressCount6 = 0;
      var progressCount7 = 0;

      var tmp = [];
      var tmpTrue = [];
      var tmpFalse = [];
      snapshot.docs.forEach(doc => {
        progressCount0 = progressCount0 + 1;
        if (doc.data().examination === 1) {
          progressCount1 = progressCount1 + 1
        } else if (doc.data().examination === 2) {
          progressCount2 = progressCount2 + 1
        } else if (doc.data().examination === 3) {
          progressCount3 = progressCount3 + 1
        } else if (doc.data().examination === 4) {
          progressCount4 = progressCount4 + 1
        } else if (doc.data().examination === 5) {
          progressCount5 = progressCount5 + 1
        } else if (doc.data().examination === 6) {
          progressCount6 = progressCount6 + 1
        } else if (doc.data().examination === 7) {
          progressCount7 = progressCount7 + 1
        }
        
        tmp.push({...doc.data(), id: doc.id});
        if (doc.data().save.includes(props.userList.user_uid)) {
          tmpTrue.push({...doc.data(), id: doc.id});
        } else {
          tmpFalse.push({...doc.data(), id: doc.id});
        }
      })
      setApplicantsListTrue(tmpTrue);
      setApplicantsListFalse(tmpFalse);
      setProgressCount([
        progressCount0, progressCount1, progressCount2, progressCount3, progressCount4, progressCount5, progressCount6, progressCount7, 
      ]);
    })

    return () => unsub();
  }

  const isAllIncludes = (arr, target) => arr.some(el => target.includes(el));
  
  return (
    <>
    <div className='works_service_mobile'>
      <div className={Styles.box}>
        <div className={Styles.box_header}>
          <div className={Styles.box_header_search}>
            <select className={Styles.box_select_recruit}
              onChange={(e) => {
                if (e.target.value === '')  {
                  setChoiceRecruit(1000); getApplicants(props.recruitListOnlyId); setSelectStore('');
                } else {
                  setChoiceRecruit(Number(e.target.value)); getApplicants([props.recruitList[Number(e.target.value)].id]);
                }
              }}>
              <option value=''>未選択</option>
              {props.recruitList.map((data, index) =>
                <option value={index} key={index}>{data.store_title}</option>
              )}
            </select>
            <div>
              <FaSearch className={Styles.box_header_search_icon} />
              <input placeholder='応募者名 検索' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            </div>
            {choiceRecruit === 1000 ? <></> :
            <>
              {props.recruitList[choiceRecruit] !== undefined && props.recruitList[choiceRecruit].place_storeCount !== 0 ?
              <select value={selectStore} onChange={(e) => setSelectStore(e.target.value)} className={Styles.box_select_store}>
                <option value=''>未選択</option>
                {1 <= props.recruitList[choiceRecruit].place_storeCount && props.recruitList[choiceRecruit].place_storeCount <= 5 ?
                  <option value={props.recruitList[choiceRecruit].place_store1Name}>{props.recruitList[choiceRecruit].place_store1Name}</option>
                : <></> }
                {2 <= props.recruitList[choiceRecruit].place_storeCount && props.recruitList[choiceRecruit].place_storeCount <= 5 ?
                  <option value={props.recruitList[choiceRecruit].place_store2Name}>{props.recruitList[choiceRecruit].place_store2Name}</option>
                : <></> }
                {3 <= props.recruitList[choiceRecruit].place_storeCount && props.recruitList[choiceRecruit].place_storeCount <= 5 ?
                  <option value={props.recruitList[choiceRecruit].place_store3Name}>{props.recruitList[choiceRecruit].place_store3Name}</option>
                : <></> }
                {4 <= props.recruitList[choiceRecruit].place_storeCount && props.recruitList[choiceRecruit].place_storeCount <= 5 ?
                  <option value={props.recruitList[choiceRecruit].place_store4Name}>{props.recruitList[choiceRecruit].place_store4Name}</option>
                : <></> }
                {5 <= props.recruitList[choiceRecruit].place_storeCount && props.recruitList[choiceRecruit].place_storeCount <= 5 ?
                  <option value={props.recruitList[choiceRecruit].place_store5Name}>{props.recruitList[choiceRecruit].store5Name}</option>
                : <></> }
              </select> : <></> }
            </> }
          </div>
          <div className={Styles.table_header}>
            <p className={Styles.table_header_1}>応募者名</p>
            <p className={Styles.table_header_2}>性別</p>
            <p className={Styles.table_header_3}>審査</p>
            <p className={Styles.table_header_4}>保存</p>
          </div>
        </div>

        {props.userList.length !== 0 && props.companyList.length !== 0 ?
        <>
          {applicantsListTrue.map((data, index) =>
          <>
            {data.examination === choiceNavigation ?
            <>
              {isAllIncludes([searchText], data.name + data.managerMemo) ?
              <>
                {selectStore === '' ?
                  <Items data={data} save={true} companyList={props.companyList} userList={props.userList} navigation={choiceNavigation} />
                : data.hopeStore !== undefined && selectStore === data.hopeStore[0] ?
                  <Items data={data} save={true} companyList={props.companyList} userList={props.userList} navigation={choiceNavigation} />
                : <></> }
              </> : <></> }
            </> : <></> }
            
            {choiceNavigation === 10000 ?
            <>
              {isAllIncludes([searchText], data.name + data.managerMemo) ?
              <>
                {selectStore === '' ?
                  <Items data={data} save={true} companyList={props.companyList} userList={props.userList} navigation={choiceNavigation} />
                : data.hopeStore !== undefined && selectStore === data.hopeStore[0] ?
                  <Items data={data} save={true} companyList={props.companyList} userList={props.userList} navigation={choiceNavigation} />
                : <></> }
              </> : <></> }
            </> : <></> }
          </> )}
          
          {applicantsListFalse.map((data, index) =>
          <>
            {data.examination === choiceNavigation ?
            <>
              {isAllIncludes([searchText], data.name + data.managerMemo) ?
              <>
                {selectStore === '' ?
                  <Items data={data} save={false} companyList={props.companyList} userList={props.userList} navigation={choiceNavigation} />
                : data.hopeStore !== undefined && selectStore === data.hopeStore[0] ?
                  <Items data={data} save={false} companyList={props.companyList} userList={props.userList} navigation={choiceNavigation} />
                : <></> }
              </> : <></> }
            </> : <></> }
            
            {choiceNavigation === 10000 ?
            <>
              {isAllIncludes([searchText], data.name + data.managerMemo) ?
              <>
                {selectStore === '' ?
                  <Items data={data} save={false} companyList={props.companyList} userList={props.userList} navigation={choiceNavigation} />
                : data.hopeStore !== undefined && selectStore === data.hopeStore[0] ?
                  <Items data={data} save={false} companyList={props.companyList} userList={props.userList} navigation={choiceNavigation} />
                : <></> }
              </> : <></> }
            </> : <></> }
          </> )}
        </> : <></> }
      </div>
    </div>
    </>
  );
}

export default MemoriiWorksManage;
